import { useCallback } from "react";
import { EnumMenu } from "../../../model";
import { useSessaoAtual } from "../../../service/app/providers";
import { ModulosMock } from "../../mocks/menu-mock";

export const useGetMenu = () => {
  const { tipoUsuario } = useSessaoAtual();

  const getMenu = useCallback(
    (isMovAberta: boolean, modulos?: EnumMenu[]) => {
      let modulos_filtered = ModulosMock
        //FILTRO MODULOS QUE PEDIRAM PARA ESCONDER
        .filter((x) => !modulos?.includes(x.codigoPai))
        .filter((x) => x.permissoes.includes(tipoUsuario()))
        //FILTRO MODULOS SEM PERMISSAO
        .sort((firstEl, secondEl) => firstEl.posicao - secondEl.posicao);

      //TIRO OS PAIS SEM FILHOS

      return modulos_filtered;
    },
    [tipoUsuario]
  );

  return { getMenu };
};
