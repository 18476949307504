import { makeStyles } from "@material-ui/core";

export const useLojasStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: '16px 16px 0 16px',
    position: 'relative',
    background: '#fff',
    borderRadius: '16px',
    marginBottom: '16px'
  },
  documentText: {
    color: "#6B6B6B",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    paddingLeft: "9px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      fontWeight: 700,
      color: "#545454",
      paddingLeft: 0,
    },
  },
  nomeLoja: {
    fontSize: "22px",
    fontWeight: 700,
    color: "#545454",
    [theme.breakpoints.down("xs")]: {
      color: "#545454",
      fontSize: "12px",
      fontWeight: "700",
      width: "100%",
      maxWidth: "110px",
    },
  },
  ultimoAcesso: {
    background: "#EFECFF",
    padding: "6px 10px",
    borderRadius: "5px",
  },
  ultimoAcessoText: {
    color: "#5333ED",
    fontWeight: 700,
    textAlign: "center",
  },

  labelStatusPai: {
    flex: 1,
    display: "flex",
    marginTop: "8px",
    justifyContent: "space-between",
  },

  onOff: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '8px 0',
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
      display: "flex",
    },
  },

  button: {
    border: "1px solid #FFFFFF",
    margin: 0,
    boxShadow: "0px 0px 4.6875px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    width: "40px",
    height: "40px",
    [theme.breakpoints.down("xs")]: {
      width: "33px",
      border: "1px solid #FFFFFF",
      height: "30px",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "8px",
      marginLeft: "10px",
    },
  },

  labelDiv: {
    width: "100px",
    borderRadius: "7px",
    padding: "6px 0",
  },

  labelStatusOn: {
    color: "#FFFFFF",
    width: "103px",
    height: "36px",
    margin: "0 23px",
    padding: "3px 0",
    fontSize: "15px",
    background: "#10C822",
    fontWeight: 700,
    borderRadius: "7px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  },

  labelStatusOff: {
    color: "#FFFFFF",
    width: "103px",
    height: "36px",
    margin: "0 23px",
    padding: "3px 0",
    fontSize: "15px",
    background: "#EC1C2E",
    fontWeight: 700,
    borderRadius: "7px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "2px 2px 0 rgba(0, 0, 0, 0.15)",
    [theme.breakpoints.down("xs")]: {
      color: "#FFFFFF",
      width: "85px",
      height: "23px",
      display: "flex",
      padding: "3px 0",
      fontSize: "13px",
      background: "#EC1C2E",
      alignItems: "center",
      fontWeight: "700",
      borderRadius: "4px",
      justifyContent: "center",
      margin: 0,
    },
  },

  statusFilho: {
    display: "flex",
    justifyContent: "space-between",
  },

  statusLoja: {
    border: "1px solid #BDBDBD",
    height: "36px",
    margin: "0 10px",
    display: "flex",
    padding: "0 20px",
    textAlign: "center",
    alignItems: "center",
    borderRadius: "6px",
    justifyContent: "center",
  },
  labelStatusLoja: {
    color: "#545454",
    padding: "0 27px 0 0",
    fontSize: "16px",
    fontWeight: 700,
  },
  aplicacao: {
    // overflow: 'auto'
  },

  divBallVerde: {
    background: "#10C822",
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    margin: 8,
  },
  divBallAmarelo: {
    background: "#FFBC00",
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    margin: 8,
  },
  divBallError: {
    background: "#F44336",
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    margin: 8,
  },
  config: {
    margin: "20px 5px",
    padding: "15px 20px",
    background: "#F0F0F0",
    boxShadow: "inset 0px 0px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: "15px",
  },
  titleConfig: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    width: "100%",
    fontSize: "16px",
    fontWeight: 700,
    color: "rgb(102, 102, 102)",
    padding: "10px",
  },
  labelCredencial: {
    border: "2px solid #F0F0F0",
    borderRadius: "4px",
  },
  divider: {
    width: "100%",
    height: "1px",
    marginBottom: 15,
    backgroundColor: "#E6E6E6",
  },
  dividerConfig: {
    width: "100%",
    height: "2px",
    marginBottom: 15,
    borderRadius: 5,
    backgroundColor: "#D1D1D1",
  },
}));
