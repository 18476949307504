import { useMemo, ReactNode, useState, useEffect } from "react";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import { useStyles } from "../../menu-principal-styles";
import { usePaiStyles } from "./list-modulo-pai-styles";
import { ListModuloPaiProps } from "./list-modulo-pai-props";
import { ListModuloFilho } from "../list-modulo-filho/list-modulo-filho"
import { Typography } from "@material-ui/core";
import { EnumMenu } from "../../../../../../../model";

export const ListModuloPai = (props: ListModuloPaiProps) => {
  const { ...listProps } = useMemo(() => {
    return {
      ...props,
    };
  }, [props]);

  const [listaModulos, setListaModulos] = useState(null as ReactNode);

  useEffect(() => {
    setListaModulos(
      listProps.modulos.filter(x => 
        (x.codigoPai === props.codigoModulo))
        .map((item) => (
        <ListModuloFilho
          modeloAtual={props.modeloAtual}
          key={item.codigo}
          codigoModulo={item.codigo}
          nomeModulo={item.nome}
          isFavorite={false}
          rota={item.rota}
        />
      ))
    );
  }, [listProps.modulos, props.modeloAtual, props.codigoModulo]);

  const classes = useStyles();
  const paiClasses = usePaiStyles();

  if(props.codigoModulo === EnumMenu.DASHBOARD) return (
    <>
      <ListModuloFilho
          modeloAtual={props.modeloAtual}
          key={props.codigoModulo}
          codigoModulo={props.codigoModulo}
          nomeModulo={props.nomeModulo}
          isFavorite
          rota={props.rota}
        />
    </>
  )
  
  return (
    <>
      <Divider classes={{ root: classes.divider }} />
      <List className={paiClasses.list}>
      {props.modeloAtual === 'Completo' && <Typography className={paiClasses.title}>
        {props.nomeModulo}
      </Typography>}
        {listaModulos}
      </List>
    </>
  );
};
