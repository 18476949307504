import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { TesteCredencialModel } from "../../../../model/api/aplicacao-credencial/teste-credencial-model";

export function usePostTesteAplicacaoPedido() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();
  const apiUrlHomo = "https://sauruspedidosapi-staging.azurewebsites.net";

  const postTesteAplicacaoPedido = useCallback(
    (model: TesteCredencialModel, codigoAplicacao: string, query?: string) =>
      invocarApi({
        url: `/api/v2/aplicacao/${codigoAplicacao}/homologacao/pedido?${query}`,
        method: "POST",
        data: model,
        baseURL: apiUrlHomo,
        headers: { "Content-Type": "application/json" },
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postTesteAplicacaoPedido,
  };
}
