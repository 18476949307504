import { useCallback } from 'react';
import { useApiBase } from '../../base';
import { CredenciaisModelIfood } from '../../../../model/api/credenciais/credenciais-model-ifood';

export function usePatchValidacaoCredencial() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const patchValidacaoCredencial = useCallback(
        (lojaId: string, credencialId: string, credencialPatch: CredenciaisModelIfood) =>
            invocarApi({
                method: 'PATCH',
                url: `/api/v2/loja/${lojaId}/credencial-dados/${credencialId}/validar`,
                headers: { "Content-type": "application/json" },
                data: credencialPatch,
                enviarTokenUsuario: true,
                timeout: 30000
            }),

        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        patchValidacaoCredencial,
    };
}

