import { Box, Button, Grid, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { useCadastros } from "../../../../../../service/app/providers/cadastros-provider";
import { useToastSaurus } from "../../../../../../service/app/use-cases";
import { ButtonModalHeader } from "../../../../controles/buttons/button-modal-header";
import { AvancarIcon, VoltarIcon } from "../../../../icons";
import { ModalHeader } from "../../../modal-header";
import { CircularLoading } from "../../../..";
import { useDetalhesPedidoStyles } from "./detalhes-do-pedido-styles";
import { DetalhesPedidoItens } from "../../../../../pages/private/pedidos/components/detalhes-do-pedido-itens/detalhes-do-pedido-itens";
import { alterarCorStatusPedido } from "../../../../cards/card-pedidos/utils/alterar-cor-pedido";
import { alterarNomePedido } from "../../../../cards/card-pedidos/utils/alterar-nome-pedido";
import { PedidosModel } from "../../../../../../model/api/pedidos/pedidos-model";
import { AtualizarIcon } from "../../../../icons/atualizar";
import { ImportacaoIcon } from "../../../../icons/importacao-icon";
import { useGetPedidosById } from "../../../../../../data/api/gestao/pedidos/get-pedidos-by-id";
import { isEmpty } from "lodash";
import { usePutPedidosById } from "../../../../../../data/api/gestao/pedidos/put-pedidos-alterar-status";
import { CanceladosIcon } from "../../../../icons/cancelado-icon";
import { useConfirm } from "material-ui-confirm";
import { useThemeQueries } from "../../../../../../theme";

interface NovaCredencialProps {
  pedido: PedidosModel
  idLoja: string
  idPedido: string
}

export const DetalhesPedido = ({ idLoja, idPedido }: NovaCredencialProps) => {
  const classes = useDetalhesPedidoStyles();
  const { showToast } = useToastSaurus()
  const { fecharDetalhesPedidos } = useCadastros();
  const { getPedidosById, carregando } = useGetPedidosById()
  const { putPedidosById } = usePutPedidosById()
  const { theme } = useThemeQueries()
  const [pedidos, setPedidos] = useState<PedidosModel>(new PedidosModel())
  const confirm = useConfirm()


  const pedidoWrapper = useCallback(
    async () => {
      try {
        const ret = await getPedidosById(idLoja, idPedido);
        if (ret.erro) throw ret.erro;
        setPedidos(ret.resultado?.data)

      } catch (e: any) {
        showToast('error', e.message)
      }
    }, [getPedidosById, idLoja, idPedido, showToast]);

  const forçarAtualizacao = useCallback(async () => {
    const query = pedidos.tpStatusPedido + 1
    try {
      if (pedidos.tpStatusPedido >= 0) {
        const res = await putPedidosById(idLoja, idPedido, query, true)
        if (res.erro) throw res.erro
      }
      const ret = await getPedidosById(idLoja, idPedido);
      if (ret.erro) throw ret.erro;
      setPedidos(ret.resultado?.data)
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [getPedidosById, idLoja, idPedido, pedidos.tpStatusPedido, putPedidosById, showToast])

  const alterarStatus = useCallback(async () => {
    const query = pedidos.tpStatusPedido + 1
    try {
      const res = await putPedidosById(idLoja, idPedido, query, false)
      if (res.erro) {
        confirm({
          title: 'Falha ao alterar o status',
          description: 'Ocorreu uma falha ou não foi permitido alterar o status do pedido, deseja forçar a atualização?',
          confirmationText: 'Forçar Atualização',
          cancellationText: 'Cancelar',
          confirmationButtonProps: {
            variant: 'contained',
            size: 'large'
          }
        }).then(async () => {
          const res = await putPedidosById(idLoja, idPedido, query, false)
          if (res.erro) throw res.erro
        }).catch(err => showToast('error', err.message))
        throw res.erro
      }

      pedidoWrapper()
      showToast('success', 'Status alterado')
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [confirm, idLoja, idPedido, pedidoWrapper, pedidos.tpStatusPedido, putPedidosById, showToast])

  useEffect(() => {
    pedidoWrapper()
  }, [pedidoWrapper])

  const onCloseModal = useCallback(() => {
    fecharDetalhesPedidos()
  }, [fecharDetalhesPedidos]);

  return (
    <>
      <div className={classes.root}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <ModalHeader
          title={`Pedido: ${pedidos?.codigoPedido}`}
          leftArea={
            <ButtonModalHeader
              tooltip="Voltar"
              icon={<VoltarIcon tipo="MODAL_HEADER" />}
              onClick={onCloseModal}
            />
          }
          rightArea={
            <Typography variant="h6">
              <strong>{pedidos?.dadosIntegracao.nomeIntegrador ?? ''}</strong>
            </Typography>
          }
        />
        <div className={classes.content} style={{
          position: 'relative'
        }}>
          <Box p={1}>
            <Grid item className={classes.gridSituacao}>
              <Typography
                className={classes.titleSituacao}
                style={{ background: alterarCorStatusPedido(pedidos.tpStatusPedido) }}
                variant="body2"
              >
                {alterarNomePedido(pedidos.tpStatusPedido)}
              </Typography>
            </Grid>
            <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: theme.spacing(1) }} item xs={12}>
              <Button
                variant="text"
                style={{
                  marginRight: theme.spacing(2)
                }}
                onClick={() => {
                  window.open(`https://maps.google.com?q=${pedidos.enderecoEntrega?.enderecoCompleto}`)
                }}
              >
                <img
                  style={{ maxWidth: 80 }}
                  src={'https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Google_Maps_icon_%282015-2020%29.svg/2048px-Google_Maps_icon_%282015-2020%29.svg.png'}
                  alt='Imagem do google maps'
                />
              </Button>
              <div>
                <Typography className={classes.titleDetalhe}>
                  Endereço Entrega: <strong className={classes.titleSubInfo}>{pedidos.enderecoEntrega?.enderecoCompleto}</strong>
                </Typography>
                <Typography className={classes.titleDetalheInfo}>
                  Informação Adicional: <strong className={classes.titleSubInfo}>{!isEmpty(pedidos.informacaoAdicional)
                    ? pedidos.informacaoAdicional
                    : ' - '}
                  </strong>
                </Typography>
              </div>

            </Grid>
            <div className={classes.dividerTop} />
          </Box>
          <div className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}>
            <DetalhesPedidoItens pedidos={pedidos} />
            <div className={classes.divProdutos}>
              <div className={classes.divPedidosTitle}>
                <Typography className={classes.titleProduto}>
                  Produtos do Pedido
                </Typography>
              </div>
              <Grid container spacing={2}>
                {pedidos.items?.map(produtos => {
                  return (
                    <>
                      <Grid item xs={12} className={classes.containerProdutoPedido}>
                        <Typography className={classes.titleProdutoPedido}>{produtos.descricao}</Typography>
                        <Typography className={classes.titleProdutoPedido}>{produtos.quantidade}UN</Typography>
                        <Typography className={classes.titleProdutoPedido}>{produtos.valorTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Typography>
                      </Grid>
                    </>
                  )
                })}
              </Grid>
            </div>
          </div>
        </div>
        <div className={classes.acoes}>
          {(pedidos.tpStatusPedido < 6) ?
            <>
              <div className={classes.iconImportacao}>
                <ImportacaoIcon tipo='BUTTON_FAB' style={{
                  outline: "6px solid white",
                  borderRadius: "100%"
                }} />
              </div>
              <div className={classes.containerImportacao}>
                <Typography className={classes.titleImportacao}>
                  Para importação dos pedidos é necessário utilização do Saurus Terminal!
                </Typography>
              </div>
            </>
            :
            <>
              <div className={classes.iconImportacaoCacelado}>
                <CanceladosIcon tipo='BUTTON_ALERTA_EXCLUIR' style={{
                  outline: "6px solid white",
                  borderRadius: "100%"
                }} />
              </div>
              <div className={classes.containerPedidoCandelado}>
                <Typography className={classes.titleImportacaoCancelado}>
                  Esse pedido foi cancelado!
                </Typography>
              </div>
            </>
          }
          {(pedidos.tpStatusPedido < 6) ?
            <>
              <div className={classes.dividerBottom} />
              <Grid container spacing={2}>
                {(pedidos.tpStatusPedido < 5) ?
                  <Grid item xs={12} sm={6}>
                    <Button
                      disabled={carregando}
                      onClick={() => { forçarAtualizacao() }}
                      variant="contained"
                      color="primary"
                      size="medium"
                      fullWidth
                    >
                      <AtualizarIcon tipo="BUTTON_PRIMARY" />
                      Forçar Atualização
                    </Button>
                  </Grid>
                  :
                  null
                }
                <Grid item xs={12} sm={pedidos.tpStatusPedido < 5 ? 6 : 12}>
                  <Button
                    disabled={carregando}
                    onClick={() => alterarStatus()}
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="medium"
                  >
                    <AvancarIcon tipo="BUTTON_PRIMARY" />
                    Próximo Status
                  </Button>
                </Grid>
              </Grid>
            </>
            :
            null
          }
        </div>
      </div>
    </>
  )
}

