import { isEmpty } from "lodash";
import { PedidosModel } from "../../../../model/api/pedidos/pedidos-model";
import { useThemeQueries } from "../../../../theme";
import { DefaultModal } from "../../default-modal";
import { DetalhesPedido } from "./components/detalhes-do-pedido/detalhes-do-pedido";

type Props = {
  openned: boolean
  pedido: PedidosModel
  idLoja: string
  idPedido: string
}

export const ModelPedidos = ({ openned, idLoja, pedido, idPedido }: Props) => {
  const { isMobile } = useThemeQueries()

  return (
    <DefaultModal
      minWidth={isMobile ? '0' : "600px"}
      open={openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {!isEmpty(idLoja) ?
        <DetalhesPedido
          idPedido={idPedido}
          idLoja={idLoja}
          pedido={pedido}
        />
        :
        ''
      }
    </DefaultModal>
  );
};
