import { AplicacaoModel } from "../../../../model/api/aplicacao/aplicacao-model";
import { CredenciaisDadosModel } from "../../../../model/api/credenciais/credenciais-dados-model";
import { LojaModel } from "../../../../model/api/loja/loja-model";
import { useThemeQueries } from "../../../../theme";
import { DefaultModal } from "../../default-modal";
import { EditarAplicacaoCliente } from "./components/editar-credencial-cliente/editar-credencial-cliente";

type Props = {
  openned: boolean;
  aplicacao: AplicacaoModel;
  loja: LojaModel;
  credencialDados: CredenciaisDadosModel
}

export const ModalEditarAplicacao = ({ aplicacao, openned, loja, credencialDados }: Props) => {
  const { isMobile } = useThemeQueries();

  return (
    <DefaultModal
      minWidth={isMobile ? '0' : "600px"}
      open={openned || false}
      variant={"temporary"}
      anchor="right"
    >
      <EditarAplicacaoCliente
        openned={openned}
        credencialDados={credencialDados}
        aplicacao={aplicacao}
        loja={loja}
      />
    </DefaultModal>
  );
};
