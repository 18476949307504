import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardLogin: {
        width: '100%',
        height: '650px',
        maxWidth: '670px',
        display: 'flex',
        borderRadius: '29px',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: ' 0px 59.2428px 154.031px rgba(0, 0, 0, 0.1)',
        background: '#FFFFFF'
    },
    imgTitulo: {
        width: '46px',
        height: '36px',
        marginBottom: -6,
        marginRight: 6,
        fill: theme.palette.primary.main,
        [theme.breakpoints.up('sm')]: {
            width: '36px',
            height: '36px',
            marginBottom: -6,
        },
        [theme.breakpoints.up('md')]: {
            width: '40px',
            height: '40px',
            marginBottom: -6,

        },
        [theme.breakpoints.up('lg')]: {
            width: '50px',
            height: '50px',
            marginBottom: -8,
        }
    }
}));