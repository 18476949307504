import { LojaModel } from "../../../../model/api/loja/loja-model";
import { useThemeQueries } from "../../../../theme";
import { DefaultModal } from "../../default-modal";
import { useEffect, useState } from "react";
import { AplicacaoModel } from "../../../../model/api/aplicacao/aplicacao-model";
import { CadastroNovaCredencial } from "./components/cadastro-credencial/cadastro-credencial";
import { isEmpty } from "lodash";
import { NovaAplicacao } from "./components/cadastro-aplicacao/cadastro-aplicacao";

type Props = {
  openned: boolean;
  loja: LojaModel;
  online?: boolean;
}

export const ModalCadastroAplicacaoCredencial = ({ openned, loja }: Props) => {
  const { isMobile } = useThemeQueries();
  const [selectedModel, setSelectedModel] = useState<AplicacaoModel | null>(null);
  const [cadastro, setCadastro] = useState<boolean>(false);

  useEffect(() => {
    if (!openned) {
      setSelectedModel(null)
      setCadastro(false)
    }
  }, [openned])

  return (
    <DefaultModal
      minWidth={isMobile ? '0' : "600px"}
      open={openned || false}
      variant={"temporary"}
      anchor="right"
    >

      {(openned && !cadastro) && (
        <>
          <NovaAplicacao
            loja={loja}
            setSelectedModel={setSelectedModel}
            selectedModel={selectedModel}
            setCadastro={setCadastro}
          />
          {/* mobile */}
          {/* <NovaAplicacao
            loja={loja}
            setSelectedModel={setSelectedModel}
            selectedModel={selectedModel}
            setCadastro={setCadastro}
          /> */}
        </>
      )}

      {(openned && !isEmpty(selectedModel) && cadastro) &&
        <CadastroNovaCredencial
          aplicacao={selectedModel}
          loja={loja}
          setCadastro={setCadastro}
        />}

    </DefaultModal>
  );
};
