import { Box, Button, Grid } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useGetAplicacao } from "../../../../../../data/api/gestao/aplicacao/get-aplicacao";
import { useGetLojas } from "../../../../../../data/api/gestao/lojas/get-lojas";
import { usePatchIniciarLoja } from "../../../../../../data/api/gestao/lojas/patch-iniciar-loja";
import { AplicacaoModel } from "../../../../../../model/api/aplicacao/aplicacao-model";
import { LojaModel } from "../../../../../../model/api/loja/loja-model";
import { useCadastros } from "../../../../../../service/app/providers/cadastros-provider";
import { useToastSaurus } from "../../../../../../service/app/use-cases";
import { useDefaultStyles } from "../../../components/defaulf-styles";
import { LojasListData } from "./lojas-list-data";
import { EditarLojaModel } from "../../../../../../model/api/loja/editar-loja-model";
import { CredenciaisDadosModel } from "../../../../../../model/api/credenciais/credenciais-dados-model";
import { AdicionarIcon } from "../../../../../components/icons";

export const LojasList = () => {
  const classes = useDefaultStyles();
  const { showToast } = useToastSaurus();
  const { patchIniciarLoja, carregando: carregandoPatch } = usePatchIniciarLoja();
  const { getLojas, carregando: carregandoGet } = useGetLojas();
  const carregando = carregandoPatch || carregandoGet;
  const { CadastroNovaLojaState, CadastroNovaCredencialState, abrirCadastroNovaLoja, CadastroNovaAplicacaoState } = useCadastros();
  const [lojas, setLoja] = useState<LojaModel[]>([]);
  const { getAplicacao } = useGetAplicacao();
  const [editando, setEditando] = useState<boolean>(false);
  const [aplicacao, setAplicacao] = useState<AplicacaoModel[]>([]);

  const aplicacaoWrapper = useCallback(async () => {
    try {
      const res = await getAplicacao()
      if (res.erro) throw res.erro
      setEditando(res.resultado?.data);
      setAplicacao(res.resultado?.data)
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [getAplicacao, showToast])

  const lojaWrapper = useCallback(async () => {
    try {
      const resPatch = await patchIniciarLoja()
      if (resPatch.erro) throw resPatch.erro
      const res = await getLojas()
      setLoja(res.resultado?.data.lojas)
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [getLojas, patchIniciarLoja, showToast])
  //faz o role de atualizar dinamicamente

  useEffect(() => {
    if (!CadastroNovaLojaState.aberto && !CadastroNovaCredencialState.aberto && !CadastroNovaAplicacaoState.aberto) {
      lojaWrapper()
      aplicacaoWrapper()
    }
  }, [lojaWrapper, CadastroNovaLojaState, aplicacaoWrapper, CadastroNovaCredencialState.aberto, CadastroNovaAplicacaoState.aberto])

  return (
    <>
      <div className={classes.defaultContainer}>
        <Box display='flex' flexDirection='column' marginTop='20px' justifyContent='center' alignItems='center' flex={1} style={{ overflowX: "hidden", overflowY: "auto", borderRadius: 15 }}>
          <Grid container>
            <Grid item xs={12} md={12} className={classes.listContainer}>
              <LojasListData
                editando={editando}
                aplicacoes={aplicacao}
                carregando={carregando}
                list={lojas}
              />
              <Button
                className={classes.botaoAdicionarLoja}
                onClick={() => {
                  abrirCadastroNovaLoja('', new LojaModel(), new EditarLojaModel(), new CredenciaisDadosModel(), '', true)
                }}
              >
                <AdicionarIcon tipo="BUTTON_FAB" />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  )

}

