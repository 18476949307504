import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import { useSessaoAtual } from '../../../service/app/providers';
import { ModulosMock } from '../../../data/mocks/menu-mock';
import { ModulosAdminMock } from '../../../data/mocks/menu-admin-mock';

export const ValidationRoute: React.FC<RouteProps> = ({
  children,
  ...otherProps
}) => {
  const { tipoUsuario } = useSessaoAtual();
  const history = useHistory()
  const pathname = history.location.pathname
  
  const retRoute = () => {
    if (tipoUsuario() > 0) {
      const rota = ModulosMock.find(x => x.rota.includes(pathname))
      if (rota?.permissoes.includes(tipoUsuario() ?? 0)) return children
      return <Redirect to="/login" />
    } else {
      const rota = ModulosAdminMock.find(x => pathname.includes(x.rota))
      if (rota?.permissoes.includes(tipoUsuario() ?? 0)) return children
      return <Redirect to="/login" />
    }
  };
  return <Route {...otherProps}>{retRoute()}</Route>;
};
