import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { CredenciaisDadosModel } from "../../../../model/api/credenciais/credenciais-dados-model";

export function usePostRegistroCredencial() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postRegistroCredencial = useCallback(
        ( lojaId: string, model: CredenciaisDadosModel ) => {            
            return invocarApi({
                url: `/api/v2/loja/${lojaId}/credencial-dados` ,
                data: model,
                method: "POST",
                enviarTokenUsuario: true
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        postRegistroCredencial,
    };
}


