import { makeStyles } from "@material-ui/core";

export const useCardCargaStyles = makeStyles((theme) => ({
    containerHistorico: {
        display: 'flex',
        margin: '10px',
        padding: '8px 5px 0 13px',
        paddingBottom: '10px',
        borderRadius: '10px',
        backgroundColor: '#F8F8F8',
        height: '100%',
        maxHeight: '300px',
        overflow: 'hidden',
    },
    rootApp: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    containerStatus: {
        margin: '10px 0 27px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    situacao: {
        width: '100%',
        height: '100%',
        margin: '0 10px',
        maxWidth: '100px',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    titleSituacao: {
        color: '#858585',
        fontSize: '14px',
        fontWeight: 600
    },
    titleDate: {
        color: '#767676',
        fontSize: '14px',
        fontWeight: 600
    },
    titleStts: {
        color: '#fff',
        fontSize: '12px',
        fontWeight: 700
    },
    titleCargas:{
        color: '#767676',
        fontSize: '14px',
        fontWeight: 600
    },
    titleCargasSincronizados:{
        color: '#10C822',
        fontSize: '18px',
        fontWeight: 600
    },
    titleCargasRejeitados:{
        color: '#F44336',
        fontSize: '18px',
        fontWeight: 600
    },
    titleCargasPendentes:{
        color: '#FFBC00',
        fontSize: '18px',
        fontWeight: 600
    },
    titleTotalProdutos:{
        color: '#5333ED',
        fontSize: '18px',
        fontWeight: 600
    },
    titleCargasResult:{
        color: '#767676',
        fontSize: '18px',
        fontWeight: 700
    },
    date:{
        color: '#5333ED',
        fontSize: '16px', 
        fontWeight: 600
    },

    divider: {
        width: '100%',
        height: '1px',
        marginBottom: 15,
        borderRadius: '6px',
        backgroundColor: '#E6E6E6'
    },
}));
