import { Button, Grid, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-validacao-ifood-styles';
import { VoltarIcon } from '../../icons';
import { useCallback } from 'react';
import { useToastSaurus } from '../../../../service/app/use-cases';
import { usePatchValidacaoCredencial } from '../../../../data/api/gestao/credenciais-dados/patch-validacao-credencial';
import { AplicacaoModel } from '../../../../model/api/aplicacao/aplicacao-model';
import { CredenciaisDadosModel } from '../../../../model/api/credenciais/credenciais-dados-model';
import { LojaModel } from '../../../../model/api/loja/loja-model';
import { usePostRegistroCredencial } from '../../../../data/api/gestao/credenciais-dados/post-registro-credencial';
import { CredenciaisModelIfood } from '../../../../model/api/credenciais/credenciais-model-ifood';
import { v4 as uuidv4 } from 'uuid';

interface DialogValidacaoIfoodProps {
  openned: boolean;
  closeModal: () => void;
  setAberto: (valor: number) => void;
  credencialDados: CredenciaisDadosModel;
  aplicacao: AplicacaoModel;
  loja: LojaModel;
  setCredencialDados: React.Dispatch<React.SetStateAction<CredenciaisDadosModel>>;
}

//Primeiro dialog

export const DialogValidacaoIfood = ({
  openned,
  closeModal,
  setAberto,
  aplicacao,
  loja,
  credencialDados,
  setCredencialDados
}: DialogValidacaoIfoodProps) => {
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const { patchValidacaoCredencial, carregando } = usePatchValidacaoCredencial();
  const { postRegistroCredencial } = usePostRegistroCredencial();

  const sincronizacaoWrapper = useCallback(async (model: CredenciaisDadosModel, patch: CredenciaisModelIfood) => {

    try {
      model = { ...model, credenciar: credencialDados.credenciar, codigoAplicacao: aplicacao.codigoAplicacao, tpSituacao: credencialDados.tpSituacao, id: uuidv4() }

      const resPost = await postRegistroCredencial(loja.id, model);
      if (resPost.erro) throw resPost.erro;

      setCredencialDados(resPost.resultado?.data!)

      const credencialId = resPost.resultado?.data?.id

      patch = { ...patch, credenciais: model.credenciais, id: credencialId!, tpSituacao: model.tpSituacao, codigoAplicacao: aplicacao.codigoAplicacao }

      const pacthIfood = await patchValidacaoCredencial(loja.id, credencialId!, patch);

      if (pacthIfood.resultado?.data.urlRedirectLogin) {
        window.open(pacthIfood.resultado?.data.urlRedirectLogin, '_blank')
        setAberto(2)
      }
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [credencialDados.credenciar, credencialDados.tpSituacao, aplicacao.codigoAplicacao, postRegistroCredencial, loja.id, setCredencialDados, patchValidacaoCredencial, setAberto, showToast])

  return (
    <>
      <DialogSaurus
        aberto={openned}
        titulo='Autenticação Ifood'
        tamanho="sm">
        <Typography className={classes.title}>Você deseja iniciar o processo de autorização do Ifood? Iremos redirecionar para iniciar a autenticação!</Typography>
        <div>
          <Grid container spacing={2} style={{
            justifyContent: 'end',
            alignItems: 'center'
          }}>
            <Grid item xs={12} md={3}>
              <Button
                variant='outlined'
                color='primary'
                onClick={closeModal}
                fullWidth
              >
                <VoltarIcon tipo='BUTTON' />
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                disabled={carregando}
                variant="contained"
                color="primary"
                fullWidth
                onClick={async () => await sincronizacaoWrapper(credencialDados, new CredenciaisModelIfood())}
              >
                Continuar
              </Button>
            </Grid>
          </Grid>
        </div>
      </DialogSaurus>
    </>
  );
};
