import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    button: {
        margin: 0,
        width: '40px',
        height: '40px',
        "& svg": {

            fill: theme.palette.primary.contrastText,

            [theme.breakpoints.up("md")]: {
                fill: theme.palette.text.primary,

            }
        }
    }
}));
