import { Button, Grid, Typography } from '@material-ui/core';
import { TextFieldSaurus } from '../../../../controles/inputs';
import { useStyles } from '../../../../cards/card-credencial-homologacao/card-credencial-homologacao-styles';
import { TesteIcon } from '../../../../icons';
import { SelectSaurus } from '../../../../controles/selects/select-saurus/select-saurus';
import { SituacaoPedidoMock } from '../../../../../../data/mocks/situacao-pedido-mock';
import { AlterarStatusPedidoModel, PedidoModel } from '../../../../../../model/api/aplicacao-credencial/pedido-model/pedido-model';
import { useState } from 'react';


interface Props {
  carregando: boolean;
  status: number;
  setStatus: (value: React.SetStateAction<number>) => void;
  redirectToResult: () => void;
  scrollToTarget: () => void;
  alterarSttsPedidoSubmit: (model: AlterarStatusPedidoModel) => Promise<void>
  alterarStatus: AlterarStatusPedidoModel
}

export const FormTesteAlterarPedidoStatus = ({ carregando, setStatus, status, redirectToResult, scrollToTarget, alterarStatus, alterarSttsPedidoSubmit }: Props) => {
  const classes = useStyles();

  // const [pedidoJSON, setPedidoJSON] = useState(JSON.stringify(new PedidoModel(), null, 2));


  // const handlePedidoJSONChange = (event: any) => {
  //   debugger
  //   const value =  alterarStatus = event.target.value;
  //   const pedidoRecebido: PedidoModel = JSON.parse(value);

  //   setPedidoJSON(pedidoRecebido);
  //   console.log('pedidoJSON', alterarStatus);
  // };


  // console.log('alterarStatus', alterarStatus);
  // console.log('pedidoJSON', pedidoJSON);

  const [pedidoJSON, setPedidoJSON] = useState(JSON.stringify(new PedidoModel(), null, 2));

  const handlePedidoJSONChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;

    try {
      const pedidoRecebido: PedidoModel = JSON.parse(value);
      setPedidoJSON(value);

      // Atualizar o pedido em alterarStatus
      alterarStatus.pedido = pedidoRecebido;
    } catch (error) {
      console.error('Erro ao analisar o JSON:', error);
    }
  };

  console.log('pedidoJSON', alterarStatus);

  return (
    <>
      <form>
        <div className={classes.divider} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectSaurus
              conteudo={SituacaoPedidoMock}
              variant="outlined"
              label="Status do Pedido"
              disabled={false}
              allowSubmit
              select
              fullWidth
              onChange={(event: any) => {
                if (event) {
                  const newStatus = SituacaoPedidoMock.filter(
                    (item) => item.Key === event.target.value,
                  )[0]?.Key;
                  setStatus(newStatus);
                }
              }}
              value={status}
            />
          </Grid>
          <Grid item xs={12} style={{ height: '400px', overflowY: 'auto', paddingTop: 24 }}>
            <TextFieldSaurus
              value={pedidoJSON}
              style={{ paddingTop: '10px', paddingBottom: '10px' }}
              onChange={handlePedidoJSONChange}
              tipo="TEXTO"
              fullWidth
              disabled={carregando}
              showStartAdornment={true}
              allowSubmit={false}
              autoComplete={'off'}
              minRows={7}
              multiline
              label="Pedido dados JSON"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={carregando}
              variant="contained"
              color="primary"
              className={classes.button}
              fullWidth
              onClick={() => {
                scrollToTarget();
                setTimeout(redirectToResult, 1000);

                alterarSttsPedidoSubmit(alterarStatus)
              }}>
              <TesteIcon tipo="BUTTON_PRIMARY" />
              <Typography style={{ fontWeight: 700, fontSize: 16 }} >
                Alterar Status Pedido
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};