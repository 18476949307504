import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useDeleteSincronizacaoDadosById () {
    const { invocarApi, ...outrasPropriedades } = useApiBase();
    
    const deleteSincronizacaoDadosById = useCallback(
        ( lojaId: string, sincronizacaoId: string, usuario: string ) => {
            return invocarApi({
                url: `/api/v2/loja/${lojaId}/sincronizacao/${sincronizacaoId}`,
                method: "DELETE",
                headers: {'usuario': usuario},
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        deleteSincronizacaoDadosById,
    };
}