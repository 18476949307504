import { ClientePedidoModel } from "../../pedidos/cliente-model";
import { ItemsModel } from "../../pedidos/itens-model";
import { PagamentosModel } from "../../pedidos/pegamento-model";
import { RedeModel } from "../../pedidos/rede-model";
import { TesteCredencialModel } from "../teste-credencial-model";

export class EnderecoEntregaModel {
  constructor(
    public logradouro: string = "",
    public numero: string = "",
    public bairro: string = "",
    public municipio: string = "",
    public uf: string = "",
    public cep: string = "",
    public latitude: number = 0,
    public longitude: number = 0,
    public complemento: string = "",
    public enderecoCompleto: string = "",
    public pais: string = "",
    public pontoReferencia: string = "",
    public codigoMunicipio: string = ""
  ) {}
}

export class LojaModel {
  constructor(
    public id: string = "",
    public nome: string = "",
    public cnpj: string = "",
    public status: string = "",
    public endereco: EnderecoEntregaModel = new EnderecoEntregaModel(),
    public rede: RedeModel = new RedeModel()
  ) {}
}

export class DadosIntegracaoModel {
  constructor(
    public nomeIntegrador: string = "",
    public cnpjIntegrador: string = "",
    public credencialCliente: string = "",
    public credencialId: string = ""
  ) {}
}

export class PedidoModel {
  constructor(
    public id: string = "",
    public tpPedidoDescricao: string = "",
    public tpPedido: string = "",
    public informacaoAdicional: string = "",
    public codigoPedido: string = "",
    public codigoLoja: string = "",
    public cupom: string = "",
    public dataCriacao: string = "",
    public agendamento: boolean = false,
    public dataAgendamentoInicial: string = "",
    public dataAgendamentoFinal: string = "",
    public entrega: boolean = false,
    public cpfNaNota: boolean = false,
    public tpStatusPedido: string = "",
    public documentoLoja: string = "",
    public tpStatusPedidoDescricao: string = "",
    public valor: number = 0,
    public valorTotal: number = 0,
    public valorConveniencia: number = 0,
    public valorEntrega: number = 0,
    public valorDesconto: number = 0,
    public valorTroco: number = 0,
    public valorRetirada: number = 0,
    public pessoaAutorizadaARetirar: string = "",
    public quantidadeItens: number = 0,

    public enderecoEntrega: EnderecoEntregaModel = new EnderecoEntregaModel(),
    public loja: LojaModel = new LojaModel(),
    public cliente: ClientePedidoModel = new ClientePedidoModel(),
    public items: ItemsModel = new ItemsModel(),
    public pagamentos: PagamentosModel = new PagamentosModel(),

    public codigoAplicacao: number = 0,
    public codigoReferecia: number = 0,
    public descontoEcommerce: number = 0,
    public taxaServico: number = 0,
    public dUpdate: string = "",
    public dInsert: number = 0,
    public dadosIntegracao: DadosIntegracaoModel = new DadosIntegracaoModel()
  ) {}
}

export class AlterarStatusPedidoModel {
  constructor(
    public loja: LojaModel = new LojaModel(),
    public pedido: PedidoModel = new PedidoModel(),
    public credencial: TesteCredencialModel = new TesteCredencialModel(),
  ) {}
}

// {
//   "loja": {
//     "documento": "string",
//     "razaoSocial": "string"
//   },
//   "pedido": {
//     "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     "tpPedidoDescricao": "string",
//     "tpPedido": "0 - Pedido para Entregar",
//     "informacaoAdicional": "string",
//     "codigoPedido": "string",
//     "codigoLoja": "string",
//     "cupom": "string",
//     "dataCriacao": "2023-08-14T14:10:36.966Z",
//     "agendamento": true,
//     "dataAgendamentoInicial": "2023-08-14T14:10:36.966Z",
//     "dataAgendamentoFinal": "2023-08-14T14:10:36.966Z",
//     "entrega": true,
//     "cpfNaNota": true,
//     "tpStatusPedido": "0 - Invalido",
//     "documentoLoja": "string",
//     "tpStatusPedidoDescricao": "string",
//     "valor": 0,
//     "valorTotal": 0,
//     "valorConveniencia": 0,
//     "valorEntrega": 0,
//     "valorDesconto": 0,
//     "valorTroco": 0,
//     "valorRetirada": 0,
//     "pessoaAutorizadaARetirar": "string",
//     "quantidadeItens": 0,
//     "enderecoEntrega": {
//       "logradouro": "string",
//       "numero": "string",
//       "bairro": "string",
//       "municipio": "string",
//       "uf": "string",
//       "cep": "string",
//       "latitude": 0,
//       "longitude": 0,
//       "complemento": "string",
//       "enderecoCompleto": "string",
//       "pais": "string",
//       "pontoReferencia": "string",
//       "codigoMunicipio": "string"
//     },
//     "loja": {
//       "id": "string",
//       "nome": "string",
//       "cnpj": "string",
//       "status": "string",
//       "endereco": {
//         "logradouro": "string",
//         "numero": "string",
//         "bairro": "string",
//         "municipio": "string",
//         "uf": "string",
//         "cep": "string",
//         "latitude": 0,
//         "longitude": 0,
//         "complemento": "string",
//         "enderecoCompleto": "string",
//         "pais": "string",
//         "pontoReferencia": "string",
//         "codigoMunicipio": "string"
//       },
//       "rede": {
//         "id": "string",
//         "nome": "string"
//       }
//     },
//     "cliente": {
//       "id": "string",
//       "referenceId": "string",
//       "nome": "string",
//       "email": "string",
//       "documento": "string",
//       "rg": "string",
//       "dataNascimento": "2023-08-14T14:10:36.966Z",
//       "telefoneCelular": "string",
//       "enderecos": [
//         {
//           "logradouro": "string",
//           "numero": "string",
//           "bairro": "string",
//           "municipio": "string",
//           "uf": "string",
//           "cep": "string",
//           "latitude": 0,
//           "longitude": 0,
//           "complemento": "string",
//           "enderecoCompleto": "string",
//           "pais": "string",
//           "pontoReferencia": "string",
//           "codigoMunicipio": "string"
//         }
//       ]
//     },
//     "items": [
//       {
//         "id": "string",
//         "nItem": 0,
//         "codigoReferencia": "string",
//         "descricao": "string",
//         "codigoLoja": "string",
//         "pesoVariavel": true,
//         "codigoBarra": "string",
//         "plu": "string",
//         "observacao": "string",
//         "quantidade": 0,
//         "subItens": [
//           "string"
//         ],
//         "valor": 0,
//         "valorTotal": 0,
//         "valorAdicional": 0,
//         "valorSubItem": 0,
//         "indisponivel": true,
//         "desistencia": true,
//         "valorDesconto": 0
//       }
//     ],
//     "pagamentos": [
//       {
//         "id": 0,
//         "nome": "string",
//         "valor": 0,
//         "tipo": "string",
//         "transacoes": [
//           "string"
//         ],
//         "pagamentoRealizado": true,
//         "valorTroco": 0
//       }
//     ],
//     "codigoAplicacao": 0,
//     "codigoReferecia": "string",
//     "descontoEcommerce": 0,
//     "taxaServico": 0,
//     "dUpdate": "2023-08-14T14:10:36.966Z",
//     "dInsert": "2023-08-14T14:10:36.966Z",
//     "dadosIntegracao": {
//       "nomeIntegrador": "string",
//       "cnpjIntegrador": "string",
//       "credencialCliente": "string",
//       "credencialId": "string"
//     }
//   },
//   "credencial": {
//     "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     "confirmacaoAutomaticaAtivada": true,
//     "credenciais": {
//       "additionalProp1": "string",
//       "additionalProp2": "string",
//       "additionalProp3": "string"
//     },
//     "codigoAplicacao": 0,
//     "ativo": true,
//     "tpSituacao": "0 - Invalido",
//     "dataCriacaoToken": "2023-08-14T14:10:36.966Z",
//     "dataAtualizacaoToken": "2023-08-14T14:10:36.966Z",
//     "jsonToken": "string",
//     "dataVencimento": "2023-08-14T14:10:36.966Z",
//     "documento": "string",
//     "ultimoRetorno": "string",
//     "urlBase": "string",
//     "urlRedirectLogin": "string",
//     "mensagemLoja": "string",
//     "statusLoja": "0 - Invalido"
//   }
// }