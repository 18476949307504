import { makeStyles } from "@material-ui/core";


export const useStyles = makeStyles((theme) => ({
  normal: {
    width: "auto",
    display: "flex",
    height: "auto",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: "1rem",
    display: "flex",
  },
  icon: {
    width: "30px",
    marginLeft: ".5rem",
    fill: theme.palette.primary.main,
  },
  fullSized: {
    display: 'flex',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(255,255,255,0.6)',
    height: '100%',
    width: '100%',
    zIndex: 1
  }
}));
