import { Button, Grid, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { AplicacaoModel } from "../../../../../../model/api/aplicacao/aplicacao-model";
import { useModalStyles } from "../../../../utils/modals/modal-styles";
import { useToastSaurus } from "../../../../../../service/app/use-cases";
import { DefaultFormRefs } from "../../../../form/utils";
import { ModalHeader } from "../../../modal-header";
import { ButtonModalHeader } from "../../../../controles/buttons/button-modal-header";
import { SalvarIcon, VoltarIcon } from "../../../../icons";
import { FormEditarAplicacaoAdm } from "../../../../form/master/administracao/editar-aplicacao-prod/form-editar-aplicacao-prod";
import { ExcluirIcon } from '../../../../icons/excluir';
import { CircularLoading } from "../../../../utils";
import { isEqual } from "lodash";
import { usePutAtualizarAplicacao } from "../../../../../../data/api/gestao/aplicacao/put-atualizar-aplicacao";
import { useDeleteRemoveAplicacao } from "../../../../../../data/api/gestao/aplicacao/delete-remover-aplicacao";
import { useCadastros } from "../../../../../../service/app/providers/cadastros-provider";

interface EditarAplicacaoProdProps {
  setCadastro: (value: number) => void;
  aplicacao: AplicacaoModel;
}

export const EditarAplicacaoProd = ({ aplicacao, setCadastro }: EditarAplicacaoProdProps) => {
  const classes = useModalStyles();
  const { showToast, showToastPersonalizado } = useToastSaurus();
  const { fecharEdicaoAplicacaoProd } = useCadastros();

  const [beforeModel, setBeforeModel] = useState<AplicacaoModel | undefined>(undefined);

  const { putAtualizarAplicacao, carregando: carregandoPut } = usePutAtualizarAplicacao();
  const { deleteRemoverAplicacao, carregando: carregandoDelete } = useDeleteRemoveAplicacao();
  const carregando = carregandoPut || carregandoDelete;

  const cadEditarAplicacaoFormRef =
    useRef<DefaultFormRefs<AplicacaoModel>>(null);

  useEffect(() => {
    cadEditarAplicacaoFormRef.current?.fillForm(
      aplicacao
    )
    setBeforeModel(aplicacao);
  }, [aplicacao])

  const handleSubmit = useCallback(async (model: AplicacaoModel) => {
    try {
      const codEqual = isEqual(model, beforeModel)
      if (codEqual) {
        showToastPersonalizado('info', 'Nenhuma alteração ocorrida!')
        return false
      }
      const ret = await putAtualizarAplicacao(model.id, model);
      if (ret.erro) {
        throw ret.erro;
      }
      setCadastro(1)
      fecharEdicaoAplicacaoProd()
      showToast('success', "Aplicação Editada com Sucesso!");
    } catch (e: any) {
      showToast('error', e.mesage)
    }
  }, [beforeModel, putAtualizarAplicacao, setCadastro, fecharEdicaoAplicacaoProd, showToast, showToastPersonalizado])

  const deleteSubmit = useCallback(async (model: AplicacaoModel) => {
    try {
      const ret = await deleteRemoverAplicacao(model.id);
      if (ret.erro) {
        throw ret.erro;
      }
      setCadastro(1)
      fecharEdicaoAplicacaoProd()
      showToast('success', "Aplicação Deletada!");
    } catch (e: any) {
      showToast('error', e.mesage)
    }
  }, [deleteRemoverAplicacao, fecharEdicaoAplicacaoProd, setCadastro, showToast])

  return (
    <>
      <div className={classes.root}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <ModalHeader
          title={'Editar Aplicação'}
          leftArea={
            <ButtonModalHeader
              tooltip="Voltar"
              icon={<VoltarIcon style={{
                fill: '#5333ED',
                width: '50px',
                height: '50px',
                marginRight: '0',
                marginBottom: '0'
              }} />}
              onClick={() => setCadastro(1)}
            />
          }
        />
       <div className={classes.content}>
          <div
            className={classNames(
              classes.contentForms,
              carregando ? classes.contentFormsLoading : undefined,
            )}
          >
            <FormEditarAplicacaoAdm
              ref={cadEditarAplicacaoFormRef}
              showLoading={false}
              loading={carregando}
              onSubmit={handleSubmit}
              model={aplicacao}
              id={aplicacao.id}
            />
          </div>
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <Button
                disabled={carregando}
                className={classes.buttonRemover}
                onClick={() => deleteSubmit(aplicacao)}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
              >
                <ExcluirIcon />
                <Typography style={{ fontWeight: 700, fontSize: 16 }} >
                  Excluir
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Button
                disabled={carregando}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={() => cadEditarAplicacaoFormRef.current?.submitForm()}
              >
                <SalvarIcon tipo="BUTTON_PRIMARY" />
                <Typography style={{ fontWeight: 700, fontSize: 16 }} >
                  Salvar
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}

