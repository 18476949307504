import { createStyles, makeStyles } from '@material-ui/styles';
import Registro from '../icons/icon-img/registro.png'

export const RegistroIcon = () => {
  const useStyles = makeStyles(() => 
    createStyles({
      img: {
        margin: '0 10px'
      },
    })
  )
  const classes = useStyles();
  return (
    <>
      <img
      className={classes.img}
      src={Registro} 
      alt="" 
      />
    </>
  );
};
