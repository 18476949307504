import { AplicacaoModel } from "../../../../../../model/api/aplicacao/aplicacao-model";
import { LojaModel } from "../../../../../../model/api/loja/loja-model";
import CardDashboardLoja from "../../../../../components/cards/card-dashboard-loja/card-dashboard-loja";
import { CardNaoEncontrado } from "../../../../../components/cards/card-nao-encontrado";
import { InformacaoIcon } from "../../../../../components/icons";


export interface DashboardProps {
   list: Array<LojaModel>;
   carregando: boolean;
   aplicacoes: AplicacaoModel[];
   editando: boolean
}

export const DashboardListData = (props: DashboardProps) => {

   return (
      <>
         {props.list.length === 0 && !props.carregando && (
            <CardNaoEncontrado
               mensagem="Nenhuma Loja encontrada."
               icon={<InformacaoIcon tipo="GERAL" />}
            />
         )}
         {props.list.length > 0 && (
            props.list.filter((loja, i) => i < 2).map((loja) => {
               return (
                  <>
                     <CardDashboardLoja
                        editando={props.editando}
                        aplicacoes={props.aplicacoes}
                        loja={loja}
                     />
                  </>
               )
            })
         )}
      </>
   );
};
