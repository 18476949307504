import { guidEmpty } from "../../../utils/guid-empty";
import { EnumSituacaoProcessamento } from "../../enums/enum-situacao-processamento";
import { AplicacaoSincronizacoesModel } from "./aplicacao-sincronizacoes";
import { CargasSincronizacaoModel } from "./cargas-sincronizacoes";


export class SincronizacaoByIdModel {
    constructor(
        public id: string = guidEmpty(),
        public dataCriacao: string = '',
        public dataUltimaAlteracao: string = '',
        public usuario: string = "",
        public porcentagem: number = 0,
        public totalProdutosProdutos: number = 0,
        public aplicacaoSincronizacao: AplicacaoSincronizacoesModel = new AplicacaoSincronizacoesModel(),
        public tpStatusSincronizacao: EnumSituacaoProcessamento = EnumSituacaoProcessamento.INVALIDA,
        public statusSincronizacao: string = '',
        public cargas: CargasSincronizacaoModel[] = Array<CargasSincronizacaoModel>()
    ) { }
}
