
export class PagamentosModel {
    constructor(
        public id: number = 0,
        public nome: string = '',
        public valor: number = 0,
        public tipo: string = '',
        public transacoes: string[] = new Array<string>(),
        public pagamentoRealizado: boolean = true,
        public valorTroco: number = 0,
    ) {}
}