import { makeStyles } from "@material-ui/core";

export const useCardAplicacaoDashboardStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflowX: "hidden",
    borderRadius: 15,
    padding: "14px 25px 0 25px",
    marginLeft: "10px",
  },

  img: {
    width: '100px',
    borderRadius: '10px',
    boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.05)'
  },
  rootApp: {
    width: "100%",
    margin: "25px 0 0 0",
    display: "flex",
    padding: "21px 0 17px 31px",
    position: "relative",
    background: "rgba(246, 246, 246, 0.4)",
    boxShadow: "inset 0px 0px 4px rgba(0, 0, 0, 0.05)",
    maxHeight: "360px",
    height: "249px",
    overflowX: "hidden",
    overflowY: "auto",
    borderRadius: "15px",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "23px 0 0 0",
      padding: 0,
      maxWidth: "471px",
      background: "rgba(246, 246, 246, 0.4)",
      boxShadow: "inset 0px 0px 4px rgba(0, 0, 0, 0.05)",
      overflowX: "auto",
      overflowY: "hidden",
      borderRadius: "15px",
    },
  },
  documentText: {
    fontSize: "12px",
    fontWeight: 700,
    color: "#6B6B6B",
    fontStyle: "normal",
  },
  nomeLoja: {
    fontSize: "22px",
    fontWeight: 700,
    color: "#545454",
  },
  ultimoAcesso: {
    display: "flex",
    padding: "6px 52px",
    background: "#EFECFF",
    alignItems: "center",
    borderRadius: "5px",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      padding: 8,
      marginLeft: 8,
    },
  },
  ultimoAcessoText: {
    fontSize: "14px",
    color: "#5333ED",
    fontWeight: 700,
    textAlign: "center",
  },
  labelStatusPai: {
    flex: 1,
    display: "flex",
    marginTop: "8px",
    justifyContent: "space-between",
  },

  teste: {
    display: "flex",
    alignItems: "center",
  },

  button: {
    border: "1px solid #FFFFFF",
    boxShadow: "0px 0px 4.6875px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
  },

  labelDiv: {
    width: "100px",
    borderRadius: "7px",
    padding: "6px 0",
  },

  labelStatusOn: {
    color: "#FFFFFF",
    width: "100px",
    margin: "0 10px",
    padding: "3px 0",
    fontSize: "15px",
    background: "#10C822",
    textAlign: "center",
    fontWeight: 700,
    borderRadius: "7px",
  },

  labelStatusOff: {
    color: "#FFFFFF",
    width: "100px",
    margin: "0 10px",
    padding: "3px 0",
    fontSize: "15px",
    background: "#EC1C2E",
    textAlign: "center",
    fontWeight: 700,
    borderRadius: "7px",
  },

  statusFilho: {
    display: "flex",
    justifyContent: "space-between",
  },

  statusLoja: {
    border: "1px solid #BDBDBD",
    height: "36px",
    margin: "0 10px",
    display: "flex",
    padding: "0 20px",
    textAlign: "center",
    alignItems: "center",
    borderRadius: "6px",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      border: "1px solid #BDBDBD",
      height: "100%",
      margin: 0,
      padding: 0,
      borderRadius: "6px",
      display: "flex",
    },
  },
  labelStatusLoja: {
    color: "#545454",
    padding: "0 27px 0 0",
    fontSize: "16px",
    fontWeight: 700,

    [theme.breakpoints.down("xs")]: {
      padding: "0 20px 0 0",
      fontSize: "11px",
    },
  },
  divBallVerde: {
    background: "#10C822",
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    margin: 8,
    [theme.breakpoints.down("xs")]: {
      width: "10px",
      height: "10px",
      margin: 8,
    },
  },
  divBallAmarelo: {
    background: "#FFBC00",
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    margin: 8,
    [theme.breakpoints.down("xs")]: {
      width: "10px",
      height: "10px",
      margin: 8,
    },
  },
  divBallError: {
    background: "#F44336",
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    margin: 8,
    [theme.breakpoints.down("xs")]: {
      width: "10px",
      height: "10px",
      margin: 8,
    },
  },
  botaoAdicionarLoja: {
    right: "30px",
    bottom: "15px",
    position: "fixed",
    background: "rgb(83, 51, 237)",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 1.80889px 5.42667px",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#321f8b",
    },
  },
  divider: {
    width: "100%",
    height: "1px",
    marginTop: "10px",
    marginBottom: "10px",
    backgroundColor: "#E6E6E6",
  },
  descricaoAplicacao: {
    color: "#808080",
    fontSize: "10px",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  aplicacaoImg: {
    display: "flex",
    flexWrap: "wrap",
    "& img": {
      maxWidth: '106px',
      maxHeight: '108px',
      borderRadius: '5px'
    },
  },
  containerSemCredencial: {
    height: "360px",
    display: "flex",
    overflowY: "auto",
    alignItems: "center",
    justifyContent: "center",
  },

  titleSemCredencial: {
    color: "#808080",
    fontSize: "20px",
    fontWeight: 700,
    textAlign: "center",
    margin: "0 0 14px 0",
  },
  aplicacaoStyle: {
    position: "relative",
    background: "#FFFFFF",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "0px 10px 10px 10px",
  },
  dividerColor: {
    borderRadius: "1px 10px 0px 0px",
    background: "black",
    width: "90px",
    height: 8,
    position: "absolute",
    top: "-8px",
    left: 0,
  },
}));
