import { AplicacaoModel } from "../../../../../../model/api/aplicacao/aplicacao-model";
import { LojaModel } from "../../../../../../model/api/loja/loja-model";
import CardLoja from "../../../../../components/cards/card-loja/card-loja";
import { CardNaoEncontrado } from "../../../../../components/cards/card-nao-encontrado";
import { InformacaoIcon } from "../../../../../components/icons";

export interface LojasListDataProps {
   list: Array<LojaModel>;
   carregando: boolean;
   aplicacoes: AplicacaoModel[];
   editando: boolean
}

export const LojasListData = (props: LojasListDataProps) => {
   return (
      <>
         {props.list.length === 0 && !props.carregando && (
            <CardNaoEncontrado
               mensagem="Nenhuma Loja Encontrada."
               icon={<InformacaoIcon tipo="GERAL" />}
            />
         )}
         {props?.list.length > 0 &&
            props.list.map((loja, index) => {
               return (
                  <>
                     <CardLoja
                        key={loja.id}
                        editando={props.editando}
                        aplicacoes={props.aplicacoes}
                        loja={loja}
                        carregando={props.carregando}
                        primeiraLoja={index === 0}
                     />
                  </>
               )
            })}
      </>
   );
};
