import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const TestesIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M48.9893 45.5044C48.9893 32.4855 48.9893 19.4665 48.9893 6.44756C48.9893 6.12348 48.9893 5.7994 48.8442 5.49767C48.1632 4.41369 47.2032 3.98904 45.9306 4.00021C36.7101 4.02256 27.4784 4.01139 18.2578 4.01139C13.402 4.01139 8.55728 4.00021 3.70142 4.01139C2.03815 4.01139 1 5.0842 1 6.73811C1 19.6341 1 32.519 1 45.415C1 46.4096 1.16744 46.5884 2.18327 46.5884C17.3871 46.5884 32.591 46.5884 47.7949 46.5996C48.1856 46.5996 48.5428 46.5437 48.8553 46.3202C49.0563 46.0632 48.9893 45.7726 48.9893 45.5044ZM2.78606 10.0348C2.84188 9.10722 2.79723 8.16852 2.79723 7.24099C2.79723 6.1123 2.95351 5.95585 4.11445 5.95585C15.2439 5.95585 26.3733 5.95585 37.5027 5.95585C40.338 5.95585 43.1846 5.95585 46.02 5.95585C47.0023 5.95585 47.1921 6.157 47.1921 7.15159C47.1921 8.13499 47.1586 9.1184 47.2032 10.0906C47.2255 10.6494 47.0804 10.8058 46.5111 10.7947C42.7716 10.7611 39.0208 10.7835 35.2812 10.7835C31.8431 10.7835 28.3937 10.7835 24.9556 10.7835C17.8225 10.7835 10.6894 10.7723 3.54514 10.7947C2.94234 10.8058 2.74141 10.6717 2.78606 10.0348ZM46.433 44.644C39.2887 44.6216 32.1556 44.6328 25.0114 44.6328C17.8448 44.6328 10.6782 44.6216 3.51165 44.644C2.90885 44.644 2.78606 44.4763 2.78606 43.8952C2.80839 33.7483 2.80839 23.5901 2.78606 13.4432C2.78606 12.8173 2.97583 12.7279 3.53398 12.7279C17.8448 12.7391 32.1445 12.7391 46.4553 12.7279C47.0916 12.7279 47.2255 12.8956 47.2255 13.5102C47.2032 23.6348 47.2032 33.7594 47.2255 43.8841C47.2255 44.5322 47.0469 44.644 46.433 44.644Z" />
                <path d="M15.4671 20.4053C15.7239 20.3829 15.9806 20.5729 16.215 20.8187C18.202 22.8526 20.1778 24.8976 22.1648 26.9203C22.4328 27.1997 22.4439 27.345 22.1537 27.6355C20.1444 29.5688 18.1574 31.5356 16.1592 33.4913C15.5787 34.0612 15.0094 34.1171 14.5852 33.6812C14.1499 33.2342 14.2169 32.6755 14.7973 32.1167C16.2932 30.664 17.7778 29.1889 19.296 27.7585C19.6978 27.3785 19.7425 27.1774 19.3183 26.7751C17.789 25.2552 16.2932 23.6796 14.7862 22.1262C14.4624 21.791 14.3062 21.4222 14.5183 20.964C14.708 20.6064 14.9759 20.4164 15.4671 20.4053Z" />
                <path d="M29.3649 32.0718C31.3519 32.0718 33.3389 32.0718 35.3259 32.0718C36.152 32.0718 36.6096 32.4071 36.6208 33.0105C36.6431 33.6252 36.1408 34.0051 35.2924 34.0051C31.2961 34.0051 27.2998 34.0051 23.3035 34.0051C22.4886 34.0051 22.0309 33.6587 22.0086 33.0664C21.9862 32.4294 22.4551 32.083 23.3258 32.083C25.3351 32.0718 27.3556 32.0718 29.3649 32.0718Z" />
            </DefaultIcon>
        </>
    );
};



