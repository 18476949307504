import { Grid, Typography } from "@material-ui/core";
import { CardCargaProps } from "./card-carga-props";
import { useCardCargaStyles } from "./card-carga-styles";
import { alterarCorStatusSituacao } from "../../../../utils/alterar-cor-situacao";
import { alterarNomeSituacao } from "../../../../utils/alterar-nome-situacao";
import { formatDataHora } from "../../../../utils/format-data-hora";
import { DefaultCard } from "../components";
import { CargasProdutos } from "../../modals/cargas-produtos/cargas-produtos";
import { useState } from "react";
import { CargasProdutosModel } from "../../../../model/api/cargas-produto/cargas-produtos-model";


export const CardCarga = ({
  cargasId,
  cargasModel,
  lojaId,
  sincronizacaoId,
  model
}: CardCargaProps) => {
  const classes = useCardCargaStyles()
  const [aberto, setAberto] = useState(false);(new CargasProdutosModel())

  return (
    <>
      <CargasProdutos
        aberto={aberto}
        cargasId={cargasId}
        fecharModal={() => { setAberto(false) }}
        lojaId={lojaId}
        sincronizacaoId={model.id}
      />
      {model?.cargas?.map((cargas) => {
        return (
          <>
            <DefaultCard onClick={() => {setAberto(true)}}>
              <div className={classes.containerHistorico}>
                <div className={classes.rootApp}>
                  <div className={classes.containerStatus}>
                    <Typography className={classes.titleDate}>
                      Última Atualização:  <strong className={classes.date}>{formatDataHora(new Date(cargas.ultimaAtualizacao))}</strong></Typography>
                    <div className={classes.situacao}
                      style={{ background: alterarCorStatusSituacao(model?.tpStatusSincronizacao) }}>
                      <Typography className={classes.titleStts}>
                        {alterarNomeSituacao(model?.tpStatusSincronizacao)}</Typography>
                    </div>
                  </div>
                  <div className={classes.divider} />
                  <Grid container spacing={2}>
                    <Grid item md={5}>
                      <Typography className={classes.titleCargas}>Total Sincronizado:</Typography>
                      <Typography className={classes.titleCargasSincronizados}>{cargas.totalProdutos}</Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography className={classes.titleCargas}>Total Produtos:</Typography>
                      <Typography className={classes.titleCargasPendentes}>{cargas.totalProdutos}</Typography>
                    </Grid>
                    <Grid item md={5}>
                      <Typography className={classes.titleCargas}>Total Rejeitados:</Typography>
                      <Typography className={classes.titleCargasRejeitados}>{cargas.produtosProdutosRejeitados}</Typography>
                    </Grid>
                    <Grid item md={5}>
                      <Typography className={classes.titleCargas}>Total Pendentes:</Typography>
                      <Typography className={classes.titleTotalProdutos}>{cargas.produtosPendentes}</Typography>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DefaultCard>
          </>
        )
      })}
    </>
  )
}

