import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';


export function useDeleteAplicacao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();
    const apiUrlHomo = 'https://sauruspedidosapi-staging.azurewebsites.net';

    const deleteAplicacao = useCallback(
        (idAplicacao: string) =>
            invocarApi({
                url: `api/v2/aplicacao/${idAplicacao}`,
                method: "DELETE",
                baseURL: apiUrlHomo,
                headers: { "Content-Type": "application/json" },
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        deleteAplicacao
    }
}

