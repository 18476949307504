import { Redirect, Route } from "react-router-dom";
import { CadastrosProvider } from "../../../service/app/providers/cadastros-provider";
import { DashboardAdminPage } from "../../../views/pages/private/administracao/dashboard-admin-page";
import { MenuAdmin } from "../../../views/pages/private/administracao/components/menu-admin/menu-admin";

const RouterMainAdmin = () => {

    return (
        <MenuAdmin>
            <CadastrosProvider>
                <Route path="/dashboard-admin" component={DashboardAdminPage} />
                <Route exact path="/">
                    <Redirect to="/dashboard-admin" />
                </Route>
            </CadastrosProvider>
        </MenuAdmin>
    )
}

export default RouterMainAdmin;