import { PedidosModel } from "../../../../../../model/api/pedidos/pedidos-model";
import { CardNaoEncontrado } from "../../../../../components/cards/card-nao-encontrado";
import { CardPedidos } from "../../../../../components/cards/card-pedidos/card-pedidos";
import { InformacaoIcon } from "../../../../../components/icons";

export interface PedidosListDataProps {
  list: Array<PedidosModel>;
  lojaId: string
  carregando: boolean;
  selectedList: Array<string>;
  onCardChecked: (id: string) => any;
}

export const PedidosListData = (props: PedidosListDataProps) => {

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhum Pedido Encontrado."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((pedidos, index) => {
          return (
            <CardPedidos
              index={index}
              selected={
                props.selectedList.filter((item) => item === pedidos.id).length >
                  0
                  ? true
                  : false
              }
              lojaId={props.lojaId}
              onCheck={onCardChecked}
              model={pedidos}
              key={pedidos.id}
            />
          );
        })}
    </>
  );
};
