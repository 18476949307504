import { Button, Tooltip } from "@material-ui/core"
import { useStyles } from "./button-private-header-styles";

interface ButtonIconProps {
    icon: JSX.Element;
    tooltip: string;
    onClick: () => any;
}

export const ButtonPrivateHeader = (props: ButtonIconProps) => {
    const classes = useStyles();
    return (
        <>
            <Tooltip arrow title={props.tooltip} placement="right">
                <Button className={classes.button} onClick={props.onClick}>
                    {props.icon}
                </Button>
            </Tooltip>
        </>
    )

}

