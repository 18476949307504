import { useCallback } from 'react';
import { useApiBase } from '../../base';

export class AuthorizationProps {
    public constructor(
        public email: string = '',
        public senha: string
    ) { }
}

export function useAuthorization() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const patchAuthorization = useCallback(
        (autenticacaoProps: AuthorizationProps) =>
            invocarApi({
                method: 'POST',
                url: `/api/v2/authorization`,
                data: new URLSearchParams({
                    'email': btoa(autenticacaoProps.email),
                    'senha': btoa(autenticacaoProps.senha)
                }),
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                enviarTokenUsuario: false
            }),

        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        patchAuthorization,
    };
}

