import { useState } from "react"
import { SincronizacaoHeader } from "./components/sincronizacao-header/sincronizacao-header"
import SincronizacaoList from "./components/sincronizacao-list/sincronizacao-list"
import { SincronizacaoListSearchProps } from "./components/sincronizacao-list/sincronizacao-list-search-props"
import { useDefaultSincronizacaoStyles } from "./sincronizacao-styles"

export const SincronizacaoPage = () => {
    const classes = useDefaultSincronizacaoStyles()

    const [openPesquisa, setOpenPesquisa] = useState(true);
    const [filtro, setFiltro] = useState<SincronizacaoListSearchProps>({
        lojaId: '',
        lojaNome: '',
        dataFinal: '',
        dataInicio: '',
        aplicacao: ''
    })

    return (
        <>
            <div className={classes.root}>
                <div className={classes.header}>
                    <SincronizacaoHeader
                        openPesquisa={openPesquisa}
                        setSearchProps={setFiltro}
                        setOpenPesquisa={setOpenPesquisa}
                    />
                </div>
                <SincronizacaoList searchProps={filtro} />
            </div>
        </>
    )
}