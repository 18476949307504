import { makeStyles } from "@material-ui/core";

export const useDefaultCadastroStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        borderRadius: '8px',
        flexDirection: 'column',
        background: '#fff',
        boxShadow: '0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)',
    },
    header: {
        flex: "0 1 auto"
    },
    list: {
        flex: "1 1 100%",
        overflow: 'hidden',
        position: "relative",
    },
    content: {
        flex: "1",
        display: "flex",
        flexDirection: "column",
    },
    defaultContainer: {
        position: "relative",
        width: "100%",
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden"
    },
    acoes: {
        flex: "0 0 auto",
        padding: theme.spacing(2),
    },
}));
