import { EnderecoModel } from "./endereco-model";
import { SubItensModel } from "./sub-itens-model";


export class ItemsModel {
    constructor(
        public id: string = '',
        public nItem: number = 0,
        public codigoReferencia: string = '',
        public descricao: string = '',
        public codigoLoja: string = '',
        public pesoVariavel: boolean = true,
        public codigoBarra: Date = new Date(),
        public plu: string = '',
        public observacao: EnderecoModel[] = new Array<EnderecoModel>(),
        public quantidade: number = 0,
        public subItens: SubItensModel[] = new Array<SubItensModel>(),
        public valor: number = 0,
        public valorTotal: number = 0,
        public valorAdicional: number = 0,
        public valorSubItem: number = 0,
        public indisponivel: boolean = true,
        public desistencia: boolean = true,
        public valorDesconto: number = 0,
    ) {}
}