import { Button, Grid } from "@material-ui/core";
import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { useCadastros } from "../../../../../../service/app/providers/cadastros-provider";
import { useToastSaurus } from "../../../../../../service/app/use-cases";
import { ButtonModalHeader } from "../../../../controles/buttons/button-modal-header";
import { DefaultFormRefs } from "../../../../form/utils";
import { SalvarNovoIcon, VoltarIcon } from "../../../../icons";
import { ModalHeader } from "../../../modal-header";
import { CardExcluir } from "../../../../cards/card-excluir/card-excluir";
import { EditarLojaModel } from "../../../../../../model/api/loja/editar-loja-model";
import { LojaModel } from "../../../../../../model/api/loja/loja-model";
import { CircularLoading } from "../../../..";
import { usePutAtualizarEmpresaDados } from "../../../../../../data/api/gestao/lojas/put-atualizar-loja-dados";
import { FormEditarLoja } from "../../../../form/master/editar-loja/form-editar-loja";
import { useModalStyles } from "../../../../utils/modals/modal-styles";
import { LixoIcon } from "../../../../icons/lixo-icon";

interface EditarLojaProps {
  id: string;
  model: LojaModel;
  editar: EditarLojaModel;
}

export const EditarLoja = ({ id, model, editar }: EditarLojaProps) => {
  const classes = useModalStyles();
  const { showToast } = useToastSaurus();
  const { putAtualizarEmpresaDados, carregando } = usePutAtualizarEmpresaDados();
  const [aberto, setAberto] = useState(false);
  const { fecharCadastroNovaLoja, CadastroNovaLojaState } = useCadastros();

  const cadEditarLojaFormRef =
    useRef<DefaultFormRefs<EditarLojaModel>>(null);

  useEffect(() => {
    cadEditarLojaFormRef.current?.fillForm(
      new EditarLojaModel(model.id, model.documento, model.razaoSocial),
    )
  }, [model.documento, model.id, model.razaoSocial])

  const editarLoja = useCallback(
    async (id: string, editar: EditarLojaModel) => {
      const ret = await putAtualizarEmpresaDados(id, editar);
      if (ret.erro) {
        throw ret.erro;
      }
    }, [putAtualizarEmpresaDados]);

  const handleSubmit = useCallback(async (editar: EditarLojaModel) => {
    try {
      await editarLoja(id, editar);
      fecharCadastroNovaLoja();
      showToast('success', "Loja Editada!")
    } catch (e: any) {
      showToast('error', e.message);
      cadEditarLojaFormRef.current?.resetForm()
    }
  }, [editarLoja, id, fecharCadastroNovaLoja, showToast])


  useEffect(() => {
    if (!CadastroNovaLojaState.aberto) {
      handleSubmit(editar)
      editarLoja(id, editar)
    }
  }, [CadastroNovaLojaState.aberto, editar, editarLoja, handleSubmit, id])


  const onCloseClick = useCallback(() => {
    fecharCadastroNovaLoja()
  }, [fecharCadastroNovaLoja]);

  return (
    <>
      <CardExcluir id={id} aberto={aberto} fecharModal={() => setAberto(false)} />
      <div className={classes.root}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <ModalHeader
          title={"Editar Loja"}
          leftArea={
            <ButtonModalHeader
              tooltip="Voltar"
              icon={<VoltarIcon tipo="MODAL_HEADER" />}
              onClick={onCloseClick}
            />
          }
        />
        <div className={classes.content} style={{
          position: 'relative',
          maxHeight: '85vh'
        }}>
          <div className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}>
            <FormEditarLoja
              ref={cadEditarLojaFormRef}
              showLoading={false}
              loading={carregando}
              onSubmit={handleSubmit}
              model={new EditarLojaModel(model.id, model.documento)}
            />
          </div>
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Button
                className={classes.buttonRemover}
                disabled={carregando}
                onClick={() => {
                  setAberto(true)
                }}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
              >
                <LixoIcon tipo="BUTTON" />
                Excluir
              </Button>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Button
                disabled={carregando}
                onClick={() => cadEditarLojaFormRef?.current?.submitForm()}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarNovoIcon tipo="BUTTON_PRIMARY" />
                Salvar
              </Button>
            </Grid>
          </Grid>
        </div>


      </div>
    </>
  )
}

