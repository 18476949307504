import { DashboardHeader } from "./components/dashboard-header/dashboard-header"
import { useDefaultDashboardStyles } from "./dashboard-page-styles"
import { DashboardList } from "./components/dashboard-list/dashboard-list"

export const DashboardPage = () => {
    const classes = useDefaultDashboardStyles()

    return (
        <>
            <div className={classes.root}>
                <div className={classes.header}>
                    <DashboardHeader />
                </div>
                <DashboardList />
            </div>
        </>
    )
}