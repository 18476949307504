import { CircularLoadingProps } from './circular-loading-props'
import classNames from "classnames";
import { useStyles } from "./circular-loading-styles";

export const CircularLoading = (props: CircularLoadingProps) => {
    const classes = useStyles();

    var myclass =
        props.tipo === 'FULLSIZED' ? classes.fullSized :
            props.tipo === 'ONAUTOCOMPLETE' ? classes.onAutoComplete :
                classes.normal;

    return (
        <>
            <div className={classNames(myclass, "loader-container circle-pulse-multiple")}>
                <div className="loader">
                    <div className="circle-1"></div>
                    <div className="circle-2"></div>
                    <div className="circle-3"></div>
                </div>
            </div>
        </>
    )
}
