import { EnumSituacaoProcessamento } from "../model/enums/enum-situacao-processamento"


export function alterarNomeSituacao(numero: number) {

    switch (numero) {
        case EnumSituacaoProcessamento.PROCESSANDO:
            return "Em Preparo"
        case EnumSituacaoProcessamento.RECEBIDO:
            return 'Recebido'
        case EnumSituacaoProcessamento.NOTIFICADO:
            return 'Notificado'
        case EnumSituacaoProcessamento.FINALIZADO:
            return 'Finalizado'
        case EnumSituacaoProcessamento.CANCELADO:
            return "Cancelado"
        case EnumSituacaoProcessamento.INVALIDA:
            return "Cancelado"
        default:
            return '';
    }
}