import { createStyles, makeStyles } from '@material-ui/styles';
import Continuar from '../icons/icon-img/continuar.png'

export const ContinuarIcon = () => {
  const useStyles = makeStyles(() => 
    createStyles({
      img: {
        marginBottom: 0,
        marginRight: 8,
        width: '24px',
        height: '24px'
      },
    })
  )
  const classes = useStyles();
  return (
    <>
      <img
      className={classes.img}
      src={Continuar} 
      alt="" 
      />
    </>
  );
};
