import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    width: "35vw",
    height: "100%",
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.primary.main,

    [theme.breakpoints.down("md")]: {
      borderLeftWidth: "0px",
      width: "50vw",
    }, [theme.breakpoints.down("sm")]: {
      width: "100vw",
      borderLeftWidth: "0px",
    },
  },
}));

