import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    margin: '4px 10px 25px',
    fontSize: '17px',
    lineHeight: '30px'
  },
  content: {
    paddingBottom: '15px'
  },
  divider: {
    background: theme.palette.primary.light,
    height: '1px',
    margin: `${theme.spacing(2)} 0`
  },
}));
