import { makeStyles } from "@material-ui/core";

export const useCardAplicacaoDashboardStyles = makeStyles((theme) => ({
    aplicacaoImg: {
        height: '117px',
        width: '120px',
        '& img': {
            height: '100%',
            maxWidth: '120px',
            borderRadius: theme.shape.borderRadius,
            objectFit: 'contain',
            backgroundColor: '#e8e8e8'
        },
    },
}));
