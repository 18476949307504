import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { CredenciaisDadosModel } from "../../../../model/api/credenciais/credenciais-dados-model";

export function usePutEditarCredenciais() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putEditarCredenciais = useCallback(
        (lojaId: string, credencialId: string, model: CredenciaisDadosModel) => {
            return invocarApi({
                url: `/api/v2/loja/${lojaId}/credencial-dados/${credencialId}`,
                method: "PUT",
                data: model,
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        putEditarCredenciais,
    };
}


