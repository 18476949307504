import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';
export const AddIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <g clip-path="url(#clip0_1_1710)">
          <path d="M7.32232 7.32233C2.59812 12.0465 -0.023054 18.3252 0.00742489 25C-0.023054 31.6748 2.59812 37.9535 7.32232 42.6777C12.0465 47.4019 18.3251 50.023 25 49.9926C31.7053 49.9926 37.9535 47.4019 42.6777 42.6777C47.4019 37.9535 50.023 31.6748 49.9926 25C49.9926 18.2947 47.4019 12.0465 42.6777 7.32233C37.9535 2.59813 31.6748 -0.0230448 25 0.00743379C18.3251 -0.0230445 12.0465 2.59813 7.32232 7.32233ZM9.15105 9.15106C13.3876 4.91451 18.9957 2.59765 25 2.56717C31.0043 2.53669 36.6124 4.91451 40.8489 9.15106C45.0855 13.3876 47.4023 18.9957 47.4328 25C47.4023 31.0043 45.0855 36.6124 40.8489 40.8489C36.6124 45.0855 31.0043 47.4023 25 47.4328C18.9957 47.4633 13.3876 45.0855 9.15105 40.8489C4.9145 36.6124 2.59764 31.0043 2.56716 25C2.53668 18.9957 4.9145 13.3876 9.15105 9.15106ZM15.8754 24.0666C15.643 24.299 15.506 24.6191 15.5212 24.9696C15.5212 25.7011 16.1001 26.28 16.8011 26.2495L23.7201 26.2799L23.6898 33.1989C23.6898 33.9304 24.2686 34.5092 24.9696 34.4788C25.3354 34.4788 25.6706 34.3266 25.884 34.1133C26.0973 33.8999 26.2495 33.5646 26.2495 33.1989L26.2799 26.2799L33.1685 26.2799C33.5343 26.2799 33.8695 26.1277 34.0829 25.9144C34.2962 25.701 34.4484 25.3657 34.4484 25C34.4484 24.2685 33.8695 23.6897 33.1685 23.7201L26.2799 23.7201V16.8315C26.2799 16.1 25.701 15.5214 25 15.4909C24.2685 15.4909 23.6896 16.0697 23.7201 16.7707V23.7201L16.8011 23.6898C16.4353 23.6898 16.1078 23.8342 15.8754 24.0666Z"  fill="#fff" />
        </g>
        <defs>
          <clipPath id="clip0_1_1710">
            <rect width="50" height="50" fill="#fff" />
          </clipPath>
        </defs>
      </DefaultIcon>
    </>
  );
};
