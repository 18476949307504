import { Button, Fade, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import { useCadastros } from '../../../../service/app/providers/cadastros-provider';
import { MaisInformacaoIcon } from '../../icons/mais-informacao';
import { CardPedidosProps } from './card-pedidos-props';
import { useCardPedidosStyles } from './card-pedidos-styles';
import { formatDataHora } from '../../../../utils/format-data-hora';
import { alterarCorStatusPedido } from './utils/alterar-cor-pedido';
import { alterarNomePedido } from './utils/alterar-nome-pedido';
import classNames from 'classnames';

export const CardPedidos = ({
   model,
   lojaId,
   index
}: CardPedidosProps) => {
   const classes = useCardPedidosStyles();
   const { abrirDetalhesPedidos } = useCadastros()

   return (
      <>
         <Fade in timeout={900}>
            <>
               <Grid className={classNames(classes.card, index % 2 === 0 && classes.cardCinza)}>
                  <Button variant="text" fullWidth style={{
                     padding: 0
                  }} onClick={() => abrirDetalhesPedidos(lojaId, model.id, '/detalhes', true)}>
                     <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                           <TableBody>
                              <TableRow>
                                 <TableCell color='#fff' width='10%' scope="row" size="small">{model.dadosIntegracao?.nomeIntegrador}</TableCell>
                                 <TableCell width='10%' align="left">{model?.codigoPedido}</TableCell>
                                 <TableCell width='10%' align="left">{model?.cliente?.nome}</TableCell>
                                 <TableCell width='10%' align="left">{model?.valorTotal?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                 <TableCell width='10%' align="left">{formatDataHora(new Date(model?.dataCriacao))}</TableCell>
                                 <TableCell width='10%' align="center">
                                    <Typography className={classes.titleStatusPedido} style={{ background: alterarCorStatusPedido(model?.tpStatusPedido) }}>{alterarNomePedido(model?.tpStatusPedido)}</Typography></TableCell>
                                 <TableCell width='5%' align="left"> <MaisInformacaoIcon /></TableCell>
                              </TableRow>
                           </TableBody>
                        </Table>
                     </TableContainer>
                  </Button>
               </Grid>
            </>
         </Fade>
      </>
   );
};
