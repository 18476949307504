import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useFormLojaValidation } from './form-nova-loja-validation';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LojaModel } from '../../../../../model/api/loja/loja-model';
import { makeUtilClasses, useThemeQueries } from '../../../../../theme';
import { useToastSaurus } from '../../../../../service/app/use-cases';
import { picker } from '../../../../../utils/picker';
import { validarCPFCNPJ } from '../../../../../utils/cpfcnpj-validate';
import { CircularLoading } from '../../../utils';
import { TextFieldSaurus } from '../../../controles/inputs';
import { LojasRefs, LojasProps } from '../editar-loja/form-loja-props';

export const FormNovasLojas = forwardRef<
  LojasRefs<LojaModel>,
  LojasProps<LojaModel>
>(
  (
    { loading, onSubmit, ...props }: LojasProps<LojaModel>,
    ref,
  ) => {
    const { FormLojaValidationYup } = useFormLojaValidation()
    const { showToast } = useToastSaurus()
    const { isMobile } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
      getValues,
    } = useForm<LojaModel>({
      resolver: yupResolver(FormLojaValidationYup),
      criteriaMode: 'all',
      mode: 'onBlur' && 'onChange',
    });

    const submitAlterarDadosConta = (values: LojaModel) => {
      const pessoa = picker<LojaModel>(values, new LojaModel())
      if (!validarCPFCNPJ(pessoa.documento)) {
        return showToast('error', 'CPF/CNPJ Inválido')
      }
      onSubmit(pessoa);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitAlterarDadosConta)();
      },
      resetForm: () => {
        reset();
        if (!isMobile) refInputNome.current?.focus();
      },
      fillForm: (model: LojaModel) => {
        reset({ ...model });
        if (!isMobile) refInputNome.current?.focus();
      },
    }));

    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {loading && props.showLoading ? (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="NORMAL" />
              </div>
            ) : null}

            <form
              onSubmit={handleSubmit(submitAlterarDadosConta)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Box my={3}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Controller
                      name="documento"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          tipo="CNPJ_CPF"
                          fullWidth={true}
                          showStartAdornment={true}
                          autoComplete={'off'}
                          label="CPF / CNPJ"
                          placeholder="Ex: 99.999.999/9999-99"
                          error={Boolean(
                            errors.documento && errors.documento.message,
                          )}
                          helperText={
                            touchedFields.documento || errors.documento
                              ? errors.documento?.message
                              : undefined
                          }
                          {...field}
                          value={getValues('documento')}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="razaoSocial"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          tipo="TEXTO"
                          fullWidth
                          showStartAdornment={true}
                          allowSubmit={false}
                          autoComplete={'off'}
                          label="Razão Social"
                          placeholder="Ex: Ryan e Leandro Ferragens Ltda"
                          error={Boolean(
                            errors.razaoSocial && errors.razaoSocial.message,
                          )}
                          helperText={
                            touchedFields.razaoSocial || errors.razaoSocial
                              ? errors.razaoSocial?.message
                              : undefined
                          }
                          {...field}
                          value={getValues('razaoSocial')}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
