import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { AdicionarLojaModel } from '../../../../model/api/loja/adicionar-loja-model';


export function usePostAdicinarLoja() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postAdicionarLoja = useCallback(
        (model: AdicionarLojaModel) =>
            invocarApi({
                url: `/api/v2/loja/adicionar`,
                method: "PUT",
                data: model,
                headers: { "Content-Type": "application/json" },
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        postAdicionarLoja
    }
}