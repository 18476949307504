import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => {

    return ({
        "@keyframes fadein": {
            "0%": {
                opacity: 0
            },
            "100%": {
                opacity: 1
            }
        },
        publicPageHeaderContainer: {
            position: 'fixed',
            zIndex: -1,
            top: 0,
            right: 0,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',

        },
        publicPageHeaderContainerWhite: {
            "& svg": {
                width: '20vw',
                [theme.breakpoints.up('sm')]: {
                    width: '17vw',
                },
                [theme.breakpoints.up('md')]: {
                    width: '10vw',
                },
                [theme.breakpoints.up('lg')]: {
                    width: '9vw',
                }
            }
        },
        publicPageHeaderContainerBlue: {
            "& svg": {
                width: '20vw',
                [theme.breakpoints.up('sm')]: {
                    width: '20vw',
                },
                [theme.breakpoints.up('md')]: {
                    width: '10vw',
                },
                [theme.breakpoints.up('lg')]: {
                    width: '12vw',
                }
            }
        },
        publicPageHeaderTopContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            "& svg:nth-child(2)": {
                marginTop: '20px',
            },
        },
        publicPageHeaderRightContainer: {
            position: 'fixed',
            zIndex: -1,
            right: 0,
            top: '50%',
            transform: `translateY(-50%)`,

        },
        publicPageHeaderBottomContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',

        },
        publicPageHeaderVersionControl: {
            position: 'fixed',
            bottom: 0,
            right: 0,
            pointerEvents: "none",
            opacity: 0.6,
            "& *": {
                float: 'left',
                marginRight: 3,
                color: '#000',

            },
            "& b": {
                fontSize: 10,
                fontWeight: "normal",
            },
            "& i": {
                fontSize: 10,
                fontWeight: "bold",
            }
        }
    })
});

