import { CardSincronizacao } from "../../../../../components/cards/card-sincronizacao/card-sincronizacao";
import { CardNaoEncontrado } from "../../../../../components/cards/card-nao-encontrado";
import { InformacaoIcon } from "../../../../../components/icons";
import { SincronizacaoDadosModel } from "../../../../../../model/api/sincronizacao/sincronizacao-dados-model ";

export interface SincronizacaoListDataProps {
    list: Array<SincronizacaoDadosModel>;
    lojaId: string;
    carregando: boolean;
    selectedList: Array<string>;
    onCardChecked: (id: string) => any;
}

export const SincronizacaoListData = (props: SincronizacaoListDataProps) => {

    const onCardChecked = (id: string) => {
        props.onCardChecked(id);
    };

    return (
        <>
            {(props.list.length === 0 && !props.carregando) && (
                <CardNaoEncontrado
                    mensagem="Nenhuma informação encontrada."
                    icon={<InformacaoIcon tipo="GERAL" />}
                />
            )}
            {
                props.list?.length > 0 &&
                props.list?.map((sincronizacao, index) => {
                    return (
                        <>
                            <CardSincronizacao
                                index={index}
                                selected={
                                    props.selectedList.filter((item) => item === sincronizacao.id).length >
                                        0
                                        ? true
                                        : false
                                }
                                onCheck={onCardChecked}
                                lojaId={props.lojaId}
                                model={sincronizacao}
                                key={sincronizacao.id}
                            />
                        </>
                    );
                })
            }
        </>
    )

}

