import { Checkbox, CheckboxProps, withStyles } from "@material-ui/core";

interface CheckboxDefaultProps extends CheckboxProps {
  classesOutro?: string
}
export const CheckboxDefault = withStyles(theme => ({
  root: {
    "&": {
      color: theme.palette.text.disabled,
    },
    "& svg": {
      width: "30px",
      height: "30px"
    },
    "&$checked": {
      color: theme.palette.primary.main,
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
      },
      "& .MuiIconButton-label:after": {
        content: '""',
        left: 4,
        top: 4,
        height: 25,
        width: 25,
        position: "absolute",
        backgroundColor: theme.palette.primary.contrastText,
        zIndex: -1
      }
    }
  },
  checked: {}
}))(({ ...props }: CheckboxDefaultProps) => {

  return (
    <>
      <Checkbox color="default" {...props} />
    </>
  )
})





