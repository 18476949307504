import { EnumStatusProdutos } from "../model/enums/enum-status-produtos";

export function alterarNomeStatusProdutos(numero: number) {

    switch (numero) {
        case EnumStatusProdutos.Pendente:
            return "Pendente"
        case EnumStatusProdutos.Atualizado:
            return 'Atualizado'
        case EnumStatusProdutos.Rejeitado:
            return 'Rejeitado'
        default:
            return '';
    }
}