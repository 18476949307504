import { useCallback } from 'react';
import * as Yup from 'yup';

export const useValidationYupSenha = () => {
    const senhaYup = useCallback(() => {
        return (
            Yup.string().required('A senha é obrigatória.').min(6, 'Senha inválida. (6-32 caracteres)').max(32, 'Senha inválida. (6-32 caracteres)')
        )
    }, [])


    return{
        senhaYup
    }
}