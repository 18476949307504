import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';

import { useStyles } from './login-page-styles';
import { makeUtilClasses } from '../../../../theme';
import { ApiError } from '../../../../model/app/errors/api-erros';
import { LoginFormModel } from '../../../../model/app/forms/master/login-form';
import { CheckboxDefault } from '../../../../views/components/checkboxes';
import { FormLogin } from '../../../components/form/master/login/form-login';
import { CircularLoading } from '../../../components/utils';
import { DefaultFormRefs } from '../../../components/form/utils/form-default-props';
import { LoginIcon } from '../../../components/icons';
import { PublicPageHeader } from '../../../components/headers/header-public-page/header-public-page';
import { useToastSaurus } from '../../../../service/app/use-cases/toast-saurus';
import { useSessaoAtual } from '../../../../service/app/providers';

export const LoginPage = () => {
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const history = useHistory();
  const { logar, carregando, usuario, tipoUsuario } = useSessaoAtual();
  const [bloqueado, setBloqueado] = useState(false);
  const chkManterConectado = useRef<HTMLInputElement>();
  const loginRefs = useRef<DefaultFormRefs<LoginFormModel>>(null);

  useEffect(() => {
    if (loginRefs) loginRefs.current?.fillForm(new LoginFormModel('', ''));
  }, [usuario]);

  const efetuarLogin = async (model: LoginFormModel) => {
    try {
      
      await logar(model.email, model.senha);
      setBloqueado(true);
      if (tipoUsuario() > 0) {
        return history.push('/dashboard');
      } else {
        return history.push('/dashboard-admin');
      }
    } catch (e: any) {
      if (e.constructor.name === ApiError.name) {
        history.push(`/ativar-email?email=${model.email}`);
        return;
      } else {
        showToast('error', e.message);
        loginRefs.current?.resetForm();
      }
    }
  };

  return (
    <>
      <PublicPageHeader topOpacity="0.8" />
      <div className={utilClasses.contentCenter}>
        <Card className={classes.cardLogin}>
          <Grid container className={utilClasses.rootContainer}>
            {carregando || bloqueado ? (
              <CircularLoading tipo="FULLSIZED" />
            ) : null}
            <Grid item xs={12}>
              <Grid container alignItems="center" direction="column">
                <Grid item xs={10} md={6}>
                  <Typography style={{
                    fontSize: '42px',
                    fontWeight: 700
                  }} align="center" color="primary">
                    Login
                  </Typography>
                  <Box mt={3}>
                    <Typography align="center" variant="body1">
                      Olá bem vindo de volta! Por favor, faça o login digitando as informações abaixo para poder continuar.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={8} lg={8} style={{ width: '100%' }}>
                  <FormLogin
                    showLoading={false}
                    ref={loginRefs}
                    loading={carregando}
                    onSubmit={(model) => {
                      efetuarLogin(model);
                    }}
                  />
                  <Box style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <FormControlLabel
                      disabled={carregando || bloqueado}
                      ref={chkManterConectado}
                      control={<CheckboxDefault />}
                      label="Mantenha-me conectado"
                    />
                  </Box>
                  <Box mt={4}>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        fullWidth={true}
                        disabled={carregando || bloqueado}
                        size="large"
                        onClick={() => {
                          loginRefs.current?.submitForm();
                        }}
                      >
                        <LoginIcon tipo="BUTTON_PRIMARY" />
                        Acessar
                      </Button>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </div>
    </>
  );
};

export default LoginPage;
