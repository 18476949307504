import { Button, Fade, Grid, Tooltip, Typography } from "@material-ui/core";
import classNames from "classnames";
import { isEqual } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useGetAplicacao } from "../../../../../../data/api/gestao/aplicacao/get-aplicacao";
import { AplicacaoModel } from "../../../../../../model/api/aplicacao/aplicacao-model";
import { LojaModel } from "../../../../../../model/api/loja/loja-model";
import { useCadastros } from "../../../../../../service/app/providers/cadastros-provider";
import { useToastSaurus } from "../../../../../../service/app/use-cases";
import { ButtonModalHeader } from "../../../../controles/buttons/button-modal-header";
import { DefaultFormRefs } from "../../../../form/utils";
import { VoltarIcon } from "../../../../icons";
import { ContinuarIcon } from "../../../../icons/continuar";
import { CircularLoading } from "../../../../utils";
import { ModalHeader } from "../../../modal-header";
import { useAplicacaoStyles } from "./cadastro-aplicacao-styles";
import semImagem from "./../../../../../../assets/img/sem-imagem.jpg"
import { useModalStyles } from "../../../../utils/modals/modal-styles";

interface NovaAplicacaoProps {
  loja: LojaModel;
  selectedModel: AplicacaoModel | null;
  setSelectedModel: (model: AplicacaoModel) => void;
  setCadastro: (status: boolean) => void;
}

//Essa é a primeira tela

export const NovaAplicacao = ({ setSelectedModel, setCadastro, selectedModel, loja }: NovaAplicacaoProps) => {
  const { showToast } = useToastSaurus()
  const { getAplicacao, carregando } = useGetAplicacao()
  const { fecharCadastroNovaCredencial } = useCadastros();
  const classe = useAplicacaoStyles();
  const classes = useModalStyles();
  const [apps, setAplicacao] = useState<AplicacaoModel[]>([])

  const cadNovaCredencialFormRef =
    useRef<DefaultFormRefs<AplicacaoModel>>(null);
  useEffect(() => {
    cadNovaCredencialFormRef.current?.fillForm(
      new AplicacaoModel(),
    )
  }, [])

  const aplicacaoWrapper = useCallback(async () => {
    try {
      const res = await getAplicacao()
      if (res.erro) throw res.erro

      setAplicacao(res.resultado?.data)
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [getAplicacao, showToast])

  useEffect(() => {
    aplicacaoWrapper()
  }, [aplicacaoWrapper])

  const onCloseClick = useCallback(() => {
    fecharCadastroNovaCredencial()
  }, [fecharCadastroNovaCredencial]);



  return (
    <>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <div className={classes.root}>
        <ModalHeader
          title={"Adicionar Credencial"}
          leftArea={
            <ButtonModalHeader
              tooltip="Voltar"
              icon={<VoltarIcon tipo="MODAL_HEADER" />}
              onClick={onCloseClick}
            />
          }
        />
        <div className={classes.content}>
          <div
            className={classNames(
              classes.contentForms,
              carregando ? classes.contentFormsLoading : undefined,
            )}
          >
            <Grid container spacing={2}>
              <div className={classe.app}>
                <Typography
                  className={classe.titlePrincipal}
                >
                  Selecione uma integração para a sua loja
                </Typography>
              </div>
              <div className={classe.dividerA} />
              <Grid
                className={classe.imagem}
                item xs={12}>
                {apps.map(app => {
                  return (
                    <>
                      <Tooltip title={app.descricaoAplicacao}>
                        <Button
                          onClick={() => { setSelectedModel(app) }}
                        >
                          <Fade in timeout={600}>
                            <>
                              {(app.uriImagem) ?
                                <img src={app.uriImagem}
                                  style={{
                                    width: '100px',
                                    borderRadius: '10px',
                                    boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.05)',
                                    border: isEqual(app, selectedModel) ? '3px solid #A2A2A2' : 'none'
                                  }}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = semImagem;
                                  }} alt={app.descricaoAplicacao} />
                                :
                                <img src={semImagem}
                                  style={{
                                    width: '100px',
                                    borderRadius: '10px',
                                    boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.05)',
                                    border: isEqual(app, selectedModel) ? '3px solid #A2A2A2' : 'none'
                                  }}
                                  alt={app.descricaoAplicacao}
                                />
                              }
                            </>
                          </Fade>
                        </Button>
                      </Tooltip>
                    </>
                  )
                })}
              </Grid>
            </Grid>
          </div>
          <div className={classes.obs}>
            <div className={classes.containerImportacao}>
              <Typography className={classes.titleImportacao}>Ao escolher uma nova credencial, será pedido os campos para a integração.</Typography>
              <Typography className={classes.titleImportacao}>Obs: Os campos podem não ser os mesmos dependendo da credencial que for adicionada</Typography>
            </div>
          </div>
          <div className={classes.acoes}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Button
                  disabled={carregando || !selectedModel}
                  onClick={() => setCadastro(true)}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  <ContinuarIcon />
                  Continuar
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  )
}

