import { Button, Card, Grid, Typography } from "@material-ui/core";
import { CircularLoading } from "../..";
import { useGetRegistroCredencial } from "../../../../data/api/gestao/credenciais-dados/get-registro-credencial";
import { useStyles } from "./card-credencial-prod-styles";
import { TextFieldSaurus } from "../../controles/inputs";
import { TesteIcon } from "../../icons";
import { SelectSaurus } from "../../controles/selects/select-saurus/select-saurus";
import { TpSituacaoMock } from "../../../../data/mocks/tp-situacao-mock";
import { useHistory } from "react-router-dom";
import { useRef } from "react";

export const CardCredencialProd = () => {
  const { carregando } = useGetRegistroCredencial();
  const classes = useStyles();

  const scrollTargetRef = useRef<HTMLDivElement | null>(null);
  const history = useHistory();

  const scrollToTarget = () => {
    if (scrollTargetRef.current) {
      scrollTargetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const redirectToResult = () => {
    history.push('#resultado');
  };

  return (
    <>
      <Card className={classes.rootApp}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <TextFieldSaurus
              tipo="TEXTO"
              fullWidth
              disabled={carregando}
              className={classes.containerTexfield}
              showStartAdornment={true}
              allowSubmit={false}
              autoComplete={'off'}
              label="Usuário"
              placeholder="Ex: usuario@gmail.com"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextFieldSaurus
              tipo="PASSWORD"
              fullWidth
              disabled={carregando}
              className={classes.containerTexfield}
              showPasswordVisibilityButton
              allowSubmit={false}
              autoComplete={'off'}
              label="Senha"
              placeholder=""
            />
          </Grid>
          <div className={classes.divider} />
          <Grid item md={12} xs={12}>
            <TextFieldSaurus
              tipo="TEXTO"
              fullWidth
              disabled={carregando}
              className={classes.containerTexfield}
              showStartAdornment={true}
              allowSubmit={false}
              autoComplete={'off'}
              label="Documento da Loja"
              placeholder="Ex: 99.999.999/9999-99"
            />
          </Grid>
        </Grid >
      </Card>
      <div className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <Button
            disabled={carregando}
            variant="contained"
            color="primary"
            className={classes.button}
            fullWidth
            onClick={() => {
              scrollToTarget();
              setTimeout(redirectToResult, 1000);
            }}
          // onClick={() => cadEditarAplicacaoFormRef.current?.submitForm()}
          >
            <TesteIcon tipo="BUTTON_PRIMARY" />
            <Typography style={{ fontWeight: 700, fontSize: 16 }}>Teste Credencial</Typography>
          </Button>
        </Grid>
      </Grid>

      <div className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <TextFieldSaurus
            tipo="TEXTO"
            fullWidth
            disabled={carregando}
            showStartAdornment={true}
            allowSubmit={false}
            autoComplete={'off'}
            label="Código Pedido"
            placeholder="Ex: 2739"
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Button
            disabled={carregando}
            variant="contained"
            color="primary"
            className={classes.button}
            fullWidth
            // onClick={() => cadEditarAplicacaoFormRef.current?.submitForm()}
            onClick={() => {
              scrollToTarget();
              setTimeout(redirectToResult, 1000);
            }}
          >
            <TesteIcon tipo="BUTTON_PRIMARY" />
            <Typography style={{ fontWeight: 700, fontSize: 16 }}>Buscar Pedido</Typography>
          </Button>
        </Grid>
      </Grid>
      <div className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <TextFieldSaurus
            tipo="DATA"
            fullWidth
            disabled={carregando}
            showStartAdornment={true}
            allowSubmit={false}
            autoComplete={'off'}
            label="Data Inicial"
            placeholder=""
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <TextFieldSaurus
            tipo="DATA"
            fullWidth
            disabled={carregando}
            showStartAdornment={true}
            allowSubmit={false}
            autoComplete={'off'}
            label="Data Final"
            placeholder=""
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <TextFieldSaurus
            tipo="TEXTO"
            fullWidth
            disabled={carregando}
            showStartAdornment={true}
            allowSubmit={false}
            autoComplete={'off'}
            label="Código Pedido"
            placeholder="Ex: 2739"
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Button
            disabled={carregando}
            variant="contained"
            color="primary"
            className={classes.button}
            fullWidth
            onClick={() => {
              scrollToTarget();
              setTimeout(redirectToResult, 1000);
            }}
          >
            <TesteIcon tipo="BUTTON_PRIMARY" />
            <Typography style={{ fontWeight: 700, fontSize: 16 }}>
              Buscar Pedido Status
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <div className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <SelectSaurus
            variant="outlined"
            fullWidth
            disabled={carregando}
            conteudo={TpSituacaoMock}
            label="Status do Pedido"
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <TextFieldSaurus
            tipo="TEXTO"
            fullWidth
            disabled={carregando}
            showStartAdornment={true}
            allowSubmit={false}
            autoComplete={'off'}
            minRows={7}
            multiline
            label="Pedido dados JSON"
            placeholder="Resultado"
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Button
            disabled={carregando}
            variant="contained"
            color="primary"
            className={classes.button}
            fullWidth
            onClick={() => {
              scrollToTarget();
              setTimeout(redirectToResult, 1000);
            }}
          // onClick={() => cadEditarAplicacaoFormRef.current?.submitForm()}
          >
            <TesteIcon tipo="BUTTON_PRIMARY" />
            <Typography style={{ fontWeight: 700, fontSize: 16 }} >
              Alterar Status Pedido
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Card className={classes.rootReturn}>
        <Grid container spacing={2}>
          {carregando && <CircularLoading tipo="FULLSIZED" />}
          <Grid item md={12} xs={12}>
            <Typography className={classes.titleReturn}>Retorno Teste</Typography>
            <div className={classes.dividerReturn} />
          </Grid>
          <div ref={scrollTargetRef} />
          <Grid item md={12} xs={12}>
            <TextFieldSaurus
              tipo="TEXTO"
              fullWidth
              disabled={carregando}
              showStartAdornment={true}
              allowSubmit={false}
              autoComplete={'off'}
              label="Status Code"
              placeholder="Resultado"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextFieldSaurus
              tipo="TEXTO"
              fullWidth
              disabled={carregando}
              showStartAdornment={true}
              allowSubmit={false}
              autoComplete={'off'}
              multiline
              minRows={13.5}
              label="Resultado"
              placeholder="Resultado"
            />
          </Grid>
        </Grid>
      </Card >
    </>
  )
}

