import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  config: {
    margin: '16px 0',
    padding: '16px 16px',
    background: '#F0F0F0',
    boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.05)',
    borderRadius: '15px'
  },
   configCard: {
    width: '100%',
    height: '100%',
    padding: '18px 25px 23px 25px',
    boxShadow: "inset 0px 0px 4px rgba(0, 0, 0, 0.05)",
    border: '1px solid #808080',
    borderRadius: 8,
  },
  titleConfigCard: {
    color: '#5F5F5F',
    fontSize: 14,
    fontWeight: 600
  },
  containerTexfield: {
    backgroundColor: 'white!important', 
    borderRadius:'8px!important'
  },
  titleConfig: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    width: "100%",
    fontSize: "18px",
    fontWeight: 600,
    color: "#545454",
    padding: "11px 0 7px 10px",
  },
  divider: {
    width: "100%",
    height: "1px",
    marginBottom: 15,
    backgroundColor: "#E6E6E6",
  },
  dividerTitle: {
    width: '100%',
    height: '3px',
    borderRadius: '5px',
    backgroundColor: '#D1D1D1',
    margin: '0 11px 13px 11px'
  },
  dividerConfig: {
    width: "100%",
    height: "2px",
    marginBottom: 15,
    borderRadius: 5,
    backgroundColor: "#D1D1D1",
  },
}));
