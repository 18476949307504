import SaurusLogo from '../icons/icon-img/saurus-pedidos.png'

export const SaurusLogoIcon = () => {
  return (
    <>
      <img
        src={SaurusLogo}
        alt=""
      />
    </>
  );
};
