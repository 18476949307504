import { atom } from "recoil";
import { useMenuStorage } from "../menu-storage";

export function useMenuGlobalState() {
    const { get } = useMenuStorage();
    return {
        menuGlobalState: atom({
            key: 'menuGlobalState',
            default: get(),
        })
    }
}
