import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useGetRegistroCredencial() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getRegistroCredencial = useCallback(
        ( lojaId: string) => {
            return invocarApi({
                url: `/api/v2/loja/${lojaId}/credencial-dados`,
                method: "GET",
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getRegistroCredencial,
    };
}


