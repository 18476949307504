import { useCallback, useEffect, useState } from 'react'
import { Button, Fade, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core'
import { useCardDashboardSincronizacaoStyles } from './card-dashboard-sincronizacao-styles'
import { useToastSaurus } from '../../../../service/app/use-cases'
import { useHistory } from 'react-router-dom'
import { formatDataHora } from '../../../../utils/format-data-hora'
import { CircularLoading } from '../../utils'
import { isEmpty } from 'lodash'
import { CardNaoEncontrado } from '../card-nao-encontrado'
import { InformacaoIcon } from '../../icons'
import { useGetSincronizacaoDashboard } from '../../../../data/api/gestao/sincronizacao-dados/get-sincronizacao-dashboard'
import { SincronizacaoDadosModel } from '../../../../model/api/sincronizacao/sincronizacao-dados-model '
import { alterarNomeSituacao } from '../../../../utils/alterar-nome-situacao'
import { toDecimalString } from '../../../../utils/to-decimal'
import { alterarCorStatusSituacao } from '../../../../utils/alterar-cor-situacao'

export const CardDashboardSincronizacao = () => {
    const classes = useCardDashboardSincronizacaoStyles()
    const history = useHistory();
    const { showToast } = useToastSaurus()
    const { getSincronizacaoDashboard, carregando } = useGetSincronizacaoDashboard()

    const [queryStatus, setQueryStatus] = useState({
        page: 1,
        totalPages: 0,
        totalResults: 0,
        list: Array<SincronizacaoDadosModel>(),
    });

    const fillResult = useCallback(
        async (
            page: number,
            totalPages: number,
            totalResults: number,
            list: Array<SincronizacaoDadosModel>
        ) => {
            setQueryStatus({
                page: page,
                list: list,
                totalResults: totalResults,
                totalPages: totalPages,
            });
        },
        []
    );

    const search = useCallback(
        async (newPage: number) => {
            try {
                const resGet = await getSincronizacaoDashboard(newPage)
                if (resGet.erro) throw resGet.erro

                if (
                    resGet.resultado?.data?.pageIndex > resGet.resultado?.data?.totalPage &&
                    resGet.resultado?.data?.totalResult > 0
                ) {
                    search(resGet.resultado?.data?.totalPages);
                    return;
                }

                fillResult(
                    resGet.resultado?.data?.pageIndex,
                    resGet.resultado?.data?.totalPage,
                    resGet.resultado?.data?.totalResult,
                    resGet.resultado?.data?.results
                )
                return resGet;
            } catch (e: any) {
                showToast("error", e.message);
            }
        },
        [getSincronizacaoDashboard, fillResult, showToast]
    );

    useEffect(() => {
        search(queryStatus.page)
    }, [queryStatus.page, search]);

    const handleDashboard = () => {
        history.push('/sincronizacao');
    };

    return (
        <>
            {carregando ? (
                <CircularLoading tipo="FULLSIZED" />
            ) : null}
            <Fade in timeout={600}>
                <Tooltip title="Clique para ir à tela de Sincronização">
                    <div className={classes.telaGrid} onClick={handleDashboard}>
                        {!isEmpty(queryStatus.list) ?
                            <>
                                <Typography className={classes.titleTelas}>Tela de Sincronização</Typography>
                                <div className={classes.divider} />
                                <TableContainer component={Paper} className={classes.tablePaper}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.tableCell} width='10%' align="left">Aplicação</TableCell>
                                                <TableCell className={classes.tableCell} width='10%' align="left">Usuário</TableCell>
                                                <TableCell className={classes.tableCell} width='10%' align="left">Total de Produto</TableCell>
                                                <TableCell className={classes.tableCell} width='10%' align="left">Porcentagem (%)</TableCell>
                                                <TableCell className={classes.tableCell} width='10%' align="left">Último envio </TableCell>
                                                <TableCell className={classes.tableCell} width='10%' align="left">Situação</TableCell>

                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </TableContainer>

                                {queryStatus.list.filter((sincro, i) => i < 3).map((sincro, index) => {
                                    return (
                                        <>
                                            <Grid container>
                                                <Grid item xs={12} className={classes.tableContainer}>
                                                    <Button variant="text" fullWidth>
                                                        <TableContainer component={Paper} className={index % 2 === 0 ? classes.oddRow : undefined}>
                                                            <Table className={classes.tableResult} aria-label="simple table">
                                                                <TableBody>
                                                                    <TableRow key={index}>
                                                                        <TableCell className={classes.tableCellResult} width='10%' scope="row" size="small">{sincro.aplicacao}</TableCell>
                                                                        <TableCell className={classes.tableCellResult} width='10%' align="left">{sincro?.usuario}</TableCell>
                                                                        <TableCell className={classes.tableCellResult} width='10%' align="left">{toDecimalString(sincro.totalProdutos)} Itens</TableCell>
                                                                        {!isEmpty(sincro?.porcentagem) ? (
                                                                            <TableCell className={classes.tableCellResult} width='10%' align="left">{sincro?.porcentagem}%</TableCell>
                                                                        ) : (
                                                                            <TableCell className={classes.tableCellResult} width='10%' align="left">Sem porcentagem</TableCell>
                                                                        )}
                                                                        <TableCell className={classes.tableCellResult} width='10%' align="left">{formatDataHora(new Date(sincro.ultimoEnvio))}</TableCell>
                                                                        <TableCell className={classes.tableCellResult} width='10%' align="left">
                                                                            {sincro.situacao ? (
                                                                                <Typography className={classes.statusTable} style={{ background: alterarCorStatusSituacao(sincro.situacao) }}>
                                                                                    {alterarNomeSituacao(sincro.situacao)}
                                                                                </Typography>
                                                                            ) : (
                                                                                'Sem Situação'
                                                                            )}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>

                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                })}

                            </>
                            :
                            <CardNaoEncontrado
                                mensagem="Nenhuma informação encontrada."
                                icon={<InformacaoIcon tipo="GERAL" />}
                            />
                        }
                    </div>
                </Tooltip>
            </Fade>
        </>
    )
}



