import { EnumStatusProdutos } from "../model/enums/enum-status-produtos"

export function alterarCorStatusProdutos(numero: number) {
    switch (numero) {
        case EnumStatusProdutos.Pendente:
            return '#5333ED'
        case EnumStatusProdutos.Atualizado:
            return '#7AE28C'
        case EnumStatusProdutos.Rejeitado:
            return "#F44336"
        default:
            return '';
    }
}