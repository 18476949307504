import { Button, Grid, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useCallback, useEffect, useRef } from 'react';
import { useToastSaurus } from '../../../../service/app/use-cases';
import { DefaultFormRefs } from '../../form/utils';
import { usePatchValidacaoCredencial } from '../../../../data/api/gestao/credenciais-dados/patch-validacao-credencial';
import { VoltarIcon } from '../../icons';
import { useStyles } from './dialog-editar-validacao-ifood-codigo-styles';
import { FormCadastroCredencial } from '../../form/master/cadastro-credencial/form-cadastro-credencial';
import { AplicacaoModel } from '../../../../model/api/aplicacao/aplicacao-model';
import { LojaModel } from '../../../../model/api/loja/loja-model';
import { CredenciaisModelIfood } from '../../../../model/api/credenciais/credenciais-model-ifood';
import { CredenciaisDadosModel } from '../../../../model/api/credenciais/credenciais-dados-model';
import { usePutEditarCredenciais } from '../../../../data/api/gestao/credenciais-dados/put-editar-credenciais';
import { useGetRegistroCredencial } from '../../../../data/api/gestao/credenciais-dados/get-registro-credencial';
import { isEmpty } from 'lodash';
import { usePostRegistroCredencial } from '../../../../data/api/gestao/credenciais-dados/post-registro-credencial';
import { v4 as uuidv4 } from 'uuid';

interface DialogEditarValidacaoIfoodCodProps {
  openned: boolean;
  closeModal: () => void;
  ativo: boolean;
  aplicacao: AplicacaoModel;
  loja: LojaModel;
  credencialDados: CredenciaisDadosModel;
}

//Segundo dialog

export const DialogEditarValidacaoIfoodCod = ({
  openned,
  closeModal,
  aplicacao,
  loja,
  credencialDados,
  ativo
}: DialogEditarValidacaoIfoodCodProps) => {
  const classes = useStyles()
  const { showToast } = useToastSaurus()
  const { patchValidacaoCredencial, carregando } = usePatchValidacaoCredencial();
  const { putEditarCredenciais } = usePutEditarCredenciais();
  const { getRegistroCredencial } = useGetRegistroCredencial();
  const { postRegistroCredencial } = usePostRegistroCredencial();

  const cadCadastroCredencialFormRef =
    useRef<DefaultFormRefs<CredenciaisModelIfood>>(null);

  useEffect(() => {
    cadCadastroCredencialFormRef.current?.fillForm(
      new CredenciaisModelIfood()
    )
  }, [])


  const validarCredencial = useCallback(
    async (model: CredenciaisDadosModel, temCredencial: boolean) => {
      try {

        const lojaCredencial = loja.credenciais.find((cod: any) => cod.codigoAplicacao === model.codigoAplicacao)?.id


        if (temCredencial && lojaCredencial) {
          const ret = await putEditarCredenciais(loja.id, lojaCredencial!, model);
          if (ret.erro) {
            throw ret.erro;
          }
        } else {
          const res = await postRegistroCredencial(loja.id, model);
          if (res.erro) {
            throw res.erro;
          }
        }
      } catch (e: any) {
        showToast('error', e.message)
      }
    }, [loja, postRegistroCredencial, putEditarCredenciais, showToast]);

  const sincronizacaoWrapper = useCallback(async (model: CredenciaisDadosModel, patch: CredenciaisModelIfood) => {
    try {
      model = {
        ...model,
        credenciar: model.credenciar,
        codigoAplicacao: aplicacao.codigoAplicacao,
        id: uuidv4(),
        tpSituacao: credencialDados.tpSituacao
      }
      patch = {
        ...patch,
        id: uuidv4(),
        credenciais: model.credenciais,
        ativo: ativo,
        codigoAplicacao: model.codigoAplicacao,
        tpSituacao: model.tpSituacao,
        documento: loja.documento,
        urlRedirectLogin: model.urlRedirectLogin
      }

      const registroCredencial = await getRegistroCredencial(loja.id);
      if (registroCredencial.erro) throw registroCredencial.erro;

      const temCredencial = !isEmpty(registroCredencial.resultado?.data.find((credencial: CredenciaisDadosModel) => credencial.codigoAplicacao === aplicacao.codigoAplicacao));
      const credencialId = registroCredencial.resultado?.data.find((credId: any) => credId.codigoAplicacao === model.codigoAplicacao)?.id;

      if (!temCredencial) {
        const resPut = await putEditarCredenciais(loja.id, credencialDados.id, model);
        if (resPut.erro) throw resPut.erro;

        const patchIfood = await patchValidacaoCredencial(loja.id, credencialId!, patch);
        if (patchIfood.erro) throw patchIfood.erro;

        showToast('success', "Credencial registrada com sucesso!");
      } else if (temCredencial) {
        await validarCredencial(model, temCredencial);

        const patchIfood = await patchValidacaoCredencial(loja.id, credencialId!, patch);
        if (patchIfood.erro) throw patchIfood.erro;

        showToast('success', "Credencial registrada com sucesso!");
        closeModal()
      }
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [aplicacao.codigoAplicacao, credencialDados.tpSituacao, credencialDados.id, ativo, loja.documento, loja.id, getRegistroCredencial, putEditarCredenciais, patchValidacaoCredencial, showToast, validarCredencial, closeModal]);


  const handleSubmit = useCallback(
    async (model: CredenciaisModelIfood) => {
      try {
        const credenciaisDadosCopy = { ...credencialDados, credenciais: model.credenciais, credenciar: new CredenciaisDadosModel().credenciar, id: uuidv4() }
        await sincronizacaoWrapper(credenciaisDadosCopy, model)
      } catch (e: any) {
        showToast('error', e.message);
      }
    }, [credencialDados, showToast, sincronizacaoWrapper])


  return (
    <DialogSaurus
      aberto={openned}
      titulo='Autenticação Ifood'
      tamanho="sm">
      <Typography className={classes.title}>
        Cole aqui o código de autorização do aplicativo no portal do Ifood:
      </Typography>
      <FormCadastroCredencial
        credencialDados={credencialDados}
        ativo={ativo}
        lojaId={loja.id}
        ref={cadCadastroCredencialFormRef}
        showLoading={true}
        loading={carregando}
        onSubmit={handleSubmit}
        model={aplicacao}
      />
      <div>
        <Grid container spacing={2} style={{
          justifyContent: 'end',
          alignItems: 'center'
        }}>
          <Grid item xs={12} md={3}>
            <Button
              variant='outlined'
              color='primary'
              onClick={closeModal}
              fullWidth
            >
              <VoltarIcon tipo='BUTTON' />
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              disabled={carregando}
              variant="contained"
              color="primary"
              fullWidth
              onClick={async () => {
                cadCadastroCredencialFormRef.current?.submitForm();
              }}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </div>
    </DialogSaurus>
  );
};
