import { EnderecoModel } from "./endereco-model";


export class ClientePedidoModel {
    constructor(
        public id: string = '',
        public referenceId: string = '',
        public nome: string = '',
        public email: string = '',
        public documento: string = '',
        public rg: string = '',
        public dataNascimento: Date = new Date(),
        public telefoneCelular: string = '',
        public enderecos: EnderecoModel[] = new Array<EnderecoModel>(),
    ) {}
}