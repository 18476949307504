import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useGetPedidosById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getPedidosById = useCallback(
        (lojaId: string, pedidoId: string | undefined) => {
            return invocarApi({
                url: `/api/v2/loja/${lojaId}/pedido/${pedidoId}`,
                method: "GET",
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
        );
    
    return {
        ...outrasPropriedades,
        getPedidosById,
    };
}


