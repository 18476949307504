import { Button, Card, CardActions, CardContent, Fade, Modal, Typography } from "@material-ui/core"
import { useCallback } from "react";
import Backdrop from '@material-ui/core/Backdrop';
import { useStyles } from "./card-excluir-styles";
import { useDeleteLojaById } from "../../../../data/api/gestao/lojas/delete-loja-by-id";
import { useToastSaurus } from "../../../../service/app/use-cases";
import { useCadastros } from "../../../../service/app/providers/cadastros-provider";

export interface CardExcluirProps {
    fecharModal: () => void,
    id: string,
    aberto: boolean,
}

export const CardExcluir = (props: CardExcluirProps) => {
    const classes = useStyles();
    const { deleteLojaById } = useDeleteLojaById()
    const { showToast } = useToastSaurus()
    const { fecharCadastroNovaLoja } =
        useCadastros();

    const removeLoja = useCallback(async (id: string) => {
        try {
            const res = await deleteLojaById(id)

            if (res.erro) throw res.erro
            showToast('success', 'Loja deletada!')
            props.fecharModal();
            fecharCadastroNovaLoja();

        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [deleteLojaById, showToast, props, fecharCadastroNovaLoja])

    return (
        <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            className={classes.modal}
            open={props.aberto}
            onClose={props.fecharModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.aberto} timeout={900}>
                <Card style={{
                    padding: '16px',
                    textAlign: 'center'
                }}>
                    <CardContent>
                        <Typography
                            className={classes.titlePrincipal}
                        >
                            Você deseja excluir esta loja?
                        </Typography>
                        <Typography style={{
                            fontSize: '16px',
                            fontWeight: 500,
                            marginTop: '25px'
                        }}>
                            <strong>Obs:</strong> Todas as informações ou qualquer tipo das  suas integração serão perdidas.
                        </Typography>
                    </CardContent>
                    <CardActions style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>

                        <Button
                            style={{ maxWidth: '278px' }}
                            size="medium"
                            color="primary"
                            variant="contained"
                            fullWidth
                            onClick={() => removeLoja(props.id)}
                        >
                            <Typography>Continuar</Typography>
                        </Button>
                        <Button
                            style={{ marginTop: 8, maxWidth: '278px', margin: '10px 10px' }}
                            size="medium"
                            color="secondary"
                            onClick={props.fecharModal}
                            fullWidth
                        >
                            <Typography>Voltar</Typography>
                        </Button>
                    </CardActions>
                </Card>
            </Fade>
        </Modal>
    )
}