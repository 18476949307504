import { EnumTpSituacao } from "../../enums/enum-tp-situacao";
import { ConfiguracaoIntegracaoModel } from "../configuracoes/configuracao-integracao-model";
import { ConfiguracoesModel } from "../configuracoes/configuracoes-model";


export class AplicacaoModel {
    constructor(
        public id: string = '',
        public codigoAplicacao: number | undefined = 0,
        public tpSituacao: EnumTpSituacao = EnumTpSituacao.Ativa,
        public tpSituacaoDescricao: string = '',
        public configuracoes: ConfiguracoesModel = new ConfiguracoesModel(),
        public configuracoesIntegracao: ConfiguracaoIntegracaoModel = new ConfiguracaoIntegracaoModel(),
        public dUpdate: Date = new Date(),
        public dInsert: Date = new Date(),
        public descricaoAplicacao: string = '',
        public uriImagem: string = ''
    ) { }
}
