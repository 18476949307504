import { useMemo, useState } from "react";
import { AplicacaoModel } from "../../../../../model/api/aplicacao/aplicacao-model";
import { useThemeQueries } from "../../../../../theme";
import { DefaultModal } from "../../../default-modal";
import { EditarAplicacaoHomo } from "./editar-aplicacao-homologacao/editar-aplicacao-homologacao";
import { VisualizacaoAplicacaoHomologacao } from "./visualizacao-aplicacao-homologacao/visualizacao-aplicacao-homologacao";
import { TesteAplicacoesHomologacao } from "./teste-aplicacoes-homologacao/teste-aplicacoes-homologacao";
import { CredenciaisDadosModel } from "../../../../../model/api/credenciais/credenciais-dados-model";
import { TesteStatusPedidoModel } from "../../../../../model/api/aplicacao-credencial/teste-status-pedido-model";

type Props = {
  openned: boolean;
  cred: CredenciaisDadosModel;
  aplicacao: AplicacaoModel;
  pedido: TesteStatusPedidoModel;
}

export const ModalAplicacaoAdminHomologacao = ({ cred, openned, aplicacao, pedido }: Props) => {
  const { isMobile } = useThemeQueries();
  const [cadastro, setCadastro] = useState<number>(1);


  const switchModais = useMemo(() => {
    switch (cadastro) {
      case 1:
        return (
          <VisualizacaoAplicacaoHomologacao
            setCadastro={setCadastro}
            aplicacao={aplicacao}
          />
        )
      case 2:
        return (
          <EditarAplicacaoHomo
            setCadastro={setCadastro}
            aplicacao={aplicacao}
          />

        )
      case 3:
        return (
          <TesteAplicacoesHomologacao
            pedido={pedido}
            setCadastro={setCadastro}
            aplicacao={aplicacao}
          />
        )
    }
  }, [aplicacao, cadastro, pedido])

  return (
    <DefaultModal
      minWidth={isMobile ? '0' : "600px"}
      open={openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {switchModais}
    </DefaultModal>
  );
};
