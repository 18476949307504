import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { AplicacaoModel } from '../../../../model/api/aplicacao/aplicacao-model';


export function usePostAplicacao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();
    const apiUrlHomo = 'https://sauruspedidosapi-staging.azurewebsites.net';

    const postAplicacao = useCallback(
        (model: AplicacaoModel) =>
            invocarApi({
                url: `/api/v2/aplicacao`,
                method: "POST",
                data: model,
                baseURL: apiUrlHomo,
                headers: { "Content-Type": "application/json" },
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        postAplicacao
    }
}