import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    cardNaoEncontrado: {
        "& .celula-grid-value": {
            whiteSpace: "normal",
            fontSize: '1rem',
            [theme.breakpoints.up("md")]: {
                fontSize: "1.2rem",
            },
            [theme.breakpoints.up("lg")]: {
                fontSize: "1.5rem",
            },
        },
        "& svg": {
            width: "80px",
            height: "80px",
            margin: theme.spacing(4),
            fill: theme.palette.text.primary,
        }
    },
}))
