import { Box, Fade, Grid, Typography } from "@material-ui/core"
import { CircularLoading } from "../../../utils"
import { useCargasProdutosStyles } from "../cargas-produtos-styles"
import { CardProdutosProps } from "./card-produtos-props"
import { alterarCorStatusProdutos } from "../../../../../utils/alterar-cor-status-produtos"
import { alterarNomeStatusProdutos } from "../../../../../utils/alterar-nome-status-produtos"


export const CardProdutos = ({
   produtos,
   carregando
}: CardProdutosProps) => {
   const classes = useCargasProdutosStyles()


   return (
      <>
         {carregando ? (
            <CircularLoading tipo="FULLSIZED" />
         ) : null}
         {produtos?.map((produto) => {
            return (
               <>
                  <Box sx={{ flexGrow: 1 }}>
                     <Fade in timeout={900}>
                        <>
                           <Grid container spacing={2}>
                              <Grid item md={2} xs={1}>
                                 <Typography className={classes.titleSinc}>{produto.codigoPrincipal}</Typography>
                              </Grid>
                              <Grid item md={2} xs={1}>
                                 <Typography style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: '' }} className={classes.titleSinc}>{produto.descricao}</Typography>
                              </Grid>
                              <Grid item md={1} xs={1}>
                                 <Typography className={classes.titleSinc}>{produto.grupo}</Typography>
                              </Grid>
                              <Grid item md={1} xs={1}>
                                 <Typography className={classes.titleSinc}>{produto?.valor?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Typography>
                              </Grid>
                              <Grid item md={2} xs={1}>
                                 <Typography className={classes.titleSinc}>{produto.ncm}</Typography>
                              </Grid>
                              <Grid item md={2} xs={1}>
                                 <Typography className={classes.titleSinc}>{produto.marca}</Typography>
                              </Grid>
                              <Grid item md={1} xs={1}>
                                 <Typography className={classes.titleSinc}>{produto.medida}</Typography>
                              </Grid>
                              <Grid item md={1} xs={1} className={classes.containerSituacao} style={{ background: alterarCorStatusProdutos(produto.status) }}>
                                 <Typography className={classes.titleSituacao}>{alterarNomeStatusProdutos(produto.status)}</Typography>
                              </Grid>
                           </Grid>
                        </>
                     </Fade>
                  </Box>
               </>
            )
         })}
      </>
   )
}