import { EnumStatusLoja } from "../../enums/enum-status-loja";


export class CredenciaisModelIfood {
    constructor(
        public id: string = '',
        public credenciais: Record<string, string> = {},
        public codigoAplicacao: number | undefined = 0,
        public ativo: boolean = true,
        public tpSituacao: number = 0,
        public dataCriacaoToken: string = '',
        public dataAtualizacaoToken: string = '',
        public jsonToken: string = '',
        public dataVencimento: string = '',
        public documento: string = '',
        public ultimoRetorno: string = '',
        public urlBase: string = '',
        public mensagemLoja: string = '',
        public statusLoja: EnumStatusLoja = EnumStatusLoja.ABERTO,
        public urlRedirectLogin: string = ''
    ) { }
}