import { Redirect, Route } from "react-router-dom"
import { MenuPrincipalProvider } from "../../../service/app/providers/menu-principal-provider"
import { MainPage } from "../../../views/pages/private/main/main-page"
import { CadastrosProvider } from "../../../service/app/providers/cadastros-provider"
import { DashboardPage } from "../../../views/pages/private/dashboard/dashboard-page"
import { PedidosPage } from "../../../views/pages/private/pedidos/pedidos-page"
import { SincronizacaoPage } from "../../../views/pages/private/sincronizacao/sincronizacao-page"
import { LojasPage } from "../../../views/pages/private/lojas/lojas-page"

const RouterMain = () => {
    return (
        <MenuPrincipalProvider>
            <MainPage>
                <CadastrosProvider>
                    <Route exact path="/dados-contrato" component={LojasPage} />
                    <Route exact path="/sincronizacao" component={SincronizacaoPage} />
                    <Route exact path="/dashboard" component={DashboardPage} />
                    <Route exact path="/pedidos" component={PedidosPage} />
                    <Route exact path="/">
                        <Redirect to="/dashboard" />
                    </Route>
                </CadastrosProvider>
            </MainPage>
        </MenuPrincipalProvider>
    )
}

export default RouterMain