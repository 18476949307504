import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { LojaModel } from "../../../../model/api/loja/loja-model";
import { CadastrosContextValue } from "./cadastro-context-value";
import { LojaModal } from "../../../../views/components/modals/nova-loja/modal-loja";
import { AplicacaoModel } from "../../../../model/api/aplicacao/aplicacao-model";
import { CredenciaisDadosModel } from "../../../../model/api/credenciais/credenciais-dados-model";
import { ModelPedidos } from "../../../../views/components/modals/pedidos/modal-pedidos";
import { ModelEnvioCarga } from "../../../../views/components/modals/envio-carga-modal/modal-envio-carga";
import { SincronizacaoByIdModel } from "../../../../model/api/sincronizacao/sincronizacao-by-id-model";
import { PedidosModel } from "../../../../model/api/pedidos/pedidos-model";
import { CargasProdutosModel } from "../../../../model/api/cargas-produto/cargas-produtos-model";
import { EditarLojaModel } from "../../../../model/api/loja/editar-loja-model";
import { CredenciaisModel } from "../../../../model/api/credenciais/credenciais-model";
import { ModalCadastroAplicacaoCredencial } from "../../../../views/components/modals/modal-cadastro-aplicacao-credencial/modal-cadastro-aplicacao-credencial";
import { isEmpty } from "lodash";
import { ModalAdcAplicacaoAdminProd } from "../../../../views/components/modals/admin/producao/adicionar-aplicacao/modal-adicionar-aplicacao-prod";
import { ModalAdcAplicacaoAdminHomologacao } from "../../../../views/components/modals/admin/homologacao/adicionar-aplicacao/modal-adicionar-aplicacao-homologacao";
import { ModalAplicacaoAdminHomologacao } from "../../../../views/components/modals/admin/homologacao/modal-aplicacao-homologacao";
import { ModalAplicacaoAdminProd } from "../../../../views/components/modals/admin/producao/modal-aplicacao-prod";
import { ModalEditarAplicacao } from "../../../../views/components/modals/editar-aplicacao/modal-editar-aplicacao";
import { TesteStatusPedidoModel } from "../../../../model/api/aplicacao-credencial/teste-status-pedido-model";


const CadastrosContext = createContext<CadastrosContextValue>({

   abrirCadastroNovaLoja: (
      id: string,
      model: LojaModel,
      editar: EditarLojaModel,
      credencialDados: CredenciaisDadosModel,
      callbackUrl: string,
      trocarUrl: boolean,
   ) => {
      return new Promise<void>(() => true);
   },
   fecharCadastroNovaLoja: (urlRetorno?: string) => {
      return new Promise<void>(() => true);
   },
   CadastroNovaLojaState: {
      id: '',
      model: new LojaModel(),
      editar: new EditarLojaModel(),
      credencialDados: new CredenciaisDadosModel(),
      aberto: false,
      ultimaUrl: ''
   },

   abrirCadastroNovaAplicacao: (
      editando: boolean,
      model: AplicacaoModel,
      loja: LojaModel,
      credencialDados: CredenciaisDadosModel,
      callbackUrl: string,
      trocarUrl: boolean,
   ) => {
      return new Promise<void>(() => true);
   },
   fecharCadastroNovaAplicacao: (urlRetorno?: string) => {
      return new Promise<void>(() => true);
   },
   CadastroNovaAplicacaoState: {
      editando: false,
      model: new AplicacaoModel(),
      loja: new LojaModel(),
      credencialDados: new CredenciaisDadosModel(),
      aberto: false,
      ultimaUrl: ''
   },

   abrirCadastroNovaCredencial: (
      loja: LojaModel,
      credencialDados: CredenciaisDadosModel | undefined,
      credencial: CredenciaisModel[],
      callbackUrl: string,
      trocarUrl: boolean,
   ) => {
      return new Promise<void>(() => true);
   },
   fecharCadastroNovaCredencial: (urlRetorno?: string) => {
      return new Promise<void>(() => true);
   },
   CadastroNovaCredencialState: {
      loja: new LojaModel(),
      credencialDados: new CredenciaisDadosModel(),
      credencial: new Array<CredenciaisModel>(),
      aberto: false,
      ultimaUrl: ''
   },

   abrirDetalhesPedidos: (
      id: string,
      idPedido: string,
      callbackUrl: string,
      trocarUrl: boolean,
   ) => {
      return new Promise<void>(() => true);
   },
   fecharDetalhesPedidos: (urlRetorno?: string) => {
      return new Promise<void>(() => true);
   },
   DetalhesPedidosState: {
      id: '',
      idPedido: '',
      aberto: false,
      ultimaUrl: ''
   },

   abrirEnvioCarga: (
      idLoja: string,
      // cargas: CargasProdutosModel,
      sincronizacao: SincronizacaoByIdModel,
      // cargaId: string,
      callbackUrl: string,
      trocarUrl: boolean,
   ) => {
      return new Promise<void>(() => true);
   },
   fecharEnvioCarga: (urlRetorno?: string) => {
      return new Promise<void>(() => true);
   },
   EnvioCargaState: {
      idLoja: '',
      // cargas: new CargasProdutosModel(),
      sincronizacao: new SincronizacaoByIdModel(),
      // cargaId: '',
      aberto: false,
      ultimaUrl: ''
   },

   abrirCargaProdutos: (
      idLoja: string,
      sincronizacaoId: string,
      cargaId: string,
      cargaModel: CargasProdutosModel,
      callbackUrl: string,
      trocarUrl: boolean,
   ) => {
      return new Promise<void>(() => true);
   },
   fecharCargaProdutos: (urlRetorno?: string) => {
      return new Promise<void>(() => true);
   },
   CargaProdutosState: {
      idLoja: '',
      sincronizacaoId: '',
      cargaId: '',
      cargaModel: new CargasProdutosModel(),
      aberto: false,
      ultimaUrl: ''
   },

   abrirEdicaoAplicacaoProd: (
      id: string,
      aplicacao: AplicacaoModel,
      callbackUrl: string,
      trocarUrl: boolean,
   ) => {
      return new Promise<void>(() => true);
   },
   fecharEdicaoAplicacaoProd: (urlRetorno?: string) => {
      return new Promise<void>(() => true);
   },
   EdicaoAplicacaoProdState: {
      id: '',
      aplicacao: new AplicacaoModel(),
      aberto: false,
      ultimaUrl: ''
   },

   abrirAdcAplicacaoProd: (
      callbackUrl: string,
      trocarUrl: boolean,
   ) => {
      return new Promise<void>(() => true);
   },
   fecharAdcAplicacaoProd: (urlRetorno?: string) => {
      return new Promise<void>(() => true);
   },
   AdcAplicacaoProdState: {
      aberto: false,
      ultimaUrl: ''
   },

   abrirEdicaoAplicacaoHomologacao: (
      id: string,
      aplicacao: AplicacaoModel,
      callbackUrl: string,
      trocarUrl: boolean,
   ) => {
      return new Promise<void>(() => true);
   },
   fecharEdicaoAplicacaoHomologacao: (urlRetorno?: string) => {
      return new Promise<void>(() => true);
   },
   EdicaoAplicacaoHomologacaoState: {
      id: '',
      aplicacao: new AplicacaoModel(),
      aberto: false,
      ultimaUrl: ''
   },

   abrirAdcAplicacaoHomologacao: (
      callbackUrl: string,
      trocarUrl: boolean,
   ) => {
      return new Promise<void>(() => true);
   },
   fecharAdcAplicacaoHomologacao: (urlRetorno?: string) => {
      return new Promise<void>(() => true);
   },
   AdcAplicacaoHomologacaoState: {
      aberto: false,
      ultimaUrl: ''
   },

   abrirTesteAplicacaoCredencial: (
      cred: CredenciaisDadosModel,
      aplicacao: AplicacaoModel,
      pedido: TesteStatusPedidoModel,
      callbackUrl: string,
      trocarUrl: boolean,
   ) => {
      return new Promise<void>(() => true);
   },
   fecharTesteAplicacaoCredencial: (urlRetorno?: string) => {
      return new Promise<void>(() => true);
   },
   TesteAplicacaoCredencialState: {
      cred: new CredenciaisDadosModel(),
      aplicacao: new AplicacaoModel(),
      pedido: new TesteStatusPedidoModel(),
      aberto: true,
      ultimaUrl: '',
   }
})

export interface CadastroProviderProps {
   children: ReactNode;
}

export const useCadastros = () => {
   return useContext(CadastrosContext);
};

export const CadastrosProvider = ({ children }: CadastroProviderProps) => {
   const adicionarRoute = 'adicionar';
   const lojaRoute = '/loja'
   const AplicacaoRoute = '/aplicacao';
   const novaLojaRoute = '/nova-loja';
   const novaAplicacao = '/nova-aplicacao';
   const novaCredencial = '/nova-credencial';
   const detalhesPedido = '/detalhes';
   const cargasProdutos = '/carga-produtos'
   const envioCargaRoute = '/envio-carga';
   const adicionarAplicacao = '/adicionar-aplicacao';
   const editarAplicacao = '/dashboard-admin/';
   const { location, push } = useHistory();

   const [CadastroNovaLojaState, setCadastroNovaLojaState] = useState({
      id: '',
      model: new LojaModel(),
      editar: new EditarLojaModel(),
      credencialDados: new CredenciaisDadosModel(),
      aberto: false,
      ultimaUrl: '',
   });

   const [CadastroNovaAplicacaoState, setCadastroNovaAplicacaoState] = useState({
      editando: false,
      model: new AplicacaoModel(),
      loja: new LojaModel(),
      credencialDados: new CredenciaisDadosModel(),
      aberto: false,
      ultimaUrl: '',
   });

   const [CadastroNovaCredencialState, setCadastroNovaCredencialState] = useState({
      loja: new LojaModel(),
      credencialDados: new CredenciaisDadosModel(),
      credencial: new Array<CredenciaisModel>(),
      aberto: false,
      ultimaUrl: '',
   });

   const [DetalhesPedidosState, setDetalhesPedidoState] = useState({
      id: '',
      idPedido: '',
      pedido: new PedidosModel(),
      aberto: false,
      ultimaUrl: '',
   });

   const [EnvioCargaState, setEnvioCargaState] = useState({
      idLoja: '',
      // cargaId: '',
      // cargas: new CargasProdutosModel(),
      sincronizacao: new SincronizacaoByIdModel(),
      aberto: false,
      ultimaUrl: '',
   });

   const [CargaProdutosState, setCargaProdutosState] = useState({
      idLoja: '',
      sincronizacaoId: '',
      cargaId: '',
      cargaModel: new CargasProdutosModel(),
      aberto: false,
      ultimaUrl: '',
   });

   const [EdicaoAplicacaoProdState, setEdicaoAplicacaoProdState] = useState({
      id: '',
      aplicacao: new AplicacaoModel(),
      aberto: false,
      ultimaUrl: '',
   });

   const [AdcAplicacaoProdState, setAdcAplicacaoProdState] = useState({
      aberto: false,
      ultimaUrl: '',
      trocarUrl: false
   });

   const [EdicaoAplicacaoHomologacaoState, setEdicaoAplicacaoHomologacaoState] = useState({
      id: '',
      aplicacao: new AplicacaoModel(),
      aberto: false,
      ultimaUrl: '',
   });

   const [AdcAplicacaoHomologacaoState, setAdcAplicacaoHomologacaoState] = useState({
      aberto: false,
      ultimaUrl: '',
      trocarUrl: false
   });

   const [TesteAplicacaoCredencialState, setTesteAplicacaoCredencialState] = useState({
      cred: new CredenciaisDadosModel(),
      aplicacao: new AplicacaoModel(),
      pedido: new TesteStatusPedidoModel(),
      aberto: false,
      ultimaUrl: '',
   });


   useEffect(() => {
      if (location.pathname.indexOf("/dados-contrato") < 0 && location.pathname.indexOf(novaLojaRoute) === 0) {
         const posrota = location.pathname.replace(novaLojaRoute, '');
         if (posrota.length > 0) {
            setCadastroNovaLojaState({
               id: posrota.replace(adicionarRoute, ''),
               model: new LojaModel(),
               editar: new EditarLojaModel(),
               credencialDados: new CredenciaisDadosModel(),
               aberto: true,
               ultimaUrl: novaLojaRoute,
            });
            return;
         }
      } else if (location.pathname.indexOf(novaAplicacao) === 0) {
         const posrota = location.pathname.replace(novaAplicacao, '');
         if (posrota.length > 0) {
            setCadastroNovaAplicacaoState({
               editando: true,
               model: new AplicacaoModel(),
               loja: new LojaModel(),
               credencialDados: new CredenciaisDadosModel(),
               aberto: true,
               ultimaUrl: novaAplicacao,
            });
            return;
         }
      } else if (location.pathname.indexOf(novaCredencial) === 0) {
         const posrota = location.pathname.replace(novaCredencial, '');
         if (posrota.length > 0) {
            setCadastroNovaCredencialState({
               loja: new LojaModel(),
               credencialDados: new CredenciaisDadosModel(),
               credencial: new Array<CredenciaisModel>(),
               aberto: true,
               ultimaUrl: novaCredencial,
            });
            return;
         }
      } else if (location.pathname.indexOf(detalhesPedido) === 0) {
         const posrota = location.pathname.replace(detalhesPedido, '');
         if (posrota.length > 0) {
            setDetalhesPedidoState({
               id: '',
               idPedido: '',
               pedido: new PedidosModel(),
               aberto: true,
               ultimaUrl: detalhesPedido,
            });
            return;
         }
      } else if (location.pathname.indexOf(detalhesPedido) === 0) {
         const posrota = location.pathname.replace(detalhesPedido, '');
         if (posrota.length > 0) {
            setEnvioCargaState({
               idLoja: '',
               // cargaId: '',
               // cargas: new CargasProdutosModel(),
               sincronizacao: new SincronizacaoByIdModel(),
               aberto: true,
               ultimaUrl: envioCargaRoute,
            });
            return;
         }
      } else if (location.pathname.indexOf(cargasProdutos) === 0) {
         const posrota = location.pathname.replace(cargasProdutos, '');
         if (posrota.length > 0) {
            setCargaProdutosState({
               idLoja: '',
               sincronizacaoId: '',
               cargaId: '',
               cargaModel: new CargasProdutosModel(),
               aberto: true,
               ultimaUrl: envioCargaRoute,
            });
            return;
         }
      }

   }, [location.pathname])

   const abrirCadastroNovaLoja = useCallback(
      async (
         id: string,
         model: LojaModel,
         editar: EditarLojaModel,
         credencialDados: CredenciaisDadosModel,
         callbackUrl: string,
         trocarUrl: boolean,
      ): Promise<void> => {
         let url = callbackUrl.length === 0 ? lojaRoute : callbackUrl;
         if (CadastroNovaLojaState.aberto) {
            url = CadastroNovaLojaState.ultimaUrl;
         }
         setCadastroNovaLojaState({
            id: id,
            model: model,
            editar: editar,
            credencialDados: credencialDados,
            aberto: true,
            ultimaUrl: url,
         });
      },
      [CadastroNovaLojaState.aberto, CadastroNovaLojaState.ultimaUrl],
   );

   const fecharCadastroNovaLoja = useCallback(
      async (urlRetorno?: string): Promise<void> => {
         setCadastroNovaLojaState({
            id: '',
            model: new LojaModel(),
            editar: new EditarLojaModel(),
            credencialDados: new CredenciaisDadosModel(),
            aberto: false,
            ultimaUrl: '',
         });
      },
      [],
   );

   const abrirCadastroNovaAplicacao = useCallback(
      async (
         editando: boolean,
         model: AplicacaoModel,
         loja: LojaModel,
         credencialDados: CredenciaisDadosModel,
         callbackUrl: string,
         trocarUrl: boolean,
      ): Promise<void> => {
         let url = callbackUrl.length === 0 ? AplicacaoRoute : callbackUrl;
         if (CadastroNovaAplicacaoState.aberto) {
            url = CadastroNovaAplicacaoState.ultimaUrl;
         }
         setCadastroNovaAplicacaoState({
            editando: editando,
            model: model,
            loja: loja,
            credencialDados: credencialDados,
            aberto: true,
            ultimaUrl: url,
         });
      },
      [CadastroNovaAplicacaoState.aberto, CadastroNovaAplicacaoState.ultimaUrl],
   );

   const fecharCadastroNovaAplicacao = useCallback(
      async (urlRetorno?: string): Promise<void> => {
         setCadastroNovaAplicacaoState({
            model: new AplicacaoModel(),
            loja: new LojaModel(),
            credencialDados: new CredenciaisDadosModel(),
            editando: false,
            aberto: false,
            ultimaUrl: '',
         });
      },
      [],
   );

   const abrirCadastroNovaCredencial = useCallback(
      async (
         loja: LojaModel,
         credencialDados: CredenciaisDadosModel,
         credencial: CredenciaisModel[],
         callbackUrl: string,
         trocarUrl: boolean,
      ): Promise<void> => {
         let url = callbackUrl.length === 0 ? AplicacaoRoute : callbackUrl;
         if (CadastroNovaCredencialState.aberto) {
            url = CadastroNovaCredencialState.ultimaUrl;
         }
         setCadastroNovaCredencialState({
            loja: loja,
            credencialDados: credencialDados,
            credencial: credencial,
            aberto: true,
            ultimaUrl: url,
         });
      },
      [CadastroNovaCredencialState.aberto, CadastroNovaCredencialState.ultimaUrl],
   );

   const fecharCadastroNovaCredencial = useCallback(
      async (urlRetorno?: string): Promise<void> => {
         setCadastroNovaCredencialState({
            loja: new LojaModel(),
            credencialDados: new CredenciaisDadosModel(),
            credencial: new Array<CredenciaisModel>(),
            aberto: false,
            ultimaUrl: '',
         });
      },
      [],
   );
   const abrirDetalhesPedidos = useCallback(
      async (
         id: string,
         idPedido: string,
         callbackUrl: string,
         trocarUrl: boolean,
      ): Promise<void> => {
         let url = callbackUrl.length === 0 ? AplicacaoRoute : callbackUrl;
         if (DetalhesPedidosState.aberto) {
            url = DetalhesPedidosState.ultimaUrl;
         }
         setDetalhesPedidoState({
            id: id,
            idPedido: idPedido,
            pedido: new PedidosModel(),
            aberto: true,
            ultimaUrl: url,
         });
      },
      [DetalhesPedidosState.aberto, DetalhesPedidosState.ultimaUrl],
   );
   const fecharDetalhesPedidos = useCallback(
      async (urlRetorno?: string): Promise<void> => {
         setDetalhesPedidoState({
            id: '',
            idPedido: '',
            pedido: new PedidosModel(),
            aberto: false,
            ultimaUrl: '',
         });
      },
      [],
   );

   const abrirEnvioCarga = useCallback(
      async (
         idLoja: string,
         // cargas: CargasProdutosModel,
         sincronizacao: SincronizacaoByIdModel,
         // cargaId: string,
         callbackUrl: string,
         trocarUrl: boolean,
      ): Promise<void> => {
         let url = callbackUrl.length === 0 ? AplicacaoRoute : callbackUrl;
         if (EnvioCargaState.aberto) {
            url = EnvioCargaState.ultimaUrl;
         }
         setEnvioCargaState({
            idLoja: idLoja,
            // cargaId: cargaId,
            // cargas: cargas,
            sincronizacao: sincronizacao,
            aberto: true,
            ultimaUrl: url,
         });
      },
      [EnvioCargaState.aberto, EnvioCargaState.ultimaUrl],
   );

   const fecharEnvioCarga = useCallback(
      async (urlRetorno?: string): Promise<void> => {
         setEnvioCargaState({
            idLoja: '',
            // cargaId: '',
            // cargas: new CargasProdutosModel(),
            sincronizacao: new SincronizacaoByIdModel(),
            aberto: false,
            ultimaUrl: '',
         });
      },
      [],
   );

   const abrirCargaProdutos = useCallback(
      async (
         idLoja: string,
         sincronizacaoId: string,
         cargaId: string,
         cargaModel: CargasProdutosModel,
         callbackUrl: string,
         trocarUrl: boolean,
      ): Promise<void> => {
         let url = callbackUrl.length === 0 ? AplicacaoRoute : callbackUrl;
         if (CargaProdutosState.aberto) {
            url = CargaProdutosState.ultimaUrl;
         }
         setCargaProdutosState({
            idLoja: idLoja,
            sincronizacaoId: sincronizacaoId,
            cargaId: cargaId,
            cargaModel: cargaModel,
            aberto: true,
            ultimaUrl: url,
         });
      },
      [CargaProdutosState.aberto, CargaProdutosState.ultimaUrl],
   );

   const fecharCargaProdutos = useCallback(
      async (urlRetorno?: string): Promise<void> => {
         setCargaProdutosState({
            idLoja: '',
            sincronizacaoId: '',
            cargaId: '',
            cargaModel: new CargasProdutosModel(),
            aberto: false,
            ultimaUrl: '',
         });
      },
      [],
   );

   const abrirAdcAplicacaoProd = useCallback(
      async (
         callbackUrl: string,
         trocarUrl: boolean,
      ): Promise<void> => {
         let url = callbackUrl.length === 0 ? adicionarAplicacao : callbackUrl;
         if (AdcAplicacaoProdState.aberto) {
            url = AdcAplicacaoProdState.ultimaUrl;
         }
         setAdcAplicacaoProdState({
            aberto: true,
            ultimaUrl: url,
            trocarUrl: trocarUrl
         });
      },
      //eslint-disable-next-line
      [location.pathname, adicionarAplicacao],
   );

   const fecharAdcAplicacaoProd = useCallback(
      async (urlRetorno?: string): Promise<void> => {
         const uUrl = AdcAplicacaoProdState.ultimaUrl;
         setAdcAplicacaoProdState({
            aberto: false,
            ultimaUrl: '',
            trocarUrl: false
         }); if (!isEmpty(urlRetorno)) {
            push(urlRetorno || '');
            return;
         }
         if (!isEmpty(uUrl)) {
            push(uUrl);
            return;
         }
      },
      //eslint-disable-next-line
      [AdcAplicacaoProdState.ultimaUrl],
   );


   const abrirEdicaoAplicacaoProd = useCallback(
      async (
         id: string,
         aplicacao: AplicacaoModel,
         callbackUrl: string,
         trocarUrl: boolean,
      ): Promise<void> => {
         let url = callbackUrl.length === 0 ? editarAplicacao : callbackUrl;

         setEdicaoAplicacaoProdState({
            id: id,
            aplicacao: aplicacao,
            aberto: true,
            ultimaUrl: url,
         });
         if (trocarUrl) {
            push(url + id);
         }
      },
      //eslint-disable-next-line
      [EdicaoAplicacaoProdState, editarAplicacao],
   )

   const fecharEdicaoAplicacaoProd = useCallback(
      async (urlRetorno?: string): Promise<void> => {
         const url = EdicaoAplicacaoProdState.ultimaUrl;
         setEdicaoAplicacaoProdState({
            id: '',
            aplicacao: new AplicacaoModel(),
            aberto: false,
            ultimaUrl: '',
         }); if (!isEmpty(urlRetorno)) {
            push(urlRetorno || '');
            return;
         }

         if (!isEmpty(url)) {
            push(url);
            return;
         }
      },
      //eslint-disable-next-line
      [EdicaoAplicacaoProdState.ultimaUrl, push],
   );

   const abrirAdcAplicacaoHomologacao = useCallback(
      async (
         callbackUrl: string,
         trocarUrl: boolean,
      ): Promise<void> => {
         let url = callbackUrl.length === 0 ? adicionarAplicacao : callbackUrl;
         if (AdcAplicacaoHomologacaoState.aberto) {
            url = AdcAplicacaoHomologacaoState.ultimaUrl;
         }
         setAdcAplicacaoHomologacaoState({
            aberto: true,
            ultimaUrl: url,
            trocarUrl: trocarUrl
         });
      },
      //eslint-disable-next-line
      [location.pathname, adicionarAplicacao],
   );

   const fecharAdcAplicacaoHomologacao = useCallback(
      async (urlRetorno?: string): Promise<void> => {
         const uUrl = AdcAplicacaoHomologacaoState.ultimaUrl;
         setAdcAplicacaoHomologacaoState({
            aberto: false,
            ultimaUrl: '',
            trocarUrl: false
         }); if (!isEmpty(urlRetorno)) {
            push(urlRetorno || '');
            return;
         }
         if (!isEmpty(uUrl)) {
            push(uUrl);
            return;
         }
      },
      //eslint-disable-next-line
      [AdcAplicacaoHomologacaoState.ultimaUrl],
   );

   const abrirEdicaoAplicacaoHomologacao = useCallback(
      async (
         id: string,
         aplicacao: AplicacaoModel,
         callbackUrl: string,
         trocarUrl: boolean,
      ): Promise<void> => {
         let url = callbackUrl.length === 0 ? editarAplicacao : callbackUrl;
         setEdicaoAplicacaoHomologacaoState({
            id: id,
            aplicacao: aplicacao,
            aberto: true,
            ultimaUrl: url,
         });
         if (trocarUrl) {
            push(url + id);
         }
      },
      //eslint-disable-next-line
      [EdicaoAplicacaoHomologacaoState, editarAplicacao],
   )
   const fecharEdicaoAplicacaoHomologacao = useCallback(
      async (urlRetorno?: string): Promise<void> => {
         const url = EdicaoAplicacaoHomologacaoState.ultimaUrl;
         setEdicaoAplicacaoHomologacaoState({
            id: '',
            aplicacao: new AplicacaoModel(),
            aberto: false,
            ultimaUrl: '',
         }); if (!isEmpty(urlRetorno)) {
            push(urlRetorno || '');
            return;
         }

         if (!isEmpty(url)) {
            push(url);
            return;
         }
      },
      //eslint-disable-next-line
      [EdicaoAplicacaoHomologacaoState.ultimaUrl, push],
   );

   const abrirTesteAplicacaoCredencial = useCallback(
      async (
         cred: CredenciaisDadosModel,
         aplicacao: AplicacaoModel,
         pedido: TesteStatusPedidoModel,
         callbackUrl: string,
         trocarUrl: boolean,
      ): Promise<void> => {
         let url = callbackUrl.length === 0 ? editarAplicacao : callbackUrl;
         setTesteAplicacaoCredencialState({
            cred: cred,
            aplicacao: aplicacao,
            pedido: pedido,
            aberto: true,
            ultimaUrl: url,
         });
      },
      //eslint-disable-next-line
      [EdicaoAplicacaoHomologacaoState, editarAplicacao],
   )
   const fecharTesteAplicacaoCredencial = useCallback(
      async (urlRetorno?: string): Promise<void> => {
         const url = EdicaoAplicacaoHomologacaoState.ultimaUrl;
         setTesteAplicacaoCredencialState({
            cred: new CredenciaisDadosModel(),
            aplicacao: new AplicacaoModel(),
            pedido: new TesteStatusPedidoModel(),
            aberto: false,
            ultimaUrl: '',
         }); if (!isEmpty(urlRetorno)) {
            push(urlRetorno || '');
            return;
         }

         if (!isEmpty(url)) {
            push(url);
            return;
         }
      },
      //eslint-disable-next-line
      [EdicaoAplicacaoHomologacaoState.ultimaUrl, push],
   );

   return (
      <CadastrosContext.Provider
         value={{
            abrirCadastroNovaLoja,
            CadastroNovaLojaState,
            fecharCadastroNovaLoja,

            abrirCadastroNovaAplicacao,
            CadastroNovaAplicacaoState,
            fecharCadastroNovaAplicacao,

            abrirCadastroNovaCredencial,
            CadastroNovaCredencialState,
            fecharCadastroNovaCredencial,

            abrirDetalhesPedidos,
            DetalhesPedidosState,
            fecharDetalhesPedidos,

            abrirEnvioCarga,
            EnvioCargaState,
            fecharEnvioCarga,

            abrirCargaProdutos,
            CargaProdutosState,
            fecharCargaProdutos,

            abrirEdicaoAplicacaoProd,
            fecharEdicaoAplicacaoProd,
            EdicaoAplicacaoProdState,

            abrirAdcAplicacaoProd,
            fecharAdcAplicacaoProd,
            AdcAplicacaoProdState,

            abrirEdicaoAplicacaoHomologacao,
            fecharEdicaoAplicacaoHomologacao,
            EdicaoAplicacaoHomologacaoState,

            abrirAdcAplicacaoHomologacao,
            fecharAdcAplicacaoHomologacao,
            AdcAplicacaoHomologacaoState,

            abrirTesteAplicacaoCredencial,
            fecharTesteAplicacaoCredencial,
            TesteAplicacaoCredencialState,

         }}
      >
         {children}

         <LojaModal
            credencial={CadastroNovaLojaState.credencialDados}
            editar={CadastroNovaLojaState.editar}
            openned={CadastroNovaLojaState.aberto}
            id={CadastroNovaLojaState.id}
            model={CadastroNovaLojaState.model}
         />

         <ModalEditarAplicacao
            credencialDados={CadastroNovaAplicacaoState.credencialDados}
            openned={CadastroNovaAplicacaoState.aberto}
            aplicacao={CadastroNovaAplicacaoState.model}
            loja={CadastroNovaAplicacaoState.loja}
         />

         <ModalCadastroAplicacaoCredencial
            // credencialDados={CadastroNovaCredencialState.credencialDados}
            openned={CadastroNovaCredencialState.aberto}
            loja={CadastroNovaCredencialState.loja}
         />

         <ModelPedidos
            idPedido={DetalhesPedidosState.idPedido}
            idLoja={DetalhesPedidosState.id}
            openned={DetalhesPedidosState.aberto}
            pedido={DetalhesPedidosState.pedido}
         />

         <ModelEnvioCarga
            lojaId={EnvioCargaState.idLoja}
            model={EnvioCargaState.sincronizacao}
            openned={EnvioCargaState.aberto}
         />

         <ModalAplicacaoAdminProd
            aplicacao={EdicaoAplicacaoProdState.aplicacao}
            openned={EdicaoAplicacaoProdState.aberto}
         />

         <ModalAplicacaoAdminHomologacao
            pedido={TesteAplicacaoCredencialState.pedido}
            aplicacao={EdicaoAplicacaoHomologacaoState.aplicacao}
            cred={TesteAplicacaoCredencialState.cred}
            openned={EdicaoAplicacaoHomologacaoState.aberto}
         />

         <ModalAdcAplicacaoAdminProd
            openned={AdcAplicacaoProdState.aberto}
         />

         <ModalAdcAplicacaoAdminHomologacao
            openned={AdcAplicacaoHomologacaoState.aberto}
         />

      </CadastrosContext.Provider>
   );
};