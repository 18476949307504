import { EnumSituacaoProcessamento } from "../../enums/enum-situacao-processamento";
import { HistoricoSincronizacaoModel } from "./historico-sincronizacoes";


export class AplicacaoSincronizacoesModel {
    constructor(
        public processamentoId: string = '',
        public codAplicacao: number = 0,
        public nomeAplicacao: string = '',
        public statusMensagem: string = '',
        public totalProdutos: number = 0,
        public totalProcessado: number = 0,
        public tpSituacaoProcessamento: EnumSituacaoProcessamento = EnumSituacaoProcessamento.INVALIDA,
        public situacaoProcessamento: string = '',
        public resetProduto: boolean = true,
        public usuario: string = '',
        public historico: HistoricoSincronizacaoModel[] = Array<HistoricoSincronizacaoModel>(),
    ) { }
}
