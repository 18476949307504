import { makeUseAxios, Options } from "axios-hooks";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { isEmpty, merge } from "lodash";
import { VariaveisAmbiente } from "../../../config";
import { ApiBaseProps } from "./api-base-props";
import { ApiBaseResponse, EnumRetornoApiBase } from './api-base-response';
import { RetornoApiModel } from "../../../model";
import { PedidosStorageKeys, useSaurusPedidosStorage } from "../../../service/app/use-cases";
import { EnumTipoUsuario } from "../../../model/enums/enum-tipo-usuario";
import { useSessaoAtual } from "../../../service/app/providers";
import { useHistory } from "react-router-dom";

type RequestParams = AxiosRequestConfig & {
  enviarTokenUsuario: boolean;
};

const defaultTimeout = 10 * 1000; /* 10 Segundos */
const useAxios = makeUseAxios({
  axios: axios.create({
    baseURL: `${VariaveisAmbiente.apiUrl}`,
    timeout: defaultTimeout,
    timeoutErrorMessage:
      "O servidor não respondeu a solicitação, tente novamente mais tarde.",
  })
});

export function useApiBase(props?: ApiBaseProps) {

  const { getRegistro } = useSaurusPedidosStorage();
  const { tipoUsuario, deslogar, usuario } = useSessaoAtual();
  const { config, opcoes } = props || {};
  const [{ loading: carregandoAxios }, invocarAxios] = useAxios<any>(
    config || "",
    merge({ manual: true }, opcoes),
  );
  const history = useHistory()

  const [carregandoInterno, setCarregandoInterno] = useState(false);

  useEffect(() => {
    setCarregandoInterno(carregandoAxios);
  }, [carregandoAxios]);

  const invocarApi = useCallback(async (cfg?: RequestParams, options?: Options, tentarNovamente: boolean = true): Promise<RetornoApiModel> => {
    const objRet = { tipoRetorno: EnumRetornoApiBase.Servidor, statusCode: 0 };
    let resultado: AxiosResponse<any> | undefined;
    let erro: Error | undefined;

    resultado = undefined;
    erro = undefined;

    const tokenStorage = getRegistro(PedidosStorageKeys.Token, false);
    const ambiente = getRegistro(PedidosStorageKeys.Ambiente);
    const admUser = tipoUsuario() === EnumTipoUsuario.EnumTipoAdm;
    const validToken = admUser ? `Admin ${tokenStorage}` : `Bearer ${tokenStorage}`

    const apiUrl = !isEmpty(ambiente) ? ambiente === 'prod' ? VariaveisAmbiente.apiUrl : VariaveisAmbiente.apiHomoUrl : VariaveisAmbiente.apiUrl

    const tokenEnvio = cfg?.enviarTokenUsuario
      ? validToken
      : null;

    try {
      resultado = await invocarAxios(
        {
          ...cfg,
          baseURL: cfg?.baseURL ? cfg?.baseURL : apiUrl,
          headers: {
            Authorization: tokenEnvio,
            'FrontUrl': VariaveisAmbiente.frontUrl,
            ...cfg?.headers,
          },
        },
        options
      );

      const { tipoRetorno: tpretorno, statusCode: retStatus } = ApiBaseResponse(undefined, resultado);
      objRet.statusCode = retStatus;
      objRet.tipoRetorno = tpretorno;

    } catch (e: Error | any) {
      if (VariaveisAmbiente.isDev) console.log(e);
      erro = e;

      const { tipoRetorno: tpretorno, statusCode: retStatus } = ApiBaseResponse(e, e.response);
      objRet.statusCode = retStatus;
      objRet.tipoRetorno = tpretorno;

      //TRATAMENTO DE ERRO DE API
      if (e.response && e.response?.data?.title) {
        if (e.response?.data?.errors) {
          if (e.response?.data?.errors?.Mensagens.length > 1) {
            const erroFormatado = e.response?.data?.errors?.Mensagens.join('. ');
            erro = new Error(erroFormatado);
          } else {
            erro = new Error(e.response?.data?.errors?.Mensagens[0]);
          }
        } else {
          erro = new Error(e.response?.data?.title);
        }
      }
      console.log(objRet)
      switch (objRet.tipoRetorno) {
        case EnumRetornoApiBase.Local:
          let msg = erro?.message?.toString() || "";

          if (msg.indexOf("timeout") > -1 && msg.indexOf("exceeded") > -1) {
            erro = new Error(
              `O servidor demorou muito para processar a requisição (${(cfg?.timeout || defaultTimeout) / 1000
              }s).`
            );
          }
          if (retStatus === 401) {
            erro = new Error(
              `Sua sessão expirou. Realize o login novamente para continuar. (401)`
            );
            deslogar(usuario?.usuarioId || '');
            history.push('/login/expirou');
          }
          else if (msg.toLowerCase().indexOf("network error") > -1) {
            erro = new Error(
              `A comunicação com o servidor foi perdida. Tente novamente em alguns instantes.` +
              (msg.length > 0 ? `Detalhe: ${msg}` : ``)
            );
          }
          break;
        case EnumRetornoApiBase.Api:
          if (retStatus === 401) {
            erro = new Error(
              `Sua sessão expirou. Realize o login novamente para continuar. (401)`
            );
            deslogar(usuario?.usuarioId || '');
            history.push('/login/expirou');
          }
          break;
        case EnumRetornoApiBase.Servidor:
          erro = new Error(
            `O procedimento solicitado causou um erro no servidor. Tente novamente em alguns instantes. Detalhe: ${erro?.message}`
          );
          break;
      }
    }
    setCarregandoInterno(false);

    return {
      resultado,
      erro,
      tipoRetorno: objRet.tipoRetorno,
      statusCode: objRet.statusCode,
    };
  },
    [deslogar, getRegistro, history, invocarAxios, tipoUsuario, usuario?.usuarioId]
  );

  return {
    invocarApi,
    carregando: carregandoInterno,
  };
}
