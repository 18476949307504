import { EnumTpStatusPedido } from "../../../../../model/enums/enum-tp-status-pedido"


export function alterarCorStatusPedido(numero: number) {

    switch (numero) {
        case EnumTpStatusPedido.AguardandoConfirmação:
            return "#0500FF"
        case EnumTpStatusPedido.Confirmado:
            return '#FFBC00'
        case EnumTpStatusPedido.EmPreparo:
            return '#FFBC00'
        case EnumTpStatusPedido.Enviado:
            return '#FFBC00'
        case EnumTpStatusPedido.FinalizadoOuEntregue:
            return '#7AE28C'
        case EnumTpStatusPedido.Cancelado:
            return "#F44336"
        case EnumTpStatusPedido.Invalido:
            return "#F44336"
        default:
            return '';
    }
}