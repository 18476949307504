import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function usePatchIniciarLoja() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const patchIniciarLoja = useCallback(
        () => {
            return invocarApi({
                url: `/api/v2/loja/iniciar`,
                method: "PATCH",
                headers: { "Content-type": "application/json" },
                data: JSON.stringify({}),
                enviarTokenUsuario: true,
                timeout: 30000
            });
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        patchIniciarLoja,
    };
}
