import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    fecharButton: {
        marginRight: -10,
        background: "#CCC",
        width: 28,
        height: 28,
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        "& svg": {
            padding: 0,
            margin: 0,
            width: 18,
            fill:"#555",
            height: 18,
        },
        "&:hover": {
            background: "#C5C5C5",
        }
    },
}));
