import { useMemo, useState } from "react";
import { AplicacaoModel } from "../../../../../model/api/aplicacao/aplicacao-model";
import { useThemeQueries } from "../../../../../theme";
import { DefaultModal } from "../../../default-modal";
import { EditarAplicacaoProd } from "./editar-aplicacao-prod/editar-aplicacao-prod";
import { VisualizacaoAplicacaoProd } from "./visualizacao-aplicacao-prod/visualizacao-aplicacao-prod";
import { TesteAplicacaoProd } from "./teste-aplicacoes-prod/teste-aplicacoes-prod";

type Props = {
  openned: boolean;
  aplicacao: AplicacaoModel;
}

export const ModalAplicacaoAdminProd = ({ aplicacao, openned }: Props) => {
  const { isMobile } = useThemeQueries();
  const [cadastro, setCadastro] = useState<number>(1);

  const switchModais = useMemo(() => {
    switch (cadastro) {
      case 1:
        return (
          <VisualizacaoAplicacaoProd
            setCadastro={setCadastro}
            aplicacao={aplicacao}
          />
        )
      case 2:
        return (
          <EditarAplicacaoProd
            setCadastro={setCadastro}
            aplicacao={aplicacao}
          />

        )
        case 3:
        return (
          <TesteAplicacaoProd
            setCadastro={setCadastro}
            aplicacao={aplicacao}
          />

        )
    }
  }, [aplicacao, cadastro])

  return (
    <DefaultModal
      minWidth={isMobile ? '0' : "600px"}
      open={openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {switchModais}
    </DefaultModal>
  );
};
