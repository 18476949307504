import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useGetSincronizacaoDados() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getSincronizacaoDados = useCallback(
        ( lojaId: string, newPage: number, query?: string) => {
            return invocarApi({
                url: `/api/v2/loja/${lojaId}/sincronizacao?page=${newPage}${query}`,
                method: "GET",
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getSincronizacaoDados,
    };
}



