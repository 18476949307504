import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { Box, Button, Card, Grid, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { makeUtilClasses, useThemeQueries } from '../../../../../../theme';
import { CircularLoading } from '../../../../utils';
import { TextFieldSaurus } from '../../../../controles/inputs';
import { AdicionarAplicacaoRefs, AdicionarAplicacaoProps } from './form-teste-credencial-props';
import { AplicacaoModel } from '../../../../../../model/api/aplicacao/aplicacao-model';
import { useStyles } from '../../../../cards/card-credencial-homologacao/card-credencial-homologacao-styles';
import { EnumAplicacao } from '../../../../../../model/enums/enum-aplicacao';
import { EnumTipoConfig } from '../../../../../../model/enums/enum-tipo-config';
import { TesteIcon } from '../../../../icons';
import { TesteCredencialModel } from '../../../../../../model/api/aplicacao-credencial/teste-credencial-model';
import { CredenciaisDadosModel } from '../../../../../../model/api/credenciais/credenciais-dados-model';
import { EnumTpSituacao } from '../../../../../../model/enums/enum-tp-situacao';

export const FormTesteCredencial = forwardRef<
  AdicionarAplicacaoRefs<CredenciaisDadosModel>,
  AdicionarAplicacaoProps<AplicacaoModel>
>(({ loading, onSubmit, ...props }: AdicionarAplicacaoProps<AplicacaoModel>, ref,) => {
  const { isMobile } = useThemeQueries();
  const utilClasses = makeUtilClasses();
  const refInputNome = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    register,
    reset
  } = useForm<Record<string, string>>({
    criteriaMode: 'all',
    mode: 'onBlur' && 'onChange',
  });

  const submitAlterarDadosConta = (values: any) => {
    const documento = values.documento
    delete values.documento
    const credenciais: Record<string, string> = values as Record<string, string>;

    var model = new TesteCredencialModel();

    model.credencial.credenciar(credenciais)

    model.loja.documento = documento
    model.documento = documento
    model.codigoAplicacao = props.model!.codigoAplicacao!
    model.ativo = props.model?.tpSituacao === EnumTpSituacao.Ativa

    onSubmit(model);

  };


  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(submitAlterarDadosConta)();
    },
    resetForm: () => {
      reset();
      if (!isMobile) refInputNome.current?.focus();
    },
    fillForm: () => {
      reset({})
      if (!isMobile) refInputNome.current?.focus();
    },
  }));


  const validationIfood = props.model?.codigoAplicacao === EnumAplicacao.Ifood

  const configuracaoAutorizacao = useMemo(() => {
    if (validationIfood) {
      return props.model?.configuracoes?.configuracaoAuthorizacao.filter((_, i) => i === 0)
    } else if (props.model?.configuracoes && props.model?.configuracoes?.configuracaoAuthorizacao) {
      return props.model?.configuracoes?.configuracaoAuthorizacao
    }
    return
  }, [props.model?.configuracoes, validationIfood])


  return (
    <>
      <form className={loading ? utilClasses.controlLoading : ''}>
        <Card className={classes.rootApp}>
          <div className={classes.rootAppSon}>
            {configuracaoAutorizacao?.map((campo: any) => {
              return (
                <>
                  <Box>
                    <Grid container style={{ paddingBottom: 16 }} justifyContent="center">
                      <div className={utilClasses.formContainer}>
                        {loading && props.showLoading ? (
                          <div className={utilClasses.controlLoading}>
                            <CircularLoading tipo="FULLSIZED" />
                          </div>
                        ) : null}
                      </div>
                      <Grid item xs={12}>
                        <div style={{
                          display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}>
                          <Grid item xs={12}>
                            <Controller
                              {...register(campo.parametro)}
                              control={control}
                              render={({ field }) => (
                                <TextFieldSaurus
                                  tipo={campo.tipo === EnumTipoConfig.Texto ? "TEXTO" : "NUMERO"}
                                  fullWidth
                                  disabled={loading}
                                  className={classes.containerTexfield}
                                  showStartAdornment={true}
                                  allowSubmit={true}
                                  autoComplete={'off'}
                                  label={campo.descricao}
                                  placeholder={campo.descricao}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )
            })}
          </div>
          <Grid container spacing={2} justifyContent="center">
            <div className={classes.divider} />
            <Grid item xs={12}>
              <Controller
                name="documento"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="CNPJ_CPF"
                    fullWidth={true}
                    disabled={loading}
                    showStartAdornment={true}
                    className={classes.containerTexfield}
                    autoComplete={'off'}
                    label="Documento da Loja"
                    placeholder="Ex: 99.999.999/9999-99"
                    error={Boolean(
                      errors.documento && errors.documento.message,
                    )}
                    helperText={
                      touchedFields.documento || errors.documento
                        ? errors.documento?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Card>
        <div className={classes.divider} />
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              className={classes.button}
              fullWidth
              onClick={() => {
                props.scrollToTarget();
                setTimeout(props.redirectToResult, 1000);
                props.cadTesteCredencialFormRef.current?.submitForm()
              }}>
              <TesteIcon tipo="BUTTON_PRIMARY" />
              <Typography style={{ fontWeight: 700, fontSize: 16 }}>Teste Credencial</Typography>
            </Button>
          </Grid>
        </Grid>
        <Button style={{ display: 'none' }} type="submit"></Button>
      </form>
    </>
  );
},
);
