import { Grid } from "@material-ui/core";
import classNames from "classnames";
import { useEffect, useRef } from "react";
import { AplicacaoModel } from "../../../../../../model/api/aplicacao/aplicacao-model";
import { useModalStyles } from "../../../../utils/modals/modal-styles";
import { DefaultFormRefs } from "../../../../form/utils";
import { ModalHeader } from "../../../modal-header";
import { ButtonModalHeader } from "../../../../controles/buttons/button-modal-header";
import { VoltarIcon } from "../../../../icons";
import { CircularLoading } from "../../../../utils";
import { CardCredencialProd } from "../../../../cards/card-credencial-prod/card-credencial-prod";
import { useGetAplicacao } from "../../../../../../data/api/gestao/aplicacao/get-aplicacao";

interface TesteAplicacaoProps {
  setCadastro: (value: number) => void;
  aplicacao: AplicacaoModel;
}

export const TesteAplicacaoProd = ({ aplicacao, setCadastro }: TesteAplicacaoProps) => {
  const classes = useModalStyles();
  const { carregando } = useGetAplicacao();

  const cadTesteAplicacoeFormRef =
    useRef<DefaultFormRefs<AplicacaoModel>>(null);

  useEffect(() => {
    cadTesteAplicacoeFormRef.current?.fillForm(
      aplicacao
    )
  }, [aplicacao])

  return (
    <>
      <div className={classes.root}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <ModalHeader
          title={'Teste de Aplicação'}
          leftArea={
            <ButtonModalHeader
              tooltip="Voltar"
              icon={<VoltarIcon style={{
                fill: '#5333ED',
                width: '50px',
                height: '50px',
                marginRight: '0',
                marginBottom: '0'
              }} />}
              onClick={() => setCadastro(1)}
            />
          }
        />
        <div className={classes.content} style={{
          position: 'relative',
          maxHeight: '92vh',
        }}>
          <div className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}>
            <Grid item xs={12}>
              <CardCredencialProd />
            </Grid>
          </div>
        </div>
      </div>
    </>
  )
}

