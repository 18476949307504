import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
      root: {
        background: theme.palette.grey[100],
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    defaultContainer: {
        position: "relative",
        width: "100%",
        height: "100%",
        overflowX: 'auto',
        maxHeight: '500px'
    },
    listContainer: {
        "& .card": {
            margin: theme.spacing(1),
        }
    },
    list: {
        flex: "1 1 100%",
        overflow: 'hidden',
    },
}));
