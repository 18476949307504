import { useThemeQueries } from "../../../../theme";
import { DefaultModal } from "../../default-modal";
import { EditarLoja } from "./components/editar-loja/editar-loja";
import { isEmpty } from "lodash";
import { EditarLojaModel } from "../../../../model/api/loja/editar-loja-model";
import { LojaModel } from "../../../../model/api/loja/loja-model";
import { CredenciaisDadosModel } from "../../../../model/api/credenciais/credenciais-dados-model";
import { NovaLojaCadastro } from "./components/nova-loja/nova-loja";

type Props = {
  openned: boolean;
  id: string;
  model: LojaModel
  editar: EditarLojaModel
  credencial: CredenciaisDadosModel
}

export const LojaModal = ({ id, model, openned, editar, credencial }: Props) => {
  const { isMobile } = useThemeQueries();
  return (
    <DefaultModal
      minWidth={isMobile ? '0' : "600px"}
      open={openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {(openned && isEmpty(id)) && <NovaLojaCadastro />}
      {(openned && !isEmpty(id)) && <EditarLoja editar={editar} model={model} id={id}/>}
    </DefaultModal>
  );
};
