import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: "1",
  },
  content: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
  },
  contentAbas: {
    paddingTop: theme.spacing(1),
    boxShadow: "0px 5px 5px rgba(0,0,0,0.05) inset",
    marginLeft: theme.spacing(2) * -1,
    marginRight: theme.spacing(2) * -1,
  },
  contentForms: {
    flex: "1 1 auto",
    padding: theme.spacing(2),
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    maxHeight: "842px",
    "& * > form": {
      visibility: "visible",
    },
    "&  .tabs-class": {
      visibility: "visible",
    },
  },
  contentFormsLoading: {
    "& * > form": {
      visibility: "hidden",
    },

    "&  .tabs-class": {
      visibility: "hidden",
    },
  },
  acoes: {
    flex: "0 0 auto",
    padding: theme.spacing(1),
    boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.1)",
  },
  obs: {
    width: "500px",
    margin: "25px 90px",
    padding: "14px",
    borderRadius: "8px",
    [theme.breakpoints.down("xs")]: {
      width: "350px",
      margin: "20px 0 20px 26px",
      padding: "0",
      borderRadius: "8px",
    },
  },
  titleAlert: {
    color: "#5F5F5F",
    fontWeight: 700,
    fontSize: 16,
  },
  buttonRemover: {
    border: "1px solid #F44336",
    color: theme.palette.error.main,
    "&:hover": {
      border: "1px solid #FF0000",
    },
    "&:disabled": {
      backgroundColor: theme.palette.error.light,
    },
    marginRight: 8,
    "& svg": {
      margin: 0,
    },
  },

  titleReturn: {
    fontSize: "18px",
    fontWeight: 600,
    color: "#545454",
  },

  titleImportacao: {
    color: "#7D7D7D",
    fontSize: "12px",
    fontWeight: 700,
    textAlign: "center",
  },

  containerImportacao: {
    margin: "0 10px 0 10px",
    padding: "35px 10px",
    background: "rgb(255 244 215)",
    borderRadius: "10px",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
      padding: "5px 0 5px 0",
      background: "rgb(255 244 215)",
      borderRadius: "8px",
    },
  },

  containerReturn: {
    width: "100%",
    height: "469px",
    padding: "11px 17px 0 17px",
    background: "rgba(246, 246, 246, 0.4)",
    boxShadow: "inset 0px 0px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: "10px",
  },

  iconImportacao: {
    position: "relative",
    left: "21px",
    right: "0px",
    top: "29px",
    marginTop: "-30px",
  },

  dividerReturn: {
    width: "100%",
    height: "3px",
    marginTop: 9,
    marginBottom: 8,
    borderRadius: "1px",
    backgroundColor: "#D1D1D1",
  },

  divider: {
    width: "100%",
    height: "1px",
    marginTop: "10px",
    marginBottom: "10px",
    backgroundColor: "#E6E6E6",
  },
}));
