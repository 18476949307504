export enum EnumMenu {

  DASHBOARD = 1000,
  DASHBOARD_ADMIN = 3000,

  PEDIDOS = 1001,

  CONTRATOS_GROUP = 2000,
  DADOS = 2001,
  SINCRONIZACAO = 2002,

  ADMINISTRATIVO_CONFIGURACOES = 10006,
  
  CONFIGURACOES_GROUP = 2003,
  CONFIGURACOES_CONFIGURACOES = 2004,
  PEDIDOS_GROUP = 2005,

  //SAIR
  MASTER_SAIR = 99999,
}
