import { makeStyles } from "@material-ui/core";

export const useCardDashboardStyles = makeStyles((theme) => ({
  card: {
    width: "390px",
    height: "500",
    padding: theme.spacing(1, 2),
    background: "#fff",
    marginLeft: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up('md')]: {
      width: 660
    }
  },
  cardCnpj: {
    width: "470px",
    height: "550px",
    padding: "14px 25px 25px 25px",
    background: "#fff",
    marginLeft: "25px",
    borderRadius: theme.shape.borderRadius,
    marginBottom: "24px",
  },
  documentText: {
    fontSize: "13px",
    fontWeight: 700,
    color: "#6B6B6B",
    margin: "10px 10px",
  },
  divNTemAcesso: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nomeLoja: {
    fontSize: "20px",
    fontWeight: 600,
    color: "#696969",
  },
  ultimoAcesso: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#EFECFF",
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  titleIntegracao: {
    color: '#5F5F5F',
    margin: '11px 8px 0 4px',
    fontSize: '18px',
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      margin: "9px",
    },
  },
  horario: {
    display: "flex",
    alignItems: "center",
  },
  ultimoAcessoText: {
    color: '#5333ED',
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
  },
  labelStatusPai: {
    flex: 1,
    display: "flex",
    marginTop: "8px",
    justifyContent: "space-between",
  },
  button: {
    margin: "24px 0 0 0",
    height: 48
  },
  buttonCNPJ: {
    margin: 0,
  },
  divider: {
    width: "100%",
    height: "2px",
    marginBottom: "16px",
    backgroundColor: "#5333ED",
  },
  dividerIntegracao: {
    width: "100%",
    height: "2px",
    marginBottom: "16px",
    backgroundColor: "#ECEDED",
  }
}));
