import { makeStyles } from "@material-ui/core";

export const usePaiStyles = makeStyles(() => ({
  list: {
    width: "100%",
  },
  titlehidden: {
    marginTop: "0rem",
    lineHeight: "0px",
    color: "transparent",
    transition: "0.2s ease-in-out margin,0.2s ease-in-out lineHeight",
    fontSize: "12px",
    fontWeight: 600,
    paddingLeft: "25px",
  },
  title: {
    transition: "0.2s ease-in-out margin,0.2s ease-in-out lineHeight",
    marginTop: ".8rem",
    lineHeight: "20px",
    fontSize: "12px",
    fontWeight: 600,
    color: "#A3A3A3",
    paddingLeft: "25px",
  },

}));