import { makeStyles } from "@material-ui/core";

export const useDefaultStyles = makeStyles((theme) => ({
  defaultContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden"
  },
  listContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: 0,
    "& .card": {
      margin: theme.spacing(1),
    },
  },
  footer: {
    width: '100%',
    display: 'flex',
    background: '#FFF',
    borderTop: '1px solid #e0e0e0',
    maxHeight: '100px',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 15px 7px 15px',
    boxShadow: '5px 10px 18px #888888',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      display: 'flex',
      background: '#FFF',
      borderTop: '1px solid #e0e0e0',
      maxHeight: '100px',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 0,
      boxShadow: '5px 10px 18px #888888'
    },
    '& p': {
      fontSize: 13
    },
    '& span': {
      fontSize: 11
    }
  },
  botaoAdicionarLoja: {
    right: '30px',
    bottom: '15px',
    position: 'fixed',
    background: 'rgb(83, 51, 237)',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1.80889px 5.42667px',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#321f8b'
    },
  },
}));
