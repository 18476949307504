import { useState } from 'react'
import { LojaModel } from '../../../../model/api/loja/loja-model'
import { useLojasStyles } from './lojas-styles'
import { Button, Fade, Grid, Typography } from '@material-ui/core'
import { useCadastros } from '../../../../service/app/providers/cadastros-provider'
import { AvancarIcon, EditarIcon } from '../../icons'
import { formatarCPFCNPJ } from '../../../../utils/cpfcnpj-format'
import { CardExpandido } from '../card-expandido/card-expandido'
import { AplicacaoModel } from '../../../../model/api/aplicacao/aplicacao-model'
import { CircularLoading } from '../../utils'
import { isEmpty } from 'lodash'
import { EditarLojaModel } from '../../../../model/api/loja/editar-loja-model'
import { CredenciaisDadosModel } from '../../../../model/api/credenciais/credenciais-dados-model'

type CardLojaProps = {
    loja: LojaModel;
    aplicacoes: AplicacaoModel[];
    carregando: boolean;
    editando: boolean;
    primeiraLoja: boolean;
}

export default function CardLoja({ loja, aplicacoes, carregando, editando, primeiraLoja }: CardLojaProps) {
    const classes = useLojasStyles()
    const [open, setOpen] = useState<boolean>(primeiraLoja)
    const { abrirCadastroNovaLoja } = useCadastros()
    const onOff = loja?.servicos?.servicoCliente?.tpStatusServico === 1

    return (
        <>
            {carregando ? (<CircularLoading tipo="FULLSIZED" />) : null}
            <Fade in timeout={900}>
                <Grid container spacing={2} className={classes.root}>
                    <div className={classes.labelStatusPai}>
                        <Grid item xs={12} md={12}>
                            <> <Typography className={classes.nomeLoja}>{
                                loja?.razaoSocial
                                    ? loja?.razaoSocial
                                    : `CNPJ: ${formatarCPFCNPJ(loja.documento)}`
                            }
                            </Typography></>
                        </Grid>
                        <Grid item xs={6} md={12}>
                            <div className={classes.onOff}>
                                <Typography className={onOff ? classes.labelStatusOn : classes.labelStatusOff}>{onOff ? 'Online' : 'Offline'}</Typography>
                                <Button
                                    className={classes.button}
                                    startIcon={<EditarIcon tipo="BUTTON_EDITAR" />}
                                    onClick={() => {
                                        abrirCadastroNovaLoja(loja.id, loja, new EditarLojaModel(), new CredenciaisDadosModel(), '', false)
                                    }}
                                >
                                </Button>
                            </div>
                        </Grid>
                    </div>
                    <Grid xs={12}>
                        <Typography className={classes.documentText}>
                            {!isEmpty(loja.razaoSocial)
                                ? `CNPJ: ${formatarCPFCNPJ(loja.documento)}`
                                : undefined
                            }
                        </Typography>
                    </Grid>
                    <div className={classes.divider} />
                    <Grid xs={12} md={12} style={{ maxHeight: 'fit-content' }}>
                        <CardExpandido aplicacoes={aplicacoes} open={open} loja={loja} editando={editando} />
                    </Grid>
                    <Grid xs={12}>
                        <Button style={{ fontSize: '16px' }} onClick={() => setOpen(prev => !prev)} fullWidth>
                            <AvancarIcon tipo="BUTTON" style={{ transform: open ? 'rotate(-90deg)' : 'rotate(90deg)' }} />
                            Ver {open ? 'Menos' : 'Mais'}
                        </Button>
                    </Grid>
                </Grid >
            </Fade >
        </>
    )
}