import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  mainHeaderContainer: {
    height: "70px",
    boxShadow: theme.shadows[5],
    color: 'rgba(105, 105, 105, 1)',
    padding: "10px",
    lineHeight: "50px",
    [theme.breakpoints.down("sm")]: {
      height: "70px",
    },
  },
  title: {
    fontWeight: 700,
    fontSize: "1.5rem",
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  flexFixed: {
    flex: 0,
    padding: '0!important'
  },
  flexContent: {
    flex: 1,
    overflow: 'hidden',
  },
  drawerResumoPerfilContainer: {
    transition: "0.2s ease-in-out margin",
    alignItems: "center",
    marginTop: 0,
    display: "flex",
    "& > *": {
      margin: "10px",
    },
    "& .fotoPerfilContainer": {
      position: 'relative',
      "& img": {
        border: '1px solid #CDCDCD',
        objectFit: 'cover',
        transition: '0.2s ease-in-out width,0.2s ease-in-out height',
        borderRadius: '100%'
      },
      "&:hover .editarPerfil": {

        opacity: 1,
      }
    },
    "& .editarPerfil": {
      position: 'absolute',
      top: 0,
      left: 0,
      background: 'rgba(255,255,255,0.8)',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      fontSize: '9px',
      transition: '0.2s ease-in-out opacity',
      opacity: 0,
      cursor: 'pointer',
      "& svg": {
        margin: 0,
        marginBottom: 5,
        width: 24,
        height: 24,
      }
    },
  },
  drawerPerfilCompletoNotMobile: {
    marginTop: -30,
  },
  drawerPerfilMini: {
    "& .fotoPerfilContainer": {
      "& img": {
        width: 58,
        height: 58,
      },

    },
  },
}));
