import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useGetSincronizacaoDashboard() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();
//Primeiro get para pegar as sincronizações
    const getSincronizacaoDashboard = useCallback(
        (newPage: number) => {
            return invocarApi({
                url: `/api/v2/sincronizacao`,
                method: "GET",
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getSincronizacaoDashboard,
    };
}