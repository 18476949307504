import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { TesteCredencialModel } from '../../../../model/api/aplicacao-credencial/teste-credencial-model';

export function usePostTesteAplicacaoCodPedido() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();
    const apiUrlHomo = 'https://sauruspedidosapi-staging.azurewebsites.net';

    const postTesteAplicacaoCodPedido = useCallback(
        (model: TesteCredencialModel, codigoAplicacao: string, codigoPedido: string) =>
            invocarApi({
                url: `/api/v2/aplicacao/${codigoAplicacao}/homologacao/pedido/${codigoPedido}`,
                method: "POST",
                data: model,
                baseURL: apiUrlHomo,
                headers: { "Content-Type": "application/json" },
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        postTesteAplicacaoCodPedido
    }
}