import { makeStyles } from "@material-ui/core";

export const useCardPedidosStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
    },
    defaultContainer: {
        position: "relative",
        width: "100%",
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden"
    },
    table: {
        minWidth: 650,
    },
    aplicacaoSub: {
        width: '100%',
        background: '#fff',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.07)'
    },
    containerSituacao: {
        width: '100%',
        height: '50px',
        display: 'flex',
        maxWidth: '135px',
        alignItems: 'center',
        borderRadius: '8px',
        justifyContent: 'center'
    },
    divider: {
        width: '100%',
        height: '1px',
        marginBottom: 8,
        backgroundColor: 'rgba(223, 223, 223, 0.3)'
    },
    titleTelas: {
        color: '#696969',
        fontSize: '22px',
        [theme.breakpoints.down("xs")]: {
            fontSize: '20px',
        },
    },
    titleSituacao: {
        color: '#fff',
        fontSize: '12px',
        fontWeight: 700,
        [theme.breakpoints.down("xs")]: {
            fontSize: '10px',
            fontWeight: 700,
        },
    },
    titleSub: {
        color: '#5F5F5F',
        fontSize: '16px',
        fontWeight: 700,
        [theme.breakpoints.down("xs")]: {
            fontSize: '13px',
        },
    },
    titleSinc: {
        color: '#696969',
        fontSize: '15px',
        padding: '15px 0',
        [theme.breakpoints.down("xs")]: {
            padding: 0,
            margin: 0,
            fontSize: '12px',
        },
    },
    card: {
        '& .MuiPaper-elevation1': {
            boxShadow: 'none'
        },
        "& .MuiTableCell-root": {
            border: 'none'
        },
    },
    cardRoot: {
        '&.MuiPaper-rounded': {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
        },
        position: 'relative'
    },
    cardCinza: {
        '& .MuiPaper-elevation1': {
            background: theme.palette.grey[100]
        }
    },
    titleStatusPedido: {
        borderRadius: theme.shape.borderRadius,
        width: '110px',
        height: '25px',
        color: '#FFFFFF',
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));
