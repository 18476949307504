import { useCallback, useState } from "react";
import { useMenuPrincipal } from "../../../../../../service/app/providers/menu-principal-provider";
import { useThemeQueries } from "../../../../../../theme";
import { ButtonPrivateHeader } from "../../../../../components/controles/buttons";
import { PrivatePageHeader } from "../../../../../components/headers/header-private-page"
import { MenuIcon } from "../../../../../components/icons";
import { Button } from "@material-ui/core";
import { useDashboardHeaderStyles } from "./dashboard-header-styles";
import { DialogAlterarUsoApi } from "../../../../../components/dialog/dialog-alterar-uso-api/dialog-alterar-uso-api";

export const DashboardHeader = () => {

   const { abrirMenu } = useMenuPrincipal()
   const { isMobile } = useThemeQueries()
   const [aberto, setAberto] = useState<boolean>(false)
   const classes = useDashboardHeaderStyles()

   const leftArea = useCallback(
      () =>
         isMobile ? (
            <ButtonPrivateHeader
               icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
               tooltip="Menu"
               onClick={abrirMenu}
            ></ButtonPrivateHeader>
         ) : null,
      [isMobile, abrirMenu]
   );

   const rightArea = useCallback(() => (
      <Button
         className={classes.btnEscondido}
         onClick={() => setAberto(true)}
      />
   ), [classes.btnEscondido])

   return (
      <>
         <PrivatePageHeader
            title="Dashboard"
            leftArea={leftArea()}
            rightArea={rightArea()}
         />
         <DialogAlterarUsoApi openned={aberto} closeModal={() => setAberto(false)} />
      </>
   )
}