import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useCardSincronizacaoStyles } from "./card-sincronizacao-styles";
import { formatDataHora } from "../../../../utils/format-data-hora";
import { CardSincronizacaoProps } from "./card-sincronizacao-props";
import { useCadastros } from "../../../../service/app/providers/cadastros-provider";
import { DownloadIcon } from "../../icons/download-icon";
import { useThemeQueries } from "../../../../theme";
import { alterarNomeSituacao } from "../../../../utils/alterar-nome-situacao";
import { alterarCorStatusSituacao } from "../../../../utils/alterar-cor-situacao";
import { useCallback } from "react";
import { useToastSaurus } from "../../../../service/app/use-cases";
import { useGetSincronizacaoDadosById } from "../../../../data/api/gestao/sincronizacao-dados/get-sincronizacao-dados-by-id";
import { toDecimalString } from "../../../../utils/to-decimal";
import { isEmpty } from "lodash";
import classNames from "classnames";
import { SincronizacaoByIdModel } from "../../../../model/api/sincronizacao/sincronizacao-by-id-model";

export const CardSincronizacao = ({
  model,
  lojaId,
  index,
}: CardSincronizacaoProps) => {
  const classes = useCardSincronizacaoStyles();
  const { abrirEnvioCarga } = useCadastros();
  const { showToast } = useToastSaurus();
  const { theme, isMobile } = useThemeQueries();
  const { getSincronizacaoDadosById } = useGetSincronizacaoDadosById();

  const abrirPedido = useCallback(async () => {
    try {
      const ret = await getSincronizacaoDadosById(lojaId, model.id, 4);
      if (ret.erro) throw ret.erro;

      if (!ret.resultado) {
        showToast("error", "Modelo Inválido");
        return;
      }

      abrirEnvioCarga(
        lojaId,
        ret.resultado.data as SincronizacaoByIdModel,
        "",
        false
      );
    } catch (e: any) {
      showToast("error", e.message);
    }
  }, [abrirEnvioCarga, getSincronizacaoDadosById, lojaId, model.id, showToast]);

  return (
    <>
      {isMobile ? (
        <Grid
          className={classNames(
            classes.card,
            index % 2 === 0 && classes.cardCinza
          )}
        >
          <Button
            variant="text"
            fullWidth
            style={{
              padding: 0,
            }}
            onClick={async () => await abrirPedido()}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell
                      color="#fff"
                      width="10%"
                      align="center"
                      scope="row"
                      size="small"
                    >
                      {model.aplicacao}
                    </TableCell>
                    <TableCell width="10%" align="center">
                      {model.usuario}
                    </TableCell>
                    <TableCell width="10%" align="center">
                      R${toDecimalString(model.totalProdutos, 2)}
                    </TableCell>
                    {!isEmpty(model.porcentagem) ? (
                      <TableCell width="10%" align="center">
                        {model.porcentagem}%
                      </TableCell>
                    ) : (
                      <TableCell width="10%" align="center">
                        -
                      </TableCell>
                    )}
                    <TableCell width="10%" align="left">
                      <Typography>
                        {formatDataHora(new Date(model.dataCriacao))}
                      </Typography>
                    </TableCell>
                    <TableCell width="10%" align="left">
                      <Typography>
                        {formatDataHora(new Date(model.ultimoEnvio))}
                      </Typography>
                    </TableCell>
                    <TableCell width="10%" align="left">
                      <Typography
                        className={classes.titleStatus}
                        style={{
                          background: alterarCorStatusSituacao(model.situacao),
                        }}
                      >
                        {alterarNomeSituacao(model.situacao)}
                      </Typography>
                    </TableCell>
                    <TableCell width="5%" align="center">
                      <DownloadIcon
                        style={{ width: "40px" }}
                        fillPath={theme.palette.primary.main}
                        fill="none"
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Button>
        </Grid>
      ) : (
        <Grid
          className={classNames(
            classes.card,
            index % 2 === 0 && classes.cardCinza
          )}
        >
          <Button
            variant="text"
            fullWidth
            style={{
              padding: 0,
            }}
            onClick={() => abrirPedido()}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell
                      color="#fff"
                      align="center"
                      width="10%"
                      scope="row"
                      size="small"
                    >
                      {model.aplicacao}
                    </TableCell>
                    <TableCell width="10%" align="center">
                      {model.usuario}
                    </TableCell>
                    <TableCell width="10%" align="center">
                      {toDecimalString(model.totalProdutos, 0)}
                    </TableCell>
                    {!isEmpty(model.porcentagem) ? (
                      <TableCell width="10%" align="center">
                        {model.porcentagem}%
                      </TableCell>
                    ) : (
                      <TableCell width="10%" align="center">
                        -
                      </TableCell>
                    )}
                    <TableCell width="10%" align="center">
                      <Typography>
                        {formatDataHora(new Date(model.dataCriacao))}
                      </Typography>
                    </TableCell>
                    <TableCell width="10%" align="center">
                      <Typography>
                        {formatDataHora(new Date(model.ultimoEnvio))}
                      </Typography>
                    </TableCell>
                    <TableCell width="10%" align="center">
                      <Typography
                        className={classes.titleStatus}
                        style={{
                          background: alterarCorStatusSituacao(model.situacao),
                        }}
                      >
                        {alterarNomeSituacao(model.situacao)}
                      </Typography>
                    </TableCell>
                    <TableCell width="6%" align="center">
                      <DownloadIcon
                        style={{ width: "40px" }}
                        fillPath={theme.palette.primary.main}
                        fill="none"
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Button>
        </Grid>
      )}
    </>
  );
};
