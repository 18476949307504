import { useCallback } from "react";
import { isEmpty } from 'lodash';
import { decodeToken } from "react-jwt";
import { PerfilModel } from '../../../model/api/gestao/master/perfil';
import { TokenSaurusPedidosModel } from "../../../model/api/gestao/master/token-saurus-pedidos";
import { PedidosStorageKeys, useSaurusPedidosStorage } from "./saurus-pedidos-storage";
import { TokenAdministracaoSaurusPedidosModel } from "../../../model/api/gestao/master/token-administracao-saurus-pedidos";

export function useSaurusPedidosToken() {
    const { setRegistro, delRegistro, getRegistro } = useSaurusPedidosStorage();
    const tokenFromSession: boolean = false;


    const isTokenExpired = useCallback((token: TokenSaurusPedidosModel | undefined): boolean => {
        if (!token?.exp) {
            return true;
        }
        return token?.exp * 1000 <= new Date().getTime();
    }, []);


    const convertToken = useCallback((token?: string): TokenSaurusPedidosModel | undefined => {
        let gtoken: TokenSaurusPedidosModel | undefined;
        try {
            gtoken = decodeToken(token!) as TokenSaurusPedidosModel;
            gtoken.contratoId = JSON.parse(JSON.stringify(gtoken.contratoId))
            gtoken.usuarioId = JSON.parse(JSON.stringify(gtoken.usuarioId))
        } catch (e) {
            gtoken = undefined;
        }
        return gtoken;
    }, []);

    const convertTokenAdm = useCallback((token?: string): TokenAdministracaoSaurusPedidosModel | undefined => {
        let gtoken: TokenAdministracaoSaurusPedidosModel | undefined;
        try {
            const utf8 = require('utf8');
            gtoken = decodeToken(token!) as TokenAdministracaoSaurusPedidosModel;
            gtoken.usuarioNome = utf8.decode(gtoken.usuarioNome);
            gtoken.contratoId = JSON.parse(JSON.stringify(gtoken.contratoId))
            gtoken.usuarioId = JSON.parse(JSON.stringify(gtoken.usuarioId))
            gtoken.administracao = JSON.parse(JSON.stringify(gtoken.administracao))
        } catch (e) {
            gtoken = undefined;
        }
        return gtoken;
    }, []);


    const getTokenFromStorage = useCallback((): TokenSaurusPedidosModel | undefined => {
        const token = getRegistro(PedidosStorageKeys.Token, tokenFromSession);
        if (isEmpty(token)) {
            return undefined;
        }

        return (convertToken(token));
    }, [getRegistro, convertToken, tokenFromSession]);

    const getTokenAdmFromStorage = useCallback((): TokenAdministracaoSaurusPedidosModel | undefined => {
        const token = getRegistro(PedidosStorageKeys.Token, tokenFromSession);
        if (isEmpty(token)) {
            return undefined;
        }

        return (convertTokenAdm(token));
    }, [getRegistro, tokenFromSession, convertTokenAdm]);


    const isTokenValid = useCallback((token: TokenSaurusPedidosModel | undefined): boolean => {
        return !isEmpty(token) && !isTokenExpired(token);
    }, [isTokenExpired]);


    const persistToken = useCallback((token: string | undefined): void => {

        if (isEmpty(token)) {
            return delRegistro(PedidosStorageKeys.Token, false);
        } else {
            return setRegistro(PedidosStorageKeys.Token, token, false);
        }

    }, [delRegistro, setRegistro]);


    const persistPerfilPermissoes = useCallback((perfis: Array<PerfilModel> | undefined) => {
        if (perfis)
            setRegistro(PedidosStorageKeys.PerfisDoUsuario, perfis, tokenFromSession);
        else
            delRegistro(PedidosStorageKeys.PerfisDoUsuario, tokenFromSession);
    }, [setRegistro, delRegistro, tokenFromSession]);


    const getPerfilPermissoes = useCallback((): Array<PerfilModel> => {
        const ret = getRegistro(PedidosStorageKeys.PerfisDoUsuario, tokenFromSession);
        if (isEmpty(ret))
            return new Array<PerfilModel>();

        return ret;
    }, [getRegistro, tokenFromSession])


    const getEmpresaAtual = useCallback((): string => {
        const ret = getRegistro(PedidosStorageKeys.EmpresaAtual, tokenFromSession);
        if (isEmpty(ret))
            return '';

        return ret;
    }, [getRegistro, tokenFromSession])

    const persistContratoSelecionado = useCallback((contratoId: string | undefined) => {
        if (contratoId === undefined)
            delRegistro(PedidosStorageKeys.ContratoId, tokenFromSession)
        else
            setRegistro(PedidosStorageKeys.ContratoId, contratoId.toString(), tokenFromSession);
    }, [setRegistro, delRegistro, tokenFromSession]);

    return {
        isTokenExpired,
        isTokenValid,
        persistToken,
        getTokenFromStorage,
        getTokenAdmFromStorage,
        convertToken,
        convertTokenAdm,
        persistPerfilPermissoes,
        getPerfilPermissoes,
        getEmpresaAtual,
        persistContratoSelecionado
    };
}