import { makeStyles } from "@material-ui/core";

export const useDashboardHeaderStyles = makeStyles((theme) => ({
    root: {
        background: '#fff',
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflowX: "hidden",
        borderRadius: 15,
        padding: '14px 25px 0 25px',
        marginLeft: '10px'
    },
    documentText: {
        fontSize: '12px',
        fontWeight: 700,
        color: '#6B6B6B',
        fontStyle: 'normal'
    },
    nomeLoja: {
        fontSize: '22px',
        fontWeight: 700,
        color: '#545454'
    },
    ultimoAcesso: {
        background: '#EFECFF',
        padding: '6px 10px',
        borderRadius: '5px'
    },
    ultimoAcessoText: {
        color: '#5333ED',
        fontWeight: 700,
        textAlign: 'center',
        fontSize: '12px'
    },

    labelStatusPai: {
        flex: 1,
        display: 'flex',
        marginTop: '8px',
        justifyContent: 'space-between'
    },

    teste: {
        display: 'flex',
        alignItems: 'center'
    },

    button: {
        border: '1px solid #FFFFFF',
        boxShadow: '0px 0px 4.6875px rgba(0, 0, 0, 0.25)',
        borderRadius: '8px'
    },

    labelDiv: {
        width: '100px',
        borderRadius: '7px',
        padding: '6px 0',
    },

    labelStatusOn: {
        color: '#FFFFFF',
        width: '100px',
        margin: '0 10px',
        padding: '3px 0',
        fontSize: '15px',
        background: '#10C822',
        textAlign: 'center',
        fontWeight: 700,
        borderRadius: '7px'

    },

    labelStatusOff: {
        color: '#FFFFFF',
        width: '100px',
        margin: '0 10px',
        padding: '3px 0',
        fontSize: '15px',
        background: '#EC1C2E',
        textAlign: 'center',
        fontWeight: 700,
        borderRadius: '7px'
    },

    statusFilho: {
        display: 'flex',
        justifyContent: 'space-between'
    },

    statusLoja: {
        border: '1px solid #BDBDBD',
        height: '36px',
        margin: '0 10px',
        display: 'flex',
        padding: '0 20px',
        textAlign: 'center',
        alignItems: 'center',
        borderRadius: '6px',
        justifyContent: 'center',
    },
    labelStatusLoja: {
        color: '#545454',
        padding: '0 27px 0 0',
        fontSize: '16px',
        fontWeight: 700
    },
    divBallVerde: {
        background: '#10C822',
        borderRadius: '50%',
        width: '14px',
        height: '14px',
        margin: 8
    },
    divBallAmarelo: {
        background: '#FFBC00',
        borderRadius: '50%',
        width: '14px',
        height: '14px',
        margin: 8
    },
    divBallError: {
        background: '#F44336',
        borderRadius: '50%',
        width: '14px',
        height: '14px',
        margin: 8
    },
    botaoAdicionarLoja: {
        right: '30px',
        bottom: '15px',
        position: 'fixed',
        background: 'rgb(83, 51, 237)',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1.80889px 5.42667px',
        borderRadius: '50%',
        '&:hover': {
            backgroundColor: '#321f8b'
        },
    },
    divider: {
        width: '100%',
        height: '1px',
        marginTop: '10px',
        marginBottom: '10px',
        backgroundColor: '#E6E6E6'
    },
    btnEscondido: {
        width: 40,
        height: 40,
        '&:hover': {
            background: 'none'
        }
    }
}));
