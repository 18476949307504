
export class ServicoClienteModel {
  constructor(

    public tpStatusServico: number = 0,

    public descricaoStatusServico: string = "",

    public mensagemServico: string = "",

    public dataUltimaExecucaoServico: string = "",
    
  ) {}
}
