import { LojaModel } from '../../../../model/api/loja/loja-model'
import { Button, Divider, Grid, Typography } from '@material-ui/core'
import { AplicacaoModel } from '../../../../model/api/aplicacao/aplicacao-model'
import { useCardDashboardStyles } from './card-dashboard-loja-styles'
import { CredenciaisDadosModel } from '../../../../model/api/credenciais/credenciais-dados-model'
import { useCadastros } from '../../../../service/app/providers/cadastros-provider'
import { formatDataHora } from '../../../../utils/format-data-hora'
import { isEmpty } from 'lodash'
import { HorarioIcon } from '../../icons/horario-icon'
import { EditarLojaModel } from '../../../../model/api/loja/editar-loja-model'
import { CardAplicacaoDashboard } from '../card-aplicacao-dashboard/card-aplicacao-dashboard'
import { formatarCPFCNPJ } from '../../../../utils/cpfcnpj-format'
import { EditarIcon } from '../../icons'

type CardLojaProps = {
    loja: LojaModel;
    aplicacoes: AplicacaoModel[];
    editando: boolean
}

export default function CardDashboardLoja({ loja, aplicacoes, editando }: CardLojaProps) {
    const classes = useCardDashboardStyles()
    const { abrirCadastroNovaLoja } = useCadastros();

    return (
        <>
            {!isEmpty(loja?.razaoSocial) ?
                <div>
                    <Grid className={classes.card}>
                        <Grid container>
                            <Grid item xs={12}>
                                <div className={classes.labelStatusPai} >
                                    <Typography className={classes.nomeLoja}>
                                        {loja?.razaoSocial}
                                    </Typography>
                                </div>
                            </Grid>
                            <div className={classes.divider} />
                            <Grid item xs={12}>
                                <div className={classes.ultimoAcesso}>
                                    <div className={classes.horario}>
                                        <HorarioIcon tipo='BUTTON' />
                                        <Typography className={classes.ultimoAcessoText}>
                                            {!isEmpty(loja?.servicos?.servicoCliente?.dataUltimaExecucaoServico && loja.servicos.servicoCliente.tpStatusServico === 0) ?
                                                <>A última consulta desta loja foi <strong style={{ fontWeight: 700 }}>{formatDataHora(new Date(loja?.servicos.servicoCliente.dataUltimaExecucaoServico))}</strong></>
                                                : 'Não há último acesso'}
                                        </Typography>
                                    </div>
                                </div>
                            </Grid>
                            <Grid xs={12}>
                                <Typography className={classes.titleIntegracao}>Integração</Typography>
                                <Divider classes={{ root: classes.dividerIntegracao }} />
                            </Grid>
                            <Grid xs={12}>
                                <CardAplicacaoDashboard loja={loja} aplicacoes={aplicacoes} editando={editando} />
                            </Grid>
                            <Grid xs={12}>
                                <Button
                                    className={classes.button}
                                    variant='contained'
                                    fullWidth
                                    size='large'
                                    color='primary'
                                    startIcon={<EditarIcon tipo='BUTTON_PRIMARY' />}
                                    onClick={() => {
                                        abrirCadastroNovaLoja(loja.id, loja, new EditarLojaModel(), new CredenciaisDadosModel(), '', false)
                                    }}
                                >
                                    Editar Loja
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                :
                <div>
                    <Grid className={classes.cardCnpj}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={12}>
                                <Typography style={{ fontSize: '12px' }}> CNPJ: </Typography>
                                <div className={classes.labelStatusPai}>
                                    <Typography className={classes.nomeLoja}>
                                        {formatarCPFCNPJ(loja?.documento)}
                                    </Typography>
                                </div>
                            </Grid>
                            <div className={classes.divider} />
                            <Grid item xs={12}>
                                <div className={classes.ultimoAcesso}>
                                    <div className={classes.horario}>
                                        <HorarioIcon tipo='BUTTON' />
                                        <Typography className={classes.ultimoAcessoText}>
                                            {!isEmpty(loja?.servicos?.servicoCliente?.dataUltimaExecucaoServico) ?
                                                <>{formatDataHora(new Date(loja?.servicos.servicoCliente.dataUltimaExecucaoServico))}</>
                                                : 'Não há último acesso'}
                                        </Typography>
                                    </div>
                                </div>
                            </Grid>
                            <Grid xs={12}>
                                <Typography className={classes.titleIntegracao}>Integração</Typography>
                            </Grid>
                            <Grid xs={12}>
                                <CardAplicacaoDashboard loja={loja} aplicacoes={aplicacoes} editando={editando} />
                            </Grid>
                            <Grid xs={12}>
                                <Button
                                    className={classes.buttonCNPJ}
                                    variant='contained'
                                    fullWidth
                                    size='large'
                                    color='primary'
                                    onClick={() => {
                                        abrirCadastroNovaLoja(loja.id, loja, new EditarLojaModel(), new CredenciaisDadosModel(), '', false)
                                    }}
                                >
                                    Editar Loja
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            }
        </>
    )
}