import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Grid, Slide } from '@material-ui/core';
import { useToastSaurus } from '../../../../../../service/app/use-cases';
import { useThemeQueries } from '../../../../../../theme';
import { SelectSaurus } from '../../../../../components/controles/selects/select-saurus/select-saurus';
import { useGetLojas } from '../../../../../../data/api/gestao/lojas/get-lojas';
import { LojaModel } from '../../../../../../model/api/loja/loja-model';
import { KeyValueModel } from '../../../../../../model';
import { SituacaoPedidoMock } from '../../../../../../data/mocks/situacao-pedido-mock';
import { useStyles } from './pesquisa-pedidos-styles'
import { ProcurarIcon } from '../../../../../components/icons';
import { PesquisarPedidosProps } from './pesquisa-pedidos-props';
import { isEmpty } from 'lodash';
import { formatarCPFCNPJ } from '../../../../../../utils/cpfcnpj-format';
import { TextFieldSaurus } from '../../../../../components/controles/inputs';
import { useGetAplicacao } from '../../../../../../data/api/gestao/aplicacao/get-aplicacao';
import { toDate } from '../../../../../../utils/to-date';
import { AplicacaoModel } from '../../../../../../model/api/aplicacao/aplicacao-model';

const dataFormatada = (val: Date) => {
  const data = toDate(val) ?? new Date(val);
  const dia = data?.getDate();
  const mes = data?.getMonth() + 1;
  const ano = data?.getFullYear();

  const dataFormated = `${ano}-${mes?.toString().length === 1 ? '0' + mes : mes
    }-${dia?.toString().length === 1 ? '0' + dia : dia}`;
  return dataFormated;
};

export const PesquisarPedidos = (props: PesquisarPedidosProps) => {
  const classes = useStyles()
  const [lojas, setLojas] = useState<KeyValueModel[]>([])
  const { getLojas } = useGetLojas();
  const { showToast } = useToastSaurus()
  const { isMobile } = useThemeQueries();
  const refLojas = useRef<LojaModel[]>([])
  const aplicacoesRef = useRef<AplicacaoModel[]>([])
  const [codPedido, setCodPedido] = useState<string>('')
  const [aplicacao, setAplicacao] = useState('');
  const [aplicacoes, setAplicacoes] = useState<KeyValueModel[]>([]);
  const [selectedDateInicio, setSelectedDateInicio] = useState<string>(() => {
    const data = new Date()
    data.setDate(data.getDate() - 7)
    return dataFormatada(data)
  });
  const [selectedDateFim, setSelectedDateFim] = useState('');
  const [lojaNome, setLojaNome] = useState('');
  const [lojaId, setLojaId] = useState('');
  const [status, setStatus] = useState(-1);
  const refInput = useRef<HTMLDivElement | null>(null);

  const { getAplicacao, carregando } = useGetAplicacao()

  useEffect(() => {
    if (props.isOpened && !isMobile) refInput.current?.focus();
    else {
      setLojaNome('');
      setLojaId('');
      setStatus(-1);
      setCodPedido(codPedido);
      setAplicacao('');
      setSelectedDateFim('');
      setSelectedDateInicio('');
    }
  }, [codPedido, isMobile, props.isOpened]);

  const pesquisar = useCallback(
    (event: any) => {
      event.preventDefault();
      props.onPesquisar({
        aplicacao: aplicacao,
        codPedido: codPedido,
        dInicial: selectedDateInicio,
        dFinal: selectedDateFim,
        lojaId: lojaId,
        lojaNome: lojaNome,
        status: status
      });
    },
    [aplicacao, codPedido, lojaId, lojaNome, props, selectedDateFim, selectedDateInicio, status],
  );

  const onChangePesquisa = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setLojaId(event.target.value);
      setLojaNome(refLojas.current.find(loja => loja.id === event.target.value)?.razaoSocial || '')

      const lojaSelecionada = refLojas.current.find((lj: LojaModel) => lj.id === event.target.value);
      if (lojaSelecionada) {
        const apps = aplicacoesRef.current.filter((app: any) =>
          lojaSelecionada.credenciais.some((curr: any) => curr.codigoAplicacao === app.codigoAplicacao)
        );

        const keyValueApp = apps.map((app: any) => new KeyValueModel(app.codigoAplicacao, app.descricaoAplicacao));
        setAplicacoes(keyValueApp);
      }
    }, []);

  const onChangeCodPedido = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setCodPedido(event.target.value)
    },
    [],
  );

  const handleDateInicioChange = (e: any) => {
    setSelectedDateInicio(e.target.value);
  };

  const handleDateFimChange = (e: any) => {
    setSelectedDateFim(e.target.value);
  };

  const onChangeAplicacao = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setAplicacao(aplicacoes.find(app => app.Key === event.target.value)?.Key || '')
    },
    [aplicacoes],
  );

  const lojasWrapper = useCallback(async () => {
    try {
      const res = await Promise.all([getLojas(), getAplicacao()]);

      if (res[0].erro || res[1].erro) {
        throw new Error('Erro ao carregar dados');
      }

      const lojasData = res[0].resultado?.data.lojas as LojaModel[];
      const appsData = res[1].resultado?.data;

      refLojas.current = lojasData;
      aplicacoesRef.current = appsData

      const keyValueLojas = lojasData.map((loja) =>
        new KeyValueModel(
          loja.id,
          isEmpty(loja.razaoSocial) ? formatarCPFCNPJ(loja.documento) : loja.razaoSocial
        )
      );

      setLojas(keyValueLojas);

      if (keyValueLojas.length > 0) {
        const [valueLoja] = keyValueLojas
        setLojaId(valueLoja.Key);
        setLojaNome(valueLoja.Value)

        // Configurar as aplicações para a primeira loja
        const lojaSelecionada = lojasData.find((lj: LojaModel) => lj.id === keyValueLojas[0].Key);
        if (lojaSelecionada) {
          const apps = appsData.filter((app: any) =>
            lojaSelecionada.credenciais.some((curr: any) => curr.codigoAplicacao === app.codigoAplicacao)
          );

          const keyValueApp = apps.map((app: any) => new KeyValueModel(app.codigoAplicacao, app.descricaoAplicacao));
          setAplicacoes(keyValueApp);
        }
      }
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getAplicacao, getLojas, showToast]);

  useEffect(() => {
    lojasWrapper();
  }, [lojasWrapper]);



  return (
    <>
      <Slide in={props.isOpened} direction="down" mountOnEnter unmountOnExit timeout={300}>
        <form onSubmit={pesquisar} style={{
          paddingLeft: 8,
          paddingRight: 8,
        }}>
          <Grid container className={classes.root} spacing={2}>
            <Grid item md={4} xs={12}>
              <SelectSaurus
                conteudo={lojas}
                style={{ height: '40px' }}
                variant="outlined"
                label='Loja'
                name='lojaNome'
                size="small"
                allowSubmit
                select
                fullWidth
                onChange={onChangePesquisa}
                value={lojaId}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <SelectSaurus
                disabled={carregando || isEmpty(aplicacoes)}
                conteudo={aplicacoes}
                style={{ height: '40px' }}
                variant="outlined"
                label='Aplicação'
                size="small"
                name='aplicacao'
                allowSubmit
                select
                value={aplicacao}
                onChange={onChangeAplicacao}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <SelectSaurus
                conteudo={SituacaoPedidoMock}
                style={{ height: '40px' }}
                variant="outlined"
                label='Status'
                size="small"
                name='status'
                disabled={false}
                allowSubmit
                select
                fullWidth
                onChange={(event) => {
                  if (event) {
                    const newStatus = SituacaoPedidoMock.filter(
                      (item) => item.Key === event.target.value,
                    )[0]?.Key;
                    setStatus(newStatus);
                  }
                }}
                value={status}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextFieldSaurus
                fullWidth
                size="small"
                allowSubmit={true}
                value={codPedido}
                tipo="TEXTO"
                name='codPedido'
                disabled={false}
                showStartAdornment={true}
                label="Código do Pedido"
                placeholder="Ex: 41354"
                onChange={onChangeCodPedido}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextFieldSaurus
                tipo="DATA"
                name='dataInicial'
                fullWidth={true}
                size="small"
                showStartAdornment={true}
                autoComplete={'on'}
                label="Data Inicial"
                value={selectedDateInicio}
                onChange={(e) => handleDateInicioChange(e)}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextFieldSaurus
                tipo="DATA"
                fullWidth={true}
                name='dataFinal'
                size="small"
                showStartAdornment={true}
                autoComplete={'on'}
                label="Data Final"
                onChange={(e) => handleDateFimChange(e)}
                value={selectedDateFim}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  <ProcurarIcon tipo="BUTTON_PRIMARY" />
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Slide>
    </>
  );
}
