import {
  Box,
  Card,
  Grid,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useToastSaurus } from "../../../../../../service/app/use-cases";
import { Paginacao } from "../../../../../components/paginacao/paginacao";
import { useDefaultSincronizacaoStyles } from "../../sincronizacao-styles";
import { SincronizacaoListData } from "./sincronizacao-list-data";
import { SincronizacaoListSearchProps } from "./sincronizacao-list-search-props";
import { isEmpty } from "lodash";
import { useGetSincronizacaoDados } from "../../../../../../data/api/gestao/sincronizacao-dados/get-sincronizacao-dados";
import { CircularLoading } from "../../../../../components";
import { SincronizacaoDadosModel } from "../../../../../../model/api/sincronizacao/sincronizacao-dados-model ";

export interface SincronizacaoListProps {
  searchProps: SincronizacaoListSearchProps;
}
export default function SincronizacaoList(props: SincronizacaoListProps) {
  const classes = useDefaultSincronizacaoStyles();
  const { getSincronizacaoDados, carregando } = useGetSincronizacaoDados();
  const { showToast } = useToastSaurus();

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<SincronizacaoDadosModel>(),
  });
  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<SincronizacaoDadosModel>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    []
  );
  const [selectedList, setSelectedList] = useState<Array<string>>([]);

  const search = useCallback(
    async (newPage: number) => {
      try {
        const query =
          "" +
          (props.searchProps.aplicacao
            ? "&codAplicacao=" + props.searchProps.aplicacao
            : "") +
          (props.searchProps.dataInicio
            ? "&dataInicial=" + props.searchProps.dataInicio
            : "") +
          (props.searchProps.dataFinal
            ? "&dataFinal=" + props.searchProps.dataFinal
            : "");
        const res = await getSincronizacaoDados(
          props.searchProps.lojaId,
          newPage,
          query
        );
        if (res.erro) throw res.erro;

        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPage &&
          res.resultado?.data?.totalResult > 0
        ) {
          search(res.resultado?.data?.totalPage);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPage,
          res.resultado?.data?.totalResult,
          res.resultado?.data?.results
        );
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [
      props.searchProps.lojaId,
      props.searchProps.aplicacao,
      props.searchProps.dataInicio,
      props.searchProps.dataFinal,
      getSincronizacaoDados,
      fillResult,
      showToast,
    ]
  );

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [queryStatus.totalPages, search]
  );

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  useEffect(() => {
    if (props.searchProps.lojaId) {
      search(queryStatus.page);
    }
  }, [queryStatus.page, search, props.searchProps]);

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Box className={classes.container}>
          <Grid container style={{ flex: "0 1 auto", overflowY: "auto" }}>
            <Grid item xs={12}>
              <Paginacao
                pageChanged={pageChanged}
                totalPages={queryStatus.totalPages}
                totalRegisters={queryStatus.totalResults}
                currentPage={queryStatus.page}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Card className={classes.cardRoot}>
                <Box p={2}>
                  {!isEmpty(props.searchProps.lojaNome) && (
                    <Toolbar className={classes.containerNameLoja}>
                      <Typography className={classes.titleLoja}>
                        {props.searchProps.lojaNome}
                      </Typography>
                    </Toolbar>
                  )}
                  <Grid className={classes.card}>
                    <TableContainer component={Paper}>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell width="10%" align="center">
                              Aplicação
                            </TableCell>
                            <TableCell width="10%" align="center">
                              Usuário
                            </TableCell>
                            <TableCell width="10%" align="center">
                              Qtde Produtos
                            </TableCell>
                            <TableCell
                              width="10%"
                              align="center"
                              style={{ padding: "0 25px" }}
                            >
                              (%) Concluído
                            </TableCell>
                            <TableCell
                              width="10%"
                              align="center"
                              style={{ padding: "0 25px" }}
                            >
                              Data Envio
                            </TableCell>
                            <TableCell
                              width="10%"
                              align="center"
                              style={{ padding: "0 30px" }}
                            >
                              Data Conclusão
                            </TableCell>
                            <TableCell
                              width="10%"
                              align="center"
                              style={{ padding: "0 40px" }}
                            >
                              Situação
                            </TableCell>
                            <TableCell width="3%" align="center">
                              Download
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <SincronizacaoListData
                    onCardChecked={onCardChecked}
                    lojaId={props.searchProps.lojaId}
                    list={queryStatus.list}
                    selectedList={selectedList}
                    carregando={carregando}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}
