import { Card, Grid, Typography } from '@material-ui/core';
import classNames from "classnames";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { DefaultFormRefs } from "../../../../form/utils";
import { ModalHeader } from "../../../modal-header";
import { ButtonModalHeader } from "../../../../controles/buttons/button-modal-header";
import { VoltarIcon } from "../../../../icons";
import { CircularLoading } from "../../../../utils";
import { useStyles } from "./teste-aplicacoes-homologacao-styles";
import { FormTesteCredencial } from '../../../../form/master/administracao/teste-credencial/form-teste-credencial';
import { TesteCredencialModel } from '../../../../../../model/api/aplicacao-credencial/teste-credencial-model';
import { isEmpty } from 'lodash';
import { useToastSaurus } from '../../../../../../service/app/use-cases';
import { useCadastros } from '../../../../../../service/app/providers/cadastros-provider';
import { AplicacaoModel } from '../../../../../../model/api/aplicacao/aplicacao-model';
import { CredenciaisDadosModel } from '../../../../../../model/api/credenciais/credenciais-dados-model';
import { FormTestePedido } from '../../../../form/master/administracao/teste-pedido/form-teste-pedido';
import { TesteStatusPedidoModel } from '../../../../../../model/api/aplicacao-credencial/teste-status-pedido-model';
import { TesteStatusPedido } from '../../../../form/master/administracao/teste-status-pedido/form-teste-status-pedido';
import { FormTesteAlterarPedidoStatus } from '../../../../form/master/administracao/teste-alterar-status-pedido/form-teste-alterar-status-pedido';
import { useHistory } from 'react-router-dom';
import { TextFieldSaurus } from '../../../../controles/inputs';
import { usePostTesteAplicacaoCredencial } from '../../../../../../data/api/gestao/teste-aplicacao-homologacao/post-aplicacao-credencial';
import { usePostTesteAplicacaoCodPedido } from '../../../../../../data/api/gestao/teste-aplicacao-homologacao/post-aplicacao-pedido-cod-pedido';
import { usePostTesteAplicacaoPedido } from '../../../../../../data/api/gestao/teste-aplicacao-homologacao/post-aplicacao-pedido';
import { usePostAplicacaoStatusPedido } from '../../../../../../data/api/gestao/teste-aplicacao-homologacao/post-aplicacao-pedido-status';
import { LojaDadosModel } from '../../../../../../model/api/loja/loja-dados-model';
import { AplicacaoCredenciaisDadosModel } from '../../../../../../model/api/aplicacao-credencial/credencial-model/aplicacao-credenciais-dados-model';
import { AlterarStatusPedidoModel, PedidoModel } from '../../../../../../model/api/aplicacao-credencial/pedido-model/pedido-model';

export interface TesteSearchProps {
  status: number;
  codAplicacao: string;
  codPedido: string;
  dInicial: string;
  dFinal: string;
}

interface TesteAplicacoeHomologacaoProps {
  setCadastro: (value: number) => void;
  aplicacao: AplicacaoModel;
  pedido: TesteStatusPedidoModel;
}

export const TesteAplicacoesHomologacao = ({ setCadastro, pedido, aplicacao }: TesteAplicacoeHomologacaoProps) => {
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const history = useHistory();
  const { fecharTesteAplicacaoCredencial } = useCadastros();

  const [alterarStatus, setAlterarStatus] = useState(new AlterarStatusPedidoModel());
  const [pedidoJSON] = useState(JSON.stringify(alterarStatus.pedido, null, 2));

  const { postTesteAplicacaoCredencial, carregando: carregandoCredencial } = usePostTesteAplicacaoCredencial();
  const { postTesteAplicacaoCodPedido, carregando: carregandoBuscarPedido } = usePostTesteAplicacaoCodPedido();
  const { postTesteAplicacaoPedido, carregando: carregandoBuscarSttsPedido } = usePostTesteAplicacaoPedido();
  const { postAplicacaoStatusPedido, carregando: carregandoAlterarSttsPedido } = usePostAplicacaoStatusPedido();

  const [selectedDateInicio, setSelectedDateInicio] = useState('');
  const [selectedDateFim, setSelectedDateFim] = useState('');
  const [status, setStatus] = useState(-1);
  const [codAplicacao, setCodAplicacao] = useState('');
  const [codPedido, setCodPedido] = useState('');
  const scrollTargetRef = useRef<HTMLDivElement | null>(null);

  const carregando = carregandoCredencial || carregandoBuscarPedido || carregandoBuscarSttsPedido || carregandoAlterarSttsPedido;


  const cadTesteCredencialFormRef =
    useRef<DefaultFormRefs<CredenciaisDadosModel>>(null);
  const cadTestePedidoFormRef =
    useRef<DefaultFormRefs<AlterarStatusPedidoModel>>(null);

  useEffect(() => {
    cadTesteCredencialFormRef.current?.fillForm(
      new CredenciaisDadosModel()
    )
    cadTestePedidoFormRef.current?.fillForm(
      new AlterarStatusPedidoModel()
    )
  }, [])

  useEffect(() => {
    try {
      const pedidoRecebido: PedidoModel = JSON.parse(pedidoJSON);
      setAlterarStatus(prevState => ({
        ...prevState,
        pedido: pedidoRecebido,
      }));
    } catch (error) {
      console.error('Erro ao analisar o JSON:', error);
    }
  }, [pedidoJSON]);

  useEffect(() => {
    setSelectedDateFim('');
    setSelectedDateInicio('');
    setStatus(-1);
    setCodAplicacao(aplicacao.codigoAplicacao ? aplicacao.codigoAplicacao.toString() : '');
    setCodPedido(pedido.pedido.codigoPedido)
  }, [aplicacao.codigoAplicacao, codAplicacao, codPedido, pedido.pedido.codigoPedido]);



  const handleDateInicioChange = (e: any) => {
    setSelectedDateInicio(e.target.value);
  };

  const handleDateFimChange = (e: any) => {
    setSelectedDateFim(e.target.value);
  };

  const onChangeAplicacao = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setCodAplicacao(event.target.value)
    },
    [],
  );

  const onChangePedido = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setCodPedido(event.target.value)
    },
    [],
  );

  const credencialSubmit = useCallback(async (model: TesteCredencialModel) => {
    try {
      const retCredencial = await postTesteAplicacaoCredencial(model, codAplicacao);
      if (retCredencial.erro) {
        throw retCredencial.erro;
      }

      setCadastro(1);
      fecharTesteAplicacaoCredencial();
      showToast('success', "Aplicação Editada com Sucesso!");
    } catch (e: any) {
      showToast('error', e.mesage)
    }
  }, [codAplicacao, fecharTesteAplicacaoCredencial, postTesteAplicacaoCredencial, setCadastro, showToast])


  const codPedidoSubmit = useCallback(async (codigo: string) => {
    try {
      const model = new TesteCredencialModel(new LojaDadosModel(), new AplicacaoCredenciaisDadosModel(), parseInt(codAplicacao))

      const retBuscarPedido = await postTesteAplicacaoCodPedido(model, codAplicacao, codigo);
      if (retBuscarPedido.erro) {
        throw retBuscarPedido.erro;
      }

      setCadastro(1);
      fecharTesteAplicacaoCredencial();
      showToast('success', "Aplicação Editada com Sucesso!");
    } catch (e: any) {
      showToast('error', e.mesage)
    }
  }, [codAplicacao, fecharTesteAplicacaoCredencial, postTesteAplicacaoCodPedido, setCadastro, showToast])

  const buscarSttsPedidoSubmit = useCallback(async () => {
    try {
      const query =
        '' +
        (!isEmpty(selectedDateInicio)
          ? '&dataInicial=' + selectedDateInicio
          : '') +
        (isEmpty(selectedDateFim)
          ? ''
          : `&dataFinal=${selectedDateFim}`) +
        (status > -1
          ? '&status=' + status
          : '');

      const model = new TesteCredencialModel(new LojaDadosModel(), new AplicacaoCredenciaisDadosModel(), parseInt(codAplicacao), true, status)

      const retBuscarSttsPedido = await postTesteAplicacaoPedido(model, codAplicacao, query);
      if (retBuscarSttsPedido.erro) {
        throw retBuscarSttsPedido.erro;
      }

      setCadastro(1);
      fecharTesteAplicacaoCredencial();
      showToast('success', "Aplicação Editada com Sucesso!");
    } catch (e: any) {
      showToast('error', e.mesage)
    }
  }, [codAplicacao, fecharTesteAplicacaoCredencial, postTesteAplicacaoPedido, selectedDateFim, selectedDateInicio, setCadastro, showToast, status])

  const alterarSttsPedidoSubmit = useCallback(async (model: AlterarStatusPedidoModel) => {
    debugger
    try {
      console.log(model, alterarStatus);


      const retAlterarSttsPedido = await postAplicacaoStatusPedido(model, codAplicacao, status);
      if (retAlterarSttsPedido.erro) {
        throw retAlterarSttsPedido.erro;
      }

      setCadastro(1);
      fecharTesteAplicacaoCredencial();
      showToast('success', "Aplicação Editada com Sucesso!");
    } catch (e: any) {
      showToast('error', e.mesage)
    }
  }, [alterarStatus, codAplicacao, fecharTesteAplicacaoCredencial, postAplicacaoStatusPedido, setCadastro, showToast, status])

  const scrollToTarget = () => {
    if (scrollTargetRef.current) {
      scrollTargetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const redirectToResult = () => {
    history.push('#resultado');
  }

  return (
    <>
      <div className={classes.root}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <ModalHeader
          title={'Teste de Aplicação'}
          leftArea={
            <ButtonModalHeader
              tooltip="Voltar"
              icon={<VoltarIcon style={{
                fill: '#5333ED',
                width: '50px',
                height: '50px',
                marginRight: '0',
                marginBottom: '0'
              }} />}
              onClick={() => setCadastro(1)}
            />
          }
        />
        <div className={classes.content}>
          <div
            className={classNames(
              classes.contentForms,
              carregando ? classes.contentFormsLoading : undefined,
            )}>
            <Grid item xs={12}>
              <FormTesteCredencial
                cadTesteCredencialFormRef={cadTesteCredencialFormRef}
                redirectToResult={redirectToResult}
                scrollToTarget={scrollToTarget}
                ref={cadTesteCredencialFormRef}
                showLoading={false}
                loading={carregando}
                onSubmit={credencialSubmit}
                model={aplicacao}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTestePedido
                cadTestePedidoFormRef={cadTestePedidoFormRef}
                redirectToResult={redirectToResult}
                scrollToTarget={scrollToTarget}
                onChangePedido={onChangePedido}
                codPedido={codPedido}
                ref={cadTestePedidoFormRef}
                showLoading={false}
                loading={carregando}
                onSubmit={codPedidoSubmit}
                model={alterarStatus}
              />
            </Grid>
            <Grid item xs={12}>
              <TesteStatusPedido
                BuscarSttsPedidoSubmit={buscarSttsPedidoSubmit}
                redirectToResult={redirectToResult}
                scrollToTarget={scrollToTarget}
                selectedDateFim={selectedDateFim}
                selectedDateInicio={selectedDateInicio}
                codAplicacao={codAplicacao}
                carregando={carregando}
                handleDateFimChange={handleDateFimChange}
                handleDateInicioChange={handleDateInicioChange}
                onChangeAplicacao={onChangeAplicacao}
                setStatus={setStatus}
                status={status}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTesteAlterarPedidoStatus
                alterarSttsPedidoSubmit={alterarSttsPedidoSubmit}
                alterarStatus={alterarStatus}
                redirectToResult={redirectToResult}
                scrollToTarget={scrollToTarget}
                setStatus={setStatus}
                status={status}
                carregando={carregando}
              />
            </Grid>
            <div ref={scrollTargetRef} />
            <div className={classes.divider} />
            <Card className={classes.containerReturn}>
              <Grid container spacing={2}>
                {carregando && <CircularLoading tipo="FULLSIZED" />}
                <Grid item md={12} xs={12}>
                  <Typography className={classes.titleReturn}>Retorno do Teste</Typography>
                  <div className={classes.dividerReturn} />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextFieldSaurus
                    tipo="TEXTO"
                    fullWidth
                    disabled={carregando}
                    showStartAdornment={true}
                    allowSubmit={false}
                    autoComplete={'off'}
                    label="Status Code"
                    placeholder="Resultado"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextFieldSaurus
                    tipo="TEXTO"
                    fullWidth
                    disabled={carregando}
                    showStartAdornment={true}
                    allowSubmit={false}
                    autoComplete={'off'}
                    multiline
                    minRows={13.5}
                    label=""
                    placeholder="Resultado"
                  />
                </Grid>
              </Grid>
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}

