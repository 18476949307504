import { useDefaultStyles } from "../../default-cadastro-styles"
import { LojasHeader } from "./components/lojas-header/lojas-header";
import { LojasList } from "./components/lojas-list/lojas-list";


export const LojasPage = () => {
    const classes = useDefaultStyles();
    return (
        <>
            <div className={classes.root}>
                <div className={classes.header}>
                    <LojasHeader />
                </div>
                <div style={{ margin: '0 22px'}}>
                    <LojasList />
                </div>
            </div>
        </>
    )
}