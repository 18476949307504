import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { AplicacaoModel } from "../../../../model/api/aplicacao/aplicacao-model";

export function usePutAtualizarAplicacao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putAtualizarAplicacao = useCallback(
        (idAplicacao: string, model: AplicacaoModel) => {
            return invocarApi({
                url: `/api/v2/aplicacao/${idAplicacao}`,
                method: "PUT",
                data: model,
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        putAtualizarAplicacao,
    };
}


