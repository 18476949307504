import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const ImportacaoIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
      <circle cx="25" cy="25" r="21.9219" stroke="#7AE28C" stroke-width="2.15625"/>
<path d="M16.5854 27.2439L22.2839 32.5031L33.9756 17.7073" stroke="#7AE28C" stroke-width="2.875" stroke-linecap="round" stroke-linejoin="round"/>
      </DefaultIcon>
    </>
  );
};