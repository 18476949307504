import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useFormEditarLojaValidation } from './form-editar-loja-validation';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditarLojaModel } from '../../../../../model/api/loja/editar-loja-model';
import { makeUtilClasses, useThemeQueries } from '../../../../../theme';
import { CircularLoading } from '../../../utils';
import { TextFieldSaurus } from '../../../controles/inputs';
import { LojasRefs, LojasProps } from './form-loja-props';

export const FormEditarLoja = forwardRef<
  LojasRefs<EditarLojaModel>,
  LojasProps<EditarLojaModel>
>(
  (
    { loading, onSubmit, ...props }: LojasProps<EditarLojaModel>,
    ref,
  ) => {
    const { isMobile } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);
    const { FormEditarLojaValidationYup } = useFormEditarLojaValidation()

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
    } = useForm<EditarLojaModel>({
      resolver: yupResolver(FormEditarLojaValidationYup),
      defaultValues: props.model,
      criteriaMode: 'all',
      mode: 'onBlur' && 'onChange',
    });


    const submitAlterarDadosConta = (values: EditarLojaModel) => {
      onSubmit(values);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitAlterarDadosConta)();
      },
      resetForm: () => {
        reset();
        if (!isMobile) refInputNome.current?.focus();
      },
      fillForm: (model: EditarLojaModel) => {
        reset({ ...model });
        if (!isMobile) refInputNome.current?.focus();
      },
    }));

    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {loading && props.showLoading ? (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="NORMAL" />
              </div>
            ) : null}

            <form
              onSubmit={handleSubmit(submitAlterarDadosConta)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Box my={3}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Controller
                      name="documento"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          tipo="CNPJ_CPF"
                          fullWidth={true}
                          disabled
                          showStartAdornment={true}
                          autoComplete={'off'}
                          label="CPF / CNPJ"
                          placeholder=""
                          error={Boolean(
                            errors.documento && errors.documento.message,
                          )}
                          helperText={
                            touchedFields.documento || errors.documento
                              ? errors.documento?.message
                              : undefined
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="razaoSocial"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          tipo="TEXTO"
                          fullWidth
                          disabled={loading}
                          showStartAdornment={true}
                          autoComplete={'off'}
                          label="Razão Social"
                          placeholder="Digite a Razão Social..."
                          error={Boolean(
                            errors.razaoSocial && errors.razaoSocial.message,
                          )}
                          helperText={
                            touchedFields.razaoSocial || errors.razaoSocial
                              ? errors.razaoSocial?.message
                              : undefined
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
