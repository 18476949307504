
export class EnderecoModel {
    constructor(
        public logradouro: string = '',
        public numero: string = '',
        public bairro: string = '',
        public municipio: string = '',
        public uf: string = '',
        public cep: string = '',
        public latitude: number = 0,
        public longitude:  number = 0,
        public complemento: string = '',
        public enderecoCompleto: string = '',
        public pais:string = '' ,
        public pontoReferencia: string = '',
        public codigoMunicipio: string = ''
    ) {}
}