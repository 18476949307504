import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { makeUtilClasses, useThemeQueries } from '../../../../../../theme';
import { TextFieldSaurus } from '../../../../controles/inputs';
import { TestePedidoRefs, TestePedidoProps } from './form-teste-pedido-props';
import { useStyles } from '../../../../cards/card-credencial-homologacao/card-credencial-homologacao-styles';
import { TesteIcon } from '../../../../icons';
import { AlterarStatusPedidoModel } from '../../../../../../model/api/aplicacao-credencial/pedido-model/pedido-model';

export const FormTestePedido = forwardRef<
  TestePedidoRefs<AlterarStatusPedidoModel>,
  TestePedidoProps<AlterarStatusPedidoModel>
>(({ loading, onSubmit, ...props }: TestePedidoProps<AlterarStatusPedidoModel>, ref,) => {
  const { isMobile } = useThemeQueries();
  const utilClasses = makeUtilClasses();
  const refInputNome = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    reset
  } = useForm<any>({
    criteriaMode: 'all',
    mode: 'onBlur' && 'onChange',
  });

  const submitAlterarDadosConta = (model: AlterarStatusPedidoModel) => {
    onSubmit(model.pedido.codigoPedido);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(submitAlterarDadosConta)();
    },
    resetForm: () => {
      reset();
      if (!isMobile) refInputNome.current?.focus();
    },
    fillForm: (model: AlterarStatusPedidoModel) => {
      reset({ ...model })
      if (!isMobile) refInputNome.current?.focus();
    },
  }));

  return (
    <>
      <form className={loading ? utilClasses.controlLoading : ''}>
        <div className={classes.divider} />
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Controller
              name='pedido.codigoPedido'
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  tipo="TEXTO"
                  fullWidth
                  disabled={loading}
                  showStartAdornment={true}
                  allowSubmit={false}
                  autoComplete={'off'}
                  label="Código Pedido"
                  placeholder="Ex: 2739"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              className={classes.button}
              fullWidth
              onClick={() => {
                props.scrollToTarget();
                setTimeout(props.redirectToResult, 1000);
                props.cadTestePedidoFormRef.current?.submitForm()

              }}>
              <TesteIcon tipo="BUTTON_PRIMARY" />
              <Typography style={{ fontWeight: 700, fontSize: 16 }}>Buscar Pedido</Typography>
            </Button>
          </Grid>
        </Grid>
        <Button style={{ display: 'none' }} type="submit"></Button>
      </form >
    </>
  );
},
);
