import { Button, Card, Fade, Typography } from "@material-ui/core";
import { AplicacaoModel } from "../../../../model/api/aplicacao/aplicacao-model";
import { CircularLoading } from "../..";
import semImagem from '../../../../assets/img/sem-imagem.jpg'
import { useCadastros } from "../../../../service/app/providers/cadastros-provider";
import { LojaModel } from "../../../../model/api/loja/loja-model";
import { useGetRegistroCredencial } from "../../../../data/api/gestao/credenciais-dados/get-registro-credencial";
import { useLojasStyles } from "../../../pages/private/lojas/lojas-styles";
import { CredenciaisDadosModel } from "../../../../model/api/credenciais/credenciais-dados-model";
import { EnumAplicacao } from "../../../../model/enums/enum-aplicacao";

type Props = {
  loja: LojaModel;
  aplicacoes: AplicacaoModel[];
  editando: boolean
}

export enum EnumSituacao {
  Invalido = 0,
  IntegraçãoDesabilitada = 1,
  IntegraçãoHabilitada = 2,
  IntegraçãoNãoAutorizada = 3,
  RedirecionamentoDeLogin = 4
}

export const CardAplicacao = ({ loja, aplicacoes, editando }: Props) => {
  const { carregando } = useGetRegistroCredencial()
  const { abrirCadastroNovaAplicacao } = useCadastros()
  const classes = useLojasStyles()

  function alterarCorStatus(numero: number) {
    switch (numero) {
      case EnumSituacao.Invalido:
        return "black"
      case EnumSituacao.IntegraçãoDesabilitada:
        return "#F44336"
      case EnumSituacao.IntegraçãoHabilitada:
        return '#10C822'
      case EnumSituacao.IntegraçãoNãoAutorizada:
        return "#FFBC00"
      case EnumSituacao.RedirecionamentoDeLogin:
        return "#FFBC00"
      default:
        return '';
    }
  }
  return (
    <>
      <Card className={classes.rootApp}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <div className={classes.aplicacaoImg} style={{ display: 'flex', flexWrap: 'wrap', gap: 0 }}>
          {loja.credenciais.map(cred => {
            const app = aplicacoes.find(app => app.codigoAplicacao === cred.codigoAplicacao);
            if (app) {
              return (
                <div key={cred.id} style={{ marginBottom: '8px' }}>
                  <div className={classes.aplicacaoImg}>
                    <Button
                      onClick={() => {
                        const credDados = new CredenciaisDadosModel(cred.id, cred.credenciais, cred.codigoAplicacao, cred.ativo, cred.confirmacaoAutomaticaAtivada, cred.tpSituacao, cred.urlRedirectLogin);
                        abrirCadastroNovaAplicacao(editando, app, loja, credDados, '', false);
                      }}>
                      <Fade in timeout={600}>
                        <div className={classes.aplicacaoStyle}>
                          <div className={classes.dividerColor} style={{ background: alterarCorStatus(cred.tpSituacao) }} />
                          {(app.uriImagem) ?
                            <>
                              {(cred.codigoAplicacao === EnumAplicacao.DeliveryVip) ?
                                <div style={{ marginBottom: '47px' }}>
                                  <img src={app.uriImagem}
                                    className={classes.img}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = semImagem;
                                    }} alt={app.descricaoAplicacao} />
                                </div>
                                :
                                <img src={app.uriImagem}
                                  className={classes.img}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = semImagem;
                                  }} alt={app.descricaoAplicacao} />
                              }
                            </>
                            :
                            <div style={{ marginBottom: '47px' }}>
                              <img src={semImagem}
                                className={classes.img}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = semImagem;
                                }} alt={app.descricaoAplicacao} />
                              <Typography className={classes.descricaoAplicacao}>{app.descricaoAplicacao}</Typography>
                            </div>
                          }
                          <Typography className={classes.descricaoAplicacao}>{app.descricaoAplicacao}</Typography>
                        </div>
                      </Fade>
                    </Button>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </Card>

    </>
  )
}

