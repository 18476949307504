import { EnumTpServicoPedido } from "../../enums/enum-tp-servico-pedido";
import { EnumTpServicoProduto } from "../../enums/enum-tp-servico-produto";

export class ConfiguracaoIntegracaoModel {
    constructor(

        public tpServicoPedido: EnumTpServicoPedido = EnumTpServicoPedido.WebHook,
        public descricaoServicoPedido: string = '',
        public tpServicoProduto: EnumTpServicoProduto = EnumTpServicoProduto.Externo,
        public descricaoServicoProduto: string = '',
        public authorizationCredenciais: string = '',
        public authorizationWebHook: string = '',
        public quantidadeEnvio: number = 0,
        public urlBase: string = '',
        public tempoVidaMinutosToken: number = 0,
        public controleProprio: boolean = true,
        
    ) { }
}