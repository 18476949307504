import { makeStyles } from "@material-ui/core";

export const useModalStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflow: "hidden",
    width: '100%'
  },
  content: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  contentAbas: {
    paddingTop: theme.spacing(1),
    boxShadow: "0px 5px 5px rgba(0,0,0,0.05) inset",
    marginLeft: theme.spacing(2) * -1,
    marginRight: theme.spacing(2) * -1,
  },
  contentForms: {
    flex: "1 1 auto",
    padding: theme.spacing(2),
    height: "0px",
    overflowX: "hidden",
    overflowY: "auto",
    "& * > form": {
      visibility: "visible",
    },
    "&  .tabs-class": {
      visibility: "visible",
    },
  },
  contentFormsLoading: {
    "& * > form": {
      visibility: "hidden",
    },

    "&  .tabs-class": {
      visibility: "hidden",
    },
  },
  acoes: {
    flex: "0 0 auto",
    padding: theme.spacing(2),
    boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.1)",
  },
  obs: {
    width: "100%",
    padding: theme.spacing(2),
  },
  titleAlert: {
    color: "#5F5F5F",
    fontWeight: 700,
    fontSize: 16,
  },
  buttonRemover: {
    border: "1px solid #F44336",
    color: theme.palette.error.main,
    "&:hover": {
      border: "1px solid #FF0000",
    },
    "&:disabled": {
      backgroundColor: theme.palette.error.light,
    },
    marginRight: 8,
    "& svg": {
      fill: theme.palette.error.main
    },
  },

  titleImportacao: {
    color: "#7D7D7D",
    fontSize: "12px",
    fontWeight: 700,
    textAlign: "center",
  },

  containerImportacao: {
    background: "rgb(255 244 215)",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2)
  },

  iconImportacao: {
    position: "relative",
    left: "21px",
    right: "0px",
    top: "29px",
    marginTop: "-30px",
  },
}));
