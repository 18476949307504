import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    padding: "14px 25px 25px 25px",
    background: theme.palette.background.paper,
    marginRight: "25px",
    borderRadius: "15px",
  },
  rootApp: {
    padding: '16px 20px 16px 20px',
    height: '334px',
    background: 'rgba(245, 245, 245, 1)',
    boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.05)',
    marginTop: '20px',
    borderRadius: '10px',
  },

  rootAppSon: {
    width: '100%',
    height: '195px',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '8px 8px'
  },
  rootReturn: {
    width: "100%",
    height: "469px",
    padding: "11px 17px 0 17px",
    background: "rgba(246, 246, 246, 0.4)",
    boxShadow: "inset 0px 0px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: "10px",
  },
  containerTexfield: {
    backgroundColor: "white!important",
    borderRadius: "8px!important",
  },
  titleReturn: {
    fontSize: "18px",
    fontWeight: 600,
    color: "#545454",
  },
  button: {
    margin: "0",
    height: "56px",
    borderRadius: "4px",
  },
  buttonCNPJ: {
    margin: "15px 0 15px 0px",
  },
  dividerReturn: {
    width: "100%",
    height: "3px",
    marginTop: 9,
    marginBottom: 8,
    borderRadius: "1px",
    backgroundColor: "#D1D1D1",
  },
  divider: {
    width: "100%",
    height: "2px",
    margin: "20px 0",
    backgroundColor: "#D1D1D1",
  },
}));
