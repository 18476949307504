import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useGetPedidos() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getPedidos = useCallback(
        (lojaId: string, newPage: number, query?: string) => {
            return invocarApi({
                url: `/api/v2/loja/${lojaId}/pedido?page=${newPage}${query}`,
                method: "GET",
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
        );
    
    return {
        ...outrasPropriedades,
        getPedidos,
    };
}


