import { EnumMenu } from "../../model";
import { MenuModel } from '../../model/app';
import { EnumTipoUsuario } from "../../model/enums/enum-tipo-usuario";

export const ModulosMock: MenuModel[] = [
  new MenuModel("Dashboard", EnumMenu.DASHBOARD, [EnumTipoUsuario.EnumTipoCliente], "/dashboard", 1),

  new MenuModel('Contratos', EnumMenu.CONTRATOS_GROUP, [EnumTipoUsuario.EnumTipoCliente], '', 2),
  new MenuModel('Pedido', EnumMenu.PEDIDOS_GROUP, [EnumTipoUsuario.EnumTipoCliente], '', 3),
  // new MenuModel("Configurações", EnumMenu.CONFIGURACOES_GROUP, [], "", 7),

  new MenuModel("Dados de Contrato", EnumMenu.DADOS, [EnumTipoUsuario.EnumTipoCliente], "/dados-contrato", 4, EnumMenu.CONTRATOS_GROUP),

  new MenuModel("Pedidos", EnumMenu.PEDIDOS, [EnumTipoUsuario.EnumTipoCliente], "/pedidos", 3, EnumMenu.PEDIDOS_GROUP),

  new MenuModel("Sincronização de Produtos", EnumMenu.SINCRONIZACAO, [EnumTipoUsuario.EnumTipoCliente], "/sincronizacao", 5, EnumMenu.CONTRATOS_GROUP),

  new MenuModel("Configurações", EnumMenu.CONFIGURACOES_CONFIGURACOES, [EnumTipoUsuario.EnumTipoCliente], "/configuracoes", 6, EnumMenu.CONFIGURACOES_GROUP),

]
