import { EnumTpStatusPedido } from "../../../../../model/enums/enum-tp-status-pedido";

export function alterarNomePedido(numero: number) {

    switch (numero) {
        case EnumTpStatusPedido.AguardandoConfirmação:
            return 'Aguardando Confirmação'
        case EnumTpStatusPedido.Confirmado:
            return 'Confirmado'
        case EnumTpStatusPedido.EmPreparo:
            return 'Em Preparo'
        case EnumTpStatusPedido.Enviado:
            return 'Enviado'
        case EnumTpStatusPedido.FinalizadoOuEntregue:
            return 'Finalizado'
        case EnumTpStatusPedido.Cancelado:
            return 'Cancelado'
        case EnumTpStatusPedido.Invalido:
            return 'Invalido'
        default:
            return '';
    }
}