import { makeStyles } from "@material-ui/core"

export const useDefaultIconStyles = makeStyles((theme) => ({
    defaultIconOnInput: {
        marginBottom: -2,
        marginRight: 8,
        width: '28px',
        height: '28px',
        fill: theme.palette.primary.main
    },
    defaultIconOnPrivateHeader: {
        marginRight: 0,
        marginBottom: 0,
        width: '30px',
        height: '30px',
        fill: theme.palette.primary.contrastText
    },
    defaultIconOnModalHeader: {
        marginRight: 0,
        marginBottom: 0,
        width: '50px',
        height: '50px',
        fill: theme.palette.primary.main
    },
    defaultIconOnButton: {
        marginBottom: 0,
        marginRight: 8,
        width: '28px',
        height: '28px',
        fill: theme.palette.primary.main
    },
    defaultIconOnButtonAdc: {
        marginBottom: 0,
        marginRight: 8,
        width: '22px',
        height: '22px',
        fill: theme.palette.primary.contrastText
    },
    defaultIconOnButtonPrimary: {
        marginBottom: 0,
        marginRight: 8,
        width: '28px',
        height: '28px',
        fill: theme.palette.primary.contrastText
    },
    defaultIconOnButtonFab: {
        margin: 5,
        width: '37px',
        height: '40px',
        fill: theme.palette.primary.contrastText
    },
    defaultIconOnButtonAdcLoja: {
        margin: 0,
        width: '52px',
        height: '53px',
        fill: theme.palette.primary.contrastText
    },
    defaultIconOnButtonlogout: {
        marginBottom: 0,
        width: '28px',
        height: '28px',
        fill: theme.palette.primary.main,
    },
    defaultIconOnButtonAlerta: {
        marginBottom: 0,
        marginRight: 8,
        width: '27px',
        height: '31px',
        fill: '#FFBC00',
        backgroundColor: '#fff'
    },
    defaultIconOnButtonAlertaExcluir: {
        marginBottom: 0,
        marginRight: 8,
        width: '27px',
        height: '31px',
        fill: '#F44336',
        backgroundColor: '#fff'
    },
    defaultIconOnButtonExportExcel: {
        marginBottom: 0,
        width: '28px',
        height: '28px',
        fill: theme.palette.primary.contrastText
    },
    defaultIconOnButtonExportExcelMobile: {
        marginBottom: 0,
        width: '28px',
        height: '28px',
        fill: theme.palette.primary.contrastText
    },
    defaultIconOnButtonEditar: {
        marginBottom: 0,
        width: '25px',
        height: '25px',
        marginLeft: '12px',
        fill: theme.palette.primary.main,
    },
    defaultIconOnButtondialog: {
        marginBottom: 0,
        marginRight: 10,
        marginLeft: 15,
        width: '30px',
        height: '30px',
        fill: theme.palette.primary.dark,
    }
}));