import { useCallback } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Swipe from 'react-easy-swipe';
import classNames from 'classnames';
import { useTabStyles } from './tabs-saurus-styles';

export class TabSaurusLabel {
  constructor(
    public label: string,
    public index: number,
    public icon?: React.ReactElement,
  ) {}
}
export class TabSaurusContent {
  constructor(public index: number, public content: React.ReactNode) {}
}
export interface TabSaurusProps {
  tabsLabel: Array<TabSaurusLabel>;
  tabsContent: Array<TabSaurusContent>;
  selectedTabIndex: number;
  onChange?(index: number): void;
}
export const TabsSaurus = ({
  tabsLabel,
  tabsContent,
  selectedTabIndex,
  onChange,
}: TabSaurusProps) => {
  const classes = useTabStyles();
  const handleChange = useCallback(
    (event, newValue) => {
      if (onChange) {
        onChange(newValue);
      }
    },
    [onChange],
  );

  const swipeRight = useCallback(() => {
    const min = tabsLabel.reduce(function (prev, curr) {
      return prev.index < curr.index ? prev : curr;
    }).index;
    const valor = selectedTabIndex > min ? selectedTabIndex - 1 : min;
    if (onChange) {
      onChange(valor);
    }
  }, [onChange, selectedTabIndex, tabsLabel]);
  const swipeLeft = useCallback(() => {
    const max = tabsLabel.reduce(function (prev, curr) {
      return prev.index > curr.index ? prev : curr;
    }).index;
    const valor = selectedTabIndex > max ? selectedTabIndex + 1 : max;
    if (onChange) {
      onChange(valor);
    }
  }, [onChange, selectedTabIndex, tabsLabel]);

  return (
    <>
      <div className={classes.tabContainer}>
        <AppBar
          className={classNames('tabs-class', classes.tabsContainer)}
          position="static"
          style={{ zIndex: 0 }}
        >
          <Tabs
            value={selectedTabIndex}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            scrollButtons="auto"
          >
            {tabsLabel.map((tab, index) => (
              <Tab
                key={index}
                value={tab.index}
                icon={tab.icon}
                label={tab.label}
              />
            ))}
          </Tabs>
        </AppBar>

        {tabsContent.map((tab, index) => (
          <div
            className={classes.tabContent}
            hidden={tab.index !== selectedTabIndex}
          >
            <Swipe
              onSwipeRight={swipeRight}
              onSwipeLeft={swipeLeft}
              className={classes.swipeDiv}
              key={index}
              tolerance={150}
            >
              {tab.content}
            </Swipe>
          </div>
        ))}
      </div>
    </>
  );
};
