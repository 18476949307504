import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { EnumStatusPedido } from '../../../../model/enums/enum-status-pedido';
import { AlterarStatusPedidoModel } from '../../../../model/api/aplicacao-credencial/pedido-model/pedido-model';

export function usePostAplicacaoStatusPedido() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();
    const apiUrlHomo = 'https://sauruspedidosapi-staging.azurewebsites.net';

    const postAplicacaoStatusPedido = useCallback(
        (model: AlterarStatusPedidoModel, codigoAplicacao: string, statusPedido: EnumStatusPedido) =>
            invocarApi({
                url: `/api/v2/aplicacao/${codigoAplicacao}/homologacao/pedido/status/${statusPedido}`,
                method: "POST",
                data: model,
                baseURL: apiUrlHomo,
                headers: { "Content-Type": "application/json" },
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        postAplicacaoStatusPedido
    }
}