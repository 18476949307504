import { Box, Grid } from "@material-ui/core";
import { CircularLoading } from "../../../../../components";
import { useCallback, useState, useEffect } from "react";
import { useStyles } from "./dashboard-list-styles";
import { PedidosStorageKeys, useSaurusPedidosStorage, useToastSaurus } from "../../../../../../service/app/use-cases";
import { LojaModel } from "../../../../../../model/api/loja/loja-model";
import { AplicacaoModel } from "../../../../../../model/api/aplicacao/aplicacao-model";
import { useGetAplicacao } from "../../../../../../data/api/gestao/aplicacao/get-aplicacao";
import { useGetLojas } from "../../../../../../data/api/gestao/lojas/get-lojas";
import { usePatchIniciarLoja } from "../../../../../../data/api/gestao/lojas/patch-iniciar-loja";
import { DashboardListData } from "./dashboard-list-data";
import { CardDashboardSincronizacao } from "../../../../../components/cards/card-dashboard-sincronizacao/card-dashboard-sincronizacao";
import { useCadastros } from "../../../../../../service/app/providers/cadastros-provider";

export const DashboardList = () => {
  const classes = useStyles();
  const { showToast } = useToastSaurus()
  const { patchIniciarLoja, carregando: carregandoPatch } = usePatchIniciarLoja()
  const { getLojas, carregando: carregandoGet } = useGetLojas()
  const { getAplicacao, carregando: carregandoGetApp } = useGetAplicacao()
  const carregando = carregandoPatch || carregandoGet || carregandoGetApp
  const [lojas, setLoja] = useState<LojaModel[]>([])
  const [aplicacoes, setAplicacoes] = useState<AplicacaoModel[]>([]);
  const [editando, setEditando] = useState<boolean>(false)
  const { setRegistro } = useSaurusPedidosStorage();
  const { CadastroNovaAplicacaoState } = useCadastros()

  const aplicacaoWrapper = useCallback(async () => {
    try {
      const res = await getAplicacao()
      if (res.erro) throw res.erro
      const imagemAplicacao = JSON.stringify(res.resultado?.data.map((app: any) => ({
        imagem: app.uriImagem,
        codAplicacao: app.codigoAplicacao
      })))
      setRegistro(PedidosStorageKeys.imagemAplicacao, imagemAplicacao);
      setAplicacoes(res.resultado?.data);
      setEditando(res.resultado?.data);
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [getAplicacao, setRegistro, showToast])

  const lojaWrapper = useCallback(async () => {
    try {
      const resPatch = await patchIniciarLoja()
      if (resPatch.erro) throw resPatch.erro

      const res = await getLojas()
      setLoja(res.resultado?.data.lojas)
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [getLojas, patchIniciarLoja, showToast])

  useEffect(() => {
    if (!CadastroNovaAplicacaoState.aberto) {
      aplicacaoWrapper()
      lojaWrapper();
    }
  }, [lojaWrapper, aplicacaoWrapper, CadastroNovaAplicacaoState.aberto])

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Box display='flex' flexDirection='column' marginTop='20px' justifyContent='center' alignItems='center' flex={1} overflow='auto'>
          <Grid container>
            <Grid item xs={12} md={12} className={classes.listContainer}>
              <DashboardListData
                editando={editando}
                aplicacoes={aplicacoes}
                list={lojas}
                carregando={carregando}
              />
            </Grid>
          </Grid>
        </Box>
        <CardDashboardSincronizacao />
      </div>
    </>
  );
};
