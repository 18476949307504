import { makeStyles } from "@material-ui/core";

export const useAplicacaoStyles = makeStyles((theme) => ({
  root: {
    flex: "1",
    display: "flex",
    position: "relative",
    overflowX: "hidden",
    flexDirection: "column",
    width: "100%",
    maxWidth: "600px",
    height: "100%",
  },
  content: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
  },
  acoes: {
    flex: '0 0 auto',
    padding: '8px',
    boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.1)',
    margin: '0 0 0 6px'
  },

  contentAbas: {
    paddingTop: theme.spacing(1),
    boxShadow: "0px 5px 5px rgba(0,0,0,0.05) inset",
    marginLeft: theme.spacing(2) * -1,
    marginRight: theme.spacing(2) * -1,
  },
  contentForms: {
    flex: "1 1 auto",
    height: "100%",
    padding: "16px",
    overflowX: "hidden",
    overflowY: "auto",
    "& * > form": {
      visibility: "visible",
    },
    "&  .tabs-class": {
      visibility: "visible",
    },
    [theme.breakpoints.down('xs')]: {
      flex: '0 1 auto',
      height: '100%',
      padding: '20px',
      overflowX: 'hidden',
      overflowY: 'auto',
      maxHeight: '545px',
      "& * > form": {
        visibility: "visible",
      },
      "&  .tabs-class": {
        visibility: "visible",
      },
    }
  },
  contentFormsLoading: {
    "& * > form": {
      visibility: "hidden",
    },

    "&  .tabs-class": {
      visibility: "hidden",
    },
  },
  documentText: {
    fontSize: "12px",
    fontWeight: 700,
    color: "#6B6B6B",
    fontStyle: "normal",
  },
  nomeLoja: {
    fontSize: "22px",
    fontWeight: 700,
    color: "#545454",
  },
  ultimoAcesso: {
    background: "#EFECFF",
    padding: "6px 10px",
    borderRadius: "5px",
  },
  ultimoAcessoText: {
    color: "#5333ED",
    fontWeight: 700,
    textAlign: "center",
  },

  labelStatusPai: {
    flex: 1,
    display: "flex",
    marginTop: "8px",
    justifyContent: "space-between",
  },

  teste: {
    display: "flex",
    alignItems: "center",
  },

  button: {
    border: "1px solid #FFFFFF",
    boxShadow: "0px 0px 4.6875px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
  },

  labelDiv: {
    width: "100px",
    borderRadius: "7px",
    padding: "6px 0",
  },

  labelStatusOn: {
    color: "#FFFFFF",
    width: "100px",
    margin: "0 10px",
    padding: "3px 0",
    fontSize: "15px",
    background: "#10C822",
    textAlign: "center",
    fontWeight: 700,
    borderRadius: "7px",
  },

  labelStatusOff: {
    color: "#FFFFFF",
    width: "100px",
    margin: "0 10px",
    padding: "3px 0",
    fontSize: "15px",
    background: "#EC1C2E",
    textAlign: "center",
    fontWeight: 700,
    borderRadius: "7px",
  },

  statusFilho: {
    display: "flex",
    justifyContent: "space-between",
  },

  statusLoja: {
    border: "1px solid #BDBDBD",
    height: "36px",
    display: "flex",
    alignItems: "center",
    borderRadius: "6px",
    justifyContent: "space-around",
    padding: "0 28px",
  },
  labelStatusLoja: {
    color: "#545454",
    padding: "0 27px 0 0",
    fontSize: "16px",
    fontWeight: 700,
  },
  aplicacao: {
    // overflow: 'auto'
  },

  divBallVerde: {
    background: "#10C822",
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    margin: 10,
  },
  divBallAmarelo: {
    background: "#FFBC00",
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    margin: 10,
  },
  divBallError: {
    background: "#F44336",
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    margin: 10,
  },

  imagem: {
    width: "100%",
    height: "480px",
    textAlign: "center",
    background: "rgba(246, 246, 246, 0.40)",
    boxShadow: "inset 0px 0px 4px 0px rgba(0, 0, 0, 0.05)",
    borderRadius: "10px",
  },
  divider: {
    width: "100%",
    height: "1px",
    marginTop: "10px",
    marginBottom: "10px",
    backgroundColor: "#E6E6E6",
  },
  dividerA: {
    width: "100%",
    height: "2px",
    marginTop: "10px",
    marginBottom: "10px",
    backgroundColor: "#5333ED",
  },
  titlePrincipal: {
    color: "#5F5F5F",
    fontSize: "18px",
    lineHeight: 2,
  },

  app: {
    textAlign: "center",
  },
}));
