import { makeStyles } from "@material-ui/core";

export const useDefaultSincronizacaoStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    position: "relative",
    background: "#f5f5f5",
    overflowX: "hidden",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
    flex: 1,
    overflowY: "auto",
    margin: "0 10px",
  },
  containerNameLoja: {
    background: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingLeft: "16px",
  },
  table: {
    minWidth: 650,
    fontSize: "22px",
  },

  telaGrid: {
    padding: "25px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      padding: 5,
    },
  },
  spacing: {
    "& .MuiGrid-spacing-xs-2": {
      marginBottom: 16,
    },
  },
  header: {
    flex: "0 1 auto",
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 30px 0",
    },
  },
  list: {
    flex: "1 1 100%",
    overflowX: "hidden",
    overflow: "auto",
    position: "relative",
  },

  content: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
  },
  defaultContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
  },
  acoes: {
    flex: "0 0 auto",
  },
  divider: {
    backgroundColor: "#5333ED",
    marginBottom: "10px",
    marginLeft: "20px",
    width: "100%",
    height: "2px",
  },
  ultimoAcesso: {
    display: "flex",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    background: "#EFECFF",
    alignItems: "center",
    borderRadius: theme.shape.borderRadius,
    justifyContent: "center",
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2)
    }
  },
  ultimoAcessoText: {
    color: "#5333ED",
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
  },
  horario: {
    display: "flex",
    alignItems: "center",
  },
  loading: {
    position: "fixed",
    right: 0,
    top: 60,
    "& > div": {
      width: 80,
      height: 80,
    },
  },
  listContainer: {
    "& .card": {
      margin: theme.spacing(1),
    },
  },

  aplicacaoSub: {
    width: "100%",
    background: "#fff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.07)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  nomeLoja: {
    width: "100%",
    background: "#fff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.07)",
  },

  titleSub: {
    color: "#5F5F5F",
    padding: "10px 10px",
    marginRight: "5rem",
    fontSize: "16px",
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      margin: 0,
      fontSize: "11px",
    },
  },
  titleLoja: {
    color: "#3D3D3D",
    overflow: "hidden",
    fontSize: "18px",
    fontWeight: 700,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  card: {
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    marginBottom: theme.spacing(2),
  },
  cardRoot: {
    "&.MuiPaper-rounded": {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    position: "relative",
  },
  titleSelecionarLoja: {
    fontSize: "18px",
    fontWeight: 600,
    color: "#5333ED",
  },
  cardSelecionarLoja: {
    margin: "25px 0 0 20px",
    padding: "12px 8px",
    width: "100%",
    maxWidth: "480px",
    background: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[15],
    [theme.breakpoints.down("md")]: {
      margin: "10px 8px 0 8px",
    },
  },
}));
