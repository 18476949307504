import { Box } from "@material-ui/core";
import { DashboardAdminList } from "./components/dashboard-admin-list/dashboard-admin-list";
import { useAdminStyles } from "./dashboard-admin-styles";


export const DashboardAdminPage = () => {
    const classes = useAdminStyles();

    return (
        <>
            <div className={classes.root}>
                <Box p={1}>
                    <DashboardAdminList />
                </Box>
            </div>
        </>
    )
}