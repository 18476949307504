import { useCallback } from "react";
import { useApiBase } from "../../base";

export function useGetCargasProdutosById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getCargasProdutosById = useCallback(
        (lojaId: string, sincronizacaoId: string, cargaId: string, newPage: number) =>
            invocarApi({
                url: `/api/v2/loja/${lojaId}/sincronizacao/${sincronizacaoId}/carga/${cargaId}?page=${newPage}`,
                method: "GET",
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getCargasProdutosById,
    };


}
