import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const HorarioIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M25.4269 3.98047C13.5822 3.98047 4.03548 13.5852 4.02405 25.5075C4.02405 37.3722 13.5822 46.9885 25.3812 47C37.0659 47.0115 46.6468 37.3722 46.6697 25.5767C46.704 13.6775 37.1688 3.992 25.4269 3.98047ZM25.324 45.109C14.6683 45.1898 5.88765 36.2768 5.88765 25.4845C5.88765 14.6921 14.6797 5.87144 25.324 5.84838C36.0254 5.83685 44.829 14.7036 44.829 25.496C44.829 36.2768 36.0369 45.2359 25.324 45.109Z" />
                <path d="M27.2106 28.759C27.2106 30.8806 27.222 32.9906 27.1992 35.1122C27.1877 35.6311 27.2906 35.8271 27.8623 35.804C29.1085 35.7579 29.3486 36.1153 29.0971 37.3606C29.0399 37.6489 28.8798 37.6258 28.6855 37.6258C26.4674 37.6258 24.2494 37.6258 22.0314 37.6374C21.7112 37.6374 21.5855 37.522 21.6312 37.2107C21.6426 37.1646 21.6312 37.1185 21.6312 37.0608C21.6083 35.7925 21.6083 35.7579 22.8546 35.7925C23.4262 35.8156 23.5177 35.6195 23.5177 35.1007C23.4948 31.5032 23.4948 27.8942 23.5177 24.2968C23.5177 23.6857 23.3233 23.5589 22.786 23.5934C22.4201 23.6165 21.8484 23.801 21.7112 23.4781C21.5283 23.04 21.6312 22.4865 21.6426 21.9792C21.6426 21.6909 21.8827 21.7255 22.0657 21.7255C23.632 21.7255 25.1984 21.7371 26.7647 21.714C27.2678 21.7025 27.222 22.0023 27.2106 22.3251C27.2106 24.4813 27.2106 26.6144 27.2106 28.759Z" />
                <path d="M28.1252 15.1879C28.1366 16.7444 26.8675 18.0474 25.3469 18.0358C23.8492 18.0243 22.5915 16.756 22.5915 15.234C22.5801 13.6428 23.792 12.4091 25.3698 12.4206C26.9018 12.4091 28.1137 13.6313 28.1252 15.1879Z" />
            </DefaultIcon>
        </>
    );
};