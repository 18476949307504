import { useCallback } from "react";
import { useMenuPrincipal } from "../../../../../../service/app/providers/menu-principal-provider";
import { useThemeQueries } from "../../../../../../theme";
import { ButtonPrivateHeader } from "../../../../../components/controles/buttons";
import { PrivatePageHeader } from "../../../../../components/headers/header-private-page"
import { DesfazerIcon, FiltroIcon, MenuIcon } from "../../../../../components/icons";
import { PedidosListSearchProps } from "../pedidos-list/pedidos-list-search-props";
import { PesquisarPedidos } from "../pesquisa-pedidos/pesquisa-pedidos";


export interface PedidosHeaderProps {
   openPesquisa: boolean;
   setOpenPesquisa: (state: boolean) => any;
   setSearchProps: (props: PedidosListSearchProps) => any;
}

export const PedidosHeader = ({
   openPesquisa,
   setOpenPesquisa,
   setSearchProps
}: PedidosHeaderProps) => {

   const { abrirMenu } = useMenuPrincipal();
   const { isMobile } = useThemeQueries();

   const leftArea = useCallback(
      () => (
         isMobile ?
            <ButtonPrivateHeader icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>} tooltip="Menu" onClick={abrirMenu}></ButtonPrivateHeader> :
            null
      ),
      [isMobile, abrirMenu]
   );

   const rightArea = useCallback(
      () => (
         <ButtonPrivateHeader
            icon={
               openPesquisa ?
                  <DesfazerIcon tipo="PRIVATE_HEADER"></DesfazerIcon>
                  :
                  <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>}
            tooltip={!openPesquisa ? "Pesquisar" : "Voltar"}
            onClick={() => setOpenPesquisa(!openPesquisa)}
         ></ButtonPrivateHeader>
      ),
      [setOpenPesquisa, openPesquisa]
   );

   const closePesquisaWrapper = useCallback(() => {
      setOpenPesquisa(false);
   }, [setOpenPesquisa]);

   const onPesquisar = useCallback(
      (props: PedidosListSearchProps) => {
         setSearchProps(props);
      },
      [setSearchProps]
   );

   const pesquisa =
      <PesquisarPedidos
         isOpened={openPesquisa!}
         onCloseSearch={closePesquisaWrapper}
         onPesquisar={onPesquisar}
      />

   return (
      <>
         <PrivatePageHeader
            title="Pedidos"
            leftArea={leftArea()}
            bottomArea={pesquisa}
            rightArea={rightArea()}
         />
      </>
   )
}
