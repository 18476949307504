import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  modal: {
    width: '480px',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  titlePrincipal: {
    color: '#5333ED',
    fontWeight: 700,
    fontSize: '20px',
    textAlign: 'center'
  }
}))

