import { makeStyles } from "@material-ui/core";
import background from './background.svg'

export const useStyles = makeStyles((theme) => ({
   background: {
      top: 0,
      left: 0,
      position: 'fixed',
      height: "100%",
      width: '100%',
      backgroundImage: 'url("' + background + '")'
   }
}))