import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { AplicacaoModel } from '../../../../../../model/api/aplicacao/aplicacao-model';
import { makeUtilClasses, useThemeQueries } from '../../../../../../theme';
import { CircularLoading } from '../../../../utils';
import { TextFieldSaurus } from '../../../../controles/inputs';
import { EditarAplicacaoProps, EditarAplicacaoRefs } from './form-visualizacao-aplicacao-props';
import { useStyles } from './form-visualizacao-aplicacao-styles';
import { SelectSaurus } from '../../../../controles/selects/select-saurus/select-saurus';
import { TpServicoPedidoMock } from '../../../../../../data/mocks/tp-servico-pedido-mock';

export const FormEditarAplicacaoEspecifica = forwardRef<
  EditarAplicacaoRefs<AplicacaoModel>,
  EditarAplicacaoProps<AplicacaoModel>
>(
  (
    { loading, onSubmit, ...props }: EditarAplicacaoProps<AplicacaoModel>,
    ref,
  ) => {
    const { isMobile } = useThemeQueries();
    const classes = useStyles();
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);
    const {
      handleSubmit,
      control,
      getFieldState,
      formState: { errors, touchedFields },
      reset,
      setValue,
      getValues
    } = useForm<AplicacaoModel>({
      defaultValues: props.model,
      criteriaMode: 'all',
      mode: 'onBlur' && 'onChange',
    });

    const submitAlterarDadosConta = (values: AplicacaoModel) => {
      onSubmit(values);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitAlterarDadosConta)();
      },
      resetForm: () => {
        reset();
        if (!isMobile) refInputNome.current?.focus();
      },
      fillForm: (model: AplicacaoModel) => {
        reset({ ...model });
        if (!isMobile) refInputNome.current?.focus();
      },
    }));

    return (
      <>
        <div className={utilClasses.formContainer}>
          {loading && props.showLoading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="NORMAL" />
            </div>
          ) : null}
          <form
            className={loading ? utilClasses.controlLoading : ''}
          >
            <Box my={3}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12}>
                  <Controller
                    name="configuracoesIntegracao.urlBase"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="TEXTO"
                        fullWidth
                        disabled
                        showStartAdornment={true}
                        allowSubmit={false}
                        autoComplete={'off'}
                        label="URL"
                        placeholder=""
                        error={Boolean(
                          errors.configuracoesIntegracao?.urlBase && errors.configuracoesIntegracao?.urlBase.message,
                        )}
                        helperText={
                          touchedFields.configuracoesIntegracao?.urlBase || errors.configuracoesIntegracao?.urlBase
                            ? errors.configuracoesIntegracao?.urlBase?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="codigoAplicacao"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="NUMERO"
                        fullWidth
                        disabled
                        showStartAdornment={true}
                        allowSubmit={true}
                        autoComplete={'off'}
                        label="Adicionar o Código da Aplicação"
                        placeholder=""
                        error={Boolean(
                          errors.codigoAplicacao && errors.codigoAplicacao.message,
                        )}
                        helperText={
                          touchedFields.codigoAplicacao || errors.codigoAplicacao
                            ? errors.codigoAplicacao?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="descricaoAplicacao"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="TEXTO"
                        fullWidth
                        disabled
                        minRows={4}
                        multiline
                        showStartAdornment={true}
                        allowSubmit={true}
                        autoComplete={'off'}
                        label="Descrição"
                        placeholder=""
                        error={Boolean(
                          errors.descricaoAplicacao && errors.descricaoAplicacao.message,
                        )}
                        helperText={
                          touchedFields.descricaoAplicacao || errors.descricaoAplicacao
                            ? errors.descricaoAplicacao?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <div className={classes.divider} />
                <Grid item xs={6}>
                  <Controller
                    name="configuracoesIntegracao.tpServicoProduto"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="NUMERO"
                        fullWidth
                        disabled
                        showStartAdornment={true}
                        allowSubmit={true}
                        autoComplete={'off'}
                        label="Produtos"
                        error={Boolean(
                          errors.configuracoesIntegracao?.tpServicoProduto && errors.configuracoesIntegracao?.tpServicoProduto.message,
                        )}
                        helperText={
                          touchedFields.configuracoesIntegracao?.tpServicoProduto || errors.configuracoesIntegracao?.tpServicoProduto
                            ? errors.configuracoesIntegracao?.tpServicoProduto?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name={"configuracoesIntegracao.tpServicoPedido"}
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        label="Serviço"
                        variant="outlined"
                        className={classes.containerTexfield}
                        fullWidth
                        disabled
                        conteudo={TpServicoPedidoMock}
                        error={Boolean(
                          getFieldState("configuracoesIntegracao.tpServicoPedido").error
                        )}
                        helperText={
                          getFieldState("configuracoesIntegracao.tpServicoPedido").isTouched
                            && getFieldState("configuracoesIntegracao.tpServicoPedido").error
                            ? getFieldState("configuracoesIntegracao.tpServicoPedido").error?.message
                            : undefined
                        }
                        {...field}
                        onChange={(event) => {
                          const item = TpServicoPedidoMock.filter(
                            (item) => item.Key === event.target.value,
                          )[0]?.Key;
                          if (item !== undefined) {
                            setValue("configuracoesIntegracao.tpServicoPedido", item);
                          }
                        }}
                        value={getValues("configuracoesIntegracao.tpServicoPedido")}
                      />
                    )}
                  />
                </Grid>

                <div className={classes.config}>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={6}>
                      <Controller
                        name="dUpdate"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            tipo="DATA"
                            fullWidth
                            disabled
                            className={classes.containerTexfield}
                            showStartAdornment={true}
                            allowSubmit={false}
                            autoComplete={'on'}
                            label="Ultimo Acesso"
                            error={Boolean(
                              errors.dUpdate && errors.dUpdate.message,
                            )}
                            helperText={
                              touchedFields.dUpdate || errors.dUpdate
                                ? errors.dUpdate?.message
                                : undefined
                            }
                            {...field}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Controller
                        name="dInsert"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            tipo="DATA"
                            fullWidth
                            disabled
                            className={classes.containerTexfield}
                            showStartAdornment={true}
                            allowSubmit={false}
                            autoComplete={'on'}
                            label="Data Criação"
                            error={Boolean(
                              errors.dInsert && errors.dInsert.message,
                            )}
                            helperText={
                              touchedFields.dInsert || errors.dInsert
                                ? errors.dInsert?.message
                                : undefined
                            }
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Box>
          </form>
        </div>
      </>
    );
  },
);
