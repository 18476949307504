import { makeStyles } from "@material-ui/core";

export const useCardDashboardSincronizacaoStyles = makeStyles((theme) => ({
  aplicacao: {
    width: "100%",
    background: "#fff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.07)",
    borderRadius: "6px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      background: "#fff",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.07)",
      borderRadius: "6px",
    },
  },

  tableContainer: {
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
      height: 50,
    },
    "& .MuiTableCell-root": {
      border: "none",
      height: 50,
    },
  },

  oddRow: {
    background: theme.palette.grey[100],
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
      height: 50,
    },
    "& .MuiTableCell-root": {
      border: "none",
      height: 50,
    },
  },

  table: {
    minWidth: 650,
    maxHeight: 30,
  },

  tableResult: {
    width: "100%",
    height: 40,
  },

  tablePaper: {
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiTableCell-root": {
      border: "none",
    },
  },

  telaGrid: {
    background: "#fff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.07)",
    borderRadius: "15px",
    margin: theme.spacing(2),
    padding: "8px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      background: "#fff",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.07)",
      margin: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
    },
  },

  tableCell: {
    padding: "8px 16px",
    fontSize: "16px",
  },

  tableCellResult: {
    padding: "8px 16px",
  },

  statusTable: {
    borderRadius: "4px",
    width: "110px",
    height: "20px",
    color: "#FFFFFF",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  buttonSituacao: {
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    maxWidth: "95px",
    marginLeft: "97px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
      borderRadius: "8px",
      maxWidth: "70px",
      margin: 0,
    },
  },

  app: {
    padding: "8px 0",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.07)",
    },
  },

  divider: {
    width: "98%",
    height: "1px",
    marginBottom: "8px",
    backgroundColor: "#5333ED",
    marginLeft: "17px",
    [theme.breakpoints.down("xs")]: {
      width: "94%",
      height: "2px",
      marginBottom: "8px",
      backgroundColor: "#5333ED",
      marginLeft: "17px",
    },
  },

  //Titulos

  titleTelas: {
    color: "#696969",
    fontSize: "18px",
    padding: "8px 0 8px 15px",
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      padding: "10px",
    },
  },

  titleSituacao: {
    color: "#fff",
    fontSize: "12px",
    textAlign: "center",
    fontWeight: 700,
    padding: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      fontWeight: 700,
      padding: "5px",
    },
  },

  titleSub: {
    color: "#5F5F5F",
    fontSize: "16px",
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },

  titleSinc: {
    color: "#696969",
    padding: "6px 10px",
    fontSize: "15px",
    [theme.breakpoints.down("xs")]: {
      padding: "6px 10px",
      fontSize: "12px",
    },
  },
  cardRoot: {
    "&.MuiPaper-rounded": {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    position: "relative",
  },
  cardCinza: {
    "& .MuiPaper-elevation1": {
      background: theme.palette.grey[100],
    },
  },
}));
