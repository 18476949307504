import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function usePutPedidosById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putPedidosById = useCallback(
        (lojaId: string, pedidoId: string, query?: number, forcarAtualizacao?: boolean) => {
            return invocarApi({
                url: `/api/v2/loja/${lojaId}/pedido/${pedidoId}/alterar-status?status=${query}${forcarAtualizacao ? '&forcarAtualizacao=true' : ''}`,
                method: "PUT",
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        putPedidosById,
    };
}


