import React from "react";
import { FC } from "react";
import { useStyles } from "./menu-admin-styles";
import { Button, Typography } from "@material-ui/core";
import { useSessaoAtual } from "../../../../../../service/app/providers";
import { SaurusLogoIcon } from "../../../../../components/icons/perfil-logo-saurus";
import { useHistory } from "react-router";
import { LogoutIcon } from "../../../../../components/icons";

export const MenuAdmin: FC = ({ children }) => {
   const { usuario } = useSessaoAtual();
   const history = useHistory();

   const classes = useStyles();

   const menu = React.useMemo(() => {
      return (
         <>
            <div className={classes.containerBody}>
               <div className={classes.containerLeft}>
                  <div className="fotoPerfilContainer"
                     style={{ paddingRight: 8 }} >
                     <SaurusLogoIcon />
                  </div>
                  <div className="nomeContainer">
                     <div className={classes.containerNomeEPlano}>
                        <Typography className={classes.usuarioNome}>
                           {usuario?.usuarioNome}
                        </Typography>
                     </div>
                     <Typography className={classes.usuarioPerfil}>
                        ADM
                     </Typography>
                  </div>
               </div>
               <div className={classes.containerRight}>
                  <Button
                     className={classes.button}
                     size="medium"
                     style={{}}
                     onClick={() => history.push('/logout')}
                     startIcon={<LogoutIcon tipo="BUTTON" />}
                  >
                     Sair
                  </Button>
               </div>
            </div>
         </>
      );
   }, [classes.button, classes.containerBody, classes.containerLeft, classes.containerNomeEPlano, classes.containerRight, classes.usuarioNome, classes.usuarioPerfil, history, usuario?.usuarioNome]);

   return (
      <>
         <div className={classes.container}>
            {menu}
            {children}
         </div>
      </>
   );
};