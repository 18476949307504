
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormLojaValidation = () => {


  const FormLojaValidationYup = useMemo(() => {
    return Yup.object().shape({
      
    });
  }, [])

  return {
    FormLojaValidationYup,
  }
}

