import { Redirect, Route, RouteProps } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { PedidosStorageKeys, useSaurusPedidosStorage, useSaurusPedidosToken, useToastSaurus } from '../../../service/app/use-cases';
import { useSessaoAtual } from '../../../service/app/providers';


export const ExpiredTokenRoute: React.FC<RouteProps> = ({
  children,
  ...otherProps
}) => {
  const { usuario, carregando: carregandoSessaoAtual } = useSessaoAtual();
  const { isTokenExpired, } = useSaurusPedidosToken();
  const { showToast } = useToastSaurus();
  const { delRegistro } = useSaurusPedidosStorage();

  const carregando = carregandoSessaoAtual

  const retRoute = useCallback(() => {
    if (usuario?.aud === "administracao") {
      <Redirect to="/dashboard-admin" />
    } else {
      if (isTokenExpired(usuario)) {
        showToast(
          'error',
          `Sua sessão expirou. Realize o login novamente para continuar. (401) `,
        );
        delRegistro(PedidosStorageKeys.Token, false);
        return <Redirect to="/login/expirou" />;
      }
    }
    
    return carregando ? (
      children
    ) : isEmpty(usuario) ? (
      <Redirect to="/login/expirou" />
    ) :
      (
        children
      );
  }, [isTokenExpired, usuario, carregando, children, showToast, delRegistro]);
  return <Route {...otherProps}>{retRoute()}</Route>;
};
