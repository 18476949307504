import { Button, Card, Fade, Tooltip, Typography } from "@material-ui/core";
import { AplicacaoModel } from "../../../../model/api/aplicacao/aplicacao-model";
import { CircularLoading } from "../..";
import semImagem from '../../../../assets/img/sem-imagem.jpg'
import { useCadastros } from "../../../../service/app/providers/cadastros-provider";
import { LojaModel } from "../../../../model/api/loja/loja-model";
import { useGetRegistroCredencial } from "../../../../data/api/gestao/credenciais-dados/get-registro-credencial";
import { isEmpty } from "lodash";
import { CredenciaisDadosModel } from "../../../../model/api/credenciais/credenciais-dados-model";
import { useCardAplicacaoDashboardStyles } from "./card-aplicacao-dashboard-styles";
import { EnumAplicacao } from "../../../../model/enums/enum-aplicacao";


type Props = {
  loja: LojaModel;
  aplicacoes: AplicacaoModel[];
  editando: boolean
}

export const CardAplicacaoDashboard = ({ loja, aplicacoes, editando }: Props) => {
  const { carregando } = useGetRegistroCredencial();
  const { abrirCadastroNovaAplicacao } = useCadastros();
  const classes = useCardAplicacaoDashboardStyles();

  return (
    <>
      <Card className={classes.rootApp}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        {!isEmpty(loja?.credenciais) ?
          <>
            <Fade in timeout={900}>
              <div className={classes.aplicacaoImg} style={{ display: 'flex', flexWrap: 'wrap', gap: 0 }}>
                {loja.credenciais.map(cred => {
                  const app = aplicacoes.find(app => app.codigoAplicacao === cred.codigoAplicacao);
                  if (app) {
                    return (
                      <div key={cred.id} style={{ marginBottom: '8px' }}>
                        <Fade in timeout={900}>
                          <div className={classes.aplicacaoImg}>
                            <Tooltip title={app.descricaoAplicacao}>
                              <Button
                                onClick={() => {
                                  const credDados = new CredenciaisDadosModel(cred.id, cred.credenciais, cred.codigoAplicacao, cred.ativo, cred.confirmacaoAutomaticaAtivada, cred.tpSituacao, cred.urlRedirectLogin);
                                  abrirCadastroNovaAplicacao(editando, app, loja, credDados, '', false);
                                }}>
                                <Fade in timeout={800}>
                                  {(app.uriImagem) ?
                                    <>
                                      {(cred.codigoAplicacao === EnumAplicacao.DeliveryVip) ?
                                        <div style={{ marginBottom: '47px' }}>
                                          <img src={app.uriImagem}
                                            className={classes.img}
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null;
                                              currentTarget.src = semImagem;
                                            }} alt={app.descricaoAplicacao} />
                                        </div>
                                        :
                                        <img src={app.uriImagem}
                                          className={classes.img}
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = semImagem;
                                          }} alt={app.descricaoAplicacao} />
                                      }
                                    </>
                                    :
                                    <div style={{ marginBottom: '47px' }}>
                                      <img src={semImagem}
                                        className={classes.img}
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src = semImagem;
                                        }} alt={app.descricaoAplicacao} />
                                      <Typography className={classes.descricaoAplicacao}>{app.descricaoAplicacao}</Typography>
                                    </div>
                                  }
                                </Fade>
                              </Button>
                            </Tooltip>
                          </div>
                        </Fade>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </Fade>
          </>
          :
          <div className={classes.containerSemCredencial}>
            <Typography className={classes.titleSemCredencial}> Essa loja não tem nenhuma Credencial registrada </Typography>
          </div>
        }
      </Card>

    </>

  )
}

