import { Grid } from "@material-ui/core";
import { isEmpty } from "lodash";
import { useState } from "react";
import { CircularLoading } from "../../../../../components";
import { CardCarga } from "../../../../../components/cards/card-carga/card-carga";
import { CardHistorico } from "../../../../../components/cards/card-historico/card-historico";
import { TabSaurusContent, TabSaurusLabel, TabsSaurus } from "../../../../../components/tabs/tabs-saurus";
import { HistoricoCargaProps } from "./components/historico-carga-props";
import { useStyles } from "./components/list-styles";

export const HistoricoCargasTabs = ({ model, cargasModel, lojaId, carregando }: HistoricoCargaProps) => {
   const classes = useStyles();
   const [abaSelecionada, setAbaSelecionada] = useState(0);

   const tabChange = (index: number) => {
      setAbaSelecionada(index)
   };

   const tabsLabel = () => {
      let tabs: TabSaurusLabel[] = []
      if (!isEmpty(model.aplicacaoSincronizacao.historico)) {
         tabs.push(
            new TabSaurusLabel('Histórico', 0),
         )
      }
      if (!isEmpty(model.cargas)) {
         tabs.push(
            new TabSaurusLabel('Cargas', 1)
         )
      }
      return tabs
   }

   const tabsContent = () => {
      const tabs: TabSaurusContent[] = []

      if (!isEmpty(model.aplicacaoSincronizacao.historico)) {
         tabs.push(
            new TabSaurusContent(
               0,
               (
                  <div className={classes.defaultContainer}>
                     {carregando && <CircularLoading tipo="FULLSIZED" />}
                     <Grid container>
                        <Grid item xs={12} className={classes.listContainer}>
                           <CardHistorico model={model} />
                        </Grid>
                     </Grid>
                  </div>
               )
            )
         )
      }
      if (!isEmpty(model.cargas)) {
         tabs.push(
            new TabSaurusContent(
               1,
               (
                  <div className={classes.defaultContainer}>
                     {carregando && <CircularLoading tipo="FULLSIZED" />}
                     <Grid container>
                        <Grid item xs={12} className={classes.listContainer}>
                           <CardCarga
                              cargasId={(model.cargas)[0]?.id ?? ''}
                              cargasModel={cargasModel}
                              sincronizacaoId={model.id}
                              lojaId={lojaId}
                              model={model}
                           />
                        </Grid>
                     </Grid>
                  </div>
               ),
            ),
         )
      }
      return tabs
   }

   return (
      <>
         <TabsSaurus
            selectedTabIndex={abaSelecionada}
            onChange={tabChange}
            tabsLabel={tabsLabel()}
            tabsContent={[
               ...tabsContent()
            ]}
         />
      </>
   );
};
