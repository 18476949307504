import { LojaModel, PedidoModel } from "./pedido-model/pedido-model";

export class TesteStatusPedidoModel {
    constructor(
        public loja: LojaModel = new LojaModel(),

        public pedido: PedidoModel = new PedidoModel(),
        
        public codigoAplicacao: number = 0,
        public ativo: boolean = true,
        public tpSituacao: string = '',
        public dataCriacaoToken: string = '',
        public dataAtualizacaoToken: string = '',
        public jsonToken: string = '',
        public dataVencimento: string = '',
        public documento: string = '',
        public ultimoRetorno: string = '',
        public urlBase: string = '',
        public urlRedirectLogin: string = '',
        public mensagemLoja: string = '',
        public statusLoja: string = '',
    ) { }
}
