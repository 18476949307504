export enum EnumAplicacao {
    Go2Go = 87900548,
    SiteMercado = 99001020,
    ClickMix = 99001070,
    MercadoNow = 99001030,
    FlyDelivery = 95753875,
    OSuper = 99001050,
    DeliveryVip = 100004,
    SaurusCardapio = 99001090,
    NuvemShop = 99001080,
    Ifood = 99001012,
    LojaIntegrada = 99001040,
    PidoMe = 99001060,
    TouchOne = 99001100
}