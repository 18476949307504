import { Button, Grid, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useCallback, useEffect, useRef } from "react";
import { AplicacaoModel } from "../../../../../../model/api/aplicacao/aplicacao-model";
import { useModalStyles } from "../../../../utils/modals/modal-styles";
import { useToastSaurus } from "../../../../../../service/app/use-cases";
import { useCadastros } from "../../../../../../service/app/providers/cadastros-provider";
import { DefaultFormRefs } from "../../../../form/utils";
import { ModalHeader } from "../../../modal-header";
import { ButtonModalHeader } from "../../../../controles/buttons/button-modal-header";
import { EditarIcon, TestesIcon, VoltarIcon } from "../../../../icons";
import { FormEditarAplicacaoEspecifica } from "../../../../form/master/administracao/visualizacao-aplicacao/form-visualizacao-aplicacao";
import { CircularLoading } from "../../../../utils";
import { useStyles } from "./visualizacao-aplicacao-homologacao-styles";
import { useGetAplicacao } from "../../../../../../data/api/gestao/aplicacao/get-aplicacao";

interface VisualizacaoAplicacaoHomologacaoProps {
  setCadastro: (value: number) => void;
  aplicacao: AplicacaoModel;
}

export const VisualizacaoAplicacaoHomologacao = ({ aplicacao, setCadastro }: VisualizacaoAplicacaoHomologacaoProps) => {
  const classes = useModalStyles();
  const classe = useStyles();
  const { showToast } = useToastSaurus();
  const { fecharEdicaoAplicacaoHomologacao } = useCadastros();
  const onOff = aplicacao.tpSituacao === 1
  const { carregando } = useGetAplicacao();


  const cadEditarAplicacaoFormRef =
    useRef<DefaultFormRefs<AplicacaoModel>>(null);

  useEffect(() => {
    cadEditarAplicacaoFormRef.current?.fillForm(
      aplicacao
    )
  }, [aplicacao])

  const handleSubmit = useCallback(async () => {
    try {
      showToast('success', "Aplicação Editada!")
    } catch (e: any) {
      showToast('error', e.message);
      cadEditarAplicacaoFormRef.current?.resetForm()
    }
  }, [showToast]);

  const onCloseClick = useCallback(() => {
    fecharEdicaoAplicacaoHomologacao()
  }, [fecharEdicaoAplicacaoHomologacao]);

  return (
    <>
      <div className={classes.root}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <ModalHeader
          title={aplicacao.descricaoAplicacao}
          leftArea={
            <ButtonModalHeader
              tooltip="Voltar"
              icon={<VoltarIcon style={{
                fill: '#5333ED',
                width: '50px',
                height: '50px',
                marginRight: '0',
                marginBottom: '0'
              }} />}
              onClick={onCloseClick}
            />
          }
        />
        <div className={classes.content} style={{
          position: 'relative',
          maxHeight: '86vh',
        }}>
          <div className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}>
            <div className={classe.onOff}>
              <Typography className={onOff ? classe.labelStatusOn : classe.labelStatusOff}>{onOff ? 'Online' : 'Offline'}</Typography>
            </div>
            <Grid item xs={12}>
              <FormEditarAplicacaoEspecifica
                ref={cadEditarAplicacaoFormRef}
                showLoading={false}
                loading={carregando}
                onSubmit={handleSubmit}
                model={aplicacao}
                id={aplicacao.id}
              />
            </Grid>
          </div>
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Button
                onClick={() => setCadastro(3)}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <TestesIcon tipo="BUTTON_PRIMARY" />
                <Typography style={{ fontWeight: 700, fontSize: 16 }} >
                  Testes
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                onClick={() => setCadastro(2)}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
              >
                <EditarIcon tipo="BUTTON" />
                <Typography style={{ fontWeight: 700, fontSize: 16 }} >
                  Editar Aplicação
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}

