import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { makeUtilClasses, useThemeQueries } from '../../../../../theme';
import { CircularLoading } from '../../..';
import { TextFieldSaurus } from '../../../controles/inputs';
import { EnumTipoConfig } from '../../../../../model/enums/enum-tipo-config';
import { CredenciaisDadosModel } from '../../../../../model/api/credenciais/credenciais-dados-model';
import { EnumAplicacao } from '../../../../../model/enums/enum-aplicacao';
import { EditarAplicacaoProps, EditarAplicacaoRefs } from './form-editar-credencial-props';

export const FormEditarCredencial = forwardRef<
  EditarAplicacaoRefs<CredenciaisDadosModel>,
  EditarAplicacaoProps<CredenciaisDadosModel>
>(({ loading, onSubmit, ...props }: EditarAplicacaoProps<CredenciaisDadosModel>, ref) => {
  const { isMobile } = useThemeQueries();
  const utilClasses = makeUtilClasses();
  const refInputNome = useRef<HTMLInputElement>(null);
  const {
    handleSubmit,
    control,
    register,
    reset,
  } = useForm<any>({
    criteriaMode: 'all',
    mode: 'onBlur' && 'onChange'
  });

  const backupId = useRef<string>('')

  const submitAlterarDadosConta = (values: any) => {
    const credenciais: Record<string, string> = values as Record<string, string>;
    var model = new CredenciaisDadosModel()
    model.id = backupId.current
    model.credenciar(credenciais, props?.ativo, props?.model?.codigoAplicacao, props.model?.tpSituacao);
    delete model.credenciais.valores
    onSubmit(model);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(submitAlterarDadosConta)();
    },
    resetForm: () => {
      reset();
      if (!isMobile) refInputNome.current?.focus();
    },
    fillForm: (model: CredenciaisDadosModel) => {
      backupId.current = model.id
      reset({ ...model?.credenciais });

      if (!isMobile) refInputNome.current?.focus();
    }
  }));

  const validationIfood = props.model?.codigoAplicacao === EnumAplicacao.Ifood

  const configuracaoAutorizacao = useMemo(() => {
    if (validationIfood) {
      return props.aplicacaoDados?.configuracoes?.configuracaoAuthorizacao.filter((_, i) => i === 0)
    } else if (props.aplicacaoDados?.configuracoes && props.aplicacaoDados?.configuracoes?.configuracaoAuthorizacao) {
      return props.aplicacaoDados?.configuracoes?.configuracaoAuthorizacao
    }
    return
  }, [props.aplicacaoDados?.configuracoes, validationIfood])

  return (
    <>
      <form onSubmit={handleSubmit(submitAlterarDadosConta)}
        className={loading ? utilClasses.controlLoading : ''}>
        {configuracaoAutorizacao?.map((campo: any) => {
          return (
            <>
              <Box my={2}>
                <div className={utilClasses.formContainer}>
                  {loading && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                      <CircularLoading tipo="FULLSIZED" />
                    </div>
                  ) : null}
                </div>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} md={12}>
                    {validationIfood ? (
                      <>
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                          <Grid spacing={2} item xs={12} md={8}>
                            <Controller
                              {...register(campo.parametro)}
                              control={control}
                              render={({ field }) => (
                                <TextFieldSaurus
                                  tipo={campo.tipo === EnumTipoConfig.Texto ? "TEXTO" : "NUMERO"}
                                  fullWidth
                                  disabled={loading}
                                  showStartAdornment={true}
                                  allowSubmit={true}
                                  autoComplete={'off'}
                                  label={campo.descricao}
                                  placeholder={campo.descricao}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={4} md={4}>
                            <Button
                              fullWidth
                              color='primary'
                              onClick={() => { props.setAberto(1) }}
                            >
                              Validar Acesso
                            </Button>
                          </Grid>
                        </div>
                      </>
                    )
                      :
                      <>
                        <Controller
                          {...register(campo.parametro)}
                          control={control}
                          render={({ field }) => (
                            <TextFieldSaurus
                              tipo={campo.tipo === EnumTipoConfig.Texto ? "TEXTO" : "NUMERO"}
                              fullWidth
                              disabled={loading}
                              showStartAdornment={true}
                              allowSubmit={true}
                              autoComplete={'off'}
                              label={campo.descricao}
                              placeholder={campo.descricao}
                              {...field}
                            />
                          )}
                        />
                      </>
                    }
                  </Grid>
                </Grid>
              </Box>
            </>
          )
        })}
      </ form>
    </>
  );
},
);
