import { Grid } from "@material-ui/core";
import { useDetalhesPedidosStyles } from "./detalhes-do-pedido-itens-styles";
import { TextFieldSaurus } from "../../../../../components/controles/inputs";
import { PedidosModel } from "../../../../../../model/api/pedidos/pedidos-model";

interface Prop {
  pedidos: PedidosModel
}

export const DetalhesPedidoItens = (({ pedidos }: Prop) => {

  const classes = useDetalhesPedidosStyles();

  return (
    <>
      <div className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <TextFieldSaurus
              value={pedidos?.cliente?.nome}
              readOnly
              tipo='TEXTO'
              fullWidth
              variant="outlined"
              label="Nome do Cliente"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextFieldSaurus
              value={pedidos.valorTotal}
              showStartAdornment
              readOnly
              tipo='DECIMAL'
              fullWidth
              placeholder="0,00"
              variant="outlined"
              label="Total do Pedido"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextFieldSaurus
              value={pedidos.valorDesconto}
              showStartAdornment
              tipo='DECIMAL'
              readOnly
              fullWidth
              placeholder="0,00"
              variant="outlined"
              label="Valor Total do Desconto"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextFieldSaurus
              value={pedidos.dataAgendamentoInicial}
              showStartAdornment
              tipo='DATA_HORA'
              readOnly
              fullWidth
              variant="outlined"
              label="Data Agendamento Inicial"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextFieldSaurus
              value={pedidos.dataAgendamentoFinal}
              showStartAdornment
              tipo='DATA_HORA'
              readOnly
              fullWidth
              variant="outlined"
              label="Data Agendamento Final"
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
)
