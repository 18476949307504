import { EnumStatusProdutos } from "../../enums/enum-status-produtos";

export class CargasProdutosModel {

    constructor(
        public produtoId: string = '',
        public codigoPrincipal:  string = '',
        public descricao:  string = '',
        public grupo:  string = '',
        public medida: string = '',
        public valor:  number = 0,
        public valorCompra: number = 0,
        public produtoBalanca: boolean = true,
        public estoqueAtual: number = 0,
        public padrao: boolean = true,
        public ativo: boolean = true,
        public marca: string = '',
        public estoqueMinimo: number = 0,
        public ncm: string = '',
        public status: EnumStatusProdutos = EnumStatusProdutos.Rejeitado,
        public descricaoStatus: string = '',
    ) {}
}