import { useCallback, useEffect, useState } from 'react'
import { Box, Card, Grid, Paper, Table, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@material-ui/core'
import { useToastSaurus } from '../../../../../../service/app/use-cases'
import { PedidosListData } from './pedidos-list-data'
import { useGetPedidos } from '../../../../../../data/api/gestao/pedidos/get-pedidos'
import { PedidosListSearchProps } from './pedidos-list-search-props'
import { PedidosModel } from '../../../../../../model/api/pedidos/pedidos-model'
import { isEmpty } from 'lodash'
import { usePedidosStyles } from './pedidos-styles'
import { CircularLoading } from '../../../../../components'
import { Paginacao } from '../../../../../components/paginacao/paginacao'
import { useThemeQueries } from '../../../../../../theme'

export interface PedidosListProps {
    searchProps: PedidosListSearchProps;
}

export default function PedidosList(props: PedidosListProps) {
    const classes = usePedidosStyles()
    const { showToast } = useToastSaurus()
    const { getPedidos, carregando } = useGetPedidos()
    const { theme } = useThemeQueries()

    const [queryStatus, setQueryStatus] = useState({
        page: 1,
        totalPage: 0,
        totalResult: 0,
        list: Array<PedidosModel>(),
    });

    const [selectedList, setSelectedList] = useState<Array<string>>([]);

    const fillResult = useCallback(
        async (
            page: number,
            totalPage: number,
            totalResult: number,
            list: Array<PedidosModel>
        ) => {
            setQueryStatus({
                page: page,
                list: list,
                totalResult: totalResult,
                totalPage: totalPage,
            });
        },
        []
    );

    const search = useCallback(
        async (newPage: number) => {
            try {
                const query =
                    '' +
                    (props.searchProps.status > -1
                        ? '&status=' + props.searchProps.status
                        : '') +
                    (props.searchProps.aplicacao
                        ? '&codigoAplicacao=' + props.searchProps.aplicacao
                        : '') +
                    (props.searchProps.codPedido
                        ? '&codigoPedido=' + props.searchProps.codPedido
                        : '') +
                    (isEmpty(props.searchProps.dInicial)
                        ? ''
                        : `&dataInicial=${props.searchProps.dInicial}`) +
                    (isEmpty(props.searchProps.dFinal)
                        ? ''
                        : `&dataFinal=${props.searchProps.dFinal}`)
                const res = await getPedidos(props.searchProps.lojaId, newPage, query);
                if (res.erro) throw res.erro;
                if (
                    res.resultado?.data?.pageIndex > res.resultado?.data?.totalPage &&
                    res.resultado?.data?.totalResult > 0
                ) {
                    search(res.resultado?.data?.totalPage);
                    return;
                }

                fillResult(
                    res.resultado?.data?.pageIndex,
                    res.resultado?.data?.totalPage,
                    res.resultado?.data?.totalResult,
                    res.resultado?.data?.results
                )
            } catch (e: any) {
                showToast("error", e.message);
            }
        },
        [props.searchProps.lojaId, props.searchProps.status, props.searchProps.aplicacao, props.searchProps.codPedido, props.searchProps.dInicial, props.searchProps.dFinal, getPedidos, fillResult, showToast]
    );

    const pageChanged = useCallback(
        async (newPage: number) => {
            if (newPage <= queryStatus.totalPage || newPage > 0) {
                search(newPage);
            }
        },
        [queryStatus.totalPage, search]
    );

    const onCardChecked = (id: string) => {
        const aux = [...selectedList];
        aux.push(id);
        setSelectedList(aux);
    };

    useEffect(() => {
        if (props.searchProps.lojaId) {
            search(queryStatus.page)
        }
    }, [queryStatus.page, search, props.searchProps]);

    return (
        <>
            <div className={classes.defaultContainer}>
                {carregando && <CircularLoading tipo="FULLSIZED" />}
                <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'space-between',
                    flex: 1,
                    overflowY: 'auto',
                    padding: theme.spacing(1),
                }}>
                    <Grid container style={{
                        flex: '0 1 auto',
                    }}>
                        <Grid item xs={12}>
                            <Paginacao
                                pageChanged={pageChanged}
                                totalPages={queryStatus.totalPage}
                                totalRegisters={queryStatus.totalResult}
                                currentPage={queryStatus.page}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Card className={classes.cardRoot}>
                                <Box p={2}>
                                    {!isEmpty(props.searchProps.lojaNome) && (
                                        <Toolbar className={classes.containerNameLoja}>
                                            <Typography className={classes.titleLoja}>
                                                {props.searchProps.lojaNome}
                                            </Typography>
                                        </Toolbar>
                                    )}
                                    <Box mb={1}>
                                        <TableContainer component={Paper}>
                                            <Table className={classes.table}>
                                                <TableHead>
                                                    <TableRow >
                                                        <TableCell width='10%' align="left" >Nome da Aplicação</TableCell>
                                                        <TableCell width='10%' align="left">Código do Pedido</TableCell>
                                                        <TableCell width='10%' align="left">Cliente do Pedido </TableCell>
                                                        <TableCell width='10%' align="left">Valor do Pedido </TableCell>
                                                        <TableCell width='10%' align="left">Data do Pedido </TableCell>
                                                        <TableCell width='10%' align="left">Situação</TableCell>
                                                        <TableCell width='5%' align="center"></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                    <PedidosListData
                                        carregando={carregando}
                                        list={queryStatus.list}
                                        lojaId={props.searchProps.lojaId}
                                        onCardChecked={onCardChecked}
                                        selectedList={selectedList}
                                    />
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Box >
            </div>
        </>
    )
}