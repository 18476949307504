import { Box } from '@material-ui/core';
import { useDefaultCadastroStyles } from '../components/default-cadastro-styles';
import { HistoricoCargaProps } from './components/historico-cargas-tabs/components/historico-carga-props';
import { HistoricoCargasTabs } from './components/historico-cargas-tabs/historico-cargas-tabs';

export const HistoricoCargasPage = ({ model, cargasModel, lojaId, carregando }: HistoricoCargaProps) => {
  const classes = useDefaultCadastroStyles();

  return (
    <Box p={1} className={classes.root}>
      <HistoricoCargasTabs carregando={carregando} lojaId={lojaId} cargasModel={cargasModel} model={model} />
    </Box>
  );
};

export default HistoricoCargasPage;
