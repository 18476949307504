import { makeStyles } from "@material-ui/core";

export const useCardHistoricoStyles = makeStyles((theme) => ({
    containerHistorico: {
        display: 'flex',
        margin: '10px',
        padding: '8px 5px 0 13px',
        paddingBottom: '10px',
        overflowX: 'auto',
        borderRadius: '10px',
        backgroundColor: '#F8F8F8',
        height: '100%',
        maxHeight: '240px',
    },
    rootApp: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    containerStatus: {
        margin: '0 0 27px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    situacao: {
        width: '100%',
        height: '100%',
        margin: '0 10px',
        maxWidth: '100px',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            margin: '0 5px 0 0',
            maxWidth: '90px',
            height: '25px'
        }
    },
    titleSituacao: {
        color: '#858585',
        fontSize: '14px',
        fontWeight: 600
    },
    titleDate: {
        color: '#767676',
        fontSize: '14px',
        fontWeight: 600,
    },
    titleStts: {
        color: '#fff',
        fontSize: '12px',
        fontWeight: 700
    },
    titleHistorico: {
        color: '#767676',
        fontSize: '14px',
        fontWeight: 500
    },
    titleHistoricoResult: {
        color: '#767676',
        fontSize: '18px',
        fontWeight: 700
    },
    date: {
        color: '#5333ED',
        fontSize: '20px',
        fontWeight: 600,
        [theme.breakpoints.down('xs')]: {
            fontSize: '14px',
        }
    },

    divider: {
        width: '100%',
        height: '1px',
        marginBottom: 15,
        borderRadius: '6px',
        backgroundColor: '#E6E6E6'
    },

}));
