import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  config: {
    width: '100%',
    margin: "20px 5px",
    padding: "29px 17px",
    background: "rgba(246, 246, 246, 0.4)",
    boxShadow: "inset 0px 0px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: "15px",
  },
  textFunc: {
    color: 'rgb(183, 183, 183)',
    margin: '20px',
    [theme.breakpoints.down("xs")]: {
      color: "rgb(183, 183, 183)",
      margin: "60px 0 0 0",
      textAlign: "justify",
    },
  },
  containerTexfield: {
    backgroundColor: 'white!important', 
    borderRadius:'8px!important'
  },
  contentForms: {
    flex: "1 1 auto",
    padding: theme.spacing(2),
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    maxHeight: "791px",
    "& * > form": {
      visibility: "visible",
    },
    "&  .tabs-class": {
      visibility: "visible",
    },
  },
  onOff: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '8px 0'
  },

  divider: {
    width: "100%",
    height: "2px",
    margin: '21px 13px',
    backgroundColor: "rgba(230, 230, 230, 1)",
  }
}));
