import { EnumTpSituacaoIntegracao } from "../../enums/enum-tp-situacao-integracao";

export class CredenciaisDadosModel {

    constructor(
        public id: string = '',
        public credenciais: Record<string, string> = {},
        public codigoAplicacao: number | undefined = 0,
        public ativo: boolean = true,
        public confirmacaoAutomaticaAtivada: boolean = true,
        public tpSituacao: EnumTpSituacaoIntegracao = EnumTpSituacaoIntegracao.IntegracaoHabilitada,
        public urlRedirectLogin: string = '',
    ) { }
    credenciar(credenciais: Record<string, string>, ativo: boolean, codigoAplicacao: number | undefined, tpSituacao: number = 0, confirmacaoAutomaticaAtivada: boolean = false
    ) {
        this.codigoAplicacao = codigoAplicacao;
        this.credenciais = credenciais
        this.ativo = ativo
        this.confirmacaoAutomaticaAtivada = confirmacaoAutomaticaAtivada
        this.tpSituacao = tpSituacao
        // this.urlRedirectLogin = urlRedirectLogin
    }
}