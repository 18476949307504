
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormEditarLojaValidation = () => {


  const FormEditarLojaValidationYup = useMemo(() => {
    return Yup.object().shape({
      
    });
  }, [])

  return {
    FormEditarLojaValidationYup,
  }
}

