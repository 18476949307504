import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useGetSincronizacaoDadosById () {
    const { invocarApi, ...outrasPropriedades } = useApiBase();
//Segundo GET para peagr uma sincronização especifica
    const getSincronizacaoDadosById = useCallback(
        ( lojaId: string, sincronizacaoId: string, retornoLista: number ) => {
            return invocarApi({
                url: `/api/v2/loja/${lojaId}/sincronizacao/${sincronizacaoId}`,
                method: "GET",
                headers: {
                    "retornoLista": retornoLista
                },
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getSincronizacaoDadosById,
    };
}