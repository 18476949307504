import { Button, Grid, Typography } from "@material-ui/core";
import classNames from "classnames";
import saveAs from "file-saver";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDeleteSincronizacaoDadosById } from "../../../../../../data/api/gestao/sincronizacao-dados/delete-sincronizacao-dados-by-id";
import { useGetSincronizacaoDadosById } from "../../../../../../data/api/gestao/sincronizacao-dados/get-sincronizacao-dados-by-id";
import { usePutincronizacaoDadosById } from "../../../../../../data/api/gestao/sincronizacao-dados/put-sincronizacao-dados-by-id";
import { CargasProdutosModel } from "../../../../../../model/api/cargas-produto/cargas-produtos-model";
import { SincronizacaoByIdModel } from "../../../../../../model/api/sincronizacao/sincronizacao-by-id-model";
import { EnumSituacaoProcessamento } from "../../../../../../model/enums/enum-situacao-processamento";
import { useCadastros } from "../../../../../../service/app/providers/cadastros-provider";
import { useToastSaurus } from "../../../../../../service/app/use-cases";
import { useThemeQueries } from "../../../../../../theme";
import { alterarCorStatusSituacao } from "../../../../../../utils/alterar-cor-situacao";
import { alterarNomeSituacao } from "../../../../../../utils/alterar-nome-situacao";
import HistoricoCargasPage from "../../../../../pages/private/historico-cargas/historico-cargas-page";
import { ButtonModalHeader } from "../../../../controles/buttons/button-modal-header";
import { TextFieldSaurus } from "../../../../controles/inputs";
import { DownloadIcon, VoltarIcon } from "../../../../icons";
import { AtualizarIcon } from "../../../../icons/atualizar";
import { CircularLoading } from "../../../../utils";
import { ModalHeader } from "../../../modal-header";
import { useEnvioCargaStyles } from "./envio-carga-styles";

interface NovaCredencialProps {
  model: SincronizacaoByIdModel
  lojaId: string
}

export const EnvioCarga = ({ model, lojaId }: NovaCredencialProps) => {
  const classes = useEnvioCargaStyles();
  const { showToast } = useToastSaurus()
  const { getSincronizacaoDadosById, carregando } = useGetSincronizacaoDadosById()
  const { putSincronizacaoDadosById } = usePutincronizacaoDadosById()
  const [produtos] = useState<CargasProdutosModel>(new CargasProdutosModel())
  const { deleteSincronizacaoDadosById } = useDeleteSincronizacaoDadosById()
  const { fecharEnvioCarga } = useCadastros();
  const { theme } = useThemeQueries()
  const [sincro, setSincro] = useState<SincronizacaoByIdModel>(new SincronizacaoByIdModel())


  const reprocessar = useCallback(async () => {
    try {
      const res = await putSincronizacaoDadosById(lojaId, model.id, model.usuario)
      if (res.erro) throw res.erro

      const ret = await getSincronizacaoDadosById(lojaId, model.id, 4);
      showToast('success', 'Carga reprocessada')
      
      setSincro(ret.resultado?.data)
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [getSincronizacaoDadosById, lojaId, model.id, model.usuario, putSincronizacaoDadosById, showToast])

  const pararProcessamento = useCallback(async () => {
    try {
      const ret = await deleteSincronizacaoDadosById(lojaId, model.id, model.usuario)
      if (ret.erro) throw ret.erro

      const res = await getSincronizacaoDadosById(lojaId, model.id, 4);
      showToast('success', 'Processo Interrompido')
      setSincro(res.resultado?.data)

    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [deleteSincronizacaoDadosById, getSincronizacaoDadosById, lojaId, model.id, model.usuario, showToast])

  const sincronizacaoWrapper = useCallback(async () => {
    try {
      const ret = await getSincronizacaoDadosById(lojaId, model.id, 4);
      if (ret.erro) throw ret.erro;
      setSincro(ret.resultado?.data)

    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [getSincronizacaoDadosById, lojaId, model.id, showToast])

  useEffect(() => {
    if (model.id) {
      sincronizacaoWrapper()
    }
  }, [model.id, sincronizacaoWrapper])


  const onCloseClick = useCallback(() => {
    fecharEnvioCarga()
  }, [fecharEnvioCarga]);

  const blob = new Blob([JSON.stringify(produtos)], { type: 'application/json' })

  return (
    <>
      <div className={classes.root}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <ModalHeader
          title={"Envio de Carga"}
          leftArea={
            <ButtonModalHeader
              tooltip="Voltar"
              icon={<VoltarIcon tipo="MODAL_HEADER" />}
              onClick={onCloseClick}
            />
          }
        />
        <div className={classes.content} style={{
          position: 'relative',
          maxHeight: '86vh',
        }}>
          <div className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}>
            <Grid container spacing={2} className={classes.containerStatus}>
              <Typography className={classes.titleSituacao}>Situação:</Typography>
              <Grid item md={12} xs={12} className={classes.situacao} style={{ background: alterarCorStatusSituacao(sincro.tpStatusSincronizacao) }}>
                <Typography className={classes.titleSituacaoStatus}>{alterarNomeSituacao(sincro.tpStatusSincronizacao)}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TextFieldSaurus
                  tipo='TEXTO'
                  size="medium"
                  disabled
                  fullWidth
                  variant="outlined"
                  label="Aplicação"
                  value={sincro?.aplicacaoSincronizacao?.nomeAplicacao}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextFieldSaurus
                  tipo='TEXTO'
                  size="medium"
                  disabled
                  fullWidth
                  variant="outlined"
                  label="Nome Usuário"
                  value={sincro?.usuario}
                />
              </Grid>
              <Grid container spacing={2} className={classes.contianerTotal}>
                <Typography className={classes.titleTotalProdutos}>Total de Produtos</Typography>
                <div className={classes.divider} />
                <Grid item md={4} xs={12}>
                  <TextFieldSaurus
                    tipo='NUMERO'
                    showStartAdornment
                    readOnly
                    fullWidth
                    variant="outlined"
                    label="Total de Produtos"
                    value={sincro?.totalProdutosProdutos}
                  />
                </Grid>
                {!isEmpty(sincro?.cargas) ?
                  <>
                    {sincro?.cargas?.filter((cargas, i) => i < 1).map((cargas) => {
                      return (
                        <>
                          <Grid item md={4} xs={4}>
                            <TextFieldSaurus
                              tipo='NUMERO'
                              showStartAdornment
                              readOnly
                              fullWidth
                              variant="outlined"
                              label="Enviados"
                              value={cargas?.produtosProcessados}
                            />
                          </Grid>
                          <Grid item md={4} xs={4}>
                            <TextFieldSaurus
                              tipo='NUMERO'
                              showStartAdornment
                              readOnly
                              fullWidth
                              variant="outlined"
                              label="Rejeitado"
                              value={cargas?.produtosProdutosRejeitados}
                            />
                          </Grid>
                        </>
                      )
                    })}
                  </>
                  :
                  ''
                }
              </Grid>
              <Grid container spacing={2} className={classes.contianerTotal}>
                <Typography className={classes.titleData}>Data</Typography>
                <div className={classes.divider} />
                <Grid item md={6} xs={12}>
                  <TextFieldSaurus
                    tipo='DATA_HORA'
                    size="medium"
                    showStartAdornment
                    disabled
                    fullWidth
                    variant="outlined"
                    label="Data do Envio"
                    value={sincro?.dataCriacao}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextFieldSaurus
                    tipo='DATA_HORA'
                    size="medium"
                    disabled
                    fullWidth
                    variant="outlined"
                    label="Data do Conclusão"
                    value={sincro?.dataUltimaAlteracao}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} className={classes.contianerTotal}>
                <Grid item md={12} xs={12} className={classes.containerBtnDownload}>
                  <DownloadIcon style={{ width: '60px', margin: '0 15px' }} fillPath={theme.palette.primary.main} fill="none" />
                  <Button
                    onClick={() => saveAs(blob, 'download.json')}
                    size="medium"
                    color="primary"
                    variant="contained">
                    Download
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.dividerHistorico} />
            <div className={classes.containerHistorico}>
              <HistoricoCargasPage carregando={carregando} cargasModel={produtos} lojaId={lojaId} model={sincro} />
            </div>
          </div>
        </div>
        {(sincro.tpStatusSincronizacao === EnumSituacaoProcessamento.FINALIZADO) ?
          ''
          :
          <>
            {(sincro.tpStatusSincronizacao < EnumSituacaoProcessamento.FINALIZADO) ?
              <div className={classes.acoes}>
                <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Grid item xs={6}>
                    <Button
                      onClick={reprocessar}
                      variant="contained"
                      color="primary"
                      size="medium"
                      fullWidth
                    >
                      <AtualizarIcon tipo="BUTTON_PRIMARY" />
                      Reprocessar
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      className={classes.btnParar}
                      onClick={pararProcessamento}
                      variant="contained"
                      color="primary"
                      size="medium"
                      fullWidth
                    >
                      Parar Processamento
                    </Button>
                  </Grid>
                </Grid>
              </div>
              :
              <div className={classes.acoes}>
                <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Grid item xs={12}>
                    <Button
                      onClick={reprocessar}
                      variant="contained"
                      color="primary"
                      size="medium"
                      fullWidth
                    >
                      <AtualizarIcon tipo="BUTTON_PRIMARY" />
                      Reprocessar
                    </Button>
                  </Grid>
                </Grid>
              </div>
            }
          </>
        }
      </div>
    </>
  )
}

