import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function usePutincronizacaoDadosById () {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putSincronizacaoDadosById = useCallback(
        ( lojaId: string, sincronizacaoId: string, usuario: string ) => {
            return invocarApi({
                url: `/api/v2/loja/${lojaId}/sincronizacao/${sincronizacaoId}/reprocessar`,
                method: "PUT",
                headers: {'usuario': usuario},
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        putSincronizacaoDadosById,
    };
}