import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useGetLojas() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getLojas = useCallback(
        () => {
            return invocarApi({
                url: `/api/v2/loja`,
                method: "GET",
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getLojas,
    };
}
