import { EnumTpStatusLoja } from "../../enums/enum-tp-status-loja";
import { EnumTpStatusPedido } from "../../enums/enum-tp-status-pedido";
import { LojaDadosModel } from "../loja/loja-dados-model";
import { AplicacaoCredenciaisDadosModel } from "./credencial-model/aplicacao-credenciais-dados-model";

export class TesteCredencialModel {
    constructor(
        public loja: LojaDadosModel = new LojaDadosModel(),

        public credencial: AplicacaoCredenciaisDadosModel = new AplicacaoCredenciaisDadosModel(),

        public codigoAplicacao: number = 0,
        public ativo: boolean = true,
        public tpSituacao: EnumTpStatusPedido = EnumTpStatusPedido.Invalido,
        public dataCriacaoToken: Date = new Date(),
        public dataAtualizacaoToken: Date = new Date(),
        public jsonToken: string = '',
        public dataVencimento: Date = new Date(),
        public documento: string = '',
        public ultimoRetorno: string = '',
        public urlBase: Date = new Date(),
        public urlRedirectLogin: string = '',
        public mensagemLoja: string = '',
        public statusLoja: EnumTpStatusLoja = EnumTpStatusLoja.Invalido,
    ) { }
}




