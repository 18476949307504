import { guidEmpty } from "../../../utils/guid-empty";
import { CredenciaisModel } from "../credenciais/credenciais-model";
import { ServicoModel } from "../servico/servico-model";

export class LojaModel {
   constructor(
      public id: string = guidEmpty(),
      public documento: string = '',
      public razaoSocial: string = '',
      public credenciais: CredenciaisModel[] = new Array<CredenciaisModel>(),
      public terminaisVinculado: string[] = new Array<string>(),
      public dUpdate: Date = new Date(),
      public dInsert: Date = new Date(),
      public servicos: ServicoModel = new ServicoModel()
   ) { }
}