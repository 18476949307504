import { emphasize, makeStyles } from "@material-ui/core";
import { ImagemInputProps } from "./imagem-input-props";

export const useStyles = makeStyles((theme) => ({
    root: ({ width, height }: ImagemInputProps) => ({
        position: 'relative',
        display: 'flex',
        width: width || '150px',
        height: height || '150px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        "& .MuiFab-primary": {
            right: 0,
            bottom: 0,
            "& svg": {
                width: 25,
                height: 25,
            }
        }
    }),
    img: ({ imageRadius, mode }: ImagemInputProps) => ({
        objectFit: mode || 'cover',
        width: '100%',
        border: '1px solid #eee',
        borderRadius: imageRadius || '0px',
        height: '100%',
        boxShadow: theme.shadows[4],
    }),
    removerImagem: () => ({
        marginTop: '10px',
        background: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        '&:hover': {
            background: emphasize(theme.palette.error.main, 0.15),
        },
    }),
}));