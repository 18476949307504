import { Box, Grid, Typography } from "@material-ui/core";
import { CardProdutos } from "./components/card-produtos";
import { useCargasProdutosStyles } from "./cargas-produtos-styles";
import { DialogSaurus } from "../../dialog/dialog-saurus/dialog-saurus";
import { useToastSaurus } from "../../../../service/app/use-cases";
import { CargasProdutosModel } from "../../../../model/api/cargas-produto/cargas-produtos-model";
import { useCallback, useEffect, useState } from "react";
import { useGetCargasProdutosById } from "../../../../data/api/gestao/cargas-produtos/get-cargas-produtos-by-id";
import { Paginacao } from "../../paginacao/paginacao";

interface NovaCredencialProps {
  sincronizacaoId: string
  cargasId: string
  lojaId: string
  fecharModal: () => void
  aberto: boolean
}

export const CargasProdutos = ({ sincronizacaoId, lojaId, cargasId, fecharModal, aberto }: NovaCredencialProps) => {
  const classes = useCargasProdutosStyles();
  const { showToast } = useToastSaurus();
  const { getCargasProdutosById, carregando } = useGetCargasProdutosById()
  const [produtos] = useState<CargasProdutosModel[]>([])


  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<CargasProdutosModel>(),
  });

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<CargasProdutosModel>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    []
  );

  const search = useCallback(
    async (newPage: number) => {
      try {
        const res = await getCargasProdutosById(lojaId, sincronizacaoId, cargasId, newPage ?? 1);
        if (res.erro) throw res.erro;

        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPage &&
          res.resultado?.data?.totalResult > 0
        ) {
          search(res.resultado?.data?.totalPage);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPage,
          res.resultado?.data?.totalResult,
          res.resultado?.data?.results
        )
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [cargasId, fillResult, getCargasProdutosById, lojaId, showToast, sincronizacaoId]
  );

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [queryStatus.totalPages, search]
  );

  useEffect(() => {
    search(queryStatus.page)
  }, [queryStatus.page, search]);


  return (
    <>
      <DialogSaurus
        isButtonTitleClose
        handleClickClose={fecharModal}
        carregando={carregando}
        aberto={aberto}
        titulo="Carga de Produtos"
        tamanho="lg"
      >
        <Paginacao
          pageChanged={pageChanged}
          totalPages={queryStatus.totalPages}
          totalRegisters={queryStatus.totalResults}
          currentPage={queryStatus.page}
        />
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid md={2} xs={2}>
              <Typography className={classes.titleSub}>Código</Typography>
            </Grid>
            <Grid md={2} xs={2}>
              <Typography className={classes.titleSub}>Descrição</Typography>
            </Grid>
            <Grid md={1} xs={1}>
              <Typography className={classes.titleSub}>Categoria</Typography>
            </Grid>
            <Grid md={1} xs={2}>
              <Typography className={classes.titleSub}>Valor</Typography>
            </Grid>
            <Grid md={2} xs={1}>
              <Typography className={classes.titleSub}>NCM</Typography>
            </Grid>
            <Grid md={2} xs={2}>
              <Typography className={classes.titleSub}>Marca</Typography>
            </Grid>
            <Grid md={1} xs={1}>
              <Typography className={classes.titleSub}>Medida</Typography>
            </Grid>
            <Grid md={1} xs={1}>
              <Typography className={classes.titleSub}>Situação</Typography>
            </Grid>
            <div className={classes.divider} />
            <CardProdutos
              carregando={carregando}
              produtos={produtos}
              cargasId={cargasId}
              lojaId={lojaId}
              sincronizacaoId={sincronizacaoId}
            />
          </Grid>
        </Box>
      </DialogSaurus>
    </>
  )
}

