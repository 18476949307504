import { EnumStatusLoja } from "../../enums/enum-status-loja";


export class CredenciaisModel {
    constructor(
        public id: string = '',
        public credenciais: Record<string, string> = {},
        public codigoAplicacao: number | undefined = 0,
        public ativo: boolean = true,
        public confirmacaoAutomaticaAtivada: boolean = false,
        public tpSituacao: number = 0,
        public dataCriacaoToken: string = '',
        public dataAtualizacaoToken: string = '',
        public jsonToken: string = '',
        public dataVencimento: string = '',
        public documento: string = '',
        public ultimoRetorno: string = '',
        public urlBase: string = '',
        public mensagemLoja: string = '',
        public statusLoja: EnumStatusLoja = EnumStatusLoja.ABERTO,
        public urlRedirectLogin: string = ''
    ) { }
    credenciar(credenciais: Record<string, string>, ativo: boolean, codigoAplicacao: number | undefined, tpSituacao: number = 0,
        // urlRedirectLogin: string
    ) {
        this.codigoAplicacao = codigoAplicacao;
        this.credenciais = credenciais
        this.ativo = ativo
        this.tpSituacao = tpSituacao
        // this.urlRedirectLogin = urlRedirectLogin
    }
}