import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useGetLojaById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getLojaById = useCallback(
        (id: string) => {
            
            return invocarApi({
                url: `/api/v2/loja/${id}`,
                method: "GET",
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getLojaById,
    };
}
