import { useStyles } from './menu-principal-perfil-styles';
import { Typography, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { MenuPrincipalPerfilProps } from './menu-principal-perfil-props';
import { PermissaoIcon } from '../../../../../../components/icons/permissao-icon';
import { SaurusLogoIcon } from '../../../../../../components/icons/perfil-logo-saurus';
import { useSessaoAtual } from '../../../../../../../service/app/providers';

export const MenuPrincipalPerfil = (props: MenuPrincipalPerfilProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { usuario } = useSessaoAtual();
  const component = React.useMemo(() => {

    return (
      <>
        <div
          className={classNames(
            classes.drawerResumoPerfilContainer,
            props.modeloAtual === 'Completo' && !props.isMobile
              ? classes.drawerPerfilCompletoNotMobile
              : undefined,
            props.modeloAtual === 'Mini' ? classes.drawerPerfilMini : undefined,
          )}
        >
          <div className="fotoPerfilContainer">
            <SaurusLogoIcon />
          </div>
          {props.modeloAtual === 'Completo' && (
            <div className="nomeContainer">
              <div className={classes.containerNomeEPlano}>
                <Typography className={classes.usuarioNome}>
                  {usuario?.usuarioNome}
                </Typography>
              </div>
              <Typography className={classes.usuarioPerfil}>
                <PermissaoIcon
                  tipo="GERAL"
                  fill={theme.palette.secondary.main}
                />
                <b>Cliente</b>
              </Typography>
            </div>
          )}
        </div>
      </>
    );
  }, [classes.containerNomeEPlano, classes.drawerPerfilCompletoNotMobile, classes.drawerPerfilMini, classes.drawerResumoPerfilContainer, classes.usuarioNome, classes.usuarioPerfil, props.isMobile, props.modeloAtual, theme.palette.secondary.main, usuario?.usuarioNome]);

  return component;
};
