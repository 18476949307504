import { makeStyles } from "@material-ui/core";

export const usePedidosStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    position: "relative",
    background: "#f5f5f5",
    overflowX: "hidden",
    flexDirection: "column",
  },
  spacing: {
    "& .MuiGrid-spacing-xs-2": {
      marginBottom: 16,
    },
  },
  header: {
    flex: "0 1 auto",
  },
  list: {
    flex: "1 1 100%",
    overflowX: "hidden",
    overflow: "auto",
    position: "relative",
  },

  title: {
    color: "#545454",
    display: "inline-flex",
    padding: "40px 0 10px 10px",
    fontSize: "31px",
    fontWeight: 700,
  },
  content: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
  },
  defaultContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
  },
  acoes: {
    flex: "0 0 auto",
  },
  divider: {
    backgroundColor: "#BDBDBD",
    marginBottom: "10px",
    width: "100%",
    height: "1px",
  },
  loading: {
    position: "fixed",
    right: 0,
    top: 60,
    "& > div": {
      width: 80,
      height: 80,
    },
  },
  card: {
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    marginBottom: theme.spacing(2),
  },
  cardRoot: {
    "&.MuiPaper-rounded": {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    position: "relative",
  },
  titleSelecionarLoja: {
    fontSize: "18px",
    fontWeight: 600,
    color: "#5333ED",
  },
  cardSelecionarLoja: {
    margin: "25px 0 0 20px",
    padding: "12px 8px",
    width: "100%",
    maxWidth: "480px",
    background: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[15],
    [theme.breakpoints.down("md")]: {
      margin: "10px 8px 30px 8px",
    },
  },
  ultimoAcesso: {
    display: "flex",
    padding: theme.spacing(1),
    background: "#EFECFF",
    alignItems: "center",
    borderRadius: theme.shape.borderRadius,
    justifyContent: "center",
    width: "100%",
  },
  ultimoAcessoText: {
    color: "#5333ED",
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
  },
  horario: {
    display: "flex",
    alignItems: "center",
  }
}));
