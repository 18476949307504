import { useStyles } from "./background-white-top-styles"

export const BackgroundWhiteTop = () => {
    const classes = useStyles()
    return (
        <>
            <div className={classes.background} />
        </>
    )
}
