import { Button, Grid, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { AplicacaoModel } from "../../../../../../../model/api/aplicacao/aplicacao-model";
import { useModalStyles } from "../../../../../utils/modals/modal-styles";
import { useToastSaurus } from "../../../../../../../service/app/use-cases";
import { useCadastros } from "../../../../../../../service/app/providers/cadastros-provider";
import { DefaultFormRefs } from "../../../../../form/utils";
import { ModalHeader } from "../../../../modal-header";
import { ButtonModalHeader } from "../../../../../controles/buttons/button-modal-header";
import { SalvarIcon, VoltarIcon } from "../../../../../icons";
import { CircularLoading } from "../../../../../utils";
import { usePostAplicacao } from "../../../../../../../data/api/gestao/aplicacao-homologacao/post-adicionar-aplicacao";
import { FormAdicionarAplicacao } from "../../../../../form/master/administracao/adicionar-aplicacao/form-adicionar-aplicacao";
import { useGetAplicacao } from "../../../../../../../data/api/gestao/aplicacao/get-aplicacao";

export const AdicionarAplicacaoProd = () => {
  const classes = useModalStyles();
  const { showToast } = useToastSaurus();
  const { fecharAdcAplicacaoProd, AdcAplicacaoProdState} = useCadastros();

  const { postAplicacao,  carregando: carregandoPost } = usePostAplicacao();
  const { getAplicacao, carregando: carregandoGet } = useGetAplicacao();
  const carregando = carregandoPost || carregandoGet;

  const [aplicacao, setAplicacao] = useState<AplicacaoModel>(new AplicacaoModel());

  const cadAdicionarAplicacaoFormRef =
    useRef<DefaultFormRefs<AplicacaoModel>>(null);

  useEffect(() => {
    cadAdicionarAplicacaoFormRef.current?.fillForm(
      new AplicacaoModel()
    )
  }, [])

  const aplicacaoWrapper = useCallback(async () => {
    try {
      const res = await getAplicacao();
      if (res.erro) throw res.erro
      setAplicacao(res.resultado?.data.lojas)
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [getAplicacao, showToast])

  const handleSubmit = useCallback(async (model: AplicacaoModel) => {
    try {
      const ret = await postAplicacao(model);
      if (ret.erro) {
        throw ret.erro;
      }
      fecharAdcAplicacaoProd();
      showToast('success', "Nova Aplicação Adicionada!")
      setAplicacao(ret.resultado?.data)
    } catch (e: any) {
      showToast('error', e.mesage)
    }
  }, [fecharAdcAplicacaoProd, postAplicacao, showToast])

  const onCloseClick = useCallback(() => {
    fecharAdcAplicacaoProd()
  }, [fecharAdcAplicacaoProd]);  
  
  useEffect(() => {
    if (!AdcAplicacaoProdState.aberto) {
      aplicacaoWrapper()
    }
  }, [AdcAplicacaoProdState.aberto, aplicacaoWrapper])

  return (
    <>
      <div className={classes.root}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <ModalHeader
          title={'Adicionar Aplicação'}
          leftArea={
            <ButtonModalHeader
              tooltip="Voltar"
              icon={<VoltarIcon style={{
                fill: '#5333ED',
                width: '50px',
                height: '50px',
                marginRight: '0',
                marginBottom: '0'
              }} />}
              onClick={onCloseClick}
            />
          }
        />
        <div className={classes.content}>
          <div
            className={classNames(
              classes.contentForms,
              carregando ? classes.contentFormsLoading : undefined,
            )}
          >
            <FormAdicionarAplicacao
              ref={cadAdicionarAplicacaoFormRef}
              showLoading={false}
              loading={carregando}
              onSubmit={handleSubmit}
              model={aplicacao}
            />
          </div>
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                disabled={carregando}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={() => cadAdicionarAplicacaoFormRef.current?.submitForm()}
              >
                <SalvarIcon tipo="BUTTON_PRIMARY" />
                <Typography style={{ fontWeight: 700, fontSize: 16 }} >
                  Salvar
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}

