import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';


export function useDeleteRemoveAplicacao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const deleteRemoverAplicacao = useCallback(
        (idAplicacao: string) =>
            invocarApi({
                url: `api/v2/aplicacao/${idAplicacao}`,
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        deleteRemoverAplicacao
    }
}

