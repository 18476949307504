import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';


export function useDeleteRemoverCredenciais() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const deleteRemoverCredenciais = useCallback(
        (idloja: string, credencialId: string) =>
            invocarApi({
                url: `/api/v2/loja/${idloja}/credencial-dados/${credencialId}`,
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        deleteRemoverCredenciais
    }
}

