import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { EditarLojaModel } from "../../../../model/api/loja/editar-loja-model";

export function usePutAtualizarEmpresaDados() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putAtualizarEmpresaDados = useCallback(
        (idloja: string, model: EditarLojaModel) => {
            return invocarApi({
                url: `/api/v2/loja/${idloja}`,
                method: "PUT",
                data: model,
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        putAtualizarEmpresaDados,
    };
}


