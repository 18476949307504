import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        height: "100%",
        padding: "0px",
        margin: "0px",
        display: 'flex',
        flexDirection: 'column'
    },

    containerBody: {
        width: '100%',
        display: 'flex',
        padding: '13px 26px',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.1)'
    },

    containerLeft: {
        display: 'flex',
        alignItems: 'center'
    },

    containerRight: {
        display: 'flex',
        alignItems: 'center'
    },

    button: {
        borderRadius: 8,
        border: '1px solid rgba(168, 168, 168, 1)',
        width: '101px',
        heigth: '40px'
    },

    containerNomeEPlano: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },

    usuarioNome: {
        fontWeight: 600,
        fontSize: "18px",
        color: theme.palette.primary.main
    },

    usuarioPerfil: {
        fontSize: "13px",
        fontWeight: 500,
        color: 'rgba(163, 163, 163, 1)'
    }
}));