import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { Box, Button, Card, Grid, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { AplicacaoModel } from '../../../../../../model/api/aplicacao/aplicacao-model';
import { makeUtilClasses, useThemeQueries } from '../../../../../../theme';
import { CircularLoading } from '../../../../utils';
import { TextFieldSaurus } from '../../../../controles/inputs';
import { AdicionarAplicacaoRefs, AdicionarAplicacaoProps } from './form-adicionar-aplicacao-props';
import { ImagemInput } from '../../../../controles/inputs/imagem-input';
import SemImagem from '../../../../../../assets/img/sem-imagem.jpg';
import { SelectSaurus } from '../../../../controles/selects/select-saurus/select-saurus';
import { TpConfigMock } from '../../../../../../data/mocks/tp-config-mock';
import { AdicionarCardIcon, EditarIcon } from '../../../../icons';
import { useStyles } from './from-adicionar-aplicacao-styles';
import { TpSituacaoMock } from '../../../../../../data/mocks/tp-situacao-mock';
import { EnumTipoConfig } from '../../../../../../model/enums/enum-tipo-config';
import { TpServicoPedidoMock } from '../../../../../../data/mocks/tp-servico-pedido-mock';
import { TpServicoProdutoMock } from '../../../../../../data/mocks/tp-servico-produto-mock';
import { ItemObject } from '../editar-aplicacao-homologacao/form-editar-aplicacao-homologacao';
import { guidEmpty } from '../../../../../../utils/guid-empty';
import { TpConfigObrigatorioMock } from '../../../../../../data/mocks/tp-config-obrigatorio-mock';
import { SimNaoBooleanMock } from '../../../../../../data/mocks/sim-nao-boolean-mock';

export const FormAdicionarAplicacao = forwardRef<
  AdicionarAplicacaoRefs<AplicacaoModel>,
  AdicionarAplicacaoProps<AplicacaoModel>
>(
  (
    { loading, onSubmit, ...props }: AdicionarAplicacaoProps<AplicacaoModel>,
    ref,
  ) => {
    const { isMobile } = useThemeQueries();
    const classes = useStyles();
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);
    const {
      handleSubmit,
      control,
      getFieldState,
      formState: { errors, touchedFields },
      reset,
      setValue,
      getValues
    } = useForm<AplicacaoModel>({
      defaultValues: props.model,
      criteriaMode: 'all',
      mode: 'onBlur' && 'onChange',
    });

    const [itens, setItens] = useState<ItemObject[]>([{
      descricao: '',
      id: '',
      index: 0,
      obrigatorio: false,
      parametro: '',
      tipo: EnumTipoConfig.Texto,
    }])

    const [item, setItem] = useState<ItemObject>({
      descricao: '',
      id: '',
      index: 0,
      obrigatorio: false,
      parametro: '',
      tipo: EnumTipoConfig.Texto,
    })

    const submitAlterarDadosConta = (model: AplicacaoModel) => {
      onSubmit(model);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitAlterarDadosConta)();
      },
      resetForm: () => {
        reset();
        if (!isMobile) refInputNome.current?.focus();
      },
      fillForm: (model: AplicacaoModel) => {
        setItens(model.configuracoes.configuracaoAuthorizacao.map((item, index) => ({
          ...item,
          index
        })))
        model.id = ''
        reset({ ...model })
        if (!isMobile) refInputNome.current?.focus();
      },
    }));

    const resetItem = useCallback(() => {
      setItem(prev => (
        {
          descricao: '',
          id: '',
          index: prev.index + 1,
          obrigatorio: false,
          parametro: '',
          tipo: EnumTipoConfig.Texto,
        }
      ))
    }, [])

    console.log(item)

    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {loading && props.showLoading ? (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="NORMAL" />
              </div>
            ) : null}
            <form
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Controller
                    name="uriImagem"
                    control={control}
                    render={() => (
                      <ImagemInput
                        loadErrorImage={SemImagem}
                        imageRadius="5%"
                        width={isMobile ? '180px' : '200px'}
                        height={isMobile ? '180px' : '200px'}
                        accept="image/*"
                        error={Boolean(
                          errors.uriImagem && errors.uriImagem.message,
                        )}
                        helperText={
                          touchedFields.uriImagem || errors.uriImagem
                            ? errors.uriImagem?.message
                            : undefined
                        }
                        value={getValues('uriImagem')}
                        onChange={({ base64 }: any) => {
                          setValue('uriImagem', base64);
                        }}
                        onBlur={() => {
                          setValue('uriImagem', '');
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="descricaoAplicacao"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="TEXTO"
                        fullWidth
                        disabled={loading}
                        showStartAdornment={true}
                        allowSubmit={true}
                        autoComplete={'off'}
                        label="Digite a Descrição da Aplicação"
                        placeholder="Ex: TouchOne"
                        error={Boolean(
                          errors.descricaoAplicacao && errors.descricaoAplicacao.message,
                        )}
                        helperText={
                          touchedFields.descricaoAplicacao || errors.descricaoAplicacao
                            ? errors.descricaoAplicacao?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="id"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="TEXTO"
                        fullWidth
                        disabled={loading}
                        showStartAdornment={true}
                        allowSubmit={false}
                        autoComplete={'off'}
                        label="Adicionar o ID"
                        placeholder="Ex: 239a478c-eaeb-416e-aec6-d01efcd06c42"
                        error={Boolean(
                          errors.id && errors.id.message,
                        )}
                        helperText={
                          touchedFields.id || errors.id
                            ? errors.id?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="codigoAplicacao"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="NUMERO"
                        fullWidth
                        disabled={loading}
                        showStartAdornment={true}
                        allowSubmit={true}
                        autoComplete={'off'}
                        label="Adicionar o Código da Aplicação"
                        placeholder=""
                        error={Boolean(
                          errors.codigoAplicacao && errors.codigoAplicacao.message,
                        )}
                        helperText={
                          touchedFields.codigoAplicacao || errors.codigoAplicacao
                            ? errors.codigoAplicacao?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name={'tpSituacao'}
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        label="Situação da Aplicação"
                        variant="outlined"
                        className={classes.containerTexfield}
                        fullWidth
                        disabled={loading}
                        conteudo={TpSituacaoMock}
                        error={Boolean(
                          getFieldState('tpSituacao').error
                        )}
                        helperText={
                          getFieldState('tpSituacao').isTouched
                            && getFieldState('tpSituacao').error
                            ? getFieldState('tpSituacao').error?.message
                            : undefined
                        }
                        {...field}
                        onChange={(event) => {
                          const item = TpSituacaoMock.filter(
                            (item) => item.Key === event.target.value,
                          )[0]?.Key;
                          if (item !== undefined) {
                            setValue('tpSituacao', item);
                          }
                        }}
                        value={getValues('tpSituacao')}
                      />
                    )}
                  />
                </Grid>

                <div className={classes.config}>
                  <Grid container spacing={2} justifyContent="center">
                    <Typography className={classes.titleConfig}>
                      Configuração de Integração
                    </Typography>
                    <div className={classes.dividerTitle} />

                    <Grid item xs={6}>
                      <Controller
                        name={'configuracoesIntegracao.tpServicoPedido'}
                        control={control}
                        render={({ field }) => (
                          <SelectSaurus
                            label="Tipo de Serviço de Pedido"
                            variant="outlined"
                            className={classes.containerTexfield}
                            fullWidth
                            disabled={loading}
                            conteudo={TpServicoPedidoMock}
                            error={Boolean(
                              getFieldState('configuracoesIntegracao.tpServicoPedido').error
                            )}
                            helperText={
                              getFieldState('configuracoesIntegracao.tpServicoPedido').isTouched
                                && getFieldState('configuracoesIntegracao.tpServicoPedido').error
                                ? getFieldState('configuracoesIntegracao.tpServicoPedido').error?.message
                                : undefined
                            }
                            {...field}
                            onChange={(event) => {
                              const item = TpServicoPedidoMock.filter(
                                (item) => item.Key === event.target.value,
                              )[0]?.Key;
                              if (item !== undefined) {
                                setValue('configuracoesIntegracao.tpServicoPedido', item);
                              }
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={'configuracoesIntegracao.tpServicoProduto'}
                        control={control}
                        render={({ field }) => (
                          <SelectSaurus
                            label="Tipo de Serviço de Produto"
                            variant="outlined"
                            className={classes.containerTexfield}
                            fullWidth
                            disabled={loading}
                            conteudo={TpServicoProdutoMock}
                            error={Boolean(
                              getFieldState('configuracoesIntegracao.tpServicoProduto').error
                            )}
                            helperText={
                              getFieldState('configuracoesIntegracao.tpServicoProduto').isTouched
                                && getFieldState('configuracoesIntegracao.tpServicoProduto').error
                                ? getFieldState('configuracoesIntegracao.tpServicoProduto').error?.message
                                : undefined
                            }
                            {...field}
                            onChange={(event) => {
                              const item = TpServicoProdutoMock.filter(
                                (item) => item.Key === event.target.value,
                              )[0]?.Key;
                              if (item !== undefined) {
                                setValue('configuracoesIntegracao.tpServicoProduto', item);
                              }
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="configuracoesIntegracao.authorizationCredenciais"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            tipo="TEXTO"
                            fullWidth
                            disabled={loading}
                            className={classes.containerTexfield}
                            showStartAdornment={true}
                            allowSubmit={false}
                            autoComplete={'off'}
                            label="Autorização de Credencias"
                            error={Boolean(
                              errors.configuracoesIntegracao?.authorizationCredenciais && errors.configuracoesIntegracao?.authorizationCredenciais.message,
                            )}
                            helperText={
                              touchedFields.configuracoesIntegracao?.authorizationCredenciais || errors.configuracoesIntegracao?.authorizationCredenciais
                                ? errors.configuracoesIntegracao?.authorizationCredenciais?.message
                                : undefined
                            }
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="configuracoesIntegracao.authorizationWebHook"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            tipo="TEXTO"
                            fullWidth
                            showStartAdornment={true}
                            disabled={loading}
                            allowSubmit={false}
                            className={classes.containerTexfield}
                            autoComplete={'off'}
                            label="Autorização de WebHook"
                            error={Boolean(
                              errors.configuracoesIntegracao?.authorizationWebHook && errors.configuracoesIntegracao?.authorizationWebHook.message,
                            )}
                            helperText={
                              touchedFields.configuracoesIntegracao?.authorizationWebHook || errors.configuracoesIntegracao?.authorizationWebHook
                                ? errors.configuracoesIntegracao?.authorizationWebHook?.message
                                : undefined
                            }
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="configuracoesIntegracao.quantidadeEnvio"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            tipo="NUMERO"
                            fullWidth
                            showStartAdornment={true}
                            disabled={loading}
                            allowSubmit={false}
                            className={classes.containerTexfield}
                            autoComplete={'off'}
                            label="Quantidade de Envio"
                            placeholder='Ex: 23'
                            error={Boolean(
                              errors.configuracoesIntegracao?.quantidadeEnvio && errors.configuracoesIntegracao?.quantidadeEnvio.message,
                            )}
                            helperText={
                              touchedFields.configuracoesIntegracao?.quantidadeEnvio || errors.configuracoesIntegracao?.quantidadeEnvio
                                ? errors.configuracoesIntegracao?.quantidadeEnvio?.message
                                : undefined
                            }
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="configuracoesIntegracao.urlBase"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            tipo="TEXTO"
                            fullWidth
                            showStartAdornment={true}
                            disabled={loading}
                            allowSubmit={false}
                            autoComplete={'off'}
                            label="Digite a UrlBase"
                            className={classes.containerTexfield}
                            placeholder=''
                            error={Boolean(
                              errors.configuracoesIntegracao?.urlBase && errors.configuracoesIntegracao?.urlBase.message,
                            )}
                            helperText={
                              touchedFields.configuracoesIntegracao?.urlBase || errors.configuracoesIntegracao?.urlBase
                                ? errors.configuracoesIntegracao?.urlBase?.message
                                : undefined
                            }
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="configuracoesIntegracao.tempoVidaMinutosToken"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            tipo="TEXTO"
                            fullWidth
                            showStartAdornment={true}
                            disabled={loading}
                            allowSubmit={false}
                            autoComplete={'off'}
                            label="Tempo do Token"
                            className={classes.containerTexfield}
                            error={Boolean(
                              errors.configuracoesIntegracao?.tempoVidaMinutosToken && errors.configuracoesIntegracao?.tempoVidaMinutosToken.message,
                            )}
                            helperText={
                              touchedFields.configuracoesIntegracao?.tempoVidaMinutosToken || errors.configuracoesIntegracao?.tempoVidaMinutosToken
                                ? errors.configuracoesIntegracao?.tempoVidaMinutosToken?.message
                                : undefined
                            }
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="configuracoesIntegracao.controleProprio"
                        control={control}
                        render={({ field }) => (
                          <SelectSaurus
                            fullWidth
                            disabled={loading}
                            allowSubmit={false}
                            autoComplete={'off'}
                            label="Controle Próprio"
                            className={classes.containerTexfield}
                            conteudo={SimNaoBooleanMock}
                            {...field}
                            onChange={event => {
                              const item = SimNaoBooleanMock.filter(
                                (item) => item.Key === event.target.value,
                              )[0]?.Key;
                              if (item !== undefined) {
                                setValue('configuracoesIntegracao.controleProprio', item);
                              }
                            }}
                            error={Boolean(
                              errors.configuracoesIntegracao?.controleProprio && errors.configuracoesIntegracao?.controleProprio.message,
                            )}
                            helperText={
                              touchedFields.configuracoesIntegracao?.controleProprio || errors.configuracoesIntegracao?.controleProprio
                                ? errors.configuracoesIntegracao?.controleProprio?.message
                                : undefined
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </div>

                <div className={classes.config}>
                  <Grid container spacing={2}>
                    <Typography className={classes.titleConfig}>
                      Configuração de Autorização
                    </Typography>
                    <div className={classes.dividerTitle} />
                    <Grid item xs={6}>
                      <Controller
                        name={`configuracoes.configuracaoAuthorizacao.${item.index}.descricao`}
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            tipo="TEXTO"
                            fullWidth
                            disabled={loading}
                            showStartAdornment={true}
                            allowSubmit={false}
                            autoComplete={'off'}
                            variant="outlined"
                            className={classes.containerTexfield}
                            label="Descrição"
                            placeholder=""
                            error={Boolean(
                              getFieldState(`configuracoes.configuracaoAuthorizacao.${item.index}.descricao`).error
                            )}
                            helperText={
                              getFieldState(`configuracoes.configuracaoAuthorizacao.${item.index}.descricao`).isTouched
                                && getFieldState(`configuracoes.configuracaoAuthorizacao.${item.index}.descricao`).error
                                ? getFieldState(`configuracoes.configuracaoAuthorizacao.${item.index}.descricao`).error?.message
                                : undefined
                            }
                            {...field}
                            onChange={(event) => {
                              setValue(`configuracoes.configuracaoAuthorizacao.${item.index}.descricao`, event.target.value) //talvez nem precisa disso
                              setItem(prev => ({ ...prev, descricao: event.target.value }))
                            }}
                            value={item.descricao}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={`configuracoes.configuracaoAuthorizacao.${item.index}.parametro`}
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            tipo="TEXTO"
                            fullWidth
                            disabled={loading}
                            showStartAdornment={true}
                            allowSubmit={false}
                            className={classes.containerTexfield}
                            autoComplete={'off'}
                            label="Parâmetro"
                            placeholder=""
                            error={Boolean(
                              getFieldState(`configuracoes.configuracaoAuthorizacao.${item.index}.parametro`).error
                            )}
                            helperText={
                              getFieldState(`configuracoes.configuracaoAuthorizacao.${item.index}.parametro`).isTouched
                                && getFieldState(`configuracoes.configuracaoAuthorizacao.${item.index}.parametro`).error
                                ? getFieldState(`configuracoes.configuracaoAuthorizacao.${item.index}.parametro`).error?.message
                                : undefined
                            }
                            {...field}
                            onChange={(event) => {
                              setValue(`configuracoes.configuracaoAuthorizacao.${item.index}.parametro`, event.target.value)
                              setItem(prev => ({ ...prev, parametro: event.target.value }))
                            }}
                            value={item.parametro}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={`configuracoes.configuracaoAuthorizacao.${item.index}.tipo`}
                        control={control}
                        render={({ field }) => (
                          <SelectSaurus
                            label="Tipo"
                            variant="outlined"
                            className={classes.containerTexfield}
                            fullWidth
                            disabled={loading}
                            conteudo={TpConfigMock}
                            error={Boolean(
                              getFieldState(`configuracoes.configuracaoAuthorizacao.${item.index}.tipo`).error
                            )}
                            helperText={
                              getFieldState(`configuracoes.configuracaoAuthorizacao.${item.index}.tipo`).isTouched
                                && getFieldState(`configuracoes.configuracaoAuthorizacao.${item.index}.tipo`).error
                                ? getFieldState(`configuracoes.configuracaoAuthorizacao.${item.index}.tipo`).error?.message
                                : undefined
                            }
                            {...field}
                            value={item.tipo}
                            onChange={(e: any) => {
                              setItem(prev => ({
                                ...prev,
                                tipo: e.target.value
                              }))
                              setValue(`configuracoes.configuracaoAuthorizacao.${item.index}.tipo`, e.target.value)
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={`configuracoes.configuracaoAuthorizacao.${item.index}.obrigatorio`}
                        control={control}
                        render={({ field }) => (
                          <SelectSaurus
                            label="Obrigatório"
                            variant="outlined"
                            className={classes.containerTexfield}
                            fullWidth
                            disabled={loading}
                            conteudo={TpConfigObrigatorioMock}
                            error={Boolean(
                              getFieldState(`configuracoes.configuracaoAuthorizacao.${item.index}.obrigatorio`).error
                            )}
                            helperText={
                              getFieldState(`configuracoes.configuracaoAuthorizacao.${item.index}.obrigatorio`).isTouched
                                && getFieldState(`configuracoes.configuracaoAuthorizacao.${item.index}.obrigatorio`).error
                                ? getFieldState(`configuracoes.configuracaoAuthorizacao.${item.index}.obrigatorio`).error?.message
                                : undefined
                            }
                            {...field}
                            value={item.obrigatorio}
                            onChange={(e: any) => {
                              setItem(prev => ({
                                ...prev,
                                obrigatorio: e.target.value
                              }))
                              setValue(`configuracoes.configuracaoAuthorizacao.${item.index}.obrigatorio`, e.target.value)
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        style={{ width: '223px', borderRadius: 5, height: '42px' }}
                        variant='contained'
                        size='medium'
                        color='primary'
                        startIcon={item.id === '' ? <AdicionarCardIcon tipo='BUTTON_ADC' /> : <EditarIcon tipo='BUTTON_ADC' />}
                        onClick={() => {
                          if (item.id === '') {
                            const itemAdd = { ...item, id: guidEmpty(), index: itens.length }
                            setItens(prev => [
                              ...prev,
                              itemAdd
                            ])
                            resetItem()
                            return
                          }
                          setItens(prev => {
                            debugger
                            const newArr = [...prev]
                            newArr[item.index] = item
                            return newArr
                          })

                          resetItem()
                        }}
                      >
                        <Typography style={{ fontWeight: 700, fontSize: 14 }} >
                          {console.log(item.id)}
                          {item.id === '' ? 'Adicionar' : 'Editar'}
                        </Typography>
                      </Button>
                    </Grid>
                    <div className={classes.dividerConfig} />
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Card className={classes.configCard}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography className={classes.titleConfigCard}>Descrição</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className={classes.titleConfigCard}>Propriedades</Typography>
                          </Grid>
                        </Grid>
                        <div className={classes.divider} />
                        {itens.map((item) => {
                          return (
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Typography className={classes.titleConfigCard}>
                                  {item.descricao}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Box
                                  width="100%"
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Typography className={classes.titleConfigCard}>
                                    {item.parametro}
                                  </Typography>
                                  <div style={{ cursor: 'pointer' }} onClick={() => {
                                    setItem(item)
                                  }}>
                                    <EditarIcon tipo='BUTTON' />
                                  </div>
                                </Box>
                              </Grid>
                            </Grid>
                          )
                        })}
                      </Card>
                    </Grid>
                  </Grid>
                </div>

                {/* Fim das config */}
              </Grid>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
