
import { EnderecoModel } from "./endereco-model";
import { RedeModel } from "./rede-model";

export class LojaPedidoModel {
    constructor(
        public id: string = '',
        public nome: string = '',
        public cnpj: string = '',
        public status: string = '',
        public endereço: EnderecoModel = new EnderecoModel(),
        public rede: RedeModel = new RedeModel(),
    ) {}
}