import { Button, Grid, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useCallback, useEffect, useRef } from 'react';
import { useToastSaurus } from '../../../../service/app/use-cases';
import { DefaultFormRefs } from '../../form/utils';
import { usePatchValidacaoCredencial } from '../../../../data/api/gestao/credenciais-dados/patch-validacao-credencial';
import { VoltarIcon } from '../../icons';
import { useStyles } from './dialog-validacao-ifood-codigo-styles';
import { FormCadastroCredencial } from '../../form/master/cadastro-credencial/form-cadastro-credencial';
import { AplicacaoModel } from '../../../../model/api/aplicacao/aplicacao-model';
import { LojaModel } from '../../../../model/api/loja/loja-model';
import { CredenciaisModelIfood } from '../../../../model/api/credenciais/credenciais-model-ifood';
import { CredenciaisDadosModel } from '../../../../model/api/credenciais/credenciais-dados-model';
import { useGetRegistroCredencial } from '../../../../data/api/gestao/credenciais-dados/get-registro-credencial';
import { usePutEditarCredenciais } from '../../../../data/api/gestao/credenciais-dados/put-editar-credenciais';
import { useGetLojaById } from '../../../../data/api/gestao/lojas/get-loja-by-id';

interface DialogValidacaoIfoodCodProps {
  openned: boolean;
  closeModal: () => void;
  ativo: boolean;
  aplicacao: AplicacaoModel;
  loja: LojaModel;
  credencialDados: CredenciaisDadosModel;
}

//Segundo dialog

export const DialogValidacaoIfoodCod = ({
  openned,
  closeModal,
  aplicacao,
  loja,
  credencialDados,
  ativo
}: DialogValidacaoIfoodCodProps) => {
  const classes = useStyles()
  const { showToast } = useToastSaurus()
  const { patchValidacaoCredencial, carregando } = usePatchValidacaoCredencial();
  const { getRegistroCredencial } = useGetRegistroCredencial();
  const { putEditarCredenciais } = usePutEditarCredenciais();
  const { getLojaById } = useGetLojaById();

  const cadCadastroCredencialFormRef =
    useRef<DefaultFormRefs<CredenciaisModelIfood>>(null);

  useEffect(() => {
    cadCadastroCredencialFormRef.current?.fillForm(
      new CredenciaisModelIfood()
    )
  }, [])

  const sincronizacaoWrapper = useCallback(async (model: CredenciaisDadosModel, patch: CredenciaisModelIfood) => {
    try {
      model = {
        ...model,
        credenciar: model.credenciar,
        codigoAplicacao: aplicacao.codigoAplicacao,
        id: model.id,
        tpSituacao: credencialDados.tpSituacao
      }
      patch = {
        ...patch,
        id: model.id,
        credenciais: model.credenciais,
        ativo: ativo,
        codigoAplicacao: model.codigoAplicacao,
        tpSituacao: model.tpSituacao,
        documento: loja.documento,
        urlRedirectLogin: model.urlRedirectLogin
      }

      const registroCredencial = await getRegistroCredencial(loja.id);
      if (registroCredencial.erro) throw registroCredencial.erro;

      const put = await putEditarCredenciais(loja.id, model.id, model);
      if (put.erro) throw put.erro;

      const patchIfood = await patchValidacaoCredencial(loja.id, model.id, patch);
      if (patchIfood.erro) throw patchIfood.erro;

      showToast('success', "Credencial registrada com sucesso!");
      closeModal()
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [aplicacao.codigoAplicacao, credencialDados.tpSituacao, ativo, loja.documento, loja.id, getRegistroCredencial, putEditarCredenciais, patchValidacaoCredencial, showToast, closeModal]);

  const handleSubmit = useCallback(
    async (model: CredenciaisModelIfood) => {
      try {
        const registroCredencial = await getLojaById(loja.id);
        if (registroCredencial.erro) throw registroCredencial.erro;

        model = {
          ...model,
          credenciais: model.credenciais,
          codigoAplicacao: aplicacao.codigoAplicacao,
          id: model.id,
          tpSituacao: aplicacao.tpSituacao
        }
        const credenciaisDadosCopy = { ...credencialDados, credenciais: model.credenciais, id: credencialDados.id, credenciar: new CredenciaisDadosModel().credenciar }

        await sincronizacaoWrapper(credenciaisDadosCopy, model)
      } catch (e: any) {
        showToast('error', e.message);
      }
    }, [aplicacao.codigoAplicacao, aplicacao.tpSituacao, credencialDados, getLojaById, loja, showToast, sincronizacaoWrapper])


  return (
    <DialogSaurus
      aberto={openned}
      titulo='Autenticação Ifood'
      tamanho="sm">
      <Typography className={classes.title}>
        Cole aqui o código de autorização do aplicativo no portal do Ifood:
      </Typography>
      <FormCadastroCredencial
        credencialDados={credencialDados}
        ativo={ativo}
        lojaId={loja.id}
        ref={cadCadastroCredencialFormRef}
        showLoading={true}
        loading={carregando}
        onSubmit={handleSubmit}
        model={aplicacao}
      />
      <div>
        <Grid container spacing={2} style={{
          justifyContent: 'end',
          alignItems: 'center'
        }}>
          <Grid item xs={12} md={3}>
            <Button
              variant='outlined'
              color='primary'
              onClick={closeModal}
              fullWidth
            >
              <VoltarIcon tipo='BUTTON' />
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              disabled={carregando}
              variant="contained"
              color="primary"
              fullWidth
              onClick={async () => {
                cadCadastroCredencialFormRef.current?.submitForm();
              }}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </div>
    </DialogSaurus>
  );
};
