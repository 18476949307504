import { ChangeEvent } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { TextFieldSaurus } from '../../../../controles/inputs';
import { useStyles } from '../../../../cards/card-credencial-homologacao/card-credencial-homologacao-styles';
import { TesteIcon } from '../../../../icons';
import { SituacaoPedidoMock } from '../../../../../../data/mocks/situacao-pedido-mock';
import { SelectSaurus } from '../../../../controles/selects/select-saurus/select-saurus';

interface Props {
  carregando: boolean;
  handleDateInicioChange: (e: any) => void;
  handleDateFimChange: (e: any) => void;
  onChangeAplicacao: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  status: number;
  setStatus: (value: React.SetStateAction<number>) => void;
  codAplicacao: string;
  selectedDateInicio: string;
  selectedDateFim: string;
  redirectToResult: () => void;
  scrollToTarget: () => void;
  BuscarSttsPedidoSubmit: () => Promise<void>
}

export const TesteStatusPedido = ({ carregando, handleDateFimChange, handleDateInicioChange, onChangeAplicacao, setStatus, status, codAplicacao, selectedDateInicio, selectedDateFim, redirectToResult, scrollToTarget, BuscarSttsPedidoSubmit }: Props) => {
  const classes = useStyles();


  return (
    <>
      <form>
        <div className={classes.divider} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextFieldSaurus
              tipo="DATA"
              fullWidth
              value={selectedDateInicio}
              disabled={carregando}
              showStartAdornment={true}
              allowSubmit={false}
              autoComplete={'off'}
              label="Data Inicial"
              onChange={(e) => handleDateInicioChange(e)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldSaurus
              tipo="DATA"
              fullWidth
              value={selectedDateFim}
              disabled={carregando}
              showStartAdornment={true}
              allowSubmit={false}
              autoComplete={'off'}
              label="Data Final"
              placeholder=""
              onChange={(e) => handleDateFimChange(e)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextFieldSaurus
              value={codAplicacao}
              tipo='TEXTO'
              variant="outlined"
              fullWidth
              label="Código Aplicação"
              placeholder="Ex: 366487"
              onChange={onChangeAplicacao}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectSaurus
              conteudo={SituacaoPedidoMock}
              variant="outlined"
              label='Status'
              name='status'
              disabled={false}
              allowSubmit
              select
              fullWidth
              onChange={(event: any) => {
                if (event) {
                  const newStatus = SituacaoPedidoMock.filter(
                    (item) => item.Key === event.target.value,
                  )[0]?.Key;
                  setStatus(newStatus);
                }
              }}
              value={status}
            />
          </Grid>

          <Grid item md={12} xs={12}>
            <Button
              disabled={carregando}
              variant="contained"
              color="primary"
              className={classes.button}
              fullWidth
              onClick={() => {
                scrollToTarget();
                setTimeout(redirectToResult, 1000);
                BuscarSttsPedidoSubmit();
              }}>
              <TesteIcon tipo="BUTTON_PRIMARY" />
              <Typography style={{ fontWeight: 700, fontSize: 16 }}>
                Buscar Pedido Status
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </form>

    </>
  );
};