export const formatarCPFCNPJ = (value: string) => {
 return maskCnpjCpf(value)
}

function maskCpf(value: string) {
 let valor = value.replace(/\D/g, "").substring(0, 11);

 valor = valor.replace(/(\d{3})(\d)/, "$1.$2");
 valor = valor.replace(/(\d{3})(\d)/, "$1.$2");
 valor = valor.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

 return valor;
}
function maskCnpj(value: string) {
 let valor = value.replace(/\D/g, "").substring(0, 14);
 valor = valor.replace(/^(\d{2})(\d)/, "$1.$2");
 valor = valor.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
 valor = valor.replace(/\.(\d{3})(\d)/, ".$1/$2");
 valor = valor.replace(/(\d{4})(\d)/, "$1-$2");
 return valor;
}
function maskCnpjCpf(value: string) {
 var valor = value.replace(/\D/g, "");
 if (valor.length > 11) {
     return maskCnpj(value);
 } else if (valor.length <= 11) {
     return maskCpf(value);
 } else {
     return valor;
 }
}