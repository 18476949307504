import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Grid, Slide, useTheme } from '@material-ui/core';
import { useThemeQueries } from '../../../../../../theme';
import { useToastSaurus } from '../../../../../../service/app/use-cases';
import { TextFieldSaurus } from '../../../../../components/controles/inputs';
import { SelectSaurus } from '../../../../../components/controles/selects/select-saurus/select-saurus';
import { useGetLojas } from '../../../../../../data/api/gestao/lojas/get-lojas';
import { KeyValueModel } from '../../../../../../model';
import { LojaModel } from '../../../../../../model/api/loja/loja-model';
import { useGetAplicacao } from '../../../../../../data/api/gestao/aplicacao/get-aplicacao';
import { isEmpty } from 'lodash';
import { ProcurarIcon } from '../../../../../components/icons';
import { PesquisarSincronizacaoProps } from './pesquisa-sincronizacao-props';
import { useStyles } from './pesquisa-sincronizacao-styles';
import { formatarCPFCNPJ } from '../../../../../../utils/cpfcnpj-format';
import { AplicacaoModel } from '../../../../../../model/api/aplicacao/aplicacao-model';
import { toDate } from '../../../../../../utils/to-date';

const dataFormatada = (val: Date) => {
  const data = toDate(val) ?? new Date(val);
  const dia = data?.getDate();
  const mes = data?.getMonth() + 1;
  const ano = data?.getFullYear();

  const dataFormated = `${ano}-${mes?.toString().length === 1 ? '0' + mes : mes
    }-${dia?.toString().length === 1 ? '0' + dia : dia}`;
  return dataFormated;
};

export const PesquisaSincronizacao = (props: PesquisarSincronizacaoProps) => {
  const classes = useStyles()
  const theme = useTheme();
  const { showToast } = useToastSaurus()
  const { isMobile } = useThemeQueries();
  const refInput = useRef<HTMLDivElement | null>(null);
  const refLojas = useRef<LojaModel[]>([])
  const aplicacoesRef = useRef<AplicacaoModel[]>([])
  const { getLojas, carregando } = useGetLojas();
  const { getAplicacao } = useGetAplicacao()
  const [lojas, setLojas] = useState<KeyValueModel[]>([])
  const [aplicacoes, setAplicacoes] = useState<KeyValueModel[]>([])
  const [aplicacao, setAplicacao] = useState('');
  const [lojaNome, setLojaNome] = useState('');
  const [lojaId, setLojaId] = useState('');
  const [dataInicial, setDataInicial] = useState<string>(() => {
    const data = new Date()
    data.setDate(data.getDate() - 7)
    return dataFormatada(data)
  });
  const [dataFinal, setDataFinal] = useState<string>('');

  useEffect(() => {
    if (props.isOpened && !isMobile) refInput.current?.focus();
    else {
      setLojaNome('');
      setLojaId('');
      setAplicacao('');
      setDataFinal('');
      setDataInicial('');
    }
  }, [isMobile, props.isOpened]);

  const pesquisar = useCallback(
    (event: any) => {
      event.preventDefault();
      props.onPesquisar({ lojaId: lojaId, lojaNome: lojaNome, aplicacao: aplicacao, dataFinal: dataFinal, dataInicio: dataInicial });
    },
    [aplicacao, dataFinal, dataInicial, lojaId, lojaNome, props],
  );

  const onChangeLoja = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setLojaId(event.target.value);
      setLojaNome(lojas.find(loja => loja.Key === event.target.value)?.Value || '')
      const loja = refLojas.current.find(lj => lj.id === event.target.value)
      const apps = aplicacoesRef.current.filter((app: any) => {
        return loja?.credenciais.reduce<boolean>((prev, curr) => curr.codigoAplicacao === app.codigoAplicacao || prev ? true : false, false)
      })
      const keyValueApp = apps.map((app: any) => new KeyValueModel(app.codigoAplicacao, app.descricaoAplicacao))
      setAplicacoes(keyValueApp)
    }, [lojas]);

  const onChangeAplicacao = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setAplicacao(aplicacoes.find(app => app.Key === event.target.value)?.Key || '')
    }, [aplicacoes]);

  const onChangeDataInicial = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setDataInicial(event.target.value)
    }, []);

  const onChangeDataFinal = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setDataFinal(event.target.value)
    }, []);

  const lojasWrapper = useCallback(async () => {
    try {
      const res = await getLojas()
      if (res.erro) throw res.erro
      const resApp = await getAplicacao()
      if (resApp.erro) throw resApp.erro

      const lojas = res.resultado?.data.lojas as LojaModel[]
      const apps = resApp.resultado?.data as AplicacaoModel[]

      aplicacoesRef.current = apps
      refLojas.current = lojas

      const keyValueLojas = lojas.map((loja) =>
        new KeyValueModel(
          loja.id,
          isEmpty(loja.razaoSocial) ? formatarCPFCNPJ(loja.documento) : loja.razaoSocial
        )
      );
      setLojas(keyValueLojas);

      if (keyValueLojas.length > 0) {
        const [valueLoja] = keyValueLojas
        setLojaId(valueLoja.Key);
        setLojaNome(valueLoja.Value)

        const loja = refLojas.current.find(lj => lj.id === valueLoja.Key)
        const appsKey = apps.filter((app: any) => {
          return loja?.credenciais.reduce<boolean>((prev, curr) => curr.codigoAplicacao === app.codigoAplicacao || prev ? true : false, false)
        })
        const keyValueApp = appsKey.map((app: any) => new KeyValueModel(app.codigoAplicacao, app.descricaoAplicacao))
        setAplicacoes(keyValueApp)
      }


    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [getAplicacao, getLojas, showToast])

  useEffect(() => {
    lojasWrapper()
  }, [lojasWrapper])

  return (
    <>
      <Slide in={props.isOpened} direction="down" mountOnEnter unmountOnExit timeout={300}>
        <form onSubmit={pesquisar} style={{
          paddingLeft: 8,
          paddingRight: 8,
        }}>
          <Grid container className={classes.root} spacing={2}>
            <Grid item lg={3} md={6} xs={12}>
              <SelectSaurus
                conteudo={lojas}
                style={{ height: '40px' }}
                variant="outlined"
                label='Loja'
                name='lojaNome'
                size="small"
                allowSubmit
                select
                fullWidth
                onChange={onChangeLoja}
                value={lojaId}
              />
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <SelectSaurus
                disabled={carregando || isEmpty(aplicacoes)}
                conteudo={aplicacoes}
                style={{ height: '40px' }}
                variant="outlined"
                label='Aplicação'
                size="small"
                name='aplicacao'
                allowSubmit
                select
                value={aplicacao}
                onChange={onChangeAplicacao}
              />
            </Grid>
            <Grid item lg={2} md={6} xs={6}>
              <TextFieldSaurus
                tipo="DATA"
                name='dataInicial'
                fullWidth={true}
                size="small"
                showStartAdornment={true}
                autoComplete={'on'}
                label="Data Inicial"
                value={dataInicial}
                onChange={onChangeDataInicial}
                adornmentColor={theme.palette.text.disabled}
              />
            </Grid>
            <Grid item lg={2} md={6} xs={6}>
              <TextFieldSaurus
                tipo="DATA"
                fullWidth={true}
                name='dataFinal'
                size="small"
                showStartAdornment={true}
                autoComplete={'on'}
                label="Data Final"
                value={dataFinal}
                onChange={onChangeDataFinal}
              />
            </Grid>
            <Grid item lg={2} md={12} xs={12}>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  <ProcurarIcon tipo="BUTTON_PRIMARY" />
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Slide>
    </>
  );
}
