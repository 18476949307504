import { makeStyles } from "@material-ui/core";

export const useCargasProdutosStyles = makeStyles((theme) => ({
    modal: {
        width: '100%',
        maxWidth: '1300px',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    root: {
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        flex: "1",
    },
    content: {
        flex: "1",
        display: "flex",
        flexDirection: "column",
    },
    titleSinc: {
        color: '#696969',
        fontSize: '15px',
        padding: 15,
        [theme.breakpoints.down("xs")]: {
            padding: 0,
            margin: 0,
            fontSize: '12px',
        },
    },
    contentAbas: {
        paddingTop: theme.spacing(1),
        boxShadow: '0px 5px 5px rgba(0,0,0,0.05) inset',
        marginLeft: theme.spacing(2) * -1,
        marginRight: theme.spacing(2) * -1,
    },
    contentForms: {
        flex: "1 1 auto",
        padding: theme.spacing(2),
        height: "100%",
        overflowX: "hidden",
        overflowY: "auto",
        "& * > form": {
            visibility: "visible",
        },
        "&  .tabs-class": {
            visibility: "visible",
        },
    },
    contentFormsLoading: {
        "& * > form": {
            visibility: "hidden",
        },

        "&  .tabs-class": {
            visibility: "hidden",
        },
    },
    acoes: {
        flex: "0 0 auto",
        padding: theme.spacing(1),
    },
    obs: {
        width: '500px',
        background: 'rgb(255 244 215)',
        borderRadius: '8px',
        padding: '30px',
        margin: '25px 50px'
    },
    containerSituacao: {
        width: '100%',
        height: '28px',
        maxWidth: '83px',
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    titleSituacao: {
        color: '#fff',
        fontSize: '12px',
        fontWeight: 700,
        [theme.breakpoints.down("xs")]: {
            fontSize: '10px',
            fontWeight: 700,
        },
    },

    aplicacaoSub: {
        width: '100%',
        display: 'flex',
        padding: '40px 0'
    },
    titleAlert: {
        color: '#5F5F5F',
        fontWeight: 700,
        fontSize: 16
    },
    titleSub: {
        color: '#5F5F5F',
        padding: '10px 10px',
        fontSize: '16px',
        fontWeight: 700,
        [theme.breakpoints.down("xs")]: {
            padding: 0,
            margin: 0,
            fontSize: '11px'
        },
    },
    buttonRemover: {
        border: '1px solid #F44336',
        color: theme.palette.error.main,
        "&:hover": {
            border: '1px solid #FF0000',
        },
        "&:disabled": {
            backgroundColor: theme.palette.error.light
        },
        marginRight: 8,
        '& svg': {
            margin: 0
        }
    },
    containerStatus: {
        padding: '10px 4px',
        margin: '0 0 27px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    situacao: {
        width: '100%',
        height: '100%',
        maxWidth: '100px',
        textAlign: 'center',
        borderRadius: '5px',
        margin: '0 10px'
    },
    titleStts: {
        color: '#fff',
        fontSize: '12px',
        fontWeight: 700
    },
    
    onOff: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '23px 10px 0 0'
    },
    contianerTotal: {
        width: '100%',
        height: '100%',
        margin: '9px 19px',
        background: '#fefefd',
        boxShadow: '0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.12)',
        borderRadius: '8px',
        padding: '12px'
    },
    containerBtnDownload: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px',
    },
    titleTotalProdutos: {
        color: '#5333ED',
        fontSize: '16px',
        fontWeight: 600
    },
    titleData: {
        color: '#5333ED',
        fontSize: '16px',
        fontWeight: 600
    },
    containerHistorico: {
        margin: '12px 0',
        padding: '10px'
    },
    divider: {
        width: '100%',
        height: '1px',
        marginBottom: 15,
        borderRadius: '6px',
        backgroundColor: '#E6E6E6'
    },

    dividerHistorico: {
        width: '100%',
        height: '2px',
        marginTop: 15,
        borderRadius: '6px',
        backgroundColor: '#E6E6E6'
    },
}));
