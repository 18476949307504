import { Button, Grid, Typography, useTheme } from "@material-ui/core";
import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { useCadastros } from "../../../../../../service/app/providers/cadastros-provider";
import { ButtonModalHeader } from "../../../../controles/buttons/button-modal-header";
import { DefaultFormRefs } from "../../../../form/utils";
import { SalvarNovoIcon, VoltarIcon } from "../../../../icons";
import { ModalHeader } from "../../../modal-header";
import { CircularLoading } from "../../../..";
import { AplicacaoModel } from "../../../../../../model/api/aplicacao/aplicacao-model";
import semImagem from '../../../../../../assets/img/sem-imagem.jpg'
import { LojaModel } from "../../../../../../model/api/loja/loja-model";
import { CredenciaisDadosModel } from "../../../../../../model/api/credenciais/credenciais-dados-model";
import { useModalStyles } from "../../../../utils/modals/modal-styles";
import { useCadastroAppStyles } from '../../../../form/master/cadastro-aplicacao/form-cadastro-aplicacao-style';
import { usePutEditarCredenciais } from "../../../../../../data/api/gestao/credenciais-dados/put-editar-credenciais";
import { useToastSaurus } from "../../../../../../service/app/use-cases";
import { useGetRegistroCredencial } from "../../../../../../data/api/gestao/credenciais-dados/get-registro-credencial";
import { FormEditarCredencial } from "../../../../form/master/editar-credencial/form-editar-credencial";
import { DialogEditarValidacaoIfood } from "../../../../dialog/dialog-editar-validacao-ifood/dialog-editar-validacao-ifood";
import { DialogEditarValidacaoIfoodCod } from "../../../../dialog/dialog-editar-validacao-ifood-codigo/dialog-editar-validacao-ifood-codigo";
import { ExcluirIcon } from "../../../../icons/excluir";
import { useDeleteRemoverCredenciais } from "../../../../../../data/api/gestao/credenciais-dados/delete-remover-credenciais";
import { useConfirm } from "material-ui-confirm";
import { EnumTpSituacaoIntegracao } from "../../../../../../model/enums/enum-tp-situacao-integracao";

interface NovaCredencialProps {
  aplicacao: AplicacaoModel;
  loja: LojaModel;
  credencialDados: CredenciaisDadosModel;
  openned: boolean;
}
//Essa é a tela para a edição de uma credencial

export const EditarAplicacaoCliente = ({ aplicacao, loja, credencialDados }: NovaCredencialProps) => {
  const classes = useModalStyles();
  const classe = useCadastroAppStyles();
  const { showToast } = useToastSaurus();
  const { fecharCadastroNovaAplicacao } = useCadastros();
  const { putEditarCredenciais, carregando: carregandoPut } = usePutEditarCredenciais();
  const { deleteRemoverCredenciais, carregando: carregandoDelete } = useDeleteRemoverCredenciais()
  const { getRegistroCredencial } = useGetRegistroCredencial();
  const [ativos, setAtivos] = useState<boolean>(credencialDados.ativo);
  const [auto, setAuto] = useState<boolean>(credencialDados.confirmacaoAutomaticaAtivada);
  const [aberto, setAberto] = useState(0);
  const confirm = useConfirm()
  const theme = useTheme()

  const cadCadastroAplicacaoFormRef =
    useRef<DefaultFormRefs<CredenciaisDadosModel>>(null);

  const carregando = [carregandoPut, carregandoDelete].includes(true)

  useEffect(() => {
    cadCadastroAplicacaoFormRef.current?.fillForm(
      credencialDados
    )
  }, [credencialDados])

  const validarCredencial = useCallback(
    async (model: CredenciaisDadosModel) => {
      model = { ...model, credenciar: new CredenciaisDadosModel().credenciar, ativo: ativos, id: model.id, confirmacaoAutomaticaAtivada: auto, tpSituacao: ativos ? EnumTpSituacaoIntegracao.IntegracaoHabilitada : EnumTpSituacaoIntegracao.IntegracaoDesabilitada }
      const lojaCredencialId = loja.credenciais.find((credId: CredenciaisDadosModel) => credId.id === model.id)?.id

      const ret = await putEditarCredenciais(loja.id, lojaCredencialId!, model);
      if (ret.erro) {
        throw ret.erro;
      }
      showToast('success', "Credencial Atualizada!")
    }, [ativos, auto, loja.credenciais, loja.id, putEditarCredenciais, showToast]);

  const handleSubmit = useCallback(
    async (model: CredenciaisDadosModel) => {
      try {
        model = { ...model, credenciar: model.credenciar, ativo: ativos, confirmacaoAutomaticaAtivada: auto }
        const res = await getRegistroCredencial(loja.id);
        if (res.erro) throw res.erro;

        await validarCredencial(model)
        fecharCadastroNovaAplicacao()
      } catch (e: any) {
        showToast('error', e.message);
      }
    }, [ativos, auto, fecharCadastroNovaAplicacao, getRegistroCredencial, loja.id, showToast, validarCredencial])

  const onCloseClick = useCallback(() => {
    fecharCadastroNovaAplicacao()
  }, [fecharCadastroNovaAplicacao]);

  const removeCredencial = useCallback(async (idLoja: string, idCredencial: string) => {
    try {
      const res = await deleteRemoverCredenciais(idLoja, idCredencial)
      if (res.erro) throw res.erro

      showToast('success', 'Credencial deletada!')

      fecharCadastroNovaAplicacao();
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [deleteRemoverCredenciais, showToast, fecharCadastroNovaAplicacao])

  return (
    <>
      <DialogEditarValidacaoIfood
        closeModal={() => setAberto(0)}
        setAberto={setAberto}
        openned={aberto === 1}
        aplicacao={aplicacao}
        loja={loja}
        credencialDados={credencialDados}
      />
      <DialogEditarValidacaoIfoodCod
        ativo={ativos}
        credencialDados={credencialDados}
        aplicacao={aplicacao}
        loja={loja}
        closeModal={() => setAberto(0)}
        openned={aberto === 2}
      />
      <div className={classes.root}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <ModalHeader
          title={"Editar Credencial"}
          leftArea={
            <ButtonModalHeader
              tooltip="Voltar"
              icon={<VoltarIcon tipo="MODAL_HEADER" />}
              onClick={onCloseClick}
            />
          }
        />
        <div className={classes.content} style={{
          position: 'relative',
          maxHeight: '85vh'
        }}>
          <div className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}>
            <Grid style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }} item xs={12}>
              <img
                style={{ maxWidth: '150px', borderRadius: theme.shape.borderRadius }}
                src={aplicacao?.uriImagem}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = semImagem;
                }} alt={aplicacao.descricaoAplicacao} />
            </Grid>
            <FormEditarCredencial
              ativo={ativos}
              setAberto={setAberto}
              loja={loja}
              aplicacaoDados={aplicacao}
              ref={cadCadastroAplicacaoFormRef}
              showLoading={true}
              loading={false}
              onSubmit={handleSubmit}
              model={credencialDados}
            />
            <Grid item xs={12}
              style={{
                background: '#F6F6F666',
                padding: '20px 16px',
                borderRadius: 10
              }}>
              <Typography
                style={{
                  margin: '0 0 8px 0',
                  fontSize: '16px',
                  color: '#858585',
                  fontWeight: 600
                }}
              >
                Confirmação automática dos pedidos recebidos
              </Typography>
              <Button
                disabled={auto}
                onClick={() => setAuto(true)}
                style={{
                  color: !auto ? '#A9A9A9' : "#5333ED",
                  boxShadow: auto ? '0px 0px 4px rgba(0, 0, 0, 0.1)' : 'none',
                  borderRadius: 5,
                  marginRight: 8,
                  fontSize: '14px',
                  borderColor: !auto ? '#F0F0F0' : "#5333ED"
                }}
                variant='outlined'>
                Ativo
              </Button>
              <Button
                disabled={!auto}
                onClick={() => setAuto(false)}
                style={{
                  color: auto ? '#A9A9A9' : "#5333ED",
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 4px',
                  borderRadius: 7,
                  fontSize: '14px',
                  borderColor: auto ? '#F0F0F0' : "#5333ED"
                }}
                variant='outlined'
              >
                Inativo
              </Button>
            </Grid>
            <Grid item xs={12}
              style={{
                background: '#F6F6F666',
                padding: theme.spacing(1, 2),
                borderRadius: theme.shape.borderRadius,
                margin: theme.spacing(2, 0)
              }}>
              <Typography
                style={{
                  margin: '0 0 8px 0',
                  fontSize: '16px',
                  color: '#858585',
                  fontWeight: 600
                }}
              >
                Situação da Credencial
              </Typography>
              <Button
                disabled={ativos}
                onClick={() => setAtivos(true)}
                style={{
                  color: !ativos ? '#A9A9A9' : "#5333ED",
                  boxShadow: ativos ? '0px 0px 4px rgba(0, 0, 0, 0.1)' : 'none',
                  borderRadius: 5,
                  marginRight: 8,
                  fontSize: '14px',
                  borderColor: !ativos ? '#F0F0F0' : "#5333ED"
                }}
                variant='outlined'>
                Ativa
              </Button>
              <Button
                disabled={!ativos}
                onClick={() => setAtivos(false)}
                style={{
                  color: ativos ? '#A9A9A9' : "#5333ED",
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 4px',
                  borderRadius: 7,
                  fontSize: '14px',
                  borderColor: ativos ? '#F0F0F0' : "#5333ED"
                }}
                variant='outlined'>
                Inativa
              </Button>
              <Typography className={classe.textFunc}>
                Esta função ativa ou desativa a credencial sem excluir para o usuário quando for necessário.
              </Typography>
            </Grid>
          </div>
        </div>

        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Button
                className={classes.buttonRemover}
                disabled={carregando}
                onClick={() => {
                  confirm({
                    title: 'Excluir credencial',
                    description: 'Deseja confirmar essa ação?',
                    confirmationText: 'Confirmar',
                    cancellationText: 'Cancelar'
                  }).then(() => {
                    removeCredencial(loja.id, credencialDados.id)
                  })
                }}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
              >
                <ExcluirIcon />
                Excluir
              </Button>
            </Grid>
            <Grid item xs={12} md={7}>
              <Button
                disabled={carregando}
                onClick={() => cadCadastroAplicacaoFormRef?.current?.submitForm()}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarNovoIcon tipo="BUTTON_PRIMARY" />
                Salvar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}