import { EnumSituacaoProcessamento } from "../model/enums/enum-situacao-processamento"



export function alterarCorStatusSituacao(numero: number) {
    switch (numero) {
        case EnumSituacaoProcessamento.NOTIFICADO:
            return '#5333ED'
        case EnumSituacaoProcessamento.RECEBIDO:
            return '#FFBC00'
        case EnumSituacaoProcessamento.PROCESSANDO:
            return "#FFBC00"
        case EnumSituacaoProcessamento.FINALIZADO:
            return '#7AE28C'
        case EnumSituacaoProcessamento.CANCELADO:
            return "#F44336"
        case EnumSituacaoProcessamento.INVALIDA:
            return "#F44336"
        default:
            return '';
    }
}