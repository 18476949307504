import { useThemeQueries } from "../../../../../../theme";
import { DefaultModal } from "../../../../default-modal";
import { AdicionarAplicacaoHomologacao } from "./adicionar-aplicacao-homologacao/adicionar-aplicacao-homologacao";

type Props = {
  openned: boolean;
}

export const ModalAdcAplicacaoAdminHomologacao = ({ openned }: Props) => {
  const { isMobile } = useThemeQueries();


  return (
    <DefaultModal
      minWidth={isMobile ? '0' : "600px"}
      open={openned || false}
      variant={"temporary"}
      anchor="right"
    >
      <AdicionarAplicacaoHomologacao />
    </DefaultModal>
  );
};
