import { Button, Grid } from "@material-ui/core";
import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { useGetLojas } from "../../../../../../data/api/gestao/lojas/get-lojas";
import { usePatchIniciarLoja } from "../../../../../../data/api/gestao/lojas/patch-iniciar-loja";
import { usePostAdicinarLoja } from "../../../../../../data/api/gestao/lojas/post-adicionar-loja";
import { AdicionarLojaModel } from "../../../../../../model/api/loja/adicionar-loja-model";
import { LojaModel } from "../../../../../../model/api/loja/loja-model";
import { useCadastros } from "../../../../../../service/app/providers/cadastros-provider";
import { useToastSaurus } from "../../../../../../service/app/use-cases";
import { picker } from "../../../../../../utils/picker";
import { ButtonModalHeader } from "../../../../controles/buttons/button-modal-header";
import { FormNovasLojas } from "../../../../form/master/nova-loja/form-nova-loja";
import { DefaultFormRefs } from "../../../../form/utils";
import { SalvarNovoIcon, VoltarIcon } from "../../../../icons";
import { useModalStyles } from "../../../../utils/modals/modal-styles";
import { ModalHeader } from "../../../modal-header";

export const NovaLojaCadastro = () => {
  const { showToast } = useToastSaurus();
  const { patchIniciarLoja, carregando: carregandoPatch } = usePatchIniciarLoja();
  const { getLojas, carregando: carregandoGet } = useGetLojas();
  const { postAdicionarLoja, carregando: carregandoPost } = usePostAdicinarLoja();
  const { fecharCadastroNovaLoja } = useCadastros();
  const classes = useModalStyles();
  const [loja, setLoja] = useState<LojaModel>();
  const carregando = carregandoPatch || carregandoGet || carregandoPost;

  const lojaWrapper = useCallback(async () => {
    try {
      const resPatch = await patchIniciarLoja()
      if (resPatch.erro) throw resPatch.erro

      const res = await getLojas()
      setLoja(res.resultado?.data.lojas)
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [getLojas, patchIniciarLoja, showToast])

  useEffect(() => {
    lojaWrapper()
  }, [lojaWrapper])

  const cadNovaLojaFormRef =
    useRef<DefaultFormRefs<AdicionarLojaModel>>(null);

  useEffect(() => {
    cadNovaLojaFormRef.current?.fillForm(
      new LojaModel(),
    )
  }, [])

  const adicionarLoja = useCallback(
    async (model: AdicionarLojaModel) => {
      const ret = await postAdicionarLoja(model);
      if (ret.erro) {
        throw ret.erro;
      }
    }, [postAdicionarLoja]);

  const handleSubmit = useCallback(async (model: AdicionarLojaModel) => {
    try {
      const NovoModuloToCreate = picker<AdicionarLojaModel>(
        model,
        new AdicionarLojaModel(),
      );
      await adicionarLoja(NovoModuloToCreate);
      fecharCadastroNovaLoja()
      showToast('success', "Nova Loja Adicionada!")
    } catch (e: any) {
      showToast('error', e.message);
      cadNovaLojaFormRef.current?.resetForm()
    }
  }, [fecharCadastroNovaLoja, adicionarLoja, showToast])

  const onCloseClick = useCallback(() => {
    fecharCadastroNovaLoja()
  }, [fecharCadastroNovaLoja]);

  return (
    <>
      <div className={classes.root}>
        <ModalHeader
          title={"Nova Loja"}
          leftArea={
            <ButtonModalHeader
              tooltip="Voltar"
              icon={<VoltarIcon tipo="MODAL_HEADER" />}
              onClick={onCloseClick}
            />
          }
        />

        <div className={classes.content}>
          <div className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}>
            <FormNovasLojas
              model={loja}
              ref={cadNovaLojaFormRef}
              showLoading={false}
              loading={carregando}
              onSubmit={handleSubmit}
            />
          </div>
          <div className={classes.acoes}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Button
                  disabled={carregando}
                  onClick={() => { cadNovaLojaFormRef?.current?.submitForm() }}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  <SalvarNovoIcon tipo="BUTTON_PRIMARY" />
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  )
}

