import { Button, Fade, Tooltip } from "@material-ui/core";
import { AplicacaoModel } from "../../../../model/api/aplicacao/aplicacao-model";
import semImagem from '../../../../assets/img/sem-imagem.jpg'
import { useCadastros } from "../../../../service/app/providers/cadastros-provider";
import { useCardAplicacaoDashboardStyles } from "./card-aplicacao-dashboard-admin-prod-styles";
import { useCallback } from "react";

type Props = {
  app: AplicacaoModel;
}

export const CardAplicacaoDashboardAdminProd = ({ app }: Props) => {
  const { abrirEdicaoAplicacaoProd } = useCadastros();
  const classes = useCardAplicacaoDashboardStyles();
  const rota = '/dashboard-admin/'

  const openAdcApp = useCallback(
    (id: string) => {
      abrirEdicaoAplicacaoProd(id, app, rota, true);
    },
    [abrirEdicaoAplicacaoProd, app, rota],
  );

  return (
      <Fade in timeout={900}>
        <div>
          <Tooltip title={app.descricaoAplicacao}>
            <Button
              onClick={() => { openAdcApp(app.id) }}

            >
              <div className={classes.aplicacaoImg}>
                {(app.uriImagem) ?
                  <img src={app.uriImagem} onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = semImagem;
                  }} alt={app.descricaoAplicacao} />
                  :
                  <img src={semImagem} alt={app.descricaoAplicacao} />
                }
              </div>
            </Button>
          </Tooltip>
        </div>
      </Fade>
  )
}

