import { useCallback } from 'react';
import { EnumMenu } from '../../../model';
import { LogoutIcon } from '../icons';
import { ConfiguracoesIcon } from '../icons/configuracoes';
import { DadosContratoIcon } from '../icons/dados-contrato';
import { DadosSincronizacaoIcon } from '../icons/dados-sinconizacao';
import { DashboardIcon } from '../icons/dashboard';
import { PedidoIcon } from '../icons/pedidos';
import { IconsHelperProps } from './icons-helper-props';

export const IconsHelper = (props: IconsHelperProps) => {
  const selectIcon = useCallback(() => {

    switch (props.codigoModulo) {

      case EnumMenu.DASHBOARD:
        return <DashboardIcon />;

      case EnumMenu.DADOS:
        return <DadosContratoIcon />;

      case EnumMenu.SINCRONIZACAO:
        return <DadosSincronizacaoIcon />

        case EnumMenu.PEDIDOS:
        return <PedidoIcon />

      case EnumMenu.CONFIGURACOES_CONFIGURACOES:
        return <ConfiguracoesIcon />

      case EnumMenu.MASTER_SAIR:
        return <LogoutIcon {...props} />;

    }
  }, [props]);

  return <>{selectIcon()}</>;
};
