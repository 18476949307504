
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: theme.spacing(1),
        position: 'relative',
        background: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[5],
        display: 'flex',
        justifyContent: "space-between",
        alignItems: 'center',
        margin: theme.spacing(1, 0),
    },
    containerFinal: {
        display: "flex",
        justifyContent: "space-between",

    },
    btnPesquisa: {
        marginLeft: theme.spacing(2),
        width: "40px",
        minWidth: "40px",
        height: "40px",
        padding: 0,
        "& svg": {
            margin: 0,
        }
    },
    btn: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        width: '100%',
        height: "100%",
    },
}));

