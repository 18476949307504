import { makeStyles } from "@material-ui/core";

export const useButtonPlanoStyle = makeStyles((theme) => ({
  buttonPlanoContainer: {
    [theme.breakpoints.between("xs", "sm")]: {
      width: "8.50vw",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "4.85vw",
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: "3vw",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      width: "2.5vw",
    },
    [theme.breakpoints.up("xl")]: {
      width: "2vw",
    },
  },
}));
