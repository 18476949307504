import { Button, Grid } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useCallback, useState } from 'react';
import { TextFieldSaurus } from '../../controles/inputs';
import { Controller, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { obterSenhaSyncAtual } from '../../../../utils/obter-senha-sync-atual';
import { VoltarIcon } from '../../icons';
import { OkIcon } from '../../icons/ok-icon';
import { PedidosStorageKeys, useSaurusPedidosStorage } from '../../../../service/app/use-cases';

interface DialogEditarValidacaoIfoodProps {
  openned: boolean;
  closeModal: () => void;
}

export const DialogAlterarUsoApi = ({
  openned,
  closeModal,
}: DialogEditarValidacaoIfoodProps) => {

  const { setRegistro } = useSaurusPedidosStorage()

  const [correto, setCorreto] = useState<boolean>(false)

  const {
    handleSubmit,
    control,
    setError,
    formState: {
      errors
    }
  } = useForm<{ senha: string }>({
    defaultValues: {
      senha: ''
    }
  })

  const onSubmit = useCallback((value: { senha: string }) => {
    const { senha } = value
    if (isEmpty(senha)) {
      setError('senha', {
        message: 'A senha não pode estar vazia.'
      })
      return;
    }

    if (senha !== obterSenhaSyncAtual()) {
      setError('senha', {
        message: 'Senha incorreta.'
      })
      return
    }

    setCorreto(true)
  }, [setError])

  const trocaAmbiente = useCallback((ambiente: 1 | 2) => {
    if (ambiente === 1) {
      setRegistro(PedidosStorageKeys.Ambiente, 'prod')
    } else {
      setRegistro(PedidosStorageKeys.Ambiente, 'homo')
    }

    window.location.reload();

    closeModal()
  }, [closeModal, setRegistro])

  return (
    <>
      <DialogSaurus
        aberto={openned}
        titulo='Troca de Ambiente'
        tamanho="xs">
        {!correto ? (

          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name='senha'
                    render={({ field }) => (
                      <TextFieldSaurus
                        {...field}
                        label="Digite a senha"
                        tipo="PASSWORD"
                        error={Boolean(errors.senha && errors.senha.message)}
                        helperText={errors.senha?.message ? errors.senha?.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                  >
                    <OkIcon tipo="BUTTON_PRIMARY" />
                    Confirmar
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={closeModal}
                  >
                    <VoltarIcon tipo="BUTTON" />
                    Voltar
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                onClick={() => trocaAmbiente(1)}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                Produção
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => trocaAmbiente(2)}
                variant="contained"
                size="large"
                color="secondary"
                fullWidth
              >
                Homologação
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogSaurus>
    </>
  );
};
