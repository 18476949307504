import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { AplicacaoModel } from "../../../../model/api/aplicacao/aplicacao-model";

export function usePutAplicacao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();
    const apiUrlHomo = 'https://sauruspedidosapi-staging.azurewebsites.net';

    const putAplicacao = useCallback(
        (idAplicacao: string, model: AplicacaoModel) => {
            return invocarApi({
                url: `/api/v2/aplicacao/${idAplicacao}`,
                method: "PUT",
                data: model,
                baseURL: apiUrlHomo,
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        putAplicacao,
    };
}


