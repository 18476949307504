import { isEmpty } from "lodash";
import React, { ReactNode, useCallback, useContext } from "react";
import { AuthorizationProps, useAuthorization } from "../../../../data/api/gestao/authorization/patch-authorization";
import { TokenSaurusPedidosModel } from "../../../../model/api/gestao/master/token-saurus-pedidos";
import { ApiError } from "../../../../model/app/errors/api-erros";
import { EnumTipoUsuario } from "../../../../model/enums/enum-tipo-usuario";
import { PedidosStorageKeys, useSaurusPedidosStorage, useSaurusPedidosToken } from "../../use-cases";
import { TokenAdministracaoSaurusPedidosModel } from "../../../../model/api/gestao/master/token-administracao-saurus-pedidos";


interface SessaoAtualContextValue {
   usuario?: TokenSaurusPedidosModel;
   admin?: TokenAdministracaoSaurusPedidosModel;
   carregando: boolean;
   sessaoExpirada: () => boolean;
   logar: (email: string, senha: string) => Promise<void>;
   deslogar: (usuarioId: string) => void;
   definirUsuario: (token: string, tokenAdmin: string) => Promise<void>;
   converterToken: (token: string) => TokenSaurusPedidosModel | undefined;
   selecionarCredenciais: (contratoId: string) => Promise<void>;
   tipoUsuario: () => EnumTipoUsuario
}


const SessaoAtualContext = React.createContext<SessaoAtualContextValue>({
   usuario: undefined,
   admin: undefined,
   carregando: false,
   sessaoExpirada: () => false,
   logar: (email: string, senha: string) => {
      return new Promise<void>(() => true)
   },
   deslogar: (usuarioId: string) => { },
   definirUsuario: async () => { },
   converterToken: (tokenCriptografado: string) => {
      return undefined;
   },
   selecionarCredenciais: (contratoId: string) => new Promise<void>(() => { }),
   tipoUsuario: () => EnumTipoUsuario.EnumTipoCliente
});

export interface SessaoAtualProviderProps {
   children: ReactNode;
}

export const useSessaoAtual = () => useContext(SessaoAtualContext);

export const SessaoAtualProvider = ({ children }: SessaoAtualProviderProps) => {
   const {
      getTokenFromStorage,
      getTokenAdmFromStorage,
      convertToken,
      convertTokenAdm,
      isTokenValid,
      persistToken,
      persistContratoSelecionado
   } = useSaurusPedidosToken();
   const { getRegistro } = useSaurusPedidosStorage();
   const [usuario, setarUsuario] = React.useState(getTokenFromStorage());
   const [admin, setarAdmin] = React.useState(getTokenAdmFromStorage());

   const { patchAuthorization, carregando: carregandoApiAutherization } =
      useAuthorization();
   const loading =
      carregandoApiAutherization;

   const sessaoExpirada = useCallback((): boolean => {
      return !isTokenValid(usuario);
   }, [isTokenValid, usuario])

   const converterToken = useCallback(
      (tokenCriptografado: string): TokenSaurusPedidosModel | undefined => {
         return convertToken(tokenCriptografado)
      }, [convertToken])

   const deslogar = useCallback(
      () => {
         persistToken(undefined)
         setarUsuario(undefined)
         setarAdmin(undefined)
      }, [persistToken])

   const selecionarCredenciais = useCallback(
      async (credenciais: string): Promise<void> => {
         persistContratoSelecionado(credenciais);
      },
      [persistContratoSelecionado],
   );

   const definirUsuario = useCallback(
      async (token: string) => {
         const user = convertToken(token);
         if (!isTokenValid(user)) {
           throw new Error('O usuário informado não é válido ou expirou seu acesso. (InvalidToken)');
        }
         if (user?.aud === "administracao") {
            persistToken(token);
            setarUsuario(user);
            setarAdmin(admin);
         } 
         persistToken(token);
         setarUsuario(user);
         setarAdmin(admin);

      },
      [admin, convertToken, isTokenValid, persistToken],
   );


   const tipoUsuario = useCallback(() => {
      const token = getRegistro(PedidosStorageKeys.Token, false)
      const admin = convertTokenAdm(token)
      if (admin !== undefined && !isEmpty(admin)) {
         return EnumTipoUsuario.EnumTipoAdm
      }
      return EnumTipoUsuario.EnumTipoCliente
   }, [convertTokenAdm, getRegistro])

   const logar = React.useCallback(
      async (
         email: string,
         senha: string,
      ): Promise<void> => {

         try {
            const retApi = await patchAuthorization(
               new AuthorizationProps(email, senha),
            );

            //Usuario não confirmado está sem autorização
            if (retApi.statusCode === 401) {
               throw new ApiError(retApi.statusCode, retApi.erro.message);
            }

            if (retApi.erro) {
               throw retApi.erro;
            }
            await definirUsuario(retApi?.resultado?.data?.token);
         } catch (e) {
            throw e;
         }
      },
      [definirUsuario, patchAuthorization]
   );


   return (
      <SessaoAtualContext.Provider
         value={{
            usuario,
            admin,
            carregando: loading,
            sessaoExpirada,
            logar,
            deslogar,
            definirUsuario,
            converterToken,
            selecionarCredenciais,
            tipoUsuario
         }}
      >
         {children}
      </SessaoAtualContext.Provider>
   );

}