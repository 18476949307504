import { Button, Grid, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-editar-validacao-ifood-styles';
import { VoltarIcon } from '../../icons';
import { useCallback } from 'react';
import { useToastSaurus } from '../../../../service/app/use-cases';
import { usePatchValidacaoCredencial } from '../../../../data/api/gestao/credenciais-dados/patch-validacao-credencial';
import { AplicacaoModel } from '../../../../model/api/aplicacao/aplicacao-model';
import { CredenciaisDadosModel } from '../../../../model/api/credenciais/credenciais-dados-model';
import { LojaModel } from '../../../../model/api/loja/loja-model';
import { useGetRegistroCredencial } from '../../../../data/api/gestao/credenciais-dados/get-registro-credencial';
import { CredenciaisModelIfood } from '../../../../model/api/credenciais/credenciais-model-ifood';
import { usePutEditarCredenciais } from '../../../../data/api/gestao/credenciais-dados/put-editar-credenciais';

interface DialogEditarValidacaoIfoodProps {
  openned: boolean;
  closeModal: () => void;
  setAberto: (valor: number) => void;
  credencialDados: CredenciaisDadosModel;
  aplicacao: AplicacaoModel;
  loja: LojaModel;
}

//Primeiro dialog

export const DialogEditarValidacaoIfood = ({
  openned,
  closeModal,
  setAberto,
  aplicacao,
  loja,
  credencialDados
}: DialogEditarValidacaoIfoodProps) => {
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const { patchValidacaoCredencial, carregando: carregandoPatch } = usePatchValidacaoCredencial();
  const { getRegistroCredencial, carregando: carregandoGet } = useGetRegistroCredencial();
  const { putEditarCredenciais, carregando: carregandoPut } = usePutEditarCredenciais();

  const carregando = carregandoPatch || carregandoGet || carregandoPut;

  const sincronizacaoWrapper = useCallback(async (model: CredenciaisDadosModel, patch: CredenciaisModelIfood) => {
    try {
      model = { ...model, credenciar: model.credenciar, codigoAplicacao: aplicacao.codigoAplicacao, id: model.id, tpSituacao: credencialDados.tpSituacao }

      const resPut = await putEditarCredenciais(loja.id, model.id, model);
      if (resPut.erro) throw resPut.erro;

      const res = await getRegistroCredencial(loja.id);
      if (res.erro) throw res.erro;

      patch = { ...patch, credenciais: model.credenciais, id: model.id, tpSituacao: model.tpSituacao, codigoAplicacao: aplicacao.codigoAplicacao }

      const pacthIfood = await patchValidacaoCredencial(loja.id, model.id, patch);

      if (pacthIfood.resultado?.data.urlRedirectLogin) {
        window.open(pacthIfood.resultado?.data.urlRedirectLogin, '_blank')
        setAberto(2)
      }

    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [aplicacao.codigoAplicacao, credencialDados.tpSituacao, putEditarCredenciais, loja.id, getRegistroCredencial, patchValidacaoCredencial, setAberto, showToast])

  return (
    <>
      <DialogSaurus
        aberto={openned}
        titulo='Autenticação Ifood'
        tamanho="sm">
        <Typography className={classes.title}>Você deseja iniciar o processo de autorização do Ifood? Iremos redirecionar para iniciar a autenticação!</Typography>
        <div>
          <Grid container spacing={2} style={{
            justifyContent: 'end',
            alignItems: 'center'
          }}>
            <Grid item xs={12} md={3}>
              <Button
                variant='outlined'
                color='primary'
                onClick={closeModal}
                fullWidth
              >
                <VoltarIcon tipo='BUTTON' />
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                disabled={carregando}
                variant="contained"
                color="primary"
                fullWidth
                onClick={async () => await sincronizacaoWrapper(credencialDados, new CredenciaisModelIfood())}
              >
                Abrir autenticação
              </Button>
            </Grid>
          </Grid>
        </div>
      </DialogSaurus>
    </>
  );
};
