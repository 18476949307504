import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({

  drawerBackground: {
    opacity: 0,
    pointerEvents: "none",
    transition: "0.1s 0.5s ease-in-out opacity",
    background: "rgba(0,0,0,0.4)",
    position: "fixed",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    zIndex: 2,
  },

  drawerBackgroundAberto: {
    opacity: 1,
    pointerEvents: 'all',
    transition: "0.1s 0.1s ease-in-out opacity",
  },

  drawer: {
    transition: "0.3s 0s ease-in-out left,0.3s 0s ease-in-out width,0.3s 0s ease-in-out min-width",
    overflow: "hidden",
    background: theme.palette.grey[50],
    width: 350,
    minWidth: 350,
    position: "relative",
    height: "100%",
    zIndex: 2,
    "& *::-webkit-scrollbar": {
      width: "8px",
    },
    /* Track */
    "& *::-webkit-scrollbar-track": {
      background: theme.palette.grey[50],
    },

    /* Handle */
    "& *::-webkit-scrollbar-thumb": {
      background: theme.palette.grey[400],
    },

    /* Handle on hover */
    "& *::-webkit-scrollbar-thumb:hover": {
      background: theme.palette.grey[500],
    },
    boxShadow: '10px 0px 14px rgba(0, 0, 0, 0.08)'
  },

  drawerSlow: {
    transition: 'none',
  },

  drawerReduzido: {
    width: 75,
    minWidth: 75,
  },

  drawerMobile: {
    position: "fixed",
    top: "0px",
  },

  openDrawerMobile: {
    boxShadow: "5px 0px 45px rgba(0,0,0,0.2)",
  },

  openDrawer: {
    boxShadow: "5px 0px 10px rgba(0,0,0,0.1)",
  },

  drawerContainer: {
    background: theme.palette.grey[50],
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  drawerTop: {
    flex: "0 0 1",
    height: "auto",
    background: "#FFF",
  },

  drawerOverflowMenu: {
    background: theme.palette.grey[50],
    flex: "1 1",
    display: 'flex',
    flexDirection: 'column',
    overflowY: "auto",
    overflowX: "hidden",

  },
  drawerBottom: {
    flex: "0 0 1",
    height: "auto",
    background: theme.palette.common.white,
  },
  divider: {
    width: "100%",
    background: theme.palette.grey[300],
    height: "1px!important"
  },
  drawerVersion: {
    flex: '1',
    display: 'flex',

  },

  publicPageHeaderVersionControl: {
    flex: '3',
    pointerEvents: "none",
    opacity: 0.6,
    display: 'flex',
    flexDirection: 'row-reverse',
    "& *": {
      marginRight: 3,
      color: '#000',

    },
    "& b": {
      fontSize: 10,
      fontWeight: "normal",
    },
    "& i": {
      fontSize: 10,
      fontWeight: "bold",
    }
  }
}));
