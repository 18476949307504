import { useCallback } from "react";
import { useMenuPrincipal } from "../../../../../../service/app/providers/menu-principal-provider";
import { useThemeQueries } from "../../../../../../theme";
import { ButtonPrivateHeader } from "../../../../../components/controles/buttons";
import { PrivatePageHeader } from "../../../../../components/headers/header-private-page"
import { DesfazerIcon, FiltroIcon, MenuIcon } from "../../../../../components/icons";
import { SincronizacaoListSearchProps } from "../sincronizacao-list/sincronizacao-list-search-props";
import { PesquisaSincronizacao } from "../sincronizacao-pesquisa/pesquisa-sincronizacao";

export interface SincronizacaoHeaderProps {
   openPesquisa: boolean;
   setOpenPesquisa: (state: boolean) => any;
   setSearchProps: (props: SincronizacaoListSearchProps) => any;
}

export const SincronizacaoHeader = ({
   openPesquisa,
   setOpenPesquisa,
   setSearchProps
}: SincronizacaoHeaderProps) => {

   const { abrirMenu } = useMenuPrincipal()
   const { isMobile } = useThemeQueries()


   const leftArea = useCallback(
      () => (
         isMobile ?
            <ButtonPrivateHeader
               icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
               tooltip="Menu" onClick={abrirMenu}></ButtonPrivateHeader>
            :
            null
      ),
      [isMobile, abrirMenu]
   );

   const rightArea = useCallback(
      () => (
         <ButtonPrivateHeader
            icon={
               openPesquisa ?
                  <DesfazerIcon tipo="PRIVATE_HEADER"></DesfazerIcon>
                  :
                  <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>}
            tooltip={!openPesquisa ? "Pesquisar" : "Voltar"}
            onClick={() => setOpenPesquisa(!openPesquisa)}
         ></ButtonPrivateHeader>
      ),
      [setOpenPesquisa, openPesquisa]
   );


   const closePesquisaWrapper = useCallback(() => {
      setOpenPesquisa(false);
   }, [setOpenPesquisa]);

   const onPesquisar = useCallback(
      (props: SincronizacaoListSearchProps) => {
         setSearchProps(props);
      },
      [setSearchProps]
   );

   const pesquisa = <PesquisaSincronizacao
      isOpened={openPesquisa!}
      onCloseSearch={closePesquisaWrapper}
      onPesquisar={onPesquisar} />;

   return (
      <>
         <PrivatePageHeader
            title="Dados de Sincronização"
            leftArea={leftArea()}
            bottomArea={pesquisa}
            rightArea={rightArea()}
         />
      </>
   )
}