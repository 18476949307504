import { Box, Grid, Typography } from "@material-ui/core";
import { PedidosHeader } from "./components/pedidos-header/pedidos-header";
import { useState } from "react";
import { PedidosListSearchProps } from "./components/pedidos-list/pedidos-list-search-props";
import PedidosList from "./components/pedidos-list/pedidos-list";
import { isEmpty } from "lodash";
import { usePedidosStyles } from "./pedidos-page-styles";


export const PedidosPage = () => {
  const classes = usePedidosStyles();
  const [openPesquisa, setOpenPesquisa] = useState(true);

  const [filtro, setFiltro] = useState<PedidosListSearchProps>({
    lojaId: '',
    lojaNome: '',
    status: -1,
    aplicacao: '',
    codPedido: '',
    dInicial: '',
    dFinal: '',
  });


  return (
    <>
      <Grid className={classes.root}>
        <Grid className={classes.header}>
          <PedidosHeader
            openPesquisa={openPesquisa}
            setSearchProps={setFiltro}
            setOpenPesquisa={setOpenPesquisa}
          />
        </Grid>
        {isEmpty(filtro.lojaId) && (
          <Box width="100%" p={1}>
            <div className={classes.ultimoAcesso}>
              <div className={classes.horario}>
                <Typography className={classes.ultimoAcessoText}>
                  Selecione uma loja acima para trazer os pedidos caso tenha!
                </Typography>
              </div>
            </div>
          </Box>
        )}
        <Grid className={classes.list}>
          <PedidosList searchProps={filtro} />
        </Grid>
      </Grid>
    </>
  )

}

