import { makeStyles } from "@material-ui/core";

export const useCardDashboardStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        padding: '14px 25px 25px 25px',
        background: theme.palette.background.paper,
        marginRight: '25px',
        borderRadius: '15px'
    },
    rootAppProd: {
        width: '100%',
        display: 'flex',
        padding: '16px 16px 16px 16px',
        flexWrap: 'wrap',
        background: 'rgba(246, 246, 246, 0.4)',
        boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.05)',
        overflowY: 'auto',
        borderRadius: '15px',
        alignContent: 'center',
        alignItems: 'center'
    },
    rootAppHomo: {
        width: '100%',
        display: 'flex',
        padding: '16px 16px 16px 16px',
        flexWrap: 'wrap',
        background: 'rgba(246, 246, 246, 0.4)',
        boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '15px',
        alignContent: 'center',
        alignItems: 'center',
    },
    rootCNPJ: {
        width: '470px',
        height: '550px',
        padding: '14px 25px 25px 25px',
        background: '#fff',
        marginLeft: '25px',
        borderRadius: '15px',
        marginBottom: '24px'
    },
    rootApp: {
        margin: '8px',
        maxHeight: '435px',
        overflowY: 'auto',
    },
    documentText: {
        fontSize: '13px',
        fontWeight: 700,
        color: '#6B6B6B',
        margin: '10px 10px'
    },
    divNTemAcesso: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    nomeLoja: {
        fontSize: '22px',
        fontWeight: 600,
        color: '#696969'
    },
    labelStatusPai: {
        flex: 1,
        display: 'flex',
        marginTop: '8px',
        justifyContent: 'space-between'
    },
    button: {
        margin: '0',
        width: '150px',
    },
    buttonCNPJ: {
        margin: '15px 0 15px 0px',
    },
    divider: {
        width: '100%',
        height: '2px',
        marginBottom: 15,
        backgroundColor: '#5333ED'
    },
    listContainer: {
        display: 'flex',
        alignItems: 'center',
        "& .card": {
            margin: theme.spacing(1),
        },
    },
}));
