import { Grid, Typography } from "@material-ui/core";
import { CardHistoricoProps } from "./card-historico-props";
import { formatDataHora } from "../../../../utils/format-data-hora";
import { useCardHistoricoStyles } from "./card-historico-styles";
import { alterarCorStatusSituacao } from "../../../../utils/alterar-cor-situacao";
import { alterarNomeSituacao } from "../../../../utils/alterar-nome-situacao";

export const CardHistorico = ({
  model
}: CardHistoricoProps) => {
  const classes = useCardHistoricoStyles()

  return (
    <>
      {model?.aplicacaoSincronizacao?.historico.map((history) => {
        return (
          <>
            <div className={classes.containerHistorico}>
              <div className={classes.rootApp}>
                <div className={classes.containerStatus}>
                  <Typography className={classes.titleDate}>Data: <strong className={classes.date}>{formatDataHora(new Date(history.data))}</strong></Typography>
                  <div className={classes.situacao} style={{ background: alterarCorStatusSituacao(model?.tpStatusSincronizacao) }}>
                    <Typography className={classes.titleStts}>{alterarNomeSituacao(model?.tpStatusSincronizacao)}</Typography>
                  </div>
                </div>
                <div className={classes.divider} />
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Typography className={classes.titleHistorico}>Usuário</Typography>
                    <Typography className={classes.titleHistoricoResult}>{history.usuario}</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography className={classes.titleHistorico}>Produtos Processados</Typography>
                    <Typography className={classes.titleHistoricoResult}>{history.produtosProcessados}</Typography>
                  </Grid>
                  <Grid item md={7} xs={12}>
                    <Typography className={classes.titleHistorico}>Mensagem Recebida</Typography>
                    <Typography className={classes.titleHistoricoResult}>{history.mensagemRecebida}</Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
          </>
        )
      })}
    </>
  )
}

