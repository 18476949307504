import { ClientePedidoModel } from "../../../model/api/pedidos/cliente-model";
import { ItemsModel } from "./itens-model";
import { EnumTpStatusPedido } from "../../../model/enums/enum-tp-status-pedido";
import { TpPedido } from "../../enums/enum-tp-pedido";
import { DadosIntegracao } from "./dados-integracao-model";
import { EnderecoModel } from "./endereco-model";
import { LojaPedidoModel } from "./loja-pedido-model";
import { PagamentosModel } from "./pegamento-model";

export class PedidosModel {
    constructor(
        public id: string = '',
        public idLoja: string = '',
        public codigoReferecia: string = '',
        public codigoPedido: string = '',
        public informacaoAdicional: string = '',
        public codigoLoja: string = '',
        public documentoLoja: string = '',
        public cupom: string = '',
        public dataCriacao: Date = new Date(),
        public agendamento: boolean = true,
        public dataAgendamentoInicial: Date = new Date(),
        public dataAgendamentoFinal: Date = new Date(),
        public entrega: boolean = true,
        public descontoEcommerce: number = 0,
        public tpPedidoDescricao: string = '',
        public tpPedido: TpPedido = TpPedido.PedidoParaEntregar,
        public cpfNaNota: boolean = true,
        public tpStatusPedido: EnumTpStatusPedido = EnumTpStatusPedido.Invalido,
        public tpStatusPedidoDescricao: string = '',
        public valor: number = 0,
        public valorTotal: number = 0,
        public valorConveniencia: Date = new Date(),
        public valorEntrega: Date = new Date(),
        public valorDesconto: Date = new Date(),
        public valorTroco: boolean = true,
        public valorRetirada: number = 0,
        public pessoaAutorizadaARetirar: string = '',
        public quantidadeItens: TpPedido = TpPedido.PedidoParaEntregar,
        public enderecoEntrega: EnderecoModel = new EnderecoModel(),
        public loja: LojaPedidoModel = new LojaPedidoModel(),
        public cliente: ClientePedidoModel = new ClientePedidoModel(),
        public items: ItemsModel[] = new Array<ItemsModel>(),
        public pagamentos: PagamentosModel[] = new Array<PagamentosModel>(),
        public codigoAplicacao: number = 0,
        public taxaServico: number = 0,
        public dUpdate: Date = new Date(),
        public dInsert: Date = new Date(),
        public dadosIntegracao: DadosIntegracao = new DadosIntegracao()
    ) { }
}
