import { Box, Button, Card, Grid, Typography } from '@material-ui/core'
import { AplicacaoModel } from '../../../../model/api/aplicacao/aplicacao-model'
import { useCadastros } from '../../../../service/app/providers/cadastros-provider'
import { useCardDashboardStyles } from './card-dashboard-admin-styles';
import { AddIcon } from '../../icons/add-icon';
import { useHistory } from 'react-router-dom';
import { CardAplicacaoDashboardAdminHomo } from '../card-aplicacao-dashboard-admin-homologacao/card-aplicacao-dashboard-admin-homologacao';
import { useEffect } from 'react';
import { CardAplicacaoDashboardAdminProd } from '../card-aplicacao-dashboard-admin-prod/card-aplicacao-dashboard-admin-prod';
import { CircularLoading } from '../../utils';

type CardDashboardAdminProps = {
    appProd: AplicacaoModel[];
    appHomo: AplicacaoModel[];
    carregando: boolean;
}

export const CardDashboardAdmin = ({ appProd, appHomo, carregando }: CardDashboardAdminProps) => {
    const classes = useCardDashboardStyles();
    const { abrirAdcAplicacaoProd, abrirAdcAplicacaoHomologacao, abrirEdicaoAplicacaoHomologacao, abrirEdicaoAplicacaoProd, EdicaoAplicacaoHomologacaoState } = useCadastros();

    const history = useHistory();
    const rota = history.location.pathname;

    useEffect(() => {
        if (rota.split('/')[2]) {
            const foundAppHomo = appHomo.find((app) => app.id === rota.split('/')[2])
            const foundAppProd = appProd.find((app) => app.id === rota.split('/')[2])

            if (foundAppHomo || foundAppProd) {
                if (EdicaoAplicacaoHomologacaoState.aberto) {
                    abrirEdicaoAplicacaoHomologacao(foundAppHomo?.id!, foundAppHomo!, '', false)
                } else if (appProd) {
                    abrirEdicaoAplicacaoProd(foundAppProd?.id!, foundAppProd!, '', false)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appHomo, rota])

    return (
        <>
            <Grid item xs={12} md={6} className={classes.listContainer}>
                <Card>
                    {carregando && <CircularLoading tipo="FULLSIZED" />}
                    <Box p={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ padding: 0 }}>
                                <div className={classes.labelStatusPai} >
                                    <Typography className={classes.nomeLoja}>
                                        Aplicações Produção
                                    </Typography>
                                </div>
                            </Grid>
                            <div className={classes.divider} />
                            <div className={classes.rootApp}>
                                <div className={classes.rootAppProd}>
                                    {appProd.map((app) => {
                                        return (
                                            <CardAplicacaoDashboardAdminProd
                                                app={app}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', margin: '16px 0px 0 0' }}>
                                <Button
                                    variant='contained'
                                    startIcon={<AddIcon tipo='BUTTON' />}
                                    className={classes.button}
                                    color='primary'
                                    size="medium"
                                    onClick={() => { abrirAdcAplicacaoProd(rota, true) }}
                                >
                                    Adicionar
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={12} md={6} className={classes.listContainer}>
                <Card>
                    {carregando && <CircularLoading tipo="FULLSIZED" />}
                    <Box p={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ padding: 0 }}>
                                <div className={classes.labelStatusPai} >
                                    <Typography className={classes.nomeLoja}>
                                        Aplicações Homologação
                                    </Typography>
                                </div>
                            </Grid>
                            <div className={classes.divider} />
                            <div className={classes.rootApp}>
                                <div className={classes.rootAppHomo}>
                                    {appHomo.map((app) => {
                                        return (
                                            <CardAplicacaoDashboardAdminHomo
                                                app={app}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', margin: '16px 0px 0 0' }}>
                                <Button
                                    variant='contained'
                                    startIcon={<AddIcon tipo='BUTTON' />}
                                    className={classes.button}
                                    color='primary'
                                    size="medium"
                                    onClick={() => { abrirAdcAplicacaoHomologacao(rota, true) }}
                                >
                                    Adicionar
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Grid>
        </>
    )
}