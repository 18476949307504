import { Button, Grid, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { useCadastros } from "../../../../../../service/app/providers/cadastros-provider";
import { useToastSaurus } from "../../../../../../service/app/use-cases";
import { ButtonModalHeader } from "../../../../controles/buttons/button-modal-header";
import { DefaultFormRefs } from "../../../../form/utils";
import { SalvarNovoIcon, VoltarIcon } from "../../../../icons";
import { ModalHeader } from "../../../modal-header";
import { CircularLoading } from "../../../..";
import { AplicacaoModel } from "../../../../../../model/api/aplicacao/aplicacao-model";
import semImagem from '../../../../../../assets/img/sem-imagem.jpg'
import { FormCadastroAplicacao } from "../../../../form/master/cadastro-aplicacao/form-cadastro-aplicacao";
import { LojaModel } from "../../../../../../model/api/loja/loja-model";
import { useModalStyles } from "../../../../utils/modals/modal-styles";
import { CredenciaisDadosModel } from "../../../../../../model/api/credenciais/credenciais-dados-model";
import { usePostRegistroCredencial } from "../../../../../../data/api/gestao/credenciais-dados/post-registro-credencial";
import { useCadastroAppStyles } from "../../../../form/master/cadastro-aplicacao/form-cadastro-aplicacao-style";
import { DialogValidacaoIfood } from "../../../../dialog/dialog-validacao-ifood/dialog-validacao-ifood";
import { DialogValidacaoIfoodCod } from "../../../../dialog/dialog-validacao-ifood-codigo/dialog-validacao-ifood-codigo";
import { EnumAplicacao } from "../../../../../../model/enums/enum-aplicacao";
import { EnumTpSituacaoIntegracao } from "../../../../../../model/enums/enum-tp-situacao-integracao";

interface NovaAplicacaoProps {
  aplicacao: AplicacaoModel;
  loja: LojaModel;
  setCadastro: (status: boolean) => void;
}
//Essa é a segunda para registrar uma credencial nova

export const CadastroNovaCredencial = ({ aplicacao, loja, setCadastro }: NovaAplicacaoProps) => {
  const classes = useModalStyles();
  const classe = useCadastroAppStyles();
  const { showToast } = useToastSaurus();
  const { postRegistroCredencial, carregando } = usePostRegistroCredencial();
  const { fecharCadastroNovaCredencial } = useCadastros();
  const [ativos, setAtivos] = useState<boolean>(true);
  const [auto, setAuto] = useState<boolean>(true);
  const [aberto, setAberto] = useState(0)

  const [credencialDados, setCredencialDados] = useState<CredenciaisDadosModel>(new CredenciaisDadosModel());

  const cadCadastroAplicacaoFormRef =
    useRef<DefaultFormRefs<CredenciaisDadosModel>>(null);

  useEffect(() => {
    cadCadastroAplicacaoFormRef.current?.fillForm(
      new CredenciaisDadosModel()
    )
  }, [])

  const validarCredencial = useCallback(
    async (model: CredenciaisDadosModel) => {
      model = { ...model, credenciar: new CredenciaisDadosModel().credenciar, ativo: ativos, confirmacaoAutomaticaAtivada: auto, tpSituacao: ativos ? EnumTpSituacaoIntegracao.IntegracaoHabilitada : EnumTpSituacaoIntegracao.IntegracaoDesabilitada }
      const res = await postRegistroCredencial(loja.id, model);
      if (res.erro) {
        throw res.erro;
      }
      showToast('success', "Nova Credencial Adicionada!")
    }, [ativos, auto, loja.id, postRegistroCredencial, showToast]);

  const handleSubmit = useCallback(
    async (model: CredenciaisDadosModel) => {
      try {
        if (model.codigoAplicacao === EnumAplicacao.Ifood) {
          fecharCadastroNovaCredencial();
        } else {
          await validarCredencial(model);
          fecharCadastroNovaCredencial();
        }
      } catch (e: any) {
        showToast('error', e.message);
        cadCadastroAplicacaoFormRef.current?.resetForm()
      }
    }, [fecharCadastroNovaCredencial, showToast, validarCredencial]);

  const onCloseClick = useCallback(() => {
    fecharCadastroNovaCredencial()
  }, [fecharCadastroNovaCredencial]);

  return (
    <>
      <DialogValidacaoIfood
        openned={aberto === 1}
        closeModal={() => setAberto(0)}
        setAberto={setAberto}
        credencialDados={credencialDados}
        aplicacao={aplicacao}
        loja={loja}
        setCredencialDados={setCredencialDados}
      />
      <DialogValidacaoIfoodCod
        openned={aberto === 2}
        closeModal={() => setAberto(0)}
        ativo={ativos}
        credencialDados={credencialDados}
        aplicacao={aplicacao}
        loja={loja}
      />
      <div className={classes.root}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <ModalHeader
          title={"Cadastro de Credencial"}
          leftArea={
            <ButtonModalHeader
              tooltip="Voltar"
              icon={<VoltarIcon style={{
                fill: '#5333ED',
                width: '50px',
                height: '50px',
                marginRight: '0',
                marginBottom: '0'
              }} />}
              onClick={onCloseClick}
            />
          }
        />
        <div className={classes.content}>
          <div className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}>
            <Grid style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '40px',
            }} item xs={12}>
              <img
                style={{
                  maxWidth: '150px'
                }}
                src={aplicacao?.uriImagem}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = semImagem;
                }} alt='' />
            </Grid>
            <FormCadastroAplicacao
              auto={auto}
              setAberto={setAberto}
              ativo={ativos}
              lojaId={loja.id}
              credencialDados={credencialDados}
              loja={loja}
              ref={cadCadastroAplicacaoFormRef}
              showLoading={false}
              loading={carregando}
              onSubmit={handleSubmit}
              model={aplicacao}
            />
            <Grid item xs={12}
              style={{
                background: 'rgba(246, 246, 246, 0.4)',
                borderRadius: '10px',
                padding: '0 23px 14px 23px',
                marginBottom: 8
              }}>
              <Typography
                style={{
                  fontSize: '16px',
                  color: '#858585',
                  fontWeight: 600,
                  padding: '9px 0 14px 0'
                }}
              >
                Confirmação automática dos pedidos recebidos
              </Typography>
              <Button
                disabled={auto}
                onClick={() => setAuto(true)}
                style={{
                  color: !auto ? '#A9A9A9' : "#5333ED",
                  boxShadow: auto ? '0px 0px 4px rgba(0, 0, 0, 0.1)' : 'none',
                  borderRadius: 5,
                  margin: '0 10px 0 0',
                  fontSize: '14px',
                  borderColor: !auto ? '#F0F0F0' : "#5333ED"
                }}
                variant='outlined'>
                Ativo
              </Button>
              <Button
                disabled={!auto}
                onClick={() => setAuto(false)}
                style={{
                  color: auto ? '#A9A9A9' : "#5333ED",
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 4px',
                  borderRadius: 7,
                  fontSize: '14px',
                  borderColor: auto ? '#F0F0F0' : "#5333ED"
                }}
                variant='outlined'
              >
                Inativo
              </Button>
            </Grid>
            <Grid item xs={12}
              style={{
                background: 'rgba(246, 246, 246, 0.4)',
                borderRadius: '10px',
                padding: '0 23px'
              }}>
              <Typography
                style={{
                  margin: '0 0 8px 0',
                  fontSize: '16px',
                  color: '#858585',
                  fontWeight: 600
                }}
              >
                Situação da Credencial
              </Typography>
              <Button
                disabled={ativos}
                onClick={() => setAtivos(true)}
                style={{
                  color: !ativos ? '#A9A9A9' : "#5333ED",
                  boxShadow: ativos ? '0px 0px 4px rgba(0, 0, 0, 0.1)' : 'none',
                  borderRadius: 5,
                  marginRight: 8,
                  fontSize: '14px',
                  borderColor: !ativos ? '#F0F0F0' : "#5333ED"
                }}
                variant='outlined'>
                Ativa
              </Button>
              <Button
                disabled={!ativos}
                onClick={() => setAtivos(false)}
                style={{
                  color: ativos ? '#A9A9A9' : "#5333ED",
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 4px',
                  borderRadius: 7,
                  fontSize: '14px',
                  borderColor: ativos ? '#F0F0F0' : "#5333ED"
                }}
                variant='outlined'>
                Inativa
              </Button>
              <Typography className={classe.textFunc}>
                Esta função ativa ou desativa a credencial sem excluir para o usuário quando for necessário.
              </Typography>
            </Grid>
          </div>
        </div>

        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Button
                disabled={carregando}
                onClick={() => cadCadastroAplicacaoFormRef?.current?.submitForm()}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarNovoIcon tipo="BUTTON_PRIMARY" />
                Salvar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}