import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ExpiredTokenRoute } from './components/expired-token-route';
import { useMemo } from 'react';
import { SessaoAtualProvider } from '../service/app/providers';
import { LoginPage } from '../views/pages/public/login/login-page'
import { LogoutPage } from '../views/pages/public/logout/logout-page'
import { RecoilRoot } from 'recoil';
import { GlobalStatesProvider } from '../service/app/providers/global-states-provider/global-states-provider';
import { ValidationRoute } from './components/validation-route';
import RouterSwitch from './components/router-main/router-switch';

export const RouterApp = () => {
  const switchApp = useMemo(() => (
    <Switch>
      <Route exact path="/logout" component={LogoutPage} />
      <Route exact path="/login" component={LoginPage} />
      <ValidationRoute>
        <ExpiredTokenRoute>
          <RouterSwitch/>
        </ExpiredTokenRoute>
      </ValidationRoute>
    </Switch>
  ), []);

  return (
    <SessaoAtualProvider>
      <GlobalStatesProvider>
        <RecoilRoot>
          <BrowserRouter>
            {switchApp}
          </BrowserRouter>
        </RecoilRoot>
      </GlobalStatesProvider>
    </SessaoAtualProvider>
  );
};
