import { KeyValueModel } from "../../model";
import { EnumTpStatusPedido } from "../../model/enums/enum-tp-status-pedido";

export const SituacaoPedidoMock: KeyValueModel[] = [
   new KeyValueModel(EnumTpStatusPedido.Invalido, 'Invalido'),
   new KeyValueModel(EnumTpStatusPedido.AguardandoConfirmação, 'Aguardando Confirmação'),
   new KeyValueModel(EnumTpStatusPedido.Confirmado, 'Pedido Confirmado'),
   new KeyValueModel(EnumTpStatusPedido.EmPreparo, 'Em Produção'),
   new KeyValueModel(EnumTpStatusPedido.Enviado, 'Pedido Enviado'),
   new KeyValueModel(EnumTpStatusPedido.FinalizadoOuEntregue, 'Entregue ou Finalizado'),
   new KeyValueModel(EnumTpStatusPedido.Cancelado, 'Cancelado'),
   new KeyValueModel(EnumTpStatusPedido.Todos, 'Todos')
];