import { makeStyles } from "@material-ui/core";

export const useDetalhesPedidoStyles = makeStyles((theme) => ({
    root: {
        flex: '1',
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
    },

    table: {
        minWidth: 650,
        fontSize: '22px'
    },

    titleSituacao: {
        color: '#fff',
        fontWeight: 700,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1, 2)
    },
    gridSituacao: {
        width: '100%',
        right: 8,
        margin: '0',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    content: {
        flex: "1",
        display: "flex",
        flexDirection: "column"
    },
    acoes: {
        flex: "0 0 auto",
        padding: theme.spacing(1),
        boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.1)'
    },

    botaoVoltar: {
        border: '1px solid #5333ED',
        borderRadius: 5,
        color: '#5333ED',
    },
    botaoExcluir: {
        border: '1px solid #F44336',
        color: '#F44336',
    },
    botaoSalvar: {
        width: '100%',
    },
    titleImportacao: {
        color: '#7D7D7D',
        fontSize: '13px',
        fontWeight: 700,
        textAlign: 'center',
    },

    titleImportacaoCancelado: {
        color: '#fff',
        fontSize: '15px',
        fontWeight: 700,
        textAlign: 'center',
    },


    iconImportacao: {
        position: 'relative',
        left: '21px',
        right: '0px',
        top: '29px',
        marginTop: '-30px',
    },

    iconImportacaoCacelado: {
        top: '25px',
        left: '28px',
        right: '0',
        position: 'relative',
        marginTop: '-25px'
    },

    containerImportacao: {
        margin: '0 30px 17px 30px',
        background: '#C7FFCD',
        padding: '15px',
        borderRadius: '10px'
    },
    containerPedidoCandelado: {
        margin: '0 30px 17px 30px',
        background: '#F44336',
        padding: '15px',
        borderRadius: '10px'
    },


    contentAbas: {
        paddingTop: theme.spacing(1),
        boxShadow: '0px 5px 5px rgba(0,0,0,0.05) inset',
        marginLeft: theme.spacing(2) * -1,
        marginRight: theme.spacing(2) * -1,
    },
    contentForms: {
        flex: '1 1 auto',
        height: '100%',
        maxHeight: '500px',
        padding: '16px',
        overflowX: 'hidden',
        overflowY: 'auto',
        "& * > form": {
            visibility: "visible",
        },
        "&  .tabs-class": {
            visibility: "visible",
        },
    },
    contentFormsLoading: {
        "& * > form": {
            visibility: "hidden",
        },

        "&  .tabs-class": {
            visibility: "hidden",
        },
    },
    documentText: {
        fontSize: '12px',
        fontWeight: 700,
        color: '#6B6B6B',
        fontStyle: 'normal'
    },
    nomeLoja: {
        fontSize: '22px',
        fontWeight: 700,
        color: '#545454'
    },
    ultimoAcesso: {
        background: '#EFECFF',
        padding: '6px 10px',
        borderRadius: '5px'
    },
    ultimoAcessoText: {
        color: '#5333ED',
        fontWeight: 700,
        textAlign: 'center'
    },

    labelStatusPai: {
        flex: 1,
        display: 'flex',
        marginTop: '8px',
        justifyContent: 'space-between'
    },

    teste: {
        display: 'flex',
        alignItems: 'center'
    },

    button: {
        border: '1px solid #FFFFFF',
        boxShadow: '0px 0px 4.6875px rgba(0, 0, 0, 0.25)',
        borderRadius: '8px'
    },

    labelDiv: {
        width: '100px',
        borderRadius: '7px',
        padding: '6px 0',
    },

    labelStatusOn: {
        color: '#FFFFFF',
        width: '100px',
        margin: '0 10px',
        padding: '3px 0',
        fontSize: '15px',
        background: '#10C822',
        textAlign: 'center',
        fontWeight: 700,
        borderRadius: '7px'

    },

    labelStatusOff: {
        color: '#FFFFFF',
        width: '100px',
        margin: '0 10px',
        padding: '3px 0',
        fontSize: '15px',
        background: '#EC1C2E',
        textAlign: 'center',
        fontWeight: 700,
        borderRadius: '7px'
    },

    divProdutos: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(3)
    },

    containerProdutoPedido: {
        backgroundColor: '#EEEEEE',
        borderRadius: 8,
        display: 'inline-flex',
        justifyContent: 'space-between',
        margin: theme.spacing(2, 1),
    },

    titleProduto: {
        color: '#808080',
        fonrSize: '22px',
        fontWeight: 700,
    },
    titleProdutoPedido: {
        color: '#808080',
        fontSize: '15px',
        fontWeight: 600,
        padding: 6,
        // overflow: 'hidden',
        // whiteSpace: 'nowrap',
        // textOverflow: 'ellipsis'
    },
    divPedidosTitle: {
    },

    titleSubInfo: {
        color: '#545353',
        fontWeight: 700,
        fontSize: '15px'
    },
    titleSubInfoD: {
        color: '#545353',
        fontWeight: 700,
        fontSize: '12px'
    },

    titleNumeroPedido: {
        color: '#8B8989',
        fontSize: '18px',
        fontWeight: 700
    },

    titleDetalhe: {
        color: '#8B8989',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '28px'
    },

    titleDetalheInfo: {
        color: '#8B8989',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '16px'
    },

    statusFilho: {
        display: 'flex',
        justifyContent: 'space-between'
    },

    statusLoja: {
        border: '1px solid #BDBDBD',
        height: '36px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '6px',
        justifyContent: 'space-around',
        padding: '0 28px'
    },
    labelStatusLoja: {
        color: '#545454',
        padding: '0 27px 0 0',
        fontSize: '16px',
        fontWeight: 700
    },
    aplicacao: {
        // overflow: 'auto'
    },

    divBallVerde: {
        background: '#10C822',
        borderRadius: '50%',
        width: '14px',
        height: '14px',
        margin: 10
    },
    divBallAmarelo: {
        background: '#FFBC00',
        borderRadius: '50%',
        width: '14px',
        height: '14px',
        margin: 10
    },
    divBallError: {
        background: '#F44336',
        borderRadius: '50%',
        width: '14px',
        height: '14px',
        margin: 10
    },

    dividerTop: {
        width: '100%',
        height: '2px!important',
        background: '#e0e0e0',
        marginBottom: 30,
    },

    dividerBottom: {
        width: '100%',
        height: '2px!important',
        background: '#e0e0e0',
        marginBottom: 10
    },
}));
