import { Button, Collapse, Grid, Typography } from "@material-ui/core";
import { isEmpty } from "lodash";
import { AplicacaoModel } from "../../../../model/api/aplicacao/aplicacao-model";
import { LojaModel } from "../../../../model/api/loja/loja-model";
import { useLojasStyles } from "../../../pages/private/lojas/lojas-styles";
import { RegistroIcon } from "../../icons/registro";
import { useCadastros } from "../../../../service/app/providers/cadastros-provider";
import { CredenciaisDadosModel } from "../../../../model/api/credenciais/credenciais-dados-model";
import { formatDataHora } from "../../../../utils/format-data-hora";
import { CircularLoading } from "../../utils";
import { useGetLojas } from "../../../../data/api/gestao/lojas/get-lojas";
import { CardAplicacao } from "../card-aplicacao/card-aplicacao";
import { CredenciaisModel } from "../../../../model/api/credenciais/credenciais-model";
import { HorarioIcon } from "../../icons/horario-icon";

interface CardExpandidoProps {
  loja: LojaModel;
  aplicacoes: AplicacaoModel[];
  open: boolean;
  editando: boolean
}

export const CardExpandido = ({ loja, aplicacoes, open, editando }: CardExpandidoProps) => {
  const classes = useLojasStyles()
  const { abrirCadastroNovaCredencial } = useCadastros()
  const { carregando } = useGetLojas();

  return (
    <>
      {carregando ? (
        <CircularLoading tipo="FULLSIZED" />
      ) : null}
      <div >
        <Collapse in={open}>
          <div className={classes.statusFilho} >
            <div className={classes.ultimoAcesso}>
              <div className={classes.horario}>
                <HorarioIcon tipo='BUTTON' />
                <Typography className={classes.ultimoAcessoText}>
                  {!isEmpty(loja?.servicos?.servicoCliente?.dataUltimaExecucaoServico) ?
                    <> Ultimo acesso realizado ás {formatDataHora(new Date(loja?.servicos.servicoCliente.dataUltimaExecucaoServico))}</>
                    : 'Não há último acesso'}
                </Typography>
              </div>
            </div>
            <div className={classes.statusLoja}>
              <div className={classes.divBallVerde} />
              <Typography className={classes.labelStatusLoja}>
                Funcionando
              </Typography>
              <div className={classes.divBallAmarelo} />
              <Typography className={classes.labelStatusLoja}>
                Desativado
              </Typography>
              <div className={classes.divBallError} />
              <Typography className={classes.labelStatusLoja}>
                Erro
              </Typography>
            </div>
          </div>
          <Grid container className={classes.containerAplicacoes}>
            {(loja?.credenciais.length > 0) ?
              <>
                <Grid item style={{ width: '100%' }}>
                  <CardAplicacao loja={loja} aplicacoes={aplicacoes} editando={editando} />
                </Grid>
              </>
              :
              <Grid item md={12} xs={12} className={classes.containerSemCredencial}>
                <Typography className={classes.titleSemCredencial}>Essa loja não tem nenhuma credencial registrada</Typography>
              </Grid>
            }
          </Grid>
          <Grid container>
            <Grid item md={12} className={classes.textRegistro}>
              <Button
                onClick={() => {
                  abrirCadastroNovaCredencial(loja, new CredenciaisDadosModel(), Array<CredenciaisModel>(), '', false)
                }}
                variant="contained"
                color="primary"
                style={{ height: 48 }}
                size='large'
              >
                <RegistroIcon />
                Registrar nova credencial
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </div>
    </>
  )
}