
import { isEmpty } from "lodash";
import { SincronizacaoByIdModel } from "../../../../model/api/sincronizacao/sincronizacao-by-id-model";
import { useThemeQueries } from "../../../../theme";
import { DefaultModal } from "../../default-modal";
import { EnvioCarga } from "./components/envio-carga/envio-carga";

type Props = {
  model: SincronizacaoByIdModel;
  lojaId: string
  openned: boolean;
}

export const ModelEnvioCarga = ({ openned, lojaId, model }: Props) => {
  const { isMobile } = useThemeQueries()

  return (
    <DefaultModal
      minWidth={isMobile ? '0' : "600px"}
      open={openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {!isEmpty(lojaId && model.id) ?
        <EnvioCarga
          lojaId={lojaId}
          model={model}
        />
        :
        ''
      }
    </DefaultModal>
  );
};
