import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useGetAplicacaoHomologacao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();
    const apiUrlHomo = 'https://sauruspedidosapi-staging.azurewebsites.net';

    const getAplicacaoHomo = useCallback(
        () => {
            return invocarApi({
                url: `/api/v2/aplicacao`,
                method: "GET",
                baseURL: apiUrlHomo,
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getAplicacaoHomo,
    };
}
