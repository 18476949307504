import { useCallback } from "react";

export enum PedidosStorageKeys {
  Token,
  TermosDeUso,
  Plano,
  EmpresaAtual,
  EmpresaAnterior,
  PerfisDoUsuario,
  UsuariosConectados,
  ConfiguracoesMenu,
  IsEmpresaFiscal,
  UltimoLogin,
  Produto,
  Pedido,
  imagemAplicacao,
  ContratoId,
  aplicacoes,
  Ambiente
}

export function useSaurusPedidosStorage() {

  const getRegistro = useCallback(
    (key: PedidosStorageKeys, session: boolean = true) =>
      JSON.parse(
        (session
          ? sessionStorage.getItem(PedidosStorageKeys[key].toString())
          : localStorage.getItem(PedidosStorageKeys[key].toString())) || "{}"
      ),
    []
  );

  const exists = useCallback((key: PedidosStorageKeys, session: boolean = true) => {
    if (session) return Boolean(sessionStorage.getItem(PedidosStorageKeys[key].toString()));
    else return Boolean(localStorage.getItem(PedidosStorageKeys[key].toString()));
  }, []);

  const setRegistro = useCallback((key: PedidosStorageKeys, obj: any, session: boolean = true) => {
    if (session) {
      sessionStorage.setItem(PedidosStorageKeys[key].toString(), JSON.stringify(obj));
    } else {
      localStorage.setItem(PedidosStorageKeys[key].toString(), JSON.stringify(obj));
    }
  }, []);

  const delRegistro = useCallback((key: PedidosStorageKeys, session: boolean = true) => {
    if (session) {
      sessionStorage.removeItem(PedidosStorageKeys[key].toString());
    } else {
      localStorage.removeItem(PedidosStorageKeys[key].toString());
    }
  }, []);

  return {
    getRegistro,
    setRegistro,
    delRegistro,
    exists,
  };
}
