import { useSessaoAtual } from "../../../service/app/providers"
import RouterMainAdmin from "./router-main-admin"
import RouterMain from "./router-main"
import { EnumTipoUsuario } from "../../../model/enums/enum-tipo-usuario"

const RouterSwitch = () => {
   const { tipoUsuario } = useSessaoAtual();
   
   return (
      <>
         {(tipoUsuario() === EnumTipoUsuario.EnumTipoAdm) ?
            <RouterMainAdmin /> :
            <RouterMain />
         }
      </>
   )
}

export default RouterSwitch