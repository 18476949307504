import { Grid } from "@material-ui/core"
import { useCallback, useEffect, useState } from "react";
import { PedidosStorageKeys, useSaurusPedidosStorage, useToastSaurus } from "../../../../../../service/app/use-cases";
import { AplicacaoModel } from "../../../../../../model/api/aplicacao/aplicacao-model";
import { useGetAplicacao } from "../../../../../../data/api/gestao/aplicacao/get-aplicacao";
import { useGetAplicacaoHomologacao } from "../../../../../../data/api/gestao/aplicacao-homologacao/get-aplicacao";
import { useStyles } from "./dashboard-admin-list-styles";
import { CardDashboardAdmin } from "../../../../../components/cards/card-dashboard-admin/card-dashboard-admin";
import { useCadastros } from "../../../../../../service/app/providers/cadastros-provider";

export const DashboardAdminList = () => {
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const { getAplicacao, carregando: carregandoGetProd } = useGetAplicacao();
  const { getAplicacaoHomo, carregando: carregandoGetHomo } = useGetAplicacaoHomologacao();
  const [aplicacoesProd, setAplicacoesProd] = useState<AplicacaoModel[]>([]);
  const [aplicacoesHomo, setAplicacoesHomo] = useState<AplicacaoModel[]>([]);
  const { setRegistro } = useSaurusPedidosStorage();
  const { EdicaoAplicacaoHomologacaoState, AdcAplicacaoHomologacaoState, EdicaoAplicacaoProdState, AdcAplicacaoProdState } = useCadastros();
  const carregando = carregandoGetProd || carregandoGetHomo;

  const aplicacaoProdWrapper = useCallback(async () => {
    try {
      const res = await getAplicacao()
      if (res.erro) throw res.erro
      const imagemAplicacao = res.resultado?.data.map((app: any) => ({
        imagem: app.uriImagem,
        codAplicacao: app.codigoAplicacao
      }))
      setRegistro(PedidosStorageKeys.imagemAplicacao, imagemAplicacao);
      setRegistro(PedidosStorageKeys.aplicacoes, aplicacoesProd);
      setAplicacoesProd(res.resultado?.data);
    } catch (e: any) {
      showToast('error', e.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAplicacao, setRegistro, showToast])

  const aplicacaoHomoWrapper = useCallback(async () => {
    try {
      const res = await getAplicacaoHomo();
      if (res.erro) throw res.erro
      const imagemAplicacao = JSON.stringify(res.resultado?.data.map((app: any) => ({
        imagem: app.uriImagem,
        codAplicacao: app.codigoAplicacao
      })))
      setRegistro(PedidosStorageKeys.imagemAplicacao, imagemAplicacao);
      setRegistro(PedidosStorageKeys.aplicacoes, aplicacoesHomo);
      setAplicacoesHomo(res.resultado?.data);
    } catch (e: any) {
      showToast('error', e.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAplicacaoHomo, setRegistro, showToast])

  useEffect(() => {
    if (!EdicaoAplicacaoHomologacaoState.aberto && !AdcAplicacaoHomologacaoState.aberto && !EdicaoAplicacaoProdState.aberto && !AdcAplicacaoProdState.aberto) {
      aplicacaoHomoWrapper()
      aplicacaoProdWrapper()
    }
  }, [EdicaoAplicacaoHomologacaoState, AdcAplicacaoHomologacaoState.aberto, EdicaoAplicacaoProdState.aberto, AdcAplicacaoProdState.aberto, aplicacaoHomoWrapper, aplicacaoProdWrapper])

  return (
    <>
      <div className={classes.defaultContainer}>
        <Grid container spacing={2}>
          <CardDashboardAdmin
            appProd={aplicacoesProd}
            appHomo={aplicacoesHomo}
            carregando={carregando}
          />
        </Grid>
      </div>
    </>
  );
};
