import { makeStyles } from "@material-ui/core";

export const useLojasStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "14px 25px 0 25px",
  },

  rootApp: {
    width: "100%",
    marginTop: theme.spacing(2),
    display: "flex",
    padding: "8px 10px 0 10px",
    position: "relative",
    background: "rgba(246, 246, 246, 0.4)",
    boxShadow: "inset 0px 0px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: "15px",
    overflowY: 'auto',
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "400px",
    },
  },
  containerAplicacoes: {
    overflowX: "auto",
    overflowY: "hidden"
  },

  containerSemCredencial: {
    height: "360px",
    marginTop: "25px",
    borderRadius: '15px',
    display: "flex",
    overflowY: "auto",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(246, 246, 246, 0.4)",
    boxShadow: "inset 0px 0px 4px rgba(0, 0, 0, 0.05)",
  },

  titleSemCredencial: {
    color: "#808080",
    fontSize: "20px",
    fontWeight: 700,
    textAlign: "center",
    margin: "0 0 14px 0",
  },

  documentText: {
    fontSize: "12px",
    fontWeight: 700,
    color: "#6B6B6B",
    fontStyle: "normal",
  },
  nomeLoja: {
    fontSize: "22px",
    fontWeight: 700,
    color: "#545454",
  },
  horario: {
    display: 'flex',
    alignItems: 'center'
  },
  ultimoAcesso: {
    display: "flex",
    padding: theme.spacing(1, 6),
    background: "#EFECFF",
    alignItems: "center",
    borderRadius: theme.shape.borderRadius,
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: "8px",
    },
  },
  ultimoAcessoText: {
    color: "#5333ED",
    ...theme.typography.body1,
    fontWeight: 700,
    textAlign: "center",
  },
  textRegistro: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "24px 0 24px 0",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      width: '100%',
      '& button': {
        width: '100%',
      }
    },
  },
  labelStatusPai: {
    flex: 1,
    display: "flex",
    marginTop: "8px",
    justifyContent: "space-between",
  },

  teste: {
    display: "flex",
    alignItems: "center",
  },

  button: {
    border: "1px solid #FFFFFF",
    boxShadow: '2px 2px 0 rgba(0, 0, 0, 0.15)',
    borderRadius: "8px",
  },

  labelDiv: {
    width: "100px",
    borderRadius: "7px",
    padding: "6px 0",
  },

  labelStatusOn: {
    color: "#FFFFFF",
    width: "100px",
    margin: "0 10px",
    padding: "3px 0",
    fontSize: "15px",
    background: "#10C822",
    textAlign: "center",
    fontWeight: 700,
    borderRadius: "7px"
  },

  labelStatusOff: {
    color: "#FFFFFF",
    width: "100px",
    margin: "0 10px",
    padding: "3px 0",
    fontSize: "15px",
    background: "#EC1C2E",
    textAlign: "center",
    fontWeight: 700,
    borderRadius: "7px",
    [theme.breakpoints.down("md")]: {
      width: "103px",
      height: "26px",
      margin: "0 12px 0 25px",
      display: "flex",
      padding: "9px 0",
      alignItems: "center",
      fontWeight: "700",
      borderRadius: "5px",
      justifyContent: "center",
    },
  },

  statusFilho: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
  },

  statusLoja: {
    border: "1px solid #BDBDBD",
    height: "36px",
    display: "flex",
    padding: theme.spacing(0, 1),
    textAlign: "center",
    alignItems: "center",
    borderRadius: "6px",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      border: "1px solid #BDBDBD",
      height: "100%",
      display: "flex",
    },
  },
  labelStatusLoja: {
    color: "#545454",
    ...theme.typography.body1,
    fontWeight: 700,
  },
  divBallVerde: {
    background: "#10C822",
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    margin: 8,
    [theme.breakpoints.down("xs")]: {
      width: "10px",
      height: "10px",
      margin: 8,
    },
  },
  divBallAmarelo: {
    background: "#FFBC00",
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    margin: 8,
    [theme.breakpoints.down("xs")]: {
      width: "10px",
      height: "10px",
      margin: 8,
    },
  },
  divBallError: {
    background: "#F44336",
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    margin: 8,
    [theme.breakpoints.down("xs")]: {
      width: "10px",
      height: "10px",
      margin: 8,
    },
  },
  botaoAdicionarLoja: {
    right: "30px",
    bottom: "15px",
    position: "fixed",
    background: "rgb(83, 51, 237)",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 1.80889px 5.42667px",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#321f8b",
    },
  },
  divider: {
    width: "100%",
    height: "1px",
    marginTop: "10px",
    marginBottom: "10px",
    backgroundColor: "#E6E6E6",
  },
  descricaoAplicacao: {
    color: "#808080",
    fontSize: "12px",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  aplicacaoImg: {
    display: "flex",
    flexWrap: "nowrap",
    "& img": {
      maxHeight: "115px",
      maxWidth: "90px",
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexWrap: "nowrap",
      "& img": {
        maxWidth: '76px',
        maxHeight: '90px',
        borderRadius: '10px'
      },
    },
  },
  img: {
    width: '100px',
    borderRadius: '10px',
    boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.05)'
  },

  aplicacaoStyle: {
    margin: theme.spacing(1),
    width: "145px",
    height: "136px",
    padding: "10px 10px",
    position: "relative",
    background: "#FFFFFF",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "0px 10px 10px 10px",
  },

  containerAplicacaoImg: {
    display: "flex",
    padding: "6px 52px",
    background: "#EFECFF",
    alignItems: "center",
    borderRadius: "5px",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
      padding: "8px",
      marginLeft: "8px",
      marginBottom: "8px",
    },
  },

  dividerColor: {
    borderRadius: "1px 10px 0px 0px",
    background: "black",
    width: "90px",
    height: 8,
    position: "absolute",
    top: "-8px",
    left: 0,
  },
}));
