import { createStyles, makeStyles } from '@material-ui/styles';
import Pedido from '../icons/icon-img/pedido.png'

export const PedidoIcon = () => {
  const useStyles = makeStyles(() => 
    createStyles({
      img: {
        width: '25px',
        height: '25px'
      },
    })
  )
  const classes = useStyles();
  return (
    <>
      <img
      className={classes.img}
      src={Pedido} 
      alt="" 
      />
    </>
  );
};
