import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const TesteIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M25.023 6.00511C28.9148 6.00511 32.8045 6.00511 36.6963 6.00511C39.3557 6.00511 42.0151 5.98833 44.6744 6.0156C45.9089 6.02818 46.8693 6.60299 47.5221 7.65819C47.8611 8.20363 47.9929 8.81619 47.9929 9.45183C47.995 17.1404 47.995 24.8289 47.995 32.5174C47.995 35.725 48.0097 38.9325 47.9887 42.1401C47.9783 43.5331 47.3276 44.5757 46.068 45.1945C45.6181 45.4169 45.1327 45.5281 44.6159 45.5281C31.501 45.5218 18.3882 45.526 5.27327 45.5197C3.79188 45.5197 2.46533 44.4162 2.15357 43.0925C2.06359 42.7149 2.00501 42.3205 2.00501 41.9345C1.99873 31.1098 1.99873 20.285 2.00292 9.46232C2.00292 7.67707 3.1579 6.31559 4.98243 6.01979C5.13517 5.99462 5.29419 6.00301 5.45112 6.00301C11.519 6.00301 17.5868 6.00511 23.6546 6.00721C24.1087 6.00511 24.5648 6.00511 25.023 6.00511ZM45.8043 14.8055C31.9194 14.8055 18.0597 14.8055 4.18943 14.8055C4.18943 14.8705 4.18943 14.923 4.18943 14.9733C4.18943 24.0716 4.18943 33.1719 4.18943 42.2702C4.18943 42.8303 4.55978 43.2352 5.11844 43.2876C5.31303 43.3065 5.50761 43.317 5.7043 43.317C10.0585 43.317 14.4106 43.317 18.7648 43.317C21.2212 43.317 23.6797 43.3149 26.1362 43.3149C28.9839 43.3149 31.8316 43.3149 34.6814 43.3149C37.0143 43.3149 39.3473 43.317 41.6803 43.3149C42.7495 43.3128 43.8166 43.3023 44.8858 43.2897C45.185 43.2855 45.4193 43.1471 45.5993 42.9058C45.7499 42.7023 45.8064 42.4799 45.8064 42.2282C45.8043 33.1551 45.8043 24.082 45.8043 15.009C45.8043 14.946 45.8043 14.881 45.8043 14.8055ZM45.7855 12.586C45.7939 12.5629 45.8022 12.5503 45.8022 12.5377C45.8043 11.4133 45.8085 10.291 45.8022 9.16653C45.8001 8.75745 45.48 8.36096 45.0762 8.27915C44.7979 8.22251 44.5071 8.20782 44.2225 8.20782C31.4215 8.20363 18.6183 8.20572 5.81728 8.20572C5.59549 8.20572 5.3737 8.21831 5.15401 8.23929C4.54304 8.29803 4.19361 8.68822 4.19152 9.30708C4.18943 10.3392 4.19152 11.3713 4.19152 12.4035C4.19152 12.4601 4.19152 12.5167 4.19152 12.5839C18.0639 12.586 31.9194 12.586 45.7855 12.586Z" />
                <path d="M33.6038 25.1582C33.9469 24.6443 34.267 24.1618 34.5872 23.6772C35.4346 22.3996 36.2757 21.1199 37.1336 19.8486C37.2758 19.6368 37.4683 19.4438 37.6776 19.2969C37.96 19.0976 38.2865 19.1249 38.5878 19.2759C39.1883 19.578 39.4059 20.2304 39.0585 20.8052C38.6735 21.4409 38.2446 22.0492 37.8345 22.6702C36.8239 24.1995 35.8154 25.7288 34.7985 27.2539C34.6771 27.4365 34.539 27.6169 34.3717 27.7574C34.0327 28.0427 33.5535 28.0427 33.1916 27.7889C33.0911 27.7176 32.997 27.6337 32.9112 27.5476C31.8797 26.5134 30.8502 25.4771 29.8187 24.4408C29.7643 24.3862 29.7078 24.3296 29.6576 24.2687C29.3082 23.8555 29.2872 23.3562 29.5948 22.9177C29.8794 22.515 30.3794 22.3639 30.8628 22.5569C30.9862 22.6052 31.1013 22.6912 31.1975 22.7856C31.8943 23.4737 32.5848 24.166 33.2774 24.8561C33.3778 24.9526 33.4803 25.0407 33.6038 25.1582Z" />
                <path d="M33.5745 36.1697C33.9302 35.6368 34.2524 35.1564 34.5726 34.6739C35.42 33.3985 36.2632 32.1188 37.1148 30.8454C37.232 30.6692 37.3763 30.4993 37.5437 30.3692C37.9036 30.0881 38.2949 30.086 38.6924 30.3126C39.1611 30.5811 39.3808 31.1895 39.1193 31.6594C38.8263 32.1817 38.4832 32.6747 38.1547 33.1761C37.0269 34.8837 35.8928 36.5893 34.763 38.2948C34.6102 38.5255 34.4617 38.7542 34.1813 38.8486C33.7105 39.008 33.2983 38.9178 32.9468 38.5612C32.0931 37.699 31.2394 36.8389 30.3837 35.9767C30.1682 35.7585 29.9443 35.5487 29.7329 35.3264C29.1722 34.7348 29.281 33.9796 29.9819 33.5642C30.3502 33.346 30.7749 33.388 31.1369 33.7047C31.4006 33.9376 31.6433 34.1935 31.8902 34.4453C32.4446 35.0096 32.9949 35.576 33.5745 36.1697Z" />
                <path d="M17.8755 24.6526C15.915 24.6526 13.9524 24.6547 11.9918 24.6526C11.3767 24.6526 11.0168 24.4219 10.8243 23.9184C10.6255 23.4023 10.9478 22.7185 11.475 22.5548C11.634 22.5066 11.8077 22.4772 11.973 22.4772C15.9025 22.473 19.8319 22.473 23.7613 22.4751C24.4351 22.4751 24.8138 22.7604 24.9707 23.3604C25.1151 23.91 24.6924 24.554 24.1275 24.6254C23.9225 24.6505 23.7111 24.6526 23.504 24.6526C21.6292 24.6547 19.7524 24.6526 17.8755 24.6526Z" />
                <path d="M17.8693 33.4425C19.8361 33.4425 21.805 33.4425 23.7718 33.4425C24.4455 33.4425 24.9624 33.881 24.9979 34.481C25.0335 35.0704 24.5334 35.6222 23.9413 35.6431C23.7593 35.6494 23.5772 35.6431 23.3931 35.6431C19.5683 35.6431 15.7434 35.6431 11.9186 35.6431C11.406 35.6431 10.9645 35.3264 10.8055 34.846C10.59 34.1978 11.1444 33.4488 11.8475 33.4488C13.854 33.4446 15.8606 33.4467 17.8693 33.4467C17.8693 33.4467 17.8693 33.4446 17.8693 33.4425Z" />
                <path d="M8.59384 10.3791C8.60639 9.79588 9.10856 9.30079 9.67558 9.31338C10.2845 9.32806 10.7657 9.82315 10.7511 10.4231C10.7385 11.0189 10.2552 11.4972 9.67349 11.4867C9.06671 11.4741 8.58128 10.9769 8.59384 10.3791Z" />
                <path d="M12.9522 11.4783C12.358 11.4804 11.8705 10.9937 11.8684 10.3958C11.8663 9.83363 12.3894 9.30498 12.9418 9.31127C13.5255 9.31757 14.0319 9.81895 14.0382 10.3979C14.0444 10.979 13.5465 11.4762 12.9522 11.4783Z" />
                <path d="M5.2858 10.3959C5.2858 9.85462 5.82563 9.31338 6.36545 9.31129C6.94085 9.30919 7.48068 9.84203 7.4765 10.4063C7.47231 10.9665 6.95968 11.4783 6.39475 11.4867C5.81935 11.4972 5.2858 10.9686 5.2858 10.3959Z" />

            </DefaultIcon>
        </>
    );
};
